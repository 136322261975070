import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyIconModule } from './norby-icon.module';
import { NorbyFormcontrolLabelComponent } from '../components/molecules/norby-formcontrol-label';
import { TooltipModule } from './tooltip.module';

@NgModule({
  declarations: [NorbyFormcontrolLabelComponent],
  imports: [CommonModule, NorbyIconModule, TooltipModule],
  exports: [NorbyFormcontrolLabelComponent]
})
export class NorbyFormcontrolLabelModule {}
