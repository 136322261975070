export enum EventTypeEnum {
  CONFERENCE_SUMMIT = 'conferenceSummit',
  FAIR_TRADE_SHOW = 'fairTradeShow',
  NETWORKING_EVENT_WORKSHOP = 'networkingEventWorkshop',
  WEBINARS_PANEL_DISCUSSION = 'webinarsPanelDiscussion',
  LAUNCH_PARTY = 'launchParty',
  POP_UP = 'popUp',
  OTHER = 'other'
}

export type EventType = `${EventTypeEnum}`;

export const EVENT_TYPES: { [k in EventType]: string } = {
  conferenceSummit: 'Conference or Summit',
  fairTradeShow: 'Fair or Trade Show',
  networkingEventWorkshop: 'Networking Event or Workshop',
  webinarsPanelDiscussion: 'Webinar or Panel Discussion',
  launchParty: 'Launch Party',
  popUp: 'Pop-Up',
  other: 'Other'
};
