import { GoogleCalendar } from '../GoogleCalendar';
import { ICalendar } from '../ICalendar';

export class Calendars {
  static getGoogleCalendarUrl(options) {
    return new GoogleCalendar(options).render();
  }

  static downloadIcs(options) {
    return new ICalendar(options).download();
  }
}
