import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyRadioButtonComponent } from '../components/molecules/norby-radio-button';
import { NorbyIconModule } from './norby-icon.module';
import { CheckboxModule } from './checkbox.module';

@NgModule({
  declarations: [NorbyRadioButtonComponent],
  imports: [CommonModule, NorbyIconModule, CheckboxModule],
  exports: [NorbyRadioButtonComponent]
})
export class NorbyRadioButtonModule {}
