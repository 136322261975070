import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyCollapsibleGroupComponent } from '../components/molecules/norby-collapsible-group/norby-collapsible-group.component';

@NgModule({
  declarations: [NorbyCollapsibleGroupComponent],
  imports: [CommonModule],
  exports: [NorbyCollapsibleGroupComponent]
})
export class NorbyCollapsibleGroupModule {}
