import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';

import { AuthService } from '../../../services';
import {
  formatPhoneNumber,
  ContentRegisterable,
  ISlug,
  IUserContent,
  isPhoneNumberValid,
  VALID_COUNTRY_CODES,
  ROOT_HOST_TOKEN
} from 'models';
import { CaptchaComponent } from 'uikit';

export type ContentRegistrationPhoneNumberPayload = {
  phoneNumber: string;
  hasOptedIntoMarketing: boolean;
};

@Component({
  selector: 'lib-content-registration-phone-number',
  templateUrl: './content-registration-phone-number.component.html',
  styleUrls: ['./content-registration-phone-number.component.less']
})
export class ContentRegistrationPhoneNumberComponent
  extends CaptchaComponent
  implements OnInit, OnChanges
{
  @Input() userContent: IUserContent;
  @Input() content: ContentRegisterable;
  @Input() slug: ISlug;
  @Output() handleNext =
    new EventEmitter<ContentRegistrationPhoneNumberPayload>();

  // Placeholder shown in phone entry box
  // Country dependent
  phonePlaceholder = '(123) 456-7890';
  phoneNumber; // The user's cached phone number
  isLoading: boolean;
  formGroup: UntypedFormGroup;
  loginCopy: string;

  readonly COUNTRY_CODES = VALID_COUNTRY_CODES;

  get hasTerms(): boolean {
    return !!this.slug?.general?.termsUrl?.length;
  }

  get hasPrivacy(): boolean {
    return !!this.slug?.general?.privacyUrl?.length;
  }

  get hasWebsite(): boolean {
    return !!this.slug?.accountInfo?.links?.website?.length;
  }

  get shouldShowTermsOptIn(): boolean {
    return this.hasTerms || this.hasPrivacy;
  }

  get termsOptInLabel(): string {
    const terms = this.hasTerms
      ? `<a href="${this.slug.general.termsUrl}" class="underline" target="_blank">Terms of Service</a>`
      : '';
    const joiner = this.hasTerms && this.hasPrivacy ? ' and ' : '';
    const privacy = this.hasPrivacy
      ? `<a href="${this.slug.general.privacyUrl}" class="underline" target="_blank">Privacy Policy</a>`
      : '';
    const info = this.hasWebsite
      ? `. For more information, see <a href="${this.slug.accountInfo.links.website}" class="underline" target="_blank">here</a>`
      : '';

    return [
      `I agree to ${this.slug.accountInfo?.title}'s `,
      terms,
      joiner,
      privacy,
      info,
      '.'
    ].join('');
  }

  get shouldShowMarketingOptIn(): boolean {
    return !!this.content;
  }

  get needsMarketingCheckBox(): boolean {
    return (
      this.shouldShowMarketingOptIn &&
      (this.content.isEvent || this.content.isPaid)
    );
  }

  get marketingOptInLabel(): string {
    const action = this.needsMarketingCheckBox
      ? 'checking this box'
      : 'proceeding';

    const sender = this.slug?.accountInfo?.title
      ? ` from ${this.slug.accountInfo.title}`
      : '';

    let label = `By ${action} you agree to receive automated
    marketing messages${sender} at the contact information provided.`;

    if (this.content?.isPaid) {
      label += ` Consent is not required as a condition of purchase.`;
    }

    label += ` Message and data rates may apply. Message frequency varies. Reply HELP for help and STOP to cancel. See terms <a href="${this.endUserMobileTermsUrl}" class="underline" target="_blank">here</a>.`;

    return label;
  }

  get endUserMobileTermsUrl(): string {
    return `https://${this.slug?.slug}.${this._host}/legal/mobile-terms`;
  }

  constructor(
    @Inject(ROOT_HOST_TOKEN) private _host: string,
    private _formBuilder: UntypedFormBuilder,
    private _auth: AuthService
  ) {
    super();
  }

  preserveOrder = (a, b): number => 0;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup
      ?.get('hasAgreedToCustomerTerms')
      ?.setValidators(this.shouldShowTermsOptIn ? Validators.requiredTrue : []);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._initCopy();

    this.formGroup = this._formBuilder.group(
      {
        phoneNumber: ['', Validators.required],
        countryCode: ['US', Validators.required],
        hasAgreedToCustomerTerms: [
          false,
          this.shouldShowTermsOptIn ? Validators.requiredTrue : []
        ],
        hasOptedIntoMarketing: [this.needsMarketingCheckBox ? false : true]
      },
      { validators: this._isPhoneNumberValid }
    );
  }

  private _initCopy() {
    this.loginCopy =
      'Please enter your phone number to verify or create your account.';

    if (this.content?.isEvent) {
      this.loginCopy = `${this.loginCopy} You'll only receive messages about this event.`;
    }
  }

  handleTermsLabelClick(event: PointerEvent) {
    if (event.target instanceof HTMLElement && event.target.tagName === 'A') {
      event.preventDefault();
      event.stopPropagation();
      const url = event.target.getAttribute('href');
      if (url?.length) {
        window.open(url, '_blank');
      }
    }
  }

  handleCountryCodeChanged(code) {
    const country = VALID_COUNTRY_CODES[code];
    this.phonePlaceholder = country?.placeholder;
  }

  async submitPhone() {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading = true;
    const { countryCode, phoneNumber, hasOptedIntoMarketing } =
      this.formGroup.value;
    this.phoneNumber = formatPhoneNumber(phoneNumber, countryCode);
    const captchaResponse = await this.executeCaptcha();

    // Initiate the signin flow with transformed number
    try {
      await this._auth.initiatePhoneSignInFlow(
        this.phoneNumber,
        captchaResponse
      );
      this.handleNext.emit({
        phoneNumber: this.phoneNumber,
        hasOptedIntoMarketing
      });
    } catch (e) {
      this.phoneNumber = null;
    }

    this.isLoading = false;
  }

  // Form group validator to ensure phone number checks out
  private _isPhoneNumberValid(
    group: UntypedFormGroup
  ): ValidationErrors | null {
    const { phoneNumber, countryCode } = group.value;
    return isPhoneNumberValid(phoneNumber, countryCode);
  }
}
