export const AVAILABLE_MASKS = [
  {
    name: 'Circle',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Fcircle.svg?alt=media&token=1c8d5058-6f0f-4083-959e-01343b9dca6d'
  },
  {
    name: 'Burst',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Fburst.svg?alt=media&token=04696d8f-7839-4964-8466-31e1801c0d12'
  },
  {
    name: 'Cloud',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Fcloud.svg?alt=media&token=427a7648-ffe5-40d6-b1cd-9fe83f8834c0'
  },
  {
    name: 'Heart',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Fheart.svg?alt=media&token=41a606d1-7a82-4046-a346-1ff41c5acd0f'
  },
  {
    name: 'Orbit',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Forbit.svg?alt=media&token=277f3dcc-3536-4cc7-8cd1-2e36fd37b704'
  },
  {
    name: 'Star 1',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Fstar1.svg?alt=media&token=e1ad2c63-bfcf-4dd9-adad-89282a68d3d0'
  },
  {
    name: 'Star 2',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Fstar2.svg?alt=media&token=b7cc5ca8-28b8-49eb-a096-fa045269e887'
  },
  {
    name: 'Star 3',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Fstar3.svg?alt=media&token=0110702b-6b00-4c00-9a32-a7152a0d3f84'
  },
  {
    name: 'Blob',
    url: 'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/shapes%2Fblob.svg?alt=media&token=bb470e1c-81b8-40df-a2f9-b3558d484153'
  }
];
