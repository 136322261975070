import { InjectionToken, TemplateRef } from '@angular/core';
import { MessageType } from '../../constants';

export class MessageData {
  type: MessageType;
  text?: string;
  template?: TemplateRef<any>;
  templateContext?: {};
}

export interface MessageAnimation {
  slideOut: number;
  slideIn: number;
}

export interface MessageConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: MessageAnimation;
}

export const defaultMessageConfig: MessageConfig = {
  position: {
    top: 20,
    right: 20
  },
  animation: {
    slideOut: 300,
    slideIn: 300
  }
};

export const MESSAGE_CONFIG_TOKEN = new InjectionToken('message-config');
