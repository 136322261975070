<div
  class="flex justify-center user-theme-button"
  [ngClass]="{ 'h-11 user-theme-button-adjacent-input': !!adjacentInput }"
>
  <button
    (click)="handleClick.emit()"
    [disabled]="!!disabled || !!loading"
    [type]="formSubmit === 'button' ? 'button' : 'submit'"
    [name]="name"
    [attr.aria-label]="ariaLabel"
    class="disabled flex justify-center items-center px-1 py-2 font-medium text-base focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
    [ngClass]="{
      'bg-neutral-900 user-theme-button-primary': type === 'primary',
      'bg-white hover:bg-neutral-300 border-neutral-300 user-theme-button-secondary':
        type === 'secondary',
      border: type === 'primary' || type === 'secondary',
      'bg-transparent hover:bg-white user-theme-button-card-ghost':
        type === 'ghost' && !!isIconOnly && !!isOnCard,
      'bg-transparent user-theme-button-ghost':
        type === 'ghost' && !!isIconOnly && !isOnCard,
      'flex-1': !!block,
      'rounded-l-none rounded-r-sm h-12': !!adjacentInput
    }"
  >
    <div
      *ngIf="loading"
      class="animate-spin flex justify-center items-center max-h-4 user-theme-button-text"
      [ngClass]="{ 'text-white': type === 'primary' }"
    >
      <root-icon *ngIf="loading" name="loader"></root-icon>
    </div>

    <div
      *ngIf="text"
      class="text-black leading-none form-check-label mx-2 pb-px user-theme-button-text"
      [ngClass]="{
        'text-lg': size === 'large',
        'text-xs': size === 'small',
        'text-white': type === 'primary',
        'text-black': type === 'secondary',
        'text-neutral-700': !!disabled || !!loading,
        'max-w-[100px] overflow-hidden whitespace-nowrap overflow-ellipsis':
          adjacentInput
      }"
    >
      {{ text }}
    </div>
    <div
      class="flex items-center max-h-4 user-theme-button-text"
      [ngClass]="{ 'text-white': type === 'primary' }"
    >
      <ng-content></ng-content>
    </div>
  </button>
</div>
