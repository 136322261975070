import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyFabComponent } from '../components/molecules/norby-fab/norby-fab.component';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyFabComponent],
  imports: [CommonModule, NorbyIconModule],
  exports: [NorbyFabComponent]
})
export class NorbyFabModule {}
