export enum RegisterableLayoutTypeEnum {
  CLASSIC_LEFT = 'classicLeft',
  CLASSIC_RIGHT = 'classicRight',
  CLASSIC_VERTICAL = 'classicVertical',
  LARGE_IMAGE = 'largeImage',
  FULL_WIDTH_IMAGE = 'fullWidthImage',
  INFO_PANEL = 'infoPanel',
  LARGE_HEADER = 'largeHeader'
}

export enum RegisterableLayoutDisplayEnum {
  CLASSIC_LEFT = 'Card style - image on left',
  CLASSIC_RIGHT = 'Card style - image on right',
  CLASSIC_VERTICAL = 'Card style - vertical',
  LARGE_IMAGE = 'Large image',
  FULL_WIDTH_IMAGE = 'Full width image',
  INFO_PANEL = 'Info panel',
  LARGE_HEADER = 'Large header'
}

export type RegisterableLayoutType = `${RegisterableLayoutTypeEnum}`;

export type RegisterableLayoutDisplayType = `${RegisterableLayoutDisplayEnum}`;

export class RegisterableLayout {
  imgPath: string;
  type: RegisterableLayoutType;
  display: RegisterableLayoutDisplayType;
}
