// TODO
export const verificationCodeErrorHandler = (e: any) => {
  switch (e.code) {
    case 'auth/invalid-verification-code':
      throw new Error('The verification code entered was invalid.');
    case 'auth/network-request-failed':
      throw new Error('Having trouble connecting right now. Try again later.');
    case 'auth/too-many-requests':
      throw new Error(
        "We've deteced unusual activity on this device and temporarily blocked further requests. Try again later."
      );
    case 'auth/user-disabled':
      throw new Error('Sorry, this account has been disabled.');
    case 'auth/web-storage-unsupported':
      throw new Error(
        "Sorry, your browser doesn't support logging in with phone number."
      );
    default:
      throw new Error('Sorry, there was an error. Try again later.');
  }
};
