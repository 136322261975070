<root-card [bodyStyle]="{ padding: '0px' }">
  <a
    [href]="href"
    target="_blank"
    rel="noreferrer"
    (click)="handleClick($event)"
    [class.cursor-pointer]="drop"
    class="group"
  >
    <lib-soft-image-view
      *ngIf="!drop || drop?.image?.url"
      [imageUrl]="drop?.image?.url"
      [image]="drop?.image"
      [imgixImage]="drop?.imgixImage"
      [alt]="title"
    >
    </lib-soft-image-view>
    <div class="p-4 [&>*]:mb-3 [&>*:last-child]:mb-0" *ngIf="!drop">
      <root-skeleton [title]="false" [paragraph]="{ rows: 2, width: 150 }">
      </root-skeleton>
    </div>
    <div class="p-4 [&>*]:mb-3 [&>*:last-child]:mb-0" *ngIf="drop">
      <div
        *ngIf="
          drop?.isFull ||
          (drop?.hasCapacity && drop?.rsvpRestrictions?.displayLimit) ||
          drop?.registrationCloseDate
        "
      >
        <root-tag
          *ngIf="drop?.isFull || drop?.isPastRegistrationDeadline"
          class="mr-3"
        >
          <root-icon size="small" name="lock"></root-icon>
          <span>Signups are closed</span>
        </root-tag>
        <root-tag
          *ngIf="
            drop?.registrationCloseDate &&
            !drop?.isPastRegistrationDeadline &&
            !drop?.isFull
          "
          class="mr-3"
        >
          <root-icon size="small" name="calendar"></root-icon>
          <span *ngIf="isCurrentDate(registrationCloseDate)">
            {{ dateTimePrompt }}
            {{ registrationCloseTime }}</span
          >
          <span
            *ngIf="!isCurrentDate(registrationCloseDate)"
            rootTooltip="{{ 'at ' + registrationCloseTime }}"
          >
            {{ dateTimePrompt }}
            {{ registrationCloseDate }}
          </span>
        </root-tag>
        <root-tag
          *ngIf="drop?.hasCapacity && drop?.rsvpRestrictions?.displayLimit"
        >
          <root-icon size="small" name="unlock"></root-icon>
          <span>{{ drop?.rsvpRestrictions?.remaining }} left</span>
        </root-tag>
      </div>
      <h3 class="group-hover:underline card-text-color">
        {{ title }}
      </h3>
      <h4 *ngIf="drop?.subtitle" class="subtitle card-text-color">
        {{ drop?.subtitle }}
      </h4>
      <div *ngIf="priceStr" class="price card-text-color">
        <root-alert [message]="priceStr"> </root-alert>
      </div>
    </div>
  </a>

  <div
    class="p-4 [&>*]:mb-3 [&>*:last-child]:mb-0"
    *ngIf="signupType === 'form'"
  >
    <lib-drop-form-prompts-view
      [isLoading]="isLoadingUserContent"
      [prompts]="drop?.prompts"
      [promptResponses]="userContent?.promptResponses"
      [isDisabled]="userContent?.rsvpEnabled"
      (onPromptResponseChanges)="handlePromptResponseChanges($event)"
    ></lib-drop-form-prompts-view>
  </div>

  <div class="p-0 px-4 pb-4">
    <div class="flex items-center" *ngIf="drop?.published">
      <div class="flex-auto mr-3">
        <lib-drop-main-button-view
          [drop]="drop"
          [userContent]="userContent"
          [promptResponses]="stagedPromptResponses"
          [isLoading]="isLoadingUserContent"
          [forceDisabled]="isDisabled || isFormInvalid"
        >
        </lib-drop-main-button-view>
      </div>
      <lib-event-drop-share-button-view
        [content]="drop"
        [userContent]="userContent"
        [isDisabled]="isDisabled"
      >
      </lib-event-drop-share-button-view>
    </div>
    <root-skeleton *ngIf="!drop?.published" [paragraph]="false"></root-skeleton>
  </div>
</root-card>
