import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'root-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.less'],
  host: {
    class:
      'block overflow-hidden border-solid border border-border-color-split rounded-none user-theme-card'
  }
})
export class CardComponent {
  @Input() cardCover?: TemplateRef<void>;
  @Input() bodyStyle: any | null = null;
  @Input() cardActions: Array<TemplateRef<void>> = [];

  constructor() {}
}
