import { ContentEvent } from '../types';

export const compareEvents = (
  ...[eventOne, eventTwo]: ContentEvent[]
): boolean => {
  if (!eventOne && !eventTwo) {
    return true;
  } else if (!eventOne || !eventTwo) {
    return false;
  }

  eventOne =
    eventOne instanceof ContentEvent
      ? eventOne
      : ContentEvent.fromObject(eventOne);
  eventTwo =
    eventTwo instanceof ContentEvent
      ? eventTwo
      : ContentEvent.fromObject(eventTwo);
  return (
    (eventOne as ContentEvent).fullHash === (eventTwo as ContentEvent).fullHash
  );
};
