import { Expose } from 'class-transformer';

export class IImage {
  @Expose()
  height?: number;

  @Expose()
  width?: number;

  @Expose()
  mimeType?: string;

  @Expose()
  palettes?: string[];

  @Expose()
  url!: string;

  @Expose()
  altText?: string; // This is only referenced in an unused component and doesn't belong here
}

export class IResponsiveImage {
  @Expose()
  thumbnail!: IImage;

  @Expose()
  small!: IImage;

  @Expose()
  medium!: IImage;

  @Expose()
  large!: IImage;
}
