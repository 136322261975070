import { inject } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { BaseComponent } from './base-component';

export abstract class ModalComponent extends BaseComponent {
  private _modalData = inject(NZ_MODAL_DATA);

  constructor() {
    super();

    if (this._modalData) {
      Object.keys(this._modalData).forEach((key) => {
        const val = this._modalData[key];
        if (val !== undefined) {
          this[key] = val;
        }
      });
    }
  }
}
