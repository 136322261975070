import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IImage } from 'models';

@Component({
  selector: 'norby-image-edit-dialog',
  templateUrl: './norby-image-edit-dialog.component.html',
  styleUrls: ['./norby-image-edit-dialog.component.less']
})
export class NorbyImageEditDialogComponent implements OnInit {
  @Input() image: IImage;
  @Input() isDisabled: boolean = false;
  @Input() isAltTextDisabled: boolean = false;

  @Output() onImageChange: EventEmitter<IImage> = new EventEmitter<IImage>();
  @Output() onImageDelete: EventEmitter<IImage> = new EventEmitter<IImage>();

  imageCopy: IImage;
  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.imageCopy = { ...this.image };
    this._initForm();
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      altText: [this.imageCopy?.altText]
    });
  }

  handleSaveButtonClick(): void {
    this.imageCopy.altText = this.formGroup.value.altText;
    this.onImageChange.emit(this.imageCopy);
  }

  handleDeleteButtonClick(): void {
    if (!this.isDisabled) {
      this.onImageDelete.emit(this.imageCopy);
    }
  }

  handleCancelButtonClick(): void {
    this.onImageChange.emit(this.image);
  }
}
