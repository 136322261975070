import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmbedViewComponent } from '../views/embed-view';
import { CardModule } from './card.module';
import { IconModule } from './icon.module';
import { ResultModule } from './result.module';

@NgModule({
  declarations: [EmbedViewComponent],
  imports: [CommonModule, IconModule, CardModule, ResultModule],
  exports: [EmbedViewComponent]
})
export class EmbedViewModule {}
