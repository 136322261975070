import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { rootTwitter, rootInstagram } from '../../../icons';
import { IconService } from '../../services';

@Component({
  selector: 'lib-embed-view',
  templateUrl: './embed-view.component.html',
  styleUrls: ['./embed-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EmbedViewComponent implements OnChanges {
  @Input() embedCode = '';

  // If specified will show placeholder card in prerendered page
  @Input() placeholderText: string;
  @Input() placeholderIcon: string;

  safeEmbedCode: SafeHtml = '';
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _sanitizer: DomSanitizer,
    private _cdr: ChangeDetectorRef,
    private _iconService: IconService
  ) {
    this.isBrowser = isPlatformBrowser(this._platform);
    this._iconService.registerIcons([rootTwitter, rootInstagram]);
  }

  ngOnChanges(): void {
    const newSafeEmbedCode = this.embedCode
      ? this._sanitizer.bypassSecurityTrustHtml(this.embedCode)
      : null;
    if (this.safeEmbedCode !== newSafeEmbedCode) {
      this.safeEmbedCode = newSafeEmbedCode;
      this._cdr.detectChanges();
    }
  }

  get showPlaceholder(): boolean {
    return !this.isBrowser && !!(this.placeholderText || this.placeholderIcon);
  }
}
