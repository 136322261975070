import { Injectable } from '@angular/core';
import { Icon } from '../../../icons';
import { IIconService } from './icon.service.interface';

@Injectable({
  providedIn: 'root'
})
export class IconService implements IIconService {
  private _registry = new Map<string, string>();

  registerIcons(icons: Icon[]): void {
    icons.forEach((icon: Icon) => this._registry.set(icon.name, icon.data));
  }

  getIcon(iconName: string): string | undefined {
    if (!this._registry.has(iconName)) {
      console.warn(
        `We could not find the icon with the name ${iconName}, did you add it to the Icon registry?`
      );
    }
    return this._registry.get(iconName);
  }
}
