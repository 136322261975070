import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NorbyTextAreaComponent } from '../components/molecules/norby-text-area';
import { NorbyFormcontrolLabelModule } from './norby-formcontrol-label.module';
import { NorbyHelpertextModule } from './norby-helpertext.module';

@NgModule({
  declarations: [NorbyTextAreaComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NorbyFormcontrolLabelModule,
    NorbyHelpertextModule
  ],
  exports: [NorbyTextAreaComponent]
})
export class NorbyTextAreaModule {}
