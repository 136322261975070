import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import { CaptchaComponent } from 'uikit';
import { AuthService, UserService } from '../../../services';

@Component({
  selector: 'lib-content-registration-phone-number-confirmation-code',
  templateUrl:
    './content-registration-phone-number-confirmation-code.component.html',
  styleUrls: [
    './content-registration-phone-number-confirmation-code.component.less'
  ]
})
export class ContentRegistrationPhoneNumberConfirmationCodeComponent
  extends CaptchaComponent
  implements OnInit
{
  @Input() phoneNumber: string;
  @Input() isOnFinalScreen: boolean;

  @Output() handleNext = new EventEmitter<void>();
  @Output() handleSetError = new EventEmitter<any>();
  @Output() handleSkipEmailStep = new EventEmitter<void>();

  formGroup: UntypedFormGroup;
  isLoading: boolean;
  isResendingCode: boolean;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _user: UserService,
    private _auth: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formGroup = this._formBuilder.group({
      verificationCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ]
    });
  }

  async submitCode() {
    if (!this.formGroup.valid || !this.phoneNumber) return;
    const verificationCode = `${this.formGroup.value.verificationCode}`;
    this.isLoading = true;

    try {
      await this._auth.completePhoneLogin({
        phoneNumber: this.phoneNumber,
        verificationCode
      });

      const userMetadata = await this._user.currentUserMetadata();

      // If the newly authorized user has an email of any kind, we can skip the email
      // authorization stages.
      if (userMetadata?.email) {
        this.handleSkipEmailStep.emit();
      }
      this.isLoading = false;
      this.handleNext.emit();
    } catch (e) {
      this.isLoading = false;
      this.handleSetError.emit(e);
    }
  }

  async resendPhoneCode() {
    if (!this.phoneNumber) return;
    const captchaResponse = await this.executeCaptcha();

    this.isResendingCode = true;
    try {
      await this._auth.initiatePhoneSignInFlow(
        this.phoneNumber,
        captchaResponse
      );
      this.isResendingCode = false;
    } catch (e) {
      this.isResendingCode = true;
      this.phoneNumber = null;
      this.handleSetError.emit(e);
    }
  }
}
