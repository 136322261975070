import { OverlayRef } from '@angular/cdk/overlay';

export class MessageRef {
  constructor(private readonly _overlay: OverlayRef) {}

  close() {
    this._overlay.dispose();
  }

  isVisible() {
    return this._overlay && this._overlay.overlayElement;
  }

  getPosition() {
    return this._overlay.overlayElement.getBoundingClientRect();
  }
}
