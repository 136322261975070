import { ISubscriptionPlan, ProjectTiers } from '../types/billing';
import { DowngradeStages, DowngradeStagesEnum } from '../types/downgrade';

export const CURRENT_PLANS_MAP: { [key: string]: ISubscriptionPlan } = {
  [ProjectTiers.FREE]: {
    type: ProjectTiers.FREE,
    monthlyPrice: 0,
    yearlyPrice: 0,
    title: 'Free',
    summary: 'For experimenting and trying out ideas.',
    promo: null,
    features: [
      'Custom link in bio and subdomain',
      'Limited SMS notifications and alerts',
      'Limited analytics',
      '7 day message history',
      '1 custom landing page',
      '2 custom events',
      '1 team member'
    ]
  },
  [ProjectTiers.STARTER]: {
    type: ProjectTiers.STARTER,
    monthlyPrice: 6,
    yearlyPrice: 6,
    title: 'Starter',
    summary: 'For new projects and side hustles.',
    promo: null,
    features: [
      'Custom link in bio and subdomain',
      'Limited SMS notifications and alerts',
      'Basic analytics',
      '30 day message history',
      '2 landing pages',
      '3 events',
      '1 team member'
    ]
  },
  [ProjectTiers.GROWTH]: {
    type: ProjectTiers.GROWTH,
    monthlyPrice: 39,
    yearlyPrice: 29,
    title: 'Growth',
    summary: 'For side projects and new ideas.',
    promo: null,
    features: [
      'Custom link in bio and subdomain',
      '200 SMS & 25k email sends',
      'Your own phone number',
      'Detailed analytics',
      '60 day message history',
      '5 landing pages',
      'Unlimited signups & events',
      '3 team members'
    ]
  },
  [ProjectTiers.PRO]: {
    type: ProjectTiers.PRO,
    monthlyPrice: 99,
    yearlyPrice: 79,
    title: 'Pro',
    summary: 'For entrepreneurs and small teams.',
    promo: null,
    features: [
      'Custom link in bio and subdomain',
      '2k SMS & 50k email sends',
      'Your own phone number',
      'Advanced analytics',
      'Full message history',
      'Unlimited landing pages',
      '5 team members',
      'Remove Norby branding'
    ]
  }
};

export const CURRENT_PLANS: ISubscriptionPlan[] =
  Object.values(CURRENT_PLANS_MAP);

export const DOWNGRADE_FEATURES_CONFIRMATION = {
  [ProjectTiers.FREE]: [
    'I will be limited to 1 landing page',
    'I will be limited to 2 events',
    'I will be limited to 1 team member',
    'My SMS limit will be capped at 10 messages',
    'My email limit will be capped at 50 emails',
    'My Inbox will only show messages from the last 7 days'
  ],
  [ProjectTiers.STARTER]: [],
  [ProjectTiers.GROWTH]: [],
  [ProjectTiers.PRO]: []
};

export type DowngradeStageCopyBlock = {
  title: string;
  subtitle: string;
};

export const DOWNGRADE_STAGES_COPY: {
  [key in DowngradeStages]: DowngradeStageCopyBlock;
} = {
  [DowngradeStagesEnum.REASON]: {
    title: 'Before you go...',
    subtitle: 'Why are you downgrading?'
  },
  [DowngradeStagesEnum.PAIN_POINTS]: {
    title: 'Help us improve',
    subtitle: 'Tell us about your experience'
  },
  [DowngradeStagesEnum.LACKING_FEATURES]: {
    title: 'Help us improve',
    subtitle: 'Tell us about your experience'
  },
  [DowngradeStagesEnum.CHANGE_IN_NEEDS]: {
    title: 'Help us improve',
    subtitle: 'Tell us about your experience'
  },
  [DowngradeStagesEnum.RETENTION_PROMO]: null,
  [DowngradeStagesEnum.CONFIRMATION]: {
    title: 'Confirm',
    subtitle:
      'Changes will take effect at the end of your billing cycle on {1}.'
  }
};
