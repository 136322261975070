import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, ReplaySubject, timer } from 'rxjs';
import {
  debounce,
  distinctUntilChanged,
  startWith,
  switchMap
} from 'rxjs/operators';

import { BaseComponent } from '../../models/base-component';
import { IconService } from '../../services/icon';
import { rootLoader } from '../../../icons/build';

@Component({
  selector: 'root-spin',
  templateUrl: './spin.component.html',
  styleUrls: ['./spin.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinComponent
  extends BaseComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() delay = 0;
  @Input() spinning = true;

  private _spinning$ = new BehaviorSubject(this.spinning);
  private _delay$ = new ReplaySubject<number>(1);
  isLoading = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _iconService: IconService
  ) {
    super();
    this._iconService.registerIcons([rootLoader]);
  }

  ngOnInit(): void {
    this._delay$
      .pipe(
        startWith(this.delay),
        distinctUntilChanged(),
        switchMap((delay) =>
          delay === 0
            ? this._spinning$
            : this._spinning$.pipe(
                debounce((isSpinning) => timer(isSpinning ? delay : 0))
              )
        ),
        this.takeUntilDestroy
      )
      .subscribe((loading) => {
        this.isLoading = loading;
        this._cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const { spinning, delay } = changes;

    if (spinning) {
      this._spinning$.next(this.spinning);
    }

    if (delay) {
      this._delay$.next(this.delay);
    }
  }
}
