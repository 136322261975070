import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[rootNestedInputGroup]'
})
export class NestedInputGroupDirective {
  @HostBinding('class')
  elementClass =
    'form-control flex items-center w-full pr-2 bg-white bg-clip-padding border border-neutral-400 rounded-sm transition ease-in-out m-0 outline-none focus-within:border-neutral-800 hover:border-neutral-800 user-theme-input';
  constructor() {}
}
