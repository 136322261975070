<div [@zoomBigMotion]="'active'">
  <div class="user-theme-tooltip-top-arrow"></div>
  <div
    class="tooltip shadow-ant-default user-theme-tooltip"
    *ngIf="tooltip || tooltipText"
  >
    <span class="user-theme-tooltip-text"
      >{{ tooltip ? '' : tooltipText }}
      <ng-container *ngTemplateOutlet="tooltip"> </ng-container>
    </span>
  </div>
  <div class="user-theme-tooltip-bottom-arrow"></div>
</div>
