import { FORMAT } from './constants';
import {
  formatTimestampString,
  formatTimestampDate,
  getHoursDuration,
  parseDate
} from './utils/time';

/**
 * Base calendar class. This class can be extended to add new calendar services.
 */
export class CalendarBase {
  options: any;
  description: string;
  title: string;
  location: string;
  start: string;
  end: string;
  recurrence: any;
  allday: boolean;
  duration: string;

  /**
   * Constructor.
   *
   * @param {Object} options
   * @param {String} options.description - event description
   * @param {String} options.title - event title
   * @param {String} options.location - event location
   * @param {String} options.start - event start time, in ISO 8601 format
   * @param {String} [options.end] - event end time, in ISO 8601 format
   * @param {Object} [options.recurrence]
   * @param {String} [options.recurrence.frequency] - recurrence frequency (`DAILY`, `WEEKLY`, `MONTHLY`, `YEARLY`)
   * @param {Number} [options.recurrence.interval] - time between recurrences
   * @param {Number} [options.recurrence.count] - number of times event should repeat
   * @param {String} [options.recurrence.end] - date when the last recurrence should occur
   * @param {String} [options.recurrence.weekstart = 'SU'] - uppercase, first two letters of the day that the week starts on
   * @param {String} [options.recurrence.weekdays] - comma-separated list of uppercase, first two letters of the days the event occurs on
   * @param {String} [options.recurrence.monthdays] - comma-separated list of monthdays	String of numbers
   */
  constructor(options) {
    this.setText(options);
    this.setTimestamps(options);
  }

  /**
   * Sets the description, title and location.
   *
   * @private
   * @param {Object} options
   * @param {String} options.description - event description
   * @param {String} options.title - event title
   * @param {String} options.location - event location
   */
  setText(options) {
    this.description = options.description || '';
    this.title = options.title || '';
    this.location = options.location || '';
  }

  /**
   * Sets the time and recurrence parameters.
   *
   * @private
   * @param {Object} options
   * @param {String} options.start - event start time
   * @param {String} [options.end] - event end time
   * @param {Boolean} [options.allday = false] - whether this is an all-day event
   * @param {Recurrence} [options.recurrence] - event recurrence
   */
  setTimestamps(options) {
    let format = FORMAT.DATE;

    this.allday = !options.end;

    if (this.allday) {
      // if allday is specified, make the end date exactly 1 day from the start date
      const end = parseDate(options.start);

      end.setDate(end.getDate() + 1);

      this.end = formatTimestampDate(end, format);
      this.duration = getHoursDuration(options.start, end.toISOString());
    } else {
      format += `T${FORMAT.TIME}`;
      this.end = formatTimestampString(options.end, format);
      this.duration = getHoursDuration(options.start, options.end);
    }

    this.start = formatTimestampString(options.start, format);
    this.recurrence = options.recurrence;
  }
}
