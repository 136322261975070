import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyTabsComponent } from '../components/atoms/norby-tabs';
import { NorbyTabModule } from './norby-tab.module';

@NgModule({
  declarations: [NorbyTabsComponent],
  imports: [CommonModule, NorbyTabModule],
  exports: [NorbyTabsComponent]
})
export class NorbyTabsModule {}
