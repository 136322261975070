import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyHelpertextComponent } from '../components/atoms/norby-helpertext/norby-helpertext.component';

@NgModule({
  declarations: [NorbyHelpertextComponent],
  imports: [CommonModule],
  exports: [NorbyHelpertextComponent]
})
export class NorbyHelpertextModule {}
