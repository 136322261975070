import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges
} from '@angular/core';

import {
  IImage,
  IResponsiveImage,
  buildImageSrcset,
  getImageSrc
} from 'models';
import { USE_RAW_IMAGES } from '../../constants';

export type Rounded = {
  topLeft: boolean;
  topRight: boolean;
  bottomLeft: boolean;
  bottomRight: boolean;
};

@Component({
  selector: 'lib-soft-image-view',
  templateUrl: './soft-image-view.component.html',
  styleUrls: ['./soft-image-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftImageViewComponent implements OnChanges {
  @Input() imageUrl: string;
  @Input() image: IImage;
  @Input() imgixImage: IResponsiveImage;
  @Input() alt: string;
  @Input() initialHeight = '100px';
  @Input() backgroundColor = 'rgba(190, 190, 190, 0.2)';

  loaded = false;
  srcset: string;
  src: string;
  width: number;
  height: number;

  constructor(
    @Optional() @Inject(USE_RAW_IMAGES) private _useRawImages,
    private _cdr: ChangeDetectorRef
  ) {}

  get altText(): string {
    return this.image?.altText ?? this.alt ?? '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { imageUrl, imgixImage } = changes;
    const { large, medium, small, thumbnail } = imgixImage?.currentValue ?? {};
    const { oldLarge, oldMedium, oldSmall, oldThumbnail } =
      imgixImage?.previousValue ?? {};

    const urlHasChanged = imageUrl?.previousValue !== imageUrl?.currentValue;
    const setHasChanged =
      large !== oldLarge ||
      medium !== oldMedium ||
      small !== oldSmall ||
      thumbnail !== oldThumbnail;

    // Only rerender if the urls have actually changed
    if (urlHasChanged || setHasChanged) {
      // Default image for old browsers or when set isn't available
      this.src = getImageSrc(this.imageUrl, imgixImage?.currentValue);
      this.width = this.image?.width;
      this.height = this.image?.height;

      if (this._useRawImages) {
        this.srcset = '';
      } else {
        // Build srcset from imgix images if supplied
        this.srcset = buildImageSrcset(imgixImage?.currentValue);

        const responsiveWidth =
          large?.width ?? medium?.width ?? small?.width ?? thumbnail?.width;

        const responsiveHeight =
          large?.height ?? medium?.height ?? small?.height ?? thumbnail?.height;

        if (!!responsiveHeight) {
          this.height = responsiveHeight;
        }

        if (!!responsiveWidth) {
          this.width = responsiveWidth;
        }
      }
      this._cdr.detectChanges();
    }
  }
}
