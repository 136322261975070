import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFilterDirective } from '../directives';

@NgModule({
  declarations: [CustomFilterDirective],
  imports: [CommonModule],
  exports: [CustomFilterDirective]
})
export class NorbyCustomFilterDropdownModule {}
