import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ContentRegisterable,
  ITicket,
  IUserContent,
  IUserPublicMetadata
} from 'models';

@Component({
  selector: 'lib-content-registration-purchase',
  templateUrl: './content-registration-purchase.component.html',
  styleUrls: ['./content-registration-purchase.component.less']
})
export class ContentRegistrationPurchaseComponent implements OnInit {
  @Input() userHasPaid: boolean;
  @Input() selectedTicket: ITicket;
  @Input() content: ContentRegisterable;
  @Input() userContent: IUserContent;
  @Input() userProfile: IUserPublicMetadata;
  @Input() isOnFinalScreen: boolean;
  @Input() hasLoadedProfile: boolean;
  @Input() themeClasses: string[];
  @Input() promoCode: string;

  @Output() handleNext = new EventEmitter<void>();
  @Output() handleSetError = new EventEmitter<any>();
  @Output() handleSkipPayment = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  handleSuccess() {
    this.handleNext.emit();
  }

  handleError(error: string) {
    this.handleSetError.emit(error);
  }
}
