<h2>Checkout</h2>
<p>
  You'll see <em>{{ ticket?.statementDescription }}</em> on your statement.
</p>
<root-spin [spinning]="isLoading">
  <div class="min-h-56 mt-4">
    <div id="payment-element"></div>
    <div id="address-element"></div>
  </div>
</root-spin>
<div class="mt-6">
  <root-button
    (handleClick)="handleCompleteCardPayment()"
    [disabled]="isDisabled || !isPaymentFormComplete || isLoading"
    [loading]="isSubmitting"
    [block]="true"
    size="large"
    type="primary"
    [text]="isFinalScreen ? 'Purchase' : 'Next'"
  >
  </root-button>
</div>
