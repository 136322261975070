import { Transform } from 'class-transformer';

import { transformTimestampToDate } from '../tools';

export enum DigestNotificationTypeEnum {
  ACCOUNT = 'account',
  EVENTS = 'events',
  SIGNUPS = 'signups',
  SENDS = 'sends',
  INBOX = 'inbox',
  ANALYTICS = 'analytics'
}
export type DigestNotificationType = `${DigestNotificationTypeEnum}`;

export enum DigestNotificationMediumEnum {
  EMAIL = 'email',
  IN_APP = 'inApp'
}
export type DigestNotificationMedium = `${DigestNotificationMediumEnum}`;

export enum DigestNotificationPeriodEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly'
}
export type DigestNotificationPeriod = `${DigestNotificationPeriodEnum}`;

export type DigestNotificationTriggerConfig = { [trigger: string]: boolean };

export class NotificationPreference {
  id!: string;
  userId!: string;
  slug!: string;
  notificationType!: DigestNotificationType;
  mediums!: DigestNotificationMedium[];
  digestPeriods!: DigestNotificationPeriod[];
  triggers!: DigestNotificationTriggerConfig;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  createdAt!: Date;
  createdAtCursor!: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  modifiedAt!: Date;
  modifiedAtCursor!: string;
}

export class NotificationPreferenceSummary {
  account!: NotificationPreference;
  events!: NotificationPreference;
  signups!: NotificationPreference;
  sends!: NotificationPreference;
  inbox!: NotificationPreference;
  analytics!: NotificationPreference;
}
