import {
  plainToClass,
  Type,
  Transform,
  instanceToInstance,
  instanceToPlain,
  Exclude,
  Expose
} from 'class-transformer';
import { BehaviorSubject } from 'rxjs';

// Lib
import { IAccessControlSettings } from '../accessControl';
import { hashString, transformTimestampToDate } from '../../tools';
import {
  CancelationNotificationSettings,
  ContentType,
  IContentKeywordDTO,
  IEventCreateDTO,
  INotificationDTO,
  IRsvpRestrictions,
  ISignupCreateDTO
} from './content';
import { ChargeType, DeliveryType } from '../general';
import { ShortLinkType } from '../IUserContent';
import { ISingleSendMetrics } from '../single-sends';

export interface IContentMetadata {
  contentId: string;
  contentType: ContentType;
  slugIdentifiers: string[];
  primarySlugIdentifier: string;
  toxicityScores: any;
}

export class ContentMetadata implements IContentMetadata {
  contentId: string;
  contentType: ContentType;
  slugIdentifiers: string[];
  primarySlugIdentifier: string;
  toxicityScores: any;

  static fromObject(object: any) {
    switch (object?.contentType) {
      case 'event':
        return plainToClass(ContentEventMetadata, object);
      case 'link':
        return plainToClass(ContentLinkMetadata, object);
      case 'drop':
        return plainToClass(ContentSignupMetadata, object);
      default:
        return plainToClass(ContentMetadata, object);
    }
  }
}

export interface IContentNotificationMetrics extends ISingleSendMetrics {}

export type IContentMetadataContentNotificationMetrics = {
  [type in ContentNotificationType]?: IContentNotificationMetrics;
};

export class IEventDropMetadataNotificationLinkTrackingInfo {
  clickCount?: number;
  uniqueClickCount?: number;
}

export class IEventDropMetadataNotificationLinksTrackingInfo {
  [rawLink: string]: IEventDropMetadataNotificationLinkTrackingInfo;
}

export class IEventDropMetadataNotificationTrackingInfo {
  @Type(() => IEventDropMetadataNotificationLinksTrackingInfo)
  links?: IEventDropMetadataNotificationLinksTrackingInfo;
}

export type IEventDropMetadataNotificationsTrackingInfo = {
  [key in ContentNotificationType]?: IEventDropMetadataNotificationTrackingInfo;
};

export abstract class IEventDropMetadata extends ContentMetadata {
  @Exclude()
  protected _changes$ = new BehaviorSubject<IEventDropMetadata>(null);

  @Exclude()
  readonly changes$ = this._changes$.asObservable();

  @Exclude()
  protected _hash: number; // Visual hash

  @Exclude()
  protected _fullHash: number; // Full object hash

  singleSendTracking?: IEventDropMetadataSingleSendsTrackingInfo;
  notificationTracking?: IEventDropMetadataNotificationsTrackingInfo;
  notifications?: INotification[];
  notificationDeliveryHistory?: INotificationDeliveryHistory;
  notificationToxicicityScores?: INotificationToxicityScores;
  notificationMetrics?: IContentMetadataContentNotificationMetrics;

  @Expose()
  @Transform(
    (obj) => {
      // If `managedNotifications` is true / false, return as is
      if (obj?.value !== null && obj?.value !== undefined) {
        return obj.value;
      }

      // If we have `notifications` on the source object, consider them
      // user controlled
      else if (obj?.obj?.['notifications']) {
        return false;
      }

      // Otherwise consider notifications backend controlled
      return true;
    },
    { toClassOnly: true }
  )
  managedNotifications?: boolean;

  singleSendDeliveryHistory?: ISingleSendDeliveryHistory;
  isToxic?: boolean;
  rsvpRestrictions?: IRsvpRestrictions;
  accessControlSettings?: IAccessControlSettings;
  password?: string;
  singleSend?: ISingleSendMetadata;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  canceledAt?: Date;

  isCanceled?: boolean;
  refundIssued?: boolean;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  refundIssuedAt?: Date;

  cancelationNotificationSettings?: CancelationNotificationSettings;
  urls!: IPrivateUrls;
  statistics?: IEventDropMetadataStatistics;
  usage?: Usage;
  deliveryTotals?: DeliveryTotals;
  keywords?: IContentKeywordDTO[];

  abstract get hash(): number;
  abstract clone(withMeta?: Partial<IEventDropMetadata>);
  abstract updateProperties(properties: Partial<IEventDropMetadata>);
  abstract toObject(): IEventDropMetadata;
}

export class ContentLinkMetadataStatistics {
  uniqueClickCount?: number;
  clickCount?: number;
}

export class ContentLinkMetadata extends ContentMetadata {
  contentType!: 'link';
  url!: string;
  statistics?: ContentLinkMetadataStatistics;
  isToxic!: boolean;
}

export function isLinkMetadata(x: IContentMetadata): x is ContentLinkMetadata {
  return x?.contentType === 'link';
}

export class IZoomEventMetadata {
  startUrl!: string;
  joinUrl!: string;
  meetingId!: number;
  accountId?: string;
}

export class ContentEventMetadata extends IEventDropMetadata {
  contentType!: 'event';
  zoom?: IZoomEventMetadata;
  contentLocation?: string;
  contentLocationPublic?: boolean;

  static new() {
    return plainToClass(ContentEventMetadata, {});
  }

  static fromObject(object: any) {
    return plainToClass(ContentEventMetadata, {
      ...object
    });
  }

  clone(withContent?: Partial<ContentEventMetadata>) {
    const clone = instanceToInstance(this);
    if (withContent) {
      clone.updateProperties(withContent);
    }
    return clone;
  }

  updateProperties(
    properties: Partial<ContentEventMetadata>,
    emitEvent = true
  ) {
    // Only update props that aren't @Excluded in the toObject transform
    const props =
      properties instanceof ContentEventMetadata
        ? properties.toObject()
        : properties;

    // Update props
    for (const key in props) {
      this[key] = properties[key];
    }

    // Clear hashes and publish changes
    this._hash = null;
    this._fullHash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  toObject() {
    return instanceToPlain(this) as ContentEventMetadata;
  }

  get hash() {
    if (!this._hash) {
      const props = `
        ${this.managedNotifications}
        ${this.rsvpRestrictions?.limit}
        ${this.rsvpRestrictions?.displayLimit}
        ${this.zoom?.meetingId}
        ${this.password}
        ${this.urls}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  get fullHash() {
    if (!this._fullHash) {
      this._fullHash = hashString(JSON.stringify(this.toObject()));
    }

    return this._fullHash;
  }

  static fromDTO(scaffold: IEventCreateDTO): ContentEventMetadata {
    const {
      contentLocation,
      contentLocationPublic,
      contentUrl,
      rsvpRestrictions,
      keywords,
      password,
      notifications
    } = scaffold;

    return this.fromObject({
      ...(contentLocation && { contentLocation }),
      contentLocationPublic: contentLocationPublic ?? false,
      ...(contentUrl && { contentUrl }),
      ...(rsvpRestrictions && { rsvpRestrictions }),
      ...(keywords && { keywords }),
      ...(password && { password }),
      ...(notifications?.length && {
        notifications,
        managedNotifications: false
      })
    });
  }
}

export function isEventMetadata(
  x: IContentMetadata
): x is ContentEventMetadata {
  return x?.contentType === 'event';
}

export type ContentNotificationType =
  | 'eventStart'
  | 'eventReminder'
  | 'rsvpConfirmation';

export class INotification extends INotificationDTO {
  id!: string;
}

export class ISingleSendMetadata {
  singleSendCount!: number;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  lastSingleSendSentAt?: Date;
}

export type UniqueClickCounts = {
  [key in ShortLinkType]?: number;
};

export type INotificationToxicityScores = {
  [notificationId: string]: any;
};

export type INotificationDeliveryHistory = {
  [type in ContentNotificationType]?: {
    [deliveryType in DeliveryType]?: {
      delivered?: boolean;
      total?: number;
      deliveryDate?: Date;
    };
  };
};

export class IPrivateUrls {
  clickThroughRaw?: string;
  recapRaw?: string;
}

export class IEventDropMetadataStatistics {
  rsvpCount?: number;
  smsEnabledCount?: number;
  emailEnabledCount?: number;
  uniqueReferralCount?: number;
  uniqueClickCount?: number;
  referrerCount?: number;
  uniqueClickCounts?: UniqueClickCounts;
  joinCount?: number;
  uniqueJoinCount?: number;
  conversionCount?: number;
  uniqueConversionCount?: number;
  ticketsPurchased?: number;
}

export type Usage = {
  [type in ChargeType]?: number;
};
export type DeliveryTotals = {
  [type in ChargeType]?: number;
};

export type ISingleSendDeliveryHistory = {
  [singleSendId: string]: {
    [type in ChargeType]?: {
      total?: number;
      usage?: number;
    };
  };
};

export class IEventDropMetadataSingleSendLinkTrackingInfo {
  clickCount?: number;
  uniqueClickCount?: number;
}

export class IEventDropMetadataSingleSendLinksTrackingInfo {
  [rawLink: string]: IEventDropMetadataSingleSendLinkTrackingInfo;
}

export class IEventDropMetadataSingleSendTrackingInfo {
  subject?: string;
  body?: string;
  links?: IEventDropMetadataSingleSendLinksTrackingInfo;
}

export class IEventDropMetadataSingleSendsTrackingInfo {
  [singleSendId: string]: IEventDropMetadataSingleSendTrackingInfo;
}

export class ContentSignupMetadata extends IEventDropMetadata {
  contentType!: 'drop';

  static new() {
    return plainToClass(ContentSignupMetadata, {});
  }

  static fromObject(object: any) {
    return plainToClass(ContentSignupMetadata, object);
  }

  clone(withContent?: Partial<ContentSignupMetadata>) {
    const clone = instanceToInstance(this);
    if (withContent) {
      clone.updateProperties(withContent);
    }
    return clone;
  }

  updateProperties(
    properties: Partial<ContentSignupMetadata>,
    emitEvent = true
  ) {
    // Only update props that aren't @Excluded in the toObject transform
    const props =
      properties instanceof ContentSignupMetadata
        ? properties.toObject()
        : properties;

    // Update props
    for (const key in props) {
      this[key] = properties[key];
    }

    // Clear hashes and publish changes
    this._hash = null;
    this._fullHash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  toObject() {
    return instanceToPlain(this) as ContentSignupMetadata;
  }

  get hash() {
    if (!this._hash) {
      const props = `
        ${this.managedNotifications}
        ${this.rsvpRestrictions?.limit}
        ${this.rsvpRestrictions?.displayLimit}
        ${this.password}
        ${this.urls}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  get fullHash() {
    if (!this._fullHash) {
      this._fullHash = hashString(JSON.stringify(this.toObject()));
    }

    return this._fullHash;
  }

  static fromDTO(scaffold: ISignupCreateDTO): ContentSignupMetadata {
    const { contentUrl, rsvpRestrictions, keywords, password, notifications } =
      scaffold;

    return this.fromObject({
      ...(contentUrl && { contentUrl }),
      ...(rsvpRestrictions && { rsvpRestrictions }),
      ...(keywords && { keywords }),
      ...(password && { password }),
      ...(notifications?.length && {
        notifications,
        managedNotifications: false
      })
    });
  }
}

export function isSignupMetadata(
  x: IContentMetadata
): x is ContentSignupMetadata {
  return x?.contentType === 'drop';
}

export type ContentMetadataFamily =
  | ContentEventMetadata
  | ContentSignupMetadata
  | ContentLinkMetadata;
