import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { rootVideo } from '../../../icons';
import { IconService } from '../../services';

@Component({
  selector: 'lib-vimeo-embed-view',
  templateUrl: './vimeo-embed-view.component.html',
  styleUrls: ['./vimeo-embed-view.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class VimeoEmbedViewComponent implements OnChanges {
  @Input() embedCode: string;
  @Input() width: string;
  @Input() height: string;
  safeEmbedCode: SafeHtml;

  constructor(
    private _sanitizer: DomSanitizer,
    private _iconService: IconService
  ) {
    this._iconService.registerIcons([rootVideo]);
  }

  ngOnChanges(): void {
    this.safeEmbedCode = this.embedCode
      ? this._sanitizer.bypassSecurityTrustHtml(this.embedCode)
      : null;
  }
}
