import { Injectable } from '@angular/core';

// 3rd party
import { Observable, from } from 'rxjs';

// Lib
import {
  Content,
  ContentEvent,
  IContentService,
  SingleSend,
  EventsFilterArgs,
  IContentPublicListingResults,
  PaginatedQuerySummary,
  ConversationMessage
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class MockContentService implements IContentService {
  getConversationMessage({ id }: { id: string }): Promise<ConversationMessage> {
    return Promise.resolve(null);
  }

  getConversationMessage$(id: string): Observable<ConversationMessage> {
    return from([null]);
  }

  getContent$<T extends Content>(id: string): Observable<T> {
    return from([null]);
  }

  getContent<T extends Content>(id: string): Promise<T> {
    return Promise.resolve(null);
  }

  getEvents = async (
    args: EventsFilterArgs
  ): Promise<IContentPublicListingResults> => {
    return null;
  };

  getEventsForCurrentSlug$(
    args: EventsFilterArgs
  ): Observable<PaginatedQuerySummary<ContentEvent>> {
    return from([null]);
  }

  getPaymentIntentSecret(
    eventId: string,
    label: string,
    qty: number,
    promoCode: string
  ) {
    return Promise.resolve(null);
  }

  getPaymentIntentEstimate(
    eventId: string,
    label: string,
    qty: number,
    promoCode: string
  ) {
    return Promise.resolve(null);
  }

  getWebHostedSendById({ id }: { id: string }): Promise<SingleSend> {
    return Promise.resolve(null);
  }

  addContentToSend = async (send: SingleSend) => Promise.resolve(null);

  getWebHostedSendById$ = ({ id }: { id: string }): Observable<SingleSend> =>
    from([null]);

  addContentToSend$ = (send$: Observable<SingleSend>) => from([null]);
}
