import { Overlay, ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Injectable, Injector, InjectionToken, Inject } from '@angular/core';

import { ModalRef, ModalConfig } from './modal-ref';
import { IModalService } from './modal.service.interface';

export const MODAL_DATA = new InjectionToken<ModalConfig>('MODAL_DATA');

@Injectable({
  providedIn: 'root'
})
export class ModalService implements IModalService {
  constructor(
    private _overlay: Overlay,
    private _injector: Injector,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  open<T>(
    component: ComponentType<T>,
    themeClasses: string[],
    config?: ModalConfig
  ): ModalRef {
    const positionStrategy = this._overlay
      .position()
      .global()
      .centerHorizontally();

    const scrollStrategy = this._overlay.scrollStrategies.block();

    let panelClasses: string[];
    let backdropClasses: string[];

    if (!config?.fullscreen) {
      panelClasses = [
        'flex',
        'flex-col',
        'xxl:min-w-120',
        'xs:w-98vw',
        'h-fit-content',
        'm-auto',
        'zoom-enter',
        'zoom-enter-active'
      ];

      backdropClasses = [
        'bg-modal-backdrop',
        'root-modal-container',
        'fade-enter',
        'fade-enter-active'
      ];
    }

    const overlayRef = this._overlay.create({
      scrollStrategy,
      positionStrategy,
      hasBackdrop: true,
      disposeOnNavigation: true,
      backdropClass: backdropClasses,
      panelClass: panelClasses,
      maxHeight: 'max-content'
    });

    const modalRef = new ModalRef(overlayRef, this._document, config);

    const injector = Injector.create({
      parent: this._injector,
      providers: [
        { provide: ModalRef, useValue: modalRef },
        { provide: MODAL_DATA, useValue: config }
      ]
    });

    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    const parent = overlayRef.overlayElement;
    const element = parent.firstChild;

    const wrapper = this._document.createElement('div');
    wrapper.classList.add('modal-overlay-panel');

    if (!config?.transparent) {
      wrapper.classList.add(
        'p-5',
        'bg-white',
        'rounded-none',
        'border-solid',
        'border',
        'border-border-color-split',
        'shadow-sm'
      );
    }

    parent.replaceChild(wrapper, element);
    wrapper.appendChild(element);

    if (!!themeClasses) {
      modalRef.addThemeClassWrapperNodes(themeClasses);
    }

    return modalRef;
  }
}
