export const UI_MOCK_URLS = {
  analytics:
    'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/images%2Fmocks%2FAnalytics.png?alt=media&token=cf396031-703d-427f-8b3c-1e537dbd0094',
  events:
    'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/images%2Fmocks%2FEvents.png?alt=media&token=2a2d41dd-93ed-4d35-a13c-283123d84365',
  forms:
    'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/images%2Fmocks%2FForms.png?alt=media&token=b5a9b55b-c0d9-474a-9de4-ba9d3ef2681a',
  landingPages:
    'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/images%2Fmocks%2FLanding%20pages.png?alt=media&token=3c676d3c-7486-493c-808e-c005c81cf99e',
  messaging:
    'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/images%2Fmocks%2FMessaging.png?alt=media&token=846e4821-86f1-4455-8bab-082b96de8170',
  marketing:
    'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/images%2Fmocks%2FSMS%20%26%20Email%20Marketing.png?alt=media&token=771149ad-41d4-4948-98a1-96b57dc76021',
  signups:
    'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/images%2Fmocks%2FSignups.png?alt=media&token=ae6c8d2b-307d-4c42-aa49-a2ca6dae0dad'
};

export const UI_MOCKS = [
  {
    url: UI_MOCK_URLS.landingPages,
    background: '#DA6648',
    title: 'Landing Pages',
    description:
      'Just add your logo and pick a theme to create a seamless, high-converting page, signup, or form.'
  },
  {
    url: UI_MOCK_URLS.messaging,
    background: '#767024',
    title: 'Messaging',
    description:
      'Build your community over text message, DM, or email – all from one integrated inbox.'
  },
  {
    url: UI_MOCK_URLS.events,
    background: '#140936',
    title: 'Events',
    description:
      'Set up your event page in seconds, send SMS and email reminders, and track attendee engagement.'
  },
  {
    url: UI_MOCK_URLS.marketing,
    background: '#3F292E',
    title: 'Marketing',
    description:
      'Segment your audience and send SMS and email marketing campaigns.'
  },
  {
    url: UI_MOCK_URLS.analytics,
    background: '#F9FAE8',
    title: 'Analytics',
    description:
      'Understand your customers with advanced analytics and automatic link, click, and referral tracking.'
  },
  { url: UI_MOCK_URLS.forms, background: '#504A40' },
  { url: UI_MOCK_URLS.signups, background: '#D5E1F0' }
];

export const TESTIMONIALS_MOCKS = [
  {
    comment:
      "It's been easy to start, grow, and manage our online community with Norby. It has intuitive and creator-driven features that have allowed us to showcase our brand in authentic and seamless ways.",
    from: 'KIND BODY THERAPY, COMMUNITY'
  },
  {
    comment: 'Norby has singlehandedly made marketing fun again.',
    from: 'SHYNE WEBSTER, BRAND STRATEGIST'
  }
];
