<div
  [style]="tagStyle"
  class="border inline-block whitespace-nowrap px-1 border-solid rounded-none user-theme-tag"
  [ngClass]="{
    'border-red-dark bg-red-light': color === 'error',
    'border-neutral-900': color === 'success',
    'border-blue-dark bg-blue-light': color === 'processing',
    'border-yellow-dark bg-yellow-light': color === 'warning',
    'border-border-color-split bg-neutral-300': color === 'default'
  }"
>
  <div class="flex">
    <div
      class="flex items-center pb-px text-sm user-theme-tag-text"
      [ngClass]="{
        'text-neutral-900': color === 'success',
        'text-blue-dark': color === 'processing',
        'text-red-dark': color === 'error',
        'text-yellow-dark': color === 'warning',
        'text-neutral-800': color === 'default'
      }"
    >
      <ng-content></ng-content>
    </div>
    <div *ngIf="closeable" class="ml-1 flex cursor-pointer">
      <img
        (click)="closeTag($event)"
        height="15"
        width="15"
        src="assets/outline/feather/x.svg"
      />
    </div>
  </div>
</div>
