<button
  type="button"
  class="h-10 border hover:text-neutral-800 rounded-sm flex flex-row flex-1 leading-5 items-center whitespace-nowrap outline-1 radio-button"
  [ngClass]="{
    'bg-mustard-200 border-mustard-400 text-neutral-800 font-medium':
      selectedValue === buttonValue,
    'bg-white border-neutral-300 text-neutral-700':
      selectedValue !== buttonValue,
    'text-sm px-2 py-1': size === 'small',
    'text-lg px-6 py-3': size === 'large',
    'text-base px-5 py-2': size === 'default'
  }"
  (click)="handleButtonClick()"
  [disabled]="isDisabled"
>
  <label
    root-checkbox
    [checked]="selectedValue === buttonValue"
    [radio]="true"
    [disabled]="true"
  >
    <span [ngClass]="{ 'ml-2': size === 'large' }">{{ buttonLabel }}</span>
  </label>
</button>
