import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageRefService } from './local-storage-ref.service';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private _localStorage: Storage;
  private _localStorage$: BehaviorSubject<any>;
  localStorage$: Observable<any>;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _localStorageRefService: LocalStorageRefService
  ) {
    if (isPlatformBrowser(this._platform)) {
      this._localStorage = this._localStorageRefService.localStorage;
      this._localStorage$ = new BehaviorSubject({ ...this._localStorage });
    } else {
      this._localStorage$ = new BehaviorSubject({});
    }
    this.localStorage$ = this._localStorage$.asObservable();
  }

  set(key: string, data: any): void {
    if (!this._localStorage) return;

    const jsonData = JSON.stringify(data);
    this._localStorage.setItem(key, jsonData);
    this._localStorage$.next({ ...this._localStorage });
  }

  delete(key: string): void {
    if (!this._localStorage) return;

    this._localStorage.removeItem(key);
    this._localStorage$.next({ ...this._localStorage });
  }

  clearAll(): void {
    if (!this._localStorage) return;

    this._localStorage.clear();
    this._localStorage$.next({});
  }
}
