import { Inject, Injectable, Optional } from '@angular/core';

import { Calendars } from '../../tools';
import { DeviceService } from '../device';
import { ApiService } from '../api';

import { BaseComponent, ErrorService } from 'uikit';
import {
  EMBEDS,
  EMBEDS_URL_TOKEN,
  EMBED_CLASS,
  Content,
  ContentEvent,
  IShareService,
  IUserContent,
  LandingPage,
  ContentRegisterable,
  ApiSurfaces,
  ENDPOINTS,
  END_USER_API_URL_TOKEN
} from 'models';
export type ShareInput = { title: string; text?: string; url?: string };
export type ShareResult = { shared: boolean; error?: string };

@Injectable({
  providedIn: 'root'
})
export class ShareService extends BaseComponent implements IShareService {
  private _webNavigator: any = null;
  private _slug: string;

  constructor(
    @Inject(EMBEDS_URL_TOKEN) private _embedsUrl,
    @Optional() @Inject(END_USER_API_URL_TOKEN) private _endUserApiUrl: string,
    private _device: DeviceService,
    private _api: ApiService,
    protected _error: ErrorService
  ) {
    super();
    this._webNavigator = window.navigator;
    this._device.currentSlug$
      .pipe(this.takeUntilDestroy)
      .subscribe((slug) => (this._slug = slug));
  }

  get canShare(): boolean {
    return (
      this._webNavigator !== null && this._webNavigator.share !== undefined
    );
  }

  async share({ title, text, url }: ShareInput): Promise<ShareResult> {
    if (!this.canShare) {
      return { shared: false, error: "This platform doesn't support sharing" };
    } else if (!text?.length && !url?.length) {
      return { shared: false, error: 'Nothing was specified to share' };
    }

    try {
      const isShared = await this._webNavigator.share({
        title,
        text,
        url
      });
      return { shared: isShared };
    } catch (error) {
      return { shared: false, error };
    }
  }

  // Helper for saving to gcal/ical
  private _getCalendarOptions(event: ContentEvent) {
    if (!event) {
      return {};
    }

    const title = event.title;
    const description = `${event.urls?.pageShortLink}`;
    const location = event.isOnline
      ? event.urls?.pageShortLink
      : event.contentLocation;
    const start = event.startMoment().toString();
    const end = event.endMoment().toString();

    return { title, description, location, start, end };
  }

  // Helper for saving to ical
  async saveToICal(event: ContentEvent, email?: string) {
    const { token } = await this._getCalendarToken(event.contentId, email);
    window.open(`${this._endUserApiUrl}/event/calendar_invite?token=${token}`);
  }

  // Helper for saving to gcal
  saveToGoogleCalendar(event: ContentEvent) {
    const options = this._getCalendarOptions(event);
    const url = Calendars.getGoogleCalendarUrl(options);
    window.open(url);
  }

  private _getCalendarToken = (
    contentId: string,
    email?: string
  ): Promise<{ token: string }> => {
    if (!contentId?.length) {
      return null;
    }

    try {
      return this._api.post(ApiSurfaces.AUTH, ENDPOINTS.auth.calendar, {
        contentId,
        ...(email && { email })
      });
    } catch (e) {
      this._error?.displayError?.(e);
      throw e;
    }
  };

  // Open the system share sheet, prepopulated with the given content
  openContentShareSheet(content: Content, userContent?: IUserContent) {
    const title = content?.title;
    let url = content?.shareUrl;

    if (content.isEvent || content.isSignup) {
      url = userContent?.shortLinks?.referralPageShortLink ?? url;
    } else if (content.isLink) {
      url = userContent?.shortLinks?.clickThroughShortLink ?? url;
    }

    this.share({ title, url })
      .then((r) => console.log(r))
      .catch((e) => console.log(e));
  }

  getUrlForLandingPage(page: LandingPage): string {
    return `https://${this._device.currentSiteRootURL}/${page?.label}`;
  }

  getEmbedCodeForLandingPage(page: LandingPage): string {
    const inner = `
        <a href="${this.getUrlForLandingPage(
          page
        )}" target="_blank" rel="noreferrer"><strong>${page?.title}</strong></a>
      `.trim();

    return `<script src="${this._embedsUrl}" defer></script>
<div class="${EMBED_CLASS}" data-type="${EMBEDS.page.type}" data-label="${page?.label}" data-slug="${this._slug}">${inner}</div>
    `.trim();
  }

  embedStringForType(variant: string, content: ContentRegisterable): string {
    const slug = this._device.currentSlug;
    const embedType = content?.isEvent ? EMBEDS.event.type : EMBEDS.drop.type;
    const contentId = content?.contentId;
    const urls = content?.urls;
    const summary = content?.summary;
    const title = content?.title;
    const buttonLabels = content?.buttonLabels;
    const inner = (
      variant === 'register'
        ? `
        <a href="${urls?.pageRaw}" target="_blank" rel="noreferrer">${buttonLabels?.pre} &rarr;</a>
      `
        : variant === 'share'
          ? `
        <a href="${urls?.pageRaw}" target="_blank" rel="noreferrer">Share &rarr;</a>
      `
          : variant === 'card'
            ? `
        <a href="${urls?.pageRaw}" target="_blank" rel="noreferrer"><strong>${title}</strong><br />${summary}</a>
      `
            : ''
    ).trim();

    return `
    <script src="${this._embedsUrl}" defer></script>
    <div class="${EMBED_CLASS}" data-type="${embedType}" data-id="${contentId}" data-variant="${variant}" data-slug="${slug}">${inner}</div>
    `.trim();
  }
}
