// string formatter that replaces text in a string, starts indexing at 1
// ex. formatString('The lazy {1} {2} over the {3}', lazy, dog, foobar)
// returns 'The lazy dog jumped over the foobar'
export function formatString(originalStr, ...overrides) {
  var oStr = originalStr;

  overrides.forEach((str, index) => {
    var pattern = '\\{' + (index + 1) + '\\}';
    var re = new RegExp(pattern, 'g');
    oStr = oStr.replace(re, str);
  });

  return oStr;
}
