import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderViewComponent } from '../views/header-view';
import { IconModule } from './icon.module';

@NgModule({
  declarations: [HeaderViewComponent],
  imports: [CommonModule, IconModule],
  exports: [HeaderViewComponent]
})
export class HeaderViewModule {}
