import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyAlertComponent } from '../components/atoms/norby-alert';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyAlertComponent],
  imports: [CommonModule, NorbyIconModule],
  exports: [NorbyAlertComponent]
})
export class NorbyAlertModule {}
