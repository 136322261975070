import { transformTimestampToDate } from '../tools';
import { PageInfo } from './general';

import { Transform } from 'class-transformer';

export enum FeatureFlagNames {
  CONTACTS = 'contacts',
  INBOX = 'inbox',
  INSTAGRAM_INTEGRATION = 'instagramIntegration'
}

export type FeatureFlagName = `${FeatureFlagNames}`;
export class IFeatureFlag {
  id!: string;
  name!: FeatureFlagName;
  description!: string;
  feature_enabled!: boolean;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  created_at!: Date;
  created_at_cursor!: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  modified_at!: Date;
  modified_at_cursor!: string;

  last_modified_by_user_id?: string;
  last_modified_by_user_display_name?: string;
}

export class ICreateFeatureFlagDto {
  name!: string;
  description!: string;
  featureEnabled!: boolean;
}

export class IUpdateFeatureFlagDto {
  description?: string;
  featureEnabled!: boolean;
}

export class IFeatureFlagResultEdge {
  cursor?: string;
  offset?: number;
  node!: IFeatureFlag;
}

export class IFeatureFlagResults {
  pageInfo!: PageInfo;
  edges!: IFeatureFlagResultEdge[];
}
