import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IErrorService } from 'models';
import { MessageType } from '../../constants';
import { MessageService } from '../message';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements IErrorService {
  constructor(private _message: MessageService) {}

  formatError(e: HttpErrorResponse | Error): string {
    let message;

    if (e instanceof HttpErrorResponse) {
      message = e?.error?.message ?? e?.error?.error;
      if (Array.isArray(e?.error?.message))
        message = e.error.message.join(', ');
    } else {
      message = e?.message;
    }

    return message;
  }

  displayError(e: HttpErrorResponse | Error) {
    this._message.show({
      text: this.formatError(e),
      type: MessageType.ERROR
    });
  }
}
