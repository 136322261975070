import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import { filter } from 'rxjs';

import { ContentRegisterable, ITicket } from 'models';
import { BaseComponent } from 'uikit';
import { ContentService } from '../../../services';

@Component({
  selector: 'lib-content-registration-tickets',
  templateUrl: './content-registration-tickets.component.html',
  styleUrls: ['./content-registration-tickets.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentRegistrationTicketsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() content: ContentRegisterable;
  @Input() subtotal: number;
  @Input() fees: number;
  @Input() total: number;
  @Input() selectedTicket: ITicket;
  @Input() themeClasses: string[];
  @Input() addedPromoCode: string;

  @Output() handleNext = new EventEmitter<void>();
  @Output() onSelectedTicket = new EventEmitter<any>();
  @Output() onSelectedTicketUpdate = new EventEmitter<ITicket>();
  @Output() onPriceRecalculate = new EventEmitter<string>();

  formGroup: UntypedFormGroup;
  isLoading: boolean;
  maxNumOfTickets;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _content: ContentService
  ) {
    super();
  }

  get hasEnteredCode() {
    const code = this.formGroup?.get('promoCode')?.value;
    return code?.length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTicket) {
      this.formGroup?.patchValue(
        {
          label: changes.selectedTicket?.currentValue?.label,
          qty: changes.selectedTicket?.currentValue?.qty
        },
        { emitEvent: false }
      );
    }
  }

  ngOnInit(): void {
    const ticketQuantityMax = this.content?.tickets?.[0]?.maxQty;
    const remainingTickets = this.content?.rsvpRestrictions?.remaining;
    const maxAllowedTickets =
      ticketQuantityMax !== undefined &&
      remainingTickets !== undefined &&
      ticketQuantityMax > remainingTickets
        ? remainingTickets
        : ticketQuantityMax !== undefined
          ? ticketQuantityMax
          : 1;

    this.maxNumOfTickets = new Array(maxAllowedTickets);
    this.formGroup = this._formBuilder.group({
      promoCode: [''],
      label: [this.selectedTicket?.label, Validators.required],
      qty: [this.selectedTicket?.qty || 1]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        this.takeUntilDestroy
      )
      .subscribe((ticket) => {
        if (this.selectedTicket.qty !== ticket.qty) {
          this._updateSelectedTicket(ticket.label, ticket.qty);
        }
      });
  }

  async submitTicketSelection() {
    if (!this.formGroup.valid) return;
    this.isLoading = true;
    this._updateSelectedTicket(
      this.formGroup.get('label')?.value,
      this.formGroup.get('qty')?.value
    );
    this.handleNext.emit();
    this.isLoading = false;
  }

  private _updateSelectedTicket(label: string, qty: number) {
    const ticketIndex = this.content?.tickets
      ?.map((ticket) => ticket?.label)
      ?.indexOf(label);

    const selectedTicket = {
      ...this.content?.tickets?.[ticketIndex],
      qty: Number(qty || 1)
    };
    this.onSelectedTicketUpdate.emit(selectedTicket);
  }

  async handleApplyPromoCode() {
    this.onPriceRecalculate.emit(this.formGroup?.get('promoCode')?.value);
  }
}
