import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpacerViewComponent } from '../views/spacer-view';

@NgModule({
  declarations: [SpacerViewComponent],
  imports: [CommonModule],
  exports: [SpacerViewComponent]
})
export class SpacerViewModule {}
