<h1 class="norby-title" [style.color]="color" [style]="titleStyles">
  {{ title }}
</h1>
<div
  *ngIf="subtitle"
  class="norby-subtitle"
  [class.text-color]="!color"
  [style.color]="color"
  [style]="subtitleStyles"
  [innerHtml]="subtitle | safeHtml"
></div>
