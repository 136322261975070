import {
  Exclude,
  Expose,
  Type,
  instanceToInstance,
  instanceToPlain,
  plainToClass
} from 'class-transformer';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';

// Lib
import { convertSingleSendBlocksToHTML } from '@magic-sauce/send-blocks-to-html';
import { Content, UniqueClickCounts } from '../content';
import {
  MmsAttachment,
  NotificationMediums,
  IQueryResult,
  PageInfo
} from '../general';
import { hashString, generateRandomName } from '../../tools';
import {
  EVENT_FILTERS,
  ISegmentFilterGroupDTO,
  Segment,
  SegmentContactPreview
} from '../ISegment';
import { IImage } from '../IImage';
import {
  DEFAULT_BLOCK_ATTRIBUTION_FONT_SIZE,
  DEFAULT_BLOCK_FONT_SIZE,
  DEFAULT_BLOCK_PADDING,
  DEFAULT_HEADER_HEIGHT,
  DEFAULT_IMAGE_ALIGNMENT
} from '../../constants';
import { ISlug, IThemeDTO, Theme } from '../ISlug';
import { MenuAlignment, SocialIconsSettings } from '../landing-pages';
import { TimeZone } from '../timezone';

export interface ISingleSendMetrics {
  blocks: number;
  bounceDrops: number;
  bounces: number;
  clicks: number;
  deferred: number;
  delivered: number;
  invalidEmails: number;
  opens: number;
  processed: number;
  requests: number;
  spamReportDrops: number;
  spamReports: number;
  uniqueClicks: number;
  uniqueOpens: number;
  unsubscribeDrops: number;
  unsubscribes: number;
}

export type SingleSendMetrics = {
  emailUsage: number;
  emailTotalDelivered: number;
  emailMetrics: ISingleSendMetrics;
  mmsTotalDelivered: number;
  mmsUsage: number;
  smsTotalDelivered: number;
  smsUsage: number;
  totalRecipients: number;
};

export type ISingleSendMetric =
  | 'blocks'
  | 'blocked'
  | 'bounceDrops'
  | 'bounces'
  | 'clicks'
  | 'deferred'
  | 'delivered'
  | 'dropped'
  | 'invalidEmails'
  | 'opens'
  | 'processed'
  | 'requests'
  | 'spamReportDrops'
  | 'spamReports'
  | 'uniqueClicks';

export interface SingleSendDynamicLinkTracking {
  links: {
    [rawUrl: string]: {
      uniqueClickCount?: number;
      clickCount?: number;
    };
  };
}

export type SendsFilterArgs = {
  after?: string;
  filter?: string;
  limit?: number;
};

export type SingleSendBlockDTO =
  | TextSingleSendBlock
  | HeaderSingleSendBlock
  | ImageSingleSendBlock
  | SpacerSingleSendBlock
  | FooterSingleSendBlock
  | DividerSingleSendBlock
  | ButtonSingleSendBlock
  | YoutubeSingleSendBlock
  | EventSingleSendBlock
  | QuoteSingleSendBlock
  | SignupSingleSendBlock
  | UpcomingEventsSingleSendBlock
  | NlqPromptSingleSendBlock
  | NlqSummarySingleSendBlock;

export enum SingleSendBlockTypeEnum {
  TEXT = 'text',
  HEADER = 'header',
  IMAGE = 'image',
  SPACER = 'spacer',
  FOOTER = 'footer',
  DIVIDER = 'divider',
  BUTTON = 'button',
  YOUTUBE = 'youtube',
  EVENT = 'event',
  QUOTE = 'quote',
  SIGNUP = 'signup',
  UPCOMING_EVENTS = 'upcomingEvents',
  NLQ_SUMMARY = 'nlqSummary',
  NLQ_PROMPT = 'nlqPrompt'
}

export type SingleSendBlockType = `${SingleSendBlockTypeEnum}`;

export interface ISingleSendBlock {
  blockType: SingleSendBlockType;
  theme: Theme;
}

export abstract class SingleSendBlock implements ISingleSendBlock {
  @Exclude()
  private _html: string;

  @Exclude()
  private _hash: number;

  readonly blockType: SingleSendBlockType;
  readonly paddingTop: number;
  readonly paddingRight: number;
  readonly paddingBottom: number;
  readonly paddingLeft: number;

  @Expose()
  @Type(() => Theme)
  readonly theme: Theme;

  static clone(block: SingleSendBlock) {
    return instanceToInstance(block);
  }

  static fromObject<T extends ISingleSendBlock>(object: T) {
    switch (object?.blockType) {
      case 'text':
        return plainToClass(TextSingleSendBlock, object);
      case 'header':
        return plainToClass(HeaderSingleSendBlock, object);
      case 'image':
        return plainToClass(ImageSingleSendBlock, object);
      case 'spacer':
        return plainToClass(SpacerSingleSendBlock, object);
      case 'footer':
        return plainToClass(FooterSingleSendBlock, object);
      case 'divider':
        return plainToClass(DividerSingleSendBlock, object);
      case 'button':
        return plainToClass(ButtonSingleSendBlock, object);
      case 'youtube':
        return plainToClass(YoutubeSingleSendBlock, object);
      case 'event':
        return plainToClass(EventSingleSendBlock, object);
      case 'quote':
        return plainToClass(QuoteSingleSendBlock, object);
      case 'signup':
        return plainToClass(SignupSingleSendBlock, object);
      case 'upcomingEvents':
        return plainToClass(UpcomingEventsSingleSendBlock, object);
      case 'nlqSummary':
        return plainToClass(NlqSummarySingleSendBlock, object);
      case 'nlqPrompt':
        return plainToClass(NlqPromptSingleSendBlock, object);
      default:
        return null;
    }
  }

  protected abstract _stringify(): string;

  abstract get isEmpty(): boolean;

  toObject() {
    return instanceToPlain(this) as ISingleSendBlock;
  }

  getHtml(
    slug: ISlug,
    theme?: Theme,
    contentBlockMap?: { [key: string]: any }
  ): string {
    this._html = convertSingleSendBlocksToHTML(
      [this as unknown as SingleSendBlockDTO],
      {
        // TODO replace with `https://${this.slug?.defaultDomain}` pending change to nor.by
        clickThroughUrl: `https://${slug?.slug}.nor.by`,
        avatarUrl: slug?.accountInfo?.avatarUrl,
        slugTitle: slug?.accountInfo?.title || slug?.slug,
        copyright: dayjs().year().toString(),
        socialLinks: slug?.accountInfo?.links,
        slugTheme: slug?.theme
      },
      contentBlockMap,
      theme
    );
    return this._html;
  }

  get hash(): number {
    if (!this._hash) {
      const props = this._stringify();
      const theme = this.theme?.hash ?? 0;
      this._hash = hashString(`${theme}${props}`);
    }

    return this._hash;
  }
}

export class TextSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.TEXT;
  readonly body: string;
  readonly signedBody: string;

  get isEmpty(): boolean {
    return !this.body?.length;
  }

  protected _stringify(): string {
    return `
    ${this.body}
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(TextSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.TEXT,
      body: '',
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class HeaderSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.HEADER;
  readonly image: IImage;
  readonly height: number;
  readonly clickThroughUrl: string;

  get isEmpty(): boolean {
    return false;
  }

  protected _stringify(): string {
    return `
    ${this.height}
    ${this.image?.url}
    ${this.clickThroughUrl}
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(HeaderSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.HEADER,
      image: null,
      height: DEFAULT_HEADER_HEIGHT,
      clickThroughUrl: '',
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class ImageSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.IMAGE;
  readonly image: IImage;
  readonly height: number;
  readonly clickThroughUrl: string;
  readonly alignment: MenuAlignment;

  get isEmpty(): boolean {
    return !this.image?.url?.length;
  }

  protected _stringify(): string {
    return `
    ${this.height}
    ${this.alignment}
    ${this.image?.url}
    ${this.clickThroughUrl}
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(ImageSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.IMAGE,
      image: null,
      height: DEFAULT_HEADER_HEIGHT,
      clickThroughUrl: '',
      alignment: DEFAULT_IMAGE_ALIGNMENT,
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class SpacerSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.SPACER;
  readonly height: number;

  get isEmpty(): boolean {
    return !(this.height > 0);
  }

  protected _stringify(): string {
    return `
    ${this.height}
    ${this.blockType}
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(SpacerSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.SPACER,
      height: DEFAULT_HEADER_HEIGHT,
      //no padding adjustment on this block, but need to ensure default padding isn't added in html conversion
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0
    });
  }
}

export class FooterSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.FOOTER;
  readonly image: IImage;
  readonly imageEnabled: boolean;
  readonly title: string;
  readonly copyright: string;
  readonly mailingAddress: string;
  readonly socialLinks: SocialIconsSettings[];

  get isEmpty(): boolean {
    return false;
  }

  protected _stringify(): string {
    return `
    ${this.image?.url}
    ${this.imageEnabled}
    ${this.title}
    ${this.copyright}
    ${this.mailingAddress}
    ${this.socialLinks}
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(FooterSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.FOOTER,
      image: null,
      imageEnabled: true,
      title: '',
      copyright: '',
      mailingAddress: '',
      socialLinks: null,
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class DividerSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.DIVIDER;
  readonly height: number;

  get isEmpty(): boolean {
    return !this.height;
  }

  protected _stringify(): string {
    return `
    ${this.blockType}
    ${this.height}
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(DividerSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.DIVIDER,
      height: DEFAULT_HEADER_HEIGHT,
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class ButtonSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.BUTTON;
  readonly height: number;
  readonly label: string;
  readonly clickThroughUrl: string;
  readonly alignment: MenuAlignment;

  get isEmpty(): boolean {
    return !this.label?.length && !this.clickThroughUrl?.length;
  }

  protected _stringify(): string {
    return `
    ${this.blockType}
    ${this.height}
    ${this.label}
    ${this.clickThroughUrl}
    ${this.alignment}
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(ButtonSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.BUTTON,
      height: DEFAULT_HEADER_HEIGHT,
      label: '',
      clickThroughUrl: '',
      alignment: DEFAULT_IMAGE_ALIGNMENT,
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class YoutubeSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.YOUTUBE;
  readonly url: string;
  readonly height: number;
  readonly alignment: MenuAlignment;

  get isEmpty(): boolean {
    return !this.url?.length;
  }

  protected _stringify(): string {
    return `
    ${this.url}
    ${this.height}
    ${this.alignment},
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(YoutubeSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.YOUTUBE,
      url: '',
      height: DEFAULT_HEADER_HEIGHT,
      alignment: DEFAULT_IMAGE_ALIGNMENT,
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class EventSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.EVENT;
  readonly contentId: string;

  get isEmpty(): boolean {
    return !this.contentId?.length;
  }

  protected _stringify(): string {
    return `
      ${this.blockType}
      ${this.contentId}
      ${this.paddingTop}
      ${this.paddingRight}
      ${this.paddingBottom}
      ${this.paddingLeft}
      `;
  }

  static new() {
    return plainToClass(EventSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.EVENT,
      contentId: '',
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class UpcomingEventsSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.UPCOMING_EVENTS;
  readonly tag: string;
  readonly limit: number;
  readonly reverse: boolean;
  eventIds: string[];

  get isEmpty(): boolean {
    return false;
  }

  protected _stringify(): string {
    return `
      ${this.blockType}
      ${this.tag}
      ${this.limit}
      ${this.reverse}
      ${this.eventIds}
      ${this.paddingTop}
      ${this.paddingRight}
      ${this.paddingBottom}
      ${this.paddingLeft}
      `;
  }

  static new() {
    return plainToClass(UpcomingEventsSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.UPCOMING_EVENTS,
      tag: '',
      limit: 10,
      reverse: false,
      eventIds: [],
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class QuoteSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.QUOTE;
  readonly alignment: MenuAlignment;
  readonly quote: string;
  readonly quoteFontSize: number;
  readonly attribution: string;
  readonly attributionFontSize: number;

  get isEmpty(): boolean {
    return false;
  }

  protected _stringify(): string {
    return `
    ${this.blockType}
    ${this.alignment}
    ${this.quote}
    ${this.quoteFontSize}
    ${this.attribution}
    ${this.attributionFontSize}
    ${this.paddingTop}
    ${this.paddingRight}
    ${this.paddingBottom}
    ${this.paddingLeft}
    `;
  }

  static new() {
    return plainToClass(QuoteSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.QUOTE,
      alignment: DEFAULT_IMAGE_ALIGNMENT,
      quote: '',
      quoteFontSize: DEFAULT_BLOCK_FONT_SIZE,
      attribution: '',
      attributionFontSize: DEFAULT_BLOCK_ATTRIBUTION_FONT_SIZE,
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class SignupSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.SIGNUP;
  readonly contentId: string;

  get isEmpty(): boolean {
    return !this.contentId?.length;
  }

  protected _stringify(): string {
    return `
      ${this.blockType}
      ${this.contentId}
      ${this.paddingTop}
      ${this.paddingRight}
      ${this.paddingBottom}
      ${this.paddingLeft}
      `;
  }

  static new() {
    return plainToClass(SignupSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.SIGNUP,
      contentId: '',
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class NlqSummarySingleSendBlockPrompt {
  prompt!: string;
  additionalInstructions: string | undefined;
}

export class NlqSummarySingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.NLQ_SUMMARY;
  readonly prompts!: NlqSummarySingleSendBlockPrompt[];
  readonly timezone!: TimeZone;
  readonly summarizationInstructions!: string | undefined;

  get isEmpty(): boolean {
    return !this.prompts?.length && !this.summarizationInstructions?.length;
  }

  protected _stringify(): string {
    return `
      ${this.blockType}
      ${this.prompts.map((prompt) => `${prompt.prompt}_${prompt.additionalInstructions}`).join(',')}
      ${this.summarizationInstructions}
      ${this.paddingTop}
      ${this.paddingRight}
      ${this.paddingBottom}
      ${this.paddingLeft}
      `;
  }

  static new() {
    return plainToClass(SignupSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.NLQ_SUMMARY,
      prompts: [],
      summarizationInstructions: '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export class NlqPromptSingleSendBlock extends SingleSendBlock {
  readonly blockType!: SingleSendBlockTypeEnum.NLQ_PROMPT;
  readonly prompt!: NlqSummarySingleSendBlockPrompt;
  readonly timezone!: TimeZone;

  get isEmpty(): boolean {
    return !this.prompt;
  }

  protected _stringify(): string {
    return `
      ${this.blockType}
      ${this.prompt?.prompt}_${this.prompt?.additionalInstructions}
      ${this.paddingTop}
      ${this.paddingRight}
      ${this.paddingBottom}
      ${this.paddingLeft}
      `;
  }

  static new() {
    return plainToClass(SignupSingleSendBlock, {
      blockType: SingleSendBlockTypeEnum.NLQ_PROMPT,
      prompt: { prompt: '', additionalInstructions: '' },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      paddingTop: DEFAULT_BLOCK_PADDING,
      paddingRight: DEFAULT_BLOCK_PADDING,
      paddingBottom: DEFAULT_BLOCK_PADDING,
      paddingLeft: DEFAULT_BLOCK_PADDING
    });
  }
}

export type UpdatedSendBlockEvent = {
  block: SingleSendBlock;
  index: number;
  content?: Content;
};

export type UpdatedUpcomingEventsSendBlock = {
  block: UpcomingEventsSingleSendBlock;
  index?: number;
  shouldDoApiCall?: boolean;
};

export type MoveSendBlockEvent = {
  blocks: SingleSendBlock[];
  index: number;
};

export type BlockControlType = {
  id: string;
  controlInstance: string;
  isNew?: boolean;
  blockType: SingleSendBlockType;
};

export class ISingleSendStatistics {
  uniqueClickCount?: number;
  uniqueClickCounts?: UniqueClickCounts;
  joinCount?: number;
  uniqueJoinCount?: number;
  conversionCount?: number;
  uniqueConversionCount?: number;
}

export enum EmailMarketingCommunicationTypeEnum {
  GENERAL = 'general',
  EVENT_UPDATES = 'eventUpdates',
  BLOG = 'blog',
  UPDATES = 'updates',
  SURVEY = 'survey',
  INVITATION = 'invitation',
  NEWSLETTER = 'newsletter',
  SALE = 'sale',
  SHOPPING = 'shopping',
  PRODUCT_LAUNCH = 'productLaunch',
  RESOURCE = 'resource'
}
export type EmailMarketingCommunicationTypes =
  `${EmailMarketingCommunicationTypeEnum}`;

export class ISingleSendV2 {
  id: string;
  slug: string;
  segmentId: string;
  unsubscribeGroupType: 'default' | 'content';
  unsubscribeGroup: string;
  totalSent: number;
  hasBeenSent: boolean;
  createdAt: Date;
  sentAt: Date;
  sentAtCursor: string;
  createdAtCursor: string;
  isToxic: boolean;
  resolvedAttachments?: string[];
  metrics: SingleSendMetrics;
  statistics: ISingleSendStatistics[];
  dynamicLinkTracking: SingleSendDynamicLinkTracking[];
  deliveryType?: NotificationMediums;
  marketingCommunicationType: EmailMarketingCommunicationTypes;
  blocks: SingleSendBlock[];
  subject!: string;
  previewText: string;
  senderName: string;
  sendAt!: string;
  sendAtCursor!: string;
  draft!: boolean;
  disableNorbyBrandedTracking: boolean;
  replyTo: string;
  attachments?: MmsAttachment[];
  excludedContactIds: string[];
  includedContactIds: string[];
  filterGroups: ISegmentFilterGroupDTO[];
  associatedSegment: Segment;
  webhosted: boolean;
  isProcessing: boolean;
  contentBlockMap: { [key: string]: any };
  theme: Theme;
}

export class ISingleSendV2DeliveryHistory {
  id: string;
  slug: string;
  medium: string;
  bounces: number;
  opens: number;
  name: string;
  contactId: string;
  mediumIdentifier: string;
  deliveryStatus: string;
  createdAt: Date;
  isDuplicate: boolean;
}

export class SingleSendV2DeliveryHistory extends ISingleSendV2DeliveryHistory {
  get fullname(): string {
    return this.name ?? generateRandomName(this.id).label;
  }
}

export class SingleSend extends ISingleSendV2 {
  @Exclude()
  protected _changes$ = new BehaviorSubject<SingleSend>(null);

  @Exclude()
  readonly changes$ = this._changes$.asObservable();

  @Exclude()
  private _hash: number;

  @Exclude()
  private _attachmentHash: number;

  @Exclude()
  private _blockHash: number;

  @Exclude()
  private _mappedContentHash: number;

  @Type(() => Segment)
  associatedSegment: Segment;

  @Type(() => Theme)
  theme: Theme;

  @Type(() => SingleSendBlock, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'blockType',
      subTypes: [
        { value: TextSingleSendBlock, name: 'text' },
        { value: HeaderSingleSendBlock, name: 'header' },
        { value: ImageSingleSendBlock, name: 'image' },
        { value: SpacerSingleSendBlock, name: 'spacer' },
        { value: FooterSingleSendBlock, name: 'footer' },
        { value: DividerSingleSendBlock, name: 'divider' },
        { value: ButtonSingleSendBlock, name: 'button' },
        { value: YoutubeSingleSendBlock, name: 'youtube' },
        { value: EventSingleSendBlock, name: 'event' },
        { value: QuoteSingleSendBlock, name: 'quote' },
        { value: SignupSingleSendBlock, name: 'signup' },
        { value: UpcomingEventsSingleSendBlock, name: 'upcomingEvents' },
        { value: NlqSummarySingleSendBlock, name: 'nlqSummary' },
        { value: NlqPromptSingleSendBlock, name: 'nlqPrompt' }
      ]
    }
  })
  readonly blocks: SingleSendBlock[];

  readonly id: string;
  readonly sendAt: string;
  readonly draft: boolean;
  readonly hasBeenSent: boolean;
  readonly renderedBody: string;

  get hash() {
    if (!this._hash) {
      const theme = this.theme?.hash ?? 0;
      const props = `
        ${this.blockHash},
        ${this.deliveryType},
        ${this.marketingCommunicationType},
        ${this.subject},
        ${this.senderName},
        ${this.previewText},
        ${this.replyTo},
        ${this.attachmentHash},
        ${this.mappedContentHash}
        ${theme}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  get attachmentHash() {
    if (!this._attachmentHash) {
      const att = this.attachments?.reduce((a, att) => `${a}${att.source}`, '');
      this._attachmentHash = hashString(att);
    }

    return this._attachmentHash;
  }

  get blockHash() {
    if (!this._blockHash) {
      const blocks = this.blocks?.reduce((a, block) => `${a}${block.hash}`, '');
      this._blockHash = hashString(blocks);
    }

    return this._blockHash;
  }

  get mappedContentHash() {
    if (!this._mappedContentHash) {
      let content = '';
      if (this.contentBlockMap) {
        content = Object.values(this.contentBlockMap)?.reduce(
          (a, content) => `${a}${content.hash}`,
          ''
        );
      }
      this._mappedContentHash = hashString(content);
    }
    return this._mappedContentHash;
  }

  get firstTextBlockContent() {
    const firstTextBlock = this.blocks?.find(
      (block) => block.blockType === 'text'
    ) as TextSingleSendBlock;
    return firstTextBlock?.body;
  }

  get isValid() {
    return (
      this.deliveryType &&
      !this.hasBeenSent &&
      !(
        this.deliveryType === 'sms' &&
        !this.firstTextBlockContent &&
        !(this.attachments?.length > 0)
      ) &&
      !(
        this.deliveryType === 'email' &&
        (!this.marketingCommunicationType || !this.subject)
      ) &&
      this.eventUpdatesConditionsAreSatisfied
    );
  }

  get eventUpdatesConditionsAreSatisfied() {
    if (
      this.deliveryType !== 'email' ||
      this.marketingCommunicationType !==
        EmailMarketingCommunicationTypeEnum.EVENT_UPDATES
    ) {
      return true;
    }

    // Email sends of type Event Updates must include at least one filter.
    if (!this.associatedSegment?.filterGroups?.length) {
      return false;
    }

    const allowedFilterTypes = EVENT_FILTERS;
    const contentIds = new Set<string>();
    let res = true;

    this.associatedSegment?.filterGroups.forEach((fg) =>
      fg.filters.forEach((f: any) => {
        // All filters for email sends of type Event Updates must refer to an event.
        if (!f.contentId || !allowedFilterTypes.has(f.type)) {
          res = false;
        }

        // All filters for email sends of type Event Updates must refer to the same event.
        if (contentIds.size && !contentIds.has(f.contentId)) {
          res = false;
        }

        contentIds.add(f.contentId);
      })
    );

    if (this.associatedSegment.includedContacts.length > 0) {
      res = false;
    }

    return res;
  }

  updateProperties(properties: Partial<ISingleSendV2>, emitEvent = true) {
    // Only update props that aren't @Excluded in the toObject transform
    const props =
      properties instanceof SingleSend ? properties.toObject() : properties;

    for (const key in props) {
      this[key] = properties[key];
    }

    // Clear hashes
    this._hash = null;

    if (properties?.attachments) {
      this._attachmentHash = null;
    }

    if (properties?.blocks) {
      this._blockHash = null;
    }

    // Publish changes
    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  static new() {
    return plainToClass(SingleSend, {
      blocks: [
        HeaderSingleSendBlock.new().toObject(),
        TextSingleSendBlock.new().toObject(),
        FooterSingleSendBlock.new().toObject()
      ],
      attachments: [],
      associatedSegment: {
        includedContacts: [],
        excludedContacts: []
      }
    });
  }

  clone(withSend?: Partial<SingleSend>) {
    const clone = instanceToInstance(this);
    if (withSend) {
      clone.updateProperties(withSend);
    }
    return clone;
  }

  toObject() {
    return instanceToPlain(this) as ISingleSendV2;
  }

  static fromObject(object: any) {
    return plainToClass(SingleSend, {
      ...object
    });
  }

  addIncludedContact(contact: SegmentContactPreview, emitEvent = true) {
    this.associatedSegment?.addIncludedContact(contact);

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  removeIncludedContact(contactId: string, emitEvent = true) {
    this.associatedSegment?.removeIncludedContact(contactId);

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  addExcludedContact(contact: SegmentContactPreview, emitEvent = true) {
    this.associatedSegment?.addExcludedContact(contact);

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  removeExcludedContact(contactId: string, emitEvent = true) {
    this.associatedSegment?.removeExcludedContact(contactId);

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  addContentToMap(content: Content) {
    if (!this.contentBlockMap) {
      this.contentBlockMap = {};
    }

    if (content?.contentId) {
      this.contentBlockMap[content.contentId] = content;
      this._mappedContentHash = null;
      this._hash = null;
    }

    return this;
  }

  addContentArrayToMap(contentArray: Content[]) {
    if (!this.contentBlockMap) {
      this.contentBlockMap = {};
    }

    if (contentArray?.length) {
      contentArray.forEach((content) => {
        if (content?.contentId) {
          this.contentBlockMap[content.contentId] = content;
        }
      });
      this._mappedContentHash = null;
      this._hash = null;
    }

    return this;
  }

  removeBlockAtIndex(idx: number, emitEvent = true) {
    this.blocks?.splice(idx, 1);
    this._blockHash = null;
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  updateBlockAtIndex(idx: number, block: SingleSendBlock, emitEvent = true) {
    this.blocks[idx] = block;
    this._blockHash = null;
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  insertBlockAtIndex(idx: number, block: SingleSendBlock, emitEvent = true) {
    if (idx < 0) {
      return;
    } else if (idx > this.blocks.length) {
      this.blocks.push(block);
    } else {
      this.blocks.splice(idx, 0, block);
    }

    this._blockHash = null;
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  toCreateDTO(): CreateSingleSendDTO {
    const {
      deliveryType,
      marketingCommunicationType,
      subject,
      previewText,
      senderName,
      sendAt,
      draft,
      disableNorbyBrandedTracking,
      replyTo,
      attachments,
      webhosted,
      theme
    } = this.toObject();

    const blocks =
      this.blocks?.reduce(
        (acc, block) => (block.isEmpty ? acc : [...acc, block.toObject()]),
        []
      ) ?? [];

    return {
      ...(deliveryType && { deliveryType }),
      ...(marketingCommunicationType && { marketingCommunicationType }),
      ...(previewText && { previewText }),
      ...(senderName && { senderName }),
      ...(replyTo && { replyTo }),
      ...(attachments && { attachments }),
      subject: deliveryType === 'email' ? subject : null,
      blocks,
      sendAt: sendAt ?? new Date().toISOString(),
      draft: draft ?? false,
      disableNorbyBrandedTracking: disableNorbyBrandedTracking ?? false,
      excludedContactIds:
        this.associatedSegment?.excludedContacts?.map(
          (contact) => contact.contactId
        ) ?? [],
      includedContactIds:
        this.associatedSegment?.includedContacts?.map(
          (contact) => contact.contactId
        ) ?? [],
      filterGroups:
        this.associatedSegment?.filterGroups.map((group) =>
          group.toCreateDTO()
        ) ?? [],
      webhosted: webhosted ?? false,
      ...(theme && { theme })
    };
  }

  toTestMessageDTO(): SingleSendTestMessageDTO {
    const {
      deliveryType,
      subject,
      previewText,
      senderName,
      disableNorbyBrandedTracking,
      replyTo,
      attachments,
      theme
    } = this.toObject();

    const blocks =
      this.blocks?.reduce(
        (acc, block) => (block.isEmpty ? acc : [...acc, block.toObject()]),
        []
      ) ?? [];

    return {
      ...(subject && { subject }),
      ...(previewText && { previewText }),
      ...(senderName && { senderName }),
      ...(replyTo && { replyTo }),
      ...(attachments && { attachments }),
      blocks,
      disableNorbyBrandedTracking: disableNorbyBrandedTracking ?? false,
      deliveryType: deliveryType ?? 'email',
      ...(theme && { theme })
    };
  }
}

export class CreateSingleSendDTO {
  deliveryType?: NotificationMediums;
  marketingCommunicationType: EmailMarketingCommunicationTypes;
  blocks: ISingleSendBlock[];
  subject: string;
  previewText: string;
  senderName: string;
  sendAt!: string;
  draft!: boolean;
  disableNorbyBrandedTracking: boolean;
  replyTo: string;
  attachments?: MmsAttachment[];
  excludedContactIds: string[];
  includedContactIds: string[];
  filterGroups: ISegmentFilterGroupDTO[];
  webhosted: boolean;
  theme?: IThemeDTO;
}

export class CreateSingleSendResponseDTO {
  singleSendId: string;
  status: boolean;
}

export class SingleSendTestMessageDTO {
  deliveryType?: NotificationMediums;
  blocks: ISingleSendBlock[];
  subject: string;
  previewText: string;
  senderName: string;
  disableNorbyBrandedTracking: boolean;
  replyTo: string;
  attachments?: MmsAttachment[];
  deliverToEmails?: string[];
  theme?: IThemeDTO;
}

export class IPSQLSendResultEdge {
  cursor?: string;
  node!: ISingleSendV2;
  offset: number;
}

export class IPSQLSendResults extends IQueryResult {
  pageInfo!: PageInfo;
  edges!: IPSQLSendResultEdge[];
}

export enum SendUserVariableTypeEnum {
  USER_FIRST_NAME = 'USER_FIRST_NAME',
  USER_LAST_NAME = 'USER_LAST_NAME',
  USER_FULL_NAME = 'USER_FULL_NAME'
}

export enum SendContentVariableTypeEnum {
  CONTENT_LOCATION = 'CONTENT_LOCATION',
  CONTENT_TITLE = 'CONTENT_TITLE',
  CONTENT_URL = 'CONTENT_URL',
  CONTENT_PAGE_URL = 'CONTENT_PAGE_URL',
  CONTENT_REFERRAL_URL = 'CONTENT_REFERRAL_URL',
  USER_REFERRAL_URL = 'USER_REFERRAL_URL',
  CONTENT_IMAGE = 'CONTENT_IMAGE',
  CONTENT_SUBTITLE = 'CONTENT_SUBTITLE',
  CONTENT_PASSWORD = 'CONTENT_PASSWORD',
  CONTENT_START_DATE = 'CONTENT_START_DATE',
  CONTENT_END_DATE = 'CONTENT_END_DATE',
  APPLE_CALENDAR_URL = 'APPLE_CALENDAR_URL',
  APPLE_CALENDAR = 'APPLE_CALENDAR',
  GOOGLE_CALENDAR_URL = 'GOOGLE_CALENDAR_URL',
  GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
  OUTLOOK_CALENDAR_URL = 'OUTLOOK_CALENDAR_URL',
  OUTLOOK_CALENDAR = 'OUTLOOK_CALENDAR',
  TOTAL_TICKETS_PURCHASED = 'TOTAL_TICKETS_PURCHASED',
  USER_PROMPT_RESPONSES = 'USER_PROMPT_RESPONSES'
}

export enum SendVariableDisplayTextEnum {
  USER_FIRST_NAME = 'First name',
  USER_LAST_NAME = 'Last name',
  USER_FULL_NAME = 'Full name',
  CONTENT_LOCATION = 'Location',
  CONTENT_TITLE = 'Title',
  CONTENT_URL = 'Join URL',
  CONTENT_PAGE_URL = 'Page URL',
  CONTENT_REFERRAL_URL = 'Content referral URL', //Deprecated
  USER_REFERRAL_URL = 'Referral link',
  CONTENT_IMAGE = 'Image', //Deprecated
  CONTENT_SUBTITLE = 'Subtitle',
  CONTENT_PASSWORD = 'Password', //Deprecated
  CONTENT_START_DATE = 'Start date',
  CONTENT_END_DATE = 'End date',
  APPLE_CALENDAR_URL = 'Apple Calendar URL',
  APPLE_CALENDAR = '"Add to Apple Calendar" link',
  GOOGLE_CALENDAR_URL = 'Google Calendar URL',
  GOOGLE_CALENDAR = '"Add to Google Calendar" link',
  OUTLOOK_CALENDAR_URL = 'Outlook Calendar URL',
  OUTLOOK_CALENDAR = '"Add to Outlook Calendar" link',
  TOTAL_TICKETS_PURCHASED = 'Total tickets purchased',
  USER_PROMPT_RESPONSES = 'Custom field responses'
}

export class SendVariable {
  type: SendUserVariableTypeEnum | SendContentVariableTypeEnum;
  contentId?: string;
  defaultValue?: string;
}

export class SendSummaryDTO {
  pending: number;
  sent: number;
  delivered: number;
  undelivered?: number;
  failed?: number;
  received?: number;
  unsubscribed: number;
  totalRecipients: number;
  deferred?: number;
  blocked?: number;
  bounced?: number;
  processed?: number;
  dropped?: number;
  spamreport?: number;
}
