<div class="relative">
  <input
    class="form-control text-base block transition ease-in-out outline-none w-full h-12 px-3 pb-4 pt-3.5 border rounded-sm bg-white disabled:bg-neutral-50 text-neutral-800 disabled:text-neutral-500 placeholder:text-neutral-500 hover:placeholder:text-neutral-600 border-neutral-300 hover:border-neutral-500 focus:border-mustard disabled:border-neutral-300"
    [ngClass]="{
      'rounded-sm': !isJoinedRight && !isJoinedLeft,
      'rounded-l-sm rounded-r-none': isJoinedRight && !isJoinedLeft,
      'rounded-r-sm rounded-l-none': isJoinedLeft && !isJoinedRight,
      'rounded-none': isJoinedLeft && isJoinedRight,
      'border-red-ui': touched && hasErrors
    }"
    [type]="type"
    [value]="value || val || ''"
    [disabled]="isDisabled"
    [required]="isRequired"
    [readonly]="isReadOnly"
    (input)="handleInput($event.target.value)"
    [attr.maxlength]="maxLength"
    [placeholder]="placeholder ? placeholder : ''"
  />
  <div
    class="text-red-base absolute ml-2 top-1/2 transform -translate-y-1/2 right-3"
  >
    <norby-icon
      *ngIf="hasErrors"
      name="alert-octagon"
      [rootTooltip]="errorText"
    ></norby-icon>
  </div>
</div>
