<div class="flex justify-start" *ngIf="options">
  <div class="mb-3 w-full">
    <norby-formcontrol-label
      [label]="label"
      [infoTooltip]="infoTooltip"
      [isRequired]="isRequired"
    ></norby-formcontrol-label>
    <div class="flex justify-between">
      <div
        [ngClass]="{
        'custom-slider-small': size === 'small',
        'custom-slider': size === 'normal',
        'flex-grow': !allowNull,
        'slider-with-reset-button': allowNull
      }"
      >
        <ngx-slider
          [value]="val"
          [options]="options"
          [ngClass]="{ 'resettable-slider-margin-top': allowNull }"
          (valueChange)="handleValueChange($event)"
        ></ngx-slider>
      </div>
      <norby-button
        *ngIf="allowNull"
        text="Reset"
        buttonType="secondary"
        [isDisabled]="isButtonDisabled"
        (buttonClicked)="handleResetValueClick()"
      ></norby-button>
    </div>
    <norby-helpertext [helperText]="helperText"></norby-helpertext>
  </div>
</div>
