import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';

// 3rd party
import {
  Appearance,
  CssFontSource,
  CustomFontSource,
  loadStripe,
  Stripe,
  StripeAddressElement,
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripePaymentElement,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { switchMap, tap } from 'rxjs';

// App
import {
  ContentRegisterable,
  ISlug,
  ITicket,
  IUserContent,
  STRIPE_CLIENT_KEY_TOKEN,
  USER_CONTENT_KEY,
  CONTENT_KEY
} from 'models';
import { BaseComponent } from 'uikit';
import {
  ContentService,
  LocalStorageService,
  SlugService
} from '../../services';

@Component({
  selector: 'lib-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFormComponent extends BaseComponent implements OnInit {
  @Input() content: ContentRegisterable;
  @Input() userContent: IUserContent;
  @Input() ticket: ITicket;
  @Input() isDisabled = false;
  @Input() isFinalScreen = false;
  @Input() themeClasses: string[];
  @Input() promoCode: string;

  @Output() onSuccess = new EventEmitter<void>();
  @Output() onError = new EventEmitter<string>();

  stripe: Stripe;
  elements: StripeElements;
  payment: StripePaymentElement;
  address: StripeAddressElement;

  isLoading = true;
  isSubmitting = false;
  isPaymentFormComplete = false;

  slug: ISlug;
  paymentIntentSecret: string;

  fonts: (CssFontSource | CustomFontSource)[];
  appearance: Appearance;

  constructor(
    @Inject(STRIPE_CLIENT_KEY_TOKEN) private _stripeClientKey: string,
    private _content: ContentService,
    private _slug: SlugService,
    private _local: LocalStorageService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this._slug
      .getCurrentSlug$()
      .pipe(
        tap((slug) => this._initStyles(slug)),
        switchMap(() => this._initPaymentIntent()),
        switchMap(() => this._initStripe()),
        switchMap(() => this._initAddressElement()),
        switchMap(() => this._initPaymentElement()),

        this.takeUntilDestroy
      )
      .subscribe(() => {
        this.isLoading = false;
        this._cdr.detectChanges();
      });
  }

  private _initStyles(slug: ISlug) {
    this.slug = slug;

    const slugTheme = this.slug?.theme;
    const contentTheme = this.content?.theme;

    const font = contentTheme?.fonts?.font ?? slugTheme?.fonts?.font;
    const familyParts = font?.fontFamily?.split('"') ?? [];
    const inferredFamily =
      familyParts.length > 1 ? familyParts[1] : font?.fontFamily;

    this.fonts = font?.isCustom
      ? [
          {
            src: font?.importUrl,
            family: inferredFamily,
            style: 'normal'
          }
        ]
      : font
        ? [
            {
              cssSrc: font?.importUrl
            }
          ]
        : [];

    const buttonBackgroundColor =
      contentTheme?.button?.backgroundColor ??
      slugTheme?.button?.backgroundColor ??
      '#000';
    const cardTextColor =
      contentTheme?.card?.textColor ?? slugTheme?.card?.textColor ?? '#000';
    const textInputBackground =
      contentTheme?.textInput?.backgroundColor ??
      slugTheme?.textInput?.backgroundColor ??
      '#fff';
    const borderRadius = `${contentTheme?.textInput?.borderRadius ?? slugTheme?.textInput?.borderRadius ?? 4}px`;
    const textInputShadow =
      contentTheme?.textInput?.dropShadow ??
      slugTheme?.textInput?.dropShadow ??
      'none';
    const textInputBorderColor =
      contentTheme?.textInput?.borderColor ??
      slugTheme?.textInput?.borderColor ??
      '#000';
    const textInputTextColor =
      contentTheme?.textInput?.textColor ??
      slugTheme?.textInput?.textColor ??
      '#000';
    const buttonHoverBorderColor =
      contentTheme?.button?.hoverBorderColor ??
      slugTheme?.button?.hoverBorderColor ??
      '#000';

    this.appearance =
      slugTheme || contentTheme
        ? {
            variables: {
              colorPrimary: buttonBackgroundColor,
              colorText: cardTextColor,
              colorBackground: textInputBackground,
              colorTextPlaceholder: '#C1C4D6',
              fontFamily: inferredFamily,
              fontSizeXl: '24px',
              fontSizeLg: '20px',
              fontSizeBase: '16px',
              fontSizeSm: '12px',
              fontSizeXs: '10px',
              fontSize2Xs: '9px',
              fontSize3Xs: '8px',
              borderRadius: borderRadius,
              gridRowSpacing: '20px'
            },
            rules: {
              '.Input': {
                boxShadow: textInputShadow,
                borderColor: textInputBorderColor,
                borderRadius: borderRadius,
                color: textInputTextColor
              },
              '.Input:hover': {
                boxShadow: textInputShadow,
                borderColor: buttonHoverBorderColor,
                color: textInputTextColor
              }
            }
          }
        : {};
  }

  private async _initPaymentIntent() {
    if (this.paymentIntentSecret) {
      return;
    }

    const intent = await this._content.getPaymentIntentSecret(
      this.content.contentId,
      this.ticket?.label,
      this.ticket?.qty,
      this.promoCode
    );

    this.paymentIntentSecret = intent?.secret;
  }

  private async _initStripe() {
    if (this.stripe || !this._stripeClientKey) {
      return;
    }

    this.stripe = await loadStripe(this._stripeClientKey);

    const options: StripeElementsOptionsClientSecret = {
      locale: 'en',
      fonts: this.fonts,
      appearance: this.appearance,
      clientSecret: this.paymentIntentSecret
    };

    // const options: StripeElementsOptionsMode = {
    //   locale: 'en',
    //   fonts: this.fonts,
    //   appearance: this.appearance,
    //   amount: 1000,
    //   currency: 'usd',
    //   mode: 'payment'
    // };

    this.elements = this.stripe.elements(options);
  }

  private async _initPaymentElement() {
    if (this.payment || !this.elements) {
      return;
    }

    const paymentElementOptions: StripePaymentElementOptions = {
      layout: 'tabs'
    };
    this.payment = this.elements.create('payment', paymentElementOptions);
    this.payment.mount('#payment-element');
    this.payment.on('change', (event) => {
      this.isPaymentFormComplete = event.complete;
      this._cdr.detectChanges();
    });
  }

  private async _initAddressElement() {
    // if (this.address || !this.elements) {
    //   return;
    // }
    // this.address = this.elements.create('address', { mode: 'shipping' });
    // this.address.mount('#address-element');
  }

  handleCompleteCardPayment() {
    this.isSubmitting = true;
    this._cdr.detectChanges();

    // Save the in progress UserContent to local storage
    // If the payment flow requires a redirect, we'll use
    // this to pick back up on the other side and complete
    // the registration
    this._local.set(USER_CONTENT_KEY, this.userContent);
    this._local.set(CONTENT_KEY, this.content.toObject());

    // Set the redirect URL for flows that require it to
    // the current page with a flag appended at the end
    // On page load we can check for the presence of this
    // flag and the UserContent object in local storage
    // to complete registration after the user has completed
    // payment
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.append('finishRegistration', '1');

    this.stripe
      .confirmPayment({
        elements: this.elements,
        clientSecret: this._stripeClientKey,
        confirmParams: {
          return_url: returnUrl.href
        },
        redirect: 'if_required'
      })
      .then((result) => {
        if (result.error) {
          this.onError.emit(result.error.message ?? '');
        } else if (result.paymentIntent?.status === 'succeeded') {
          this.onSuccess.emit();
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        this.isSubmitting = false;
        this._cdr.detectChanges();
      });
  }
}
