import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FormItemDirective,
  InputGroupSuffixDirective,
  ListItemDirective,
  ListDirective,
  NestedInputGroupDirective,
  InputGroupDirective
} from '../directives';

@NgModule({
  declarations: [
    FormItemDirective,
    NestedInputGroupDirective,
    InputGroupDirective,
    InputGroupSuffixDirective,
    ListDirective,
    ListItemDirective
  ],
  imports: [CommonModule],
  exports: [
    FormItemDirective,
    NestedInputGroupDirective,
    InputGroupDirective,
    InputGroupSuffixDirective,
    ListDirective,
    ListItemDirective
  ]
})
export class FormModule {}
