import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { TooltipComponent } from '../root-components/tooltip/tooltip.component';
import { THEME_CLASSES, POSITION_MAP } from '../constants';

@Directive({
  selector: '[rootTooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('rootTooltip') text = '';
  @Input() tooltip: TemplateRef<any>;
  @Input() delayClose: boolean = false;
  @Input() themeClasses: string[];

  private _delayIndex: number = 0;
  private _mouseOnHost: boolean = false;
  private _overlayRef: OverlayRef;

  @HostListener('mouseenter')
  show() {
    this._mouseOnHost = true;
    if (!this._overlayRef.hasAttached()) {
      this._initOverlay();
    } else {
      this._delayIndex++;
    }
  }

  @HostListener('mouseleave')
  hide() {
    this._mouseOnHost = false;
    if (this.delayClose) {
      const index = this._delayIndex;

      setTimeout(() => {
        if (
          this._overlayRef.hasAttached() &&
          !this._mouseOnHost &&
          index === this._delayIndex
        ) {
          this._overlayRef.detach();
        }
      }, 2000);
    } else {
      this._overlayRef.detach();
    }
  }

  constructor(
    @Optional() @Inject(THEME_CLASSES) private _themeClasses,
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        POSITION_MAP.topMiddleTooltip,
        POSITION_MAP.bottomMiddleTooltip
      ]);

    this._overlayRef = this._overlay.create({
      positionStrategy,
      scrollStrategy: this._overlay.scrollStrategies.reposition()
    });
    if (!!this._themeClasses) {
      this._themeClasses.forEach((elem: string) => {
        this._overlayRef.addPanelClass(`${elem}`);
      });
    } else if (!!this.themeClasses) {
      this.themeClasses.forEach((elem: string) => {
        this._overlayRef.addPanelClass(`${elem}`);
      });
    }
  }

  ngOnDestroy(): void {
    this._overlayRef.detach();
  }

  private _initOverlay() {
    if (this.text || this.tooltip) {
      const tooltipRef: ComponentRef<TooltipComponent> =
        this._overlayRef.attach(new ComponentPortal(TooltipComponent));
      tooltipRef.instance.tooltipText = this.text;
      tooltipRef.instance.tooltip = this.tooltip;
    }
  }
}
