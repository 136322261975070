import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SendsVariableSelectorDirective } from '../directives/sends-variable-selector.directive';
import { NorbySendsVariableSelectorComponent } from '../components/molecules/norby-sends-variable-selector/norby-sends-variable-selector.component';
import { FormModule } from './form.module';
import { DropdownModule } from './dropdown.module';
import { NorbyIconModule } from './norby-icon.module';
import { NorbyButtonModule } from './norby-button.module';
import { NorbyCustomFilterDropdownModule } from './norby-custom-filter-dropdown.module';
import { SearchResultsModule } from './search-results.module';
import { NorbyInputBaseModule } from './norby-input-base.module';
import { SpinModule } from './spin.module';

@NgModule({
  declarations: [
    SendsVariableSelectorDirective,
    NorbySendsVariableSelectorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormModule,
    DropdownModule,
    SearchResultsModule,
    SpinModule,

    NorbyIconModule,
    NorbyInputBaseModule,
    NorbyButtonModule,
    NorbyCustomFilterDropdownModule
  ],
  exports: [SendsVariableSelectorDirective, NorbySendsVariableSelectorComponent]
})
export class SendsVariableSelectorModule {}
