<div class="table-cell w-full align-top">
  <h3
    *ngIf="!!title"
    class="w-full h-4 mt-4 bg-skeleton-row rounded-sm"
    [style.width]="toCSSUnit(titleProps.width)"
  ></h3>
  <ul *ngIf="!!paragraph" class="skeleton p-0 m-0" [class.mt-6]="!!title">
    <li
      class="w-full h-4 list-none bg-skeleton-row rounded-sm"
      *ngFor="let row of rowsList; let i = index"
      [style.width]="toCSSUnit(widthList[i])"
    ></li>
  </ul>
</div>
