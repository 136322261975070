import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ISlug, NlqRecurringReport } from 'models';

@Component({
  selector: 'norby-report-detail-view',
  templateUrl: './report-detail-view.component.html',
  styleUrls: ['./report-detail-view.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportDetailViewComponent {
  private _cachedSendHash: number;

  @Output() menuStateChanged = new EventEmitter<void>();

  @Input() report: NlqRecurringReport;
  @Input() slug: ISlug;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngDoCheck() {
    if (this.report && this.report.hash !== this._cachedSendHash) {
      this._cachedSendHash = this.report.hash;
      this._cdr.markForCheck();
    }
  }

  handleMenuStateChanged() {
    this.menuStateChanged.next();
  }

  get fromName(): string {
    return this.slug?.accountInfo?.title || 'No name';
  }

  get replyTo(): string {
    return this.slug?.mailing?.replyTo || 'No reply to';
  }

  trackBy(idx: number, item) {
    return item.hash;
  }
}
