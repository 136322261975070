// 3rd party
import {
  Exclude,
  Expose,
  Transform,
  Type,
  instanceToPlain,
  plainToClass
} from 'class-transformer';
import dayjs from 'dayjs';

// Lib
import { IQueryResult, PageInfo } from './general';
import { hashString, uuidv4 } from '../tools';
import { NlqExampleQuestion } from './example-questions';

export type PaginatedMessageFilters = {
  id?: string;
  after?: string;
  offset?: string;
  limit?: number;
};

export enum AiConversationTypes {
  EVENT_GENERATION = 'eventGeneration',
  SIGNUP_GENERATION = 'signupGeneration',
  ANALYTICS_NLQ = 'analyticsNLQ'
}
export type AiConversationType = `${AiConversationTypes}`;

export enum AiConversationStates {
  NEEDS_INITIAL_USER_RESPONSE = 'needsInitialUserResponse',
  THINKING = 'thinking',
  ACTIVE = 'active',
  COMPLETE = 'complete',
  CLOSED = 'closed',
  INITIALIZING = 'initializing'
}
export type AiConversationState = `${AiConversationStates}`;

export abstract class AiConversationResultResponseBase {
  _conversationType!: AiConversationType;
}

export class AiConversationResultResponseEvent extends AiConversationResultResponseBase {
  startDate!: string;
  endDate!: string;
  confirmationMessage!: string;
  isOnline: boolean;
  location: string;
  title!: string;
  subtitle!: string;
  description!: string;
  colorScheme!: string[];
}

export class AiConversationResultResponseSignup extends AiConversationResultResponseBase {
  confirmationMessage!: string;
  title!: string;
  subtitle!: string;
  description!: string;
  colorScheme!: string[];
}

export class AiConversationResultResponseAnalytics extends AiConversationResultResponseBase {}

export class StartConversationDto {
  conversationType!: AiConversationType;
  message?: string;
}

export class StartConversationResponseDto {
  conversationId!: string;
  replyMessage!: string;
  state!: AiConversationState;
}

export class ReplyToConversationDto {
  message?: string;
}

export class RateAIConversationMessageDTO {
  rating?: number;
  ratingReason?: string;
}

export class ReplyToConversationResponse {
  conversationId!: string;
  replyMessage?: string;
  state!: AiConversationState;
  inbound: boolean;

  @Type(() => AiConversationResultResponseBase, {
    discriminator: {
      property: '_conversationType',
      subTypes: [
        { value: AiConversationResultResponseEvent, name: 'eventGeneration' },
        { value: AiConversationResultResponseSignup, name: 'signupGeneration' },
        { value: AiConversationResultResponseAnalytics, name: 'analyticsNLQ' }
      ]
    }
  })
  data?: AiConversationResultResponseEvent | AiConversationResultResponseSignup;

  static fromObject(object: any) {
    return plainToClass(ReplyToConversationResponse, {
      ...object
    });
  }
}

export class IConversation {
  id: string;
  slug: string;
  userId: string;
  model: string;
  conversationType: string;
  state: AiConversationState;
  role: string;
  content: string;
  createdAt: string;
  createdAtCursor: string;
  modifiedAt: string;
  modifiedAtCursor: string;
  conversationTitle: string;
}

export class Conversation extends IConversation {
  static fromObject(object: any) {
    return plainToClass(Conversation, { ...object });
  }
}

export class IConversationResultsEdge {
  cursor?: string;
  node!: IConversation;
  offset: number;
}

export class IConversationResults extends IQueryResult {
  pageInfo!: PageInfo;
  edges!: IConversationResultsEdge[];
}

export class IConversationMessage {
  id: string;
  slug: string;
  conversationId: string;
  role: string;
  content: string;
  createdAt: string;
  createdAtCursor: string;
  variantExampleQuestions?: NlqExampleQuestion[];
  isPublic?: boolean;
  metadata?: AiConversationMessageMetadataDto;
  rating?: number;
  ratingReason?: string;
}

export class AiConversationMessageMetadataDto {
  shortLinkUrl?: string;
}

const H1_H2_REGEX = /<h[12]\b[^>]*>(.*?)<\/h[12]>/;
const P_REGEX = /<p\b[^>]*>(.*?)<\/p>/;

export class ConversationMessage extends IConversationMessage {
  @Exclude()
  private _timestamp: dayjs.Dayjs;

  @Exclude()
  private _displayHtml: string;

  @Exclude()
  private _plaintextTitle: string;

  @Exclude()
  private _plaintextDescription: string;

  @Expose()
  @Transform((obj) => obj?.value ?? `optimistic-${uuidv4()}`, {
    toClassOnly: true
  })
  id: string;

  static fromObject(object: any) {
    return plainToClass(ConversationMessage, { ...object });
  }

  toObject() {
    return instanceToPlain(this) as IConversationMessage;
  }

  get isInbound(): boolean {
    return !this.isOptimistic && (!this.role || this.role === 'assistant');
  }

  get isOutbound(): boolean {
    return !this.isInbound;
  }

  get isOptimistic(): boolean {
    return !this.id || this.id.startsWith('optimistic');
  }

  get timestamp(): dayjs.Dayjs {
    if (!this._timestamp) {
      this._timestamp = this.createdAt ? dayjs(this.createdAt) : null;
    }

    return this._timestamp;
  }

  get title(): string {
    if (!this._plaintextTitle) {
      this._plaintextTitle =
        this.content?.match?.(H1_H2_REGEX)?.[1] ?? 'Norby Chat';
    }

    return this._plaintextTitle;
  }

  get description(): string {
    if (!this._plaintextDescription) {
      this._plaintextDescription =
        this.content?.match?.(P_REGEX)?.[1] ?? 'Norby Chat';
    }

    return this._plaintextDescription;
  }

  get displayHtml(): string {
    if (!this._displayHtml) {
      const regex = new RegExp(`<(table)([^>]*)>([\\s\\S]*?)<\\/table>`, 'gi');
      this._displayHtml = this.content.replace(
        regex,
        (match, tag, attrs, content) => {
          const regex = new RegExp(`<tr(\\s|>)`, 'gi');
          const matches = content.match(regex);
          const count = matches ? matches.length - 1 : 0;
          const footerHtml =
            count > 4
              ? `<div class="table-footer">${count} row${count === 1 ? '' : 's'}</div>`
              : '';

          return `<div class="table-outer-wrapper">
                    <div class="table-inner-wrapper">
                      <${tag}${attrs}>${content}</${tag}>
                      ${footerHtml}
                    </div>
                  </div>`;
        }
      );
    }

    return this._displayHtml;
  }
}

export class ConversationMessageGroup {
  @Exclude()
  private _hash: number;

  private _messages: ConversationMessage[] = [];

  static fromMessages(messages: ConversationMessage[]) {
    const group = plainToClass(ConversationMessageGroup, {});
    group.setMessages(messages);
    return group;
  }

  // Messages should be kept in sorted order with newest messages at the end
  setMessages(messages: ConversationMessage[]) {
    this._messages = messages;
    this._hash = null;
  }

  // Update a message in place
  updateMessageAtIndex(index: number, message: ConversationMessage) {
    this._messages.splice(index, 1, message);
    this._hash = null;
  }

  // Add an old message to the front of the array
  addOlderMessage(message: ConversationMessage) {
    this._messages.unshift(message);
    this._hash = null;
  }

  // Add a new message to the end of the array
  addNewerMessage(message: ConversationMessage) {
    // Deduplication for race condition when conversations are started
    // The first message might come back in the lookup call and then
    // also have a topic event, which results in it being displayed twice
    // Whenever adding a new message to a group, we just check the most
    // recent previous message in that group to make sure it's not
    // a dupe
    const prevNewest = this._messages[this._messages.length - 1];
    if (prevNewest?.id !== message?.id) {
      this._messages.push(message);
      this._hash = null;
    }
  }

  removeMessageAtIndex(idx: number) {
    this._messages.splice(idx, 1);
    this._hash = null;
  }

  reset() {
    this._messages = [];
    this._hash = null;
  }

  // Messages are grouped by their direction and whether or not
  // they are optimistic, so if one message in the group is inbound
  // they all are, if one message in the group is optimistic they
  // all are, etc
  get isOptimistic() {
    return this.newestMessage?.isOptimistic;
  }

  get isInbound() {
    return this.newestMessage?.isInbound;
  }

  get isOutbound() {
    return this.newestMessage?.isOutbound;
  }

  // The newest message in the group is the last one in the list
  get newestMessage() {
    return this.length ? this._messages[this.length - 1] : null;
  }

  // The oldest message in a group is teh first one in the list
  get oldestMessage() {
    return this.length ? this._messages[0] : null;
  }

  get length() {
    return this._messages.length;
  }

  get messages() {
    return this._messages;
  }

  get hash() {
    if (!this._hash) {
      const messageIds = this._messages.reduce(
        (acc, curr) => `${curr.id}${acc}`,
        ''
      );
      this._hash = hashString(messageIds);
    }

    return this._hash;
  }
}

export class IConversationMessageResultsEdge {
  cursor?: string;
  node!: IConversationMessage;
  offset: number;
}

export class IConversationMessagesResults extends IQueryResult {
  pageInfo!: PageInfo;
  edges!: IConversationMessageResultsEdge[];
}
