<div class="flex flex-row gap-4 mb-4">
  <div
    class="flex flex-col gap-2 items-center justify-center cursor-pointer"
    (click)="handleStyleSelection('brand')"
  >
    <div
      class="w-30 h-30 border rounded flex items-center justify-center bg-neutral-100"
      [ngClass]="{
        'border-neutral-900': val === 'brand',
        'hover:border-neutral-500': val !== 'brand'
      }"
    >
      <norby-image
        *ngIf="imageUrl"
        [imageUrl]="imageUrl"
        [width]="width"
      ></norby-image>
    </div>
    <label>Logo Only</label>
  </div>
  <div
    class="flex flex-col gap-2 items-center justify-center cursor-pointer"
    (click)="handleStyleSelection('creator')"
  >
    <div
      class="w-30 h-30 border rounded flex flex-col items-center justify-center"
      [ngClass]="{
        'border-neutral-900': val === 'creator',
        'hover:border-neutral-500': val !== 'creator'
      }"
    >
      <norby-image
        *ngIf="imageUrl"
        [imageUrl]="imageUrl"
        [maskUrl]="maskUrl"
        [width]="width"
        [maskWidth]="width"
        [maskHeight]="height"
      ></norby-image>
    </div>
    <label>Avatar</label>
  </div>
</div>
