import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';

import { CheckboxComponent } from '../root-components/checkbox/checkbox.component';
import { CheckboxWrapperComponent } from '../root-components/checkbox/checkbox-wrapper.component';

@NgModule({
  declarations: [CheckboxComponent, CheckboxWrapperComponent],
  imports: [CommonModule, FormsModule, A11yModule],
  exports: [CheckboxComponent, CheckboxWrapperComponent]
})
export class CheckboxModule {}
