<div class="steps-content max-w-md">
  <form [formGroup]="formGroup" (ngSubmit)="submitEmailCode()">
    <h2 rootFormItem>Enter your verification code</h2>

    <p *ngIf="emailVerificationCodeMedium === 'email'" rootFormItem>
      Please enter the code we just sent to your email. If you can't find the
      code, check your spam folder.
    </p>

    <p *ngIf="emailVerificationCodeMedium === 'sms'" rootFormItem>
      Please enter the code we just sent you over text.
    </p>

    <div rootFormItem>
      <norby-input
        type="number"
        class="number-input"
        formControlName="verificationCode"
        placeholder="Your verification code"
        [isRequired]="true"
        characterLimit="6"
      ></norby-input>
    </div>

    <div rootFormItem>
      <root-button
        type="primary"
        size="large"
        [block]="true"
        [disabled]="!formGroup?.valid"
        [loading]="isLoading"
        [text]="isOnFinalScreen ? 'Submit' : 'Next'"
      >
      </root-button>
    </div>
  </form>
</div>
