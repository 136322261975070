<button
  type="button"
  class="h-10 border border-neutral-900 ant-btn-animation flex flex-row flex-1 leading-5 items-center whitespace-nowrap radio-button focus:border-dark-navy"
  [ngClass]="{
    'bg-neutral-900 hover:bg-neutral-800 hover:border-neutral-800 focus:bg-dark-navy text-white':
      selectedValue === buttonValue,
    'bg-white hover:bg-neutral-700 hover:border-neutral-700 text-neutral-900 hover:text-white':
      selectedValue !== buttonValue,
    'text-sm px-2 py-1': size === 'small',
    'text-lg px-6 py-3': size === 'large',
    'text-base px-5 py-2': size === 'default',
    'rounded-sm': isFirst && isLast,
    'rounded-l-sm': isFirst && !isLast,
    'rounded-r-sm': !isFirst && isLast
  }"
  (click)="handleButtonClick()"
  [disabled]="isDisabled"
>
  <norby-icon [name]="iconName" [size]="size"></norby-icon
  ><span [ngClass]="{ 'ml-2': size === 'large' }">{{ buttonLabel }}</span>
</button>
