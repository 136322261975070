import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-icon-button-with-label',
  templateUrl: './norby-icon-button-with-label.component.html',
  styleUrls: ['./norby-icon-button-with-label.component.less']
})
export class NorbyIconButtonWithLabelComponent {
  @Input() iconName: string;
  @Input() buttonLabel: string;
  @Input() isDisabled?: boolean = false;
  @Input() isJoinedRight?: boolean = false;
  @Input() isJoinedLeft?: boolean = false;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() buttonType?: 'primary' | 'default' = 'default';
  @Input() iconPlacement?: 'left' | 'right' = 'left';

  @Output() onButtonClicked = new EventEmitter();

  handleButtonClick(event: Event): void {
    this.onButtonClicked.emit();
  }
}
