<lib-event-card-view
  *ngIf="content?.isEvent"
  [event]="content"
  [userEvent]="userContent"
  [isLoadingUserContent]="!hasLoaded"
  [overrideTitle]="overrideTitle"
  (cardClick)="handleClick($event)"
></lib-event-card-view>

<lib-drop-card-view
  *ngIf="content?.isSignup"
  [drop]="content"
  [userContent]="userContent"
  [isLoadingUserContent]="!hasLoaded"
  [overrideTitle]="overrideTitle"
  [overrideSignupType]="overrideSignupType"
  (cardClick)="handleClick($event)"
></lib-drop-card-view>
