import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardModule } from './card.module';
import { SkeletonModule } from './skeleton.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { ReportDetailViewComponent } from '../components/organisms/report-detail-view';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';

@NgModule({
  declarations: [ReportDetailViewComponent],
  imports: [
    CommonModule,
    CardModule,
    SkeletonModule,
    SoftImageViewModule,
    SafeHtmlPipeModule
  ],
  exports: [ReportDetailViewComponent]
})
export class ReportDetailViewModule {}
