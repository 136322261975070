import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { rootCalendly } from '../../../icons';
import { IconService } from '../../services';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

export const EMBED_URL =
  'https://assets.calendly.com/assets/external/widget.js';

@Component({
  selector: 'lib-calendly-embed-view',
  templateUrl: './calendly-embed-view.component.html',
  styleUrls: ['./calendly-embed-view.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CalendlyEmbedViewComponent implements OnChanges, OnInit {
  @Input() embedCode: string;
  safeEmbedCode: SafeHtml;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platform,
    private _renderer: Renderer2,
    private _sanitizer: DomSanitizer,
    private _iconService: IconService
  ) {
    this._iconService.registerIcons([rootCalendly]);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this._platform)) {
      this._initScript();
    }
  }

  ngOnChanges(): void {
    this.safeEmbedCode = this.embedCode
      ? this._sanitizer.bypassSecurityTrustHtml(this.embedCode)
      : null;
  }

  private _initScript(): HTMLScriptElement {
    const script = this._renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = EMBED_URL;
    this._renderer.appendChild(this._document.body, script);
    return script;
  }
}
