import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'norby-number-input',
  templateUrl: './norby-number-input.component.html',
  styleUrls: ['./norby-number-input.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyNumberInputComponent),
      multi: true
    }
  ]
})
export class NorbyNumberInputComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() value: number;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() helperText?: string;
  @Input() infoTooltip?: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() isJoinedRight: boolean = false;
  @Input() isJoinedLeft: boolean = false;
  @Input() size: 'small' | 'medium' = 'medium';

  @Output() onHandleChange: EventEmitter<number> = new EventEmitter<number>();

  val: number;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.val = this.value;
  }

  ngOnInit(): void {
    this.val = this.value;
  }

  writeValue(value: number) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  handleInput(value: string) {
    this.val = +value;
    this.onHandleChange.emit(this.val);
    this._onChanged(this.val);
    this._markAsTouched();
  }

  handleKeydown(event) {
    return (
      event.ctrlKey ||
      event.altKey ||
      (47 < event.keyCode && event.keyCode < 58 && event.shiftKey == false) ||
      (95 < event.keyCode && event.keyCode < 106) ||
      event.keyCode == 8 ||
      event.keyCode == 9 ||
      (event.keyCode > 34 && event.keyCode < 40) ||
      event.keyCode == 46 ||
      (event.keyCode == 190 && event.target.value.indexOf('.') == -1) ||
      (event.keyCode == 110 && event.target.value.indexOf('.') == -1)
    );
  }
}
