import {
  animate,
  AnimationTriggerMetadata,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  SHADOW_BORDER_RADIUS,
  SHADOW_COLOR,
  SHADOW_SPREAD_RADIUS,
  TRANSITION_CURVE,
  TRANSITION_DURATION
} from './const';

export const flashFadeInOut = trigger('flashFadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      `1.5s ${TRANSITION_CURVE}`,
      keyframes([
        style({ opacity: 0, offset: 0 }),
        style({ opacity: 1, offset: 0.5 }),
        style({ opacity: 0, offset: 1 })
      ])
    )
  ])
]);

export const showOnHover = trigger('showOnHover', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(300, style({ opacity: 1 }))
  ]),
  transition(':leave', [animate(300, style({ opacity: 0 }))])
]);

export type MessageAnimationState = 'default' | 'closing';

export const messageAnimations: {
  readonly slideMessage: AnimationTriggerMetadata;
} = {
  slideMessage: trigger('slideAnimation', [
    state('default', style({ opacity: 1 })),
    transition('void => *', [
      style({ transform: 'translateY(-100%)', opacity: 0 }),
      animate(
        '{{ slideIn }}ms',
        style({ transform: 'translateY(0)', opacity: 1 })
      )
    ]),
    transition(
      'default => closing',
      animate(
        '{{ slideOut }}ms',
        style({ transform: 'translateY(-100%)', opacity: 0 })
      )
    )
  ])
};

export const buttonClicked = trigger('buttonClicked', [
  transition('* <=> true', [
    animate(
      '0.3s',
      keyframes([
        style({
          borderRadius: SHADOW_BORDER_RADIUS,
          boxShadow: `0 0 0 ${SHADOW_SPREAD_RADIUS} ${SHADOW_COLOR}`
        })
      ])
    )
  ])
]);

// slides up on enter, down on leave
export const slideUpInDownOut = trigger('slideUpInDownOut', [
  transition('void => slide', [
    style({ transform: 'translateY(100%)', opacity: 0 }),
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({ transform: 'translateY(0)', opacity: 1 })
    )
  ]),
  transition('slide => void', [
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({ transform: 'translateY(100%)' })
    )
  ])
]);

// blocks animations on initial render
export const initialNoop = trigger('initalRenderNoop', [
  transition(':enter', [])
]);
