import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import {
  IFont,
  IBaseElementStyle,
  ICardStyle,
  IButtonStyle,
  Theme
} from 'models';
import { ILiveThemedServiceInterface } from './live-themed.service.interface';

const CSS_MAP = {
  textColor: 'color',
  backgroundColor: 'background-color',
  borderColor: 'border-color',
  borderRadius: 'border-radius',
  dropShadow: 'box-shadow'
};

/*
  Service used by the `live-themed` directive for generating CSS styles
  from THeme objects and managing associated style nodes.
*/

@Injectable({
  providedIn: 'root'
})
export class LiveThemedService
  implements ILiveThemedServiceInterface, OnDestroy
{
  private _fontHashRegistry = new Set<number>();
  private _googleFonts: { [key: string]: IFont } = {};
  private _customFonts: { [key: string]: IFont } = {};
  private _innerFontStyleNode: HTMLStyleElement;
  themes: { [key: string]: Theme } = {};

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  ngOnDestroy(): void {
    this._removeFontStyleNode();
  }

  get fontStyleNode(): HTMLStyleElement {
    if (!this._innerFontStyleNode) {
      this._innerFontStyleNode = this._document.createElement('style');
      this._documentHead.appendChild(this._innerFontStyleNode);
    }

    return this._innerFontStyleNode;
  }

  private get _documentHead() {
    return (
      this._document.head || this._document.getElementsByTagName('head')[0]
    );
  }

  private _isNumberValid(value: number) {
    return value !== null && value !== undefined && value > -1;
  }

  private _removeFontStyleNode() {
    if (!this._innerFontStyleNode) {
      return;
    }

    // Remove any previously generated font imports
    this._documentHead.removeChild(this._innerFontStyleNode);
    this._innerFontStyleNode = null;
  }

  // Build CSS for input elements
  // Includes logic for input groups with buttons and selects
  private _generateTextInputCSS(control: IBaseElementStyle, selector: string) {
    const hasTextColor = !!control?.textColor;
    const hasBackgroundColor = !!control?.backgroundColor;
    const hasBorderColor = !!control?.borderColor;
    const hasDropShadow = !!control?.dropShadow;
    const hasBorderRadius = this._isNumberValid(control?.borderRadius);

    // Assemble rule sets
    const userThemeInputRules = [];
    const userThemeInputTextRules = [];
    const notLeftOrRightRules = [];
    const primaryButtonRules = [];
    const rightInputRules = [];
    const leftInputRules = [];
    const leftSelectRules = [];

    if (hasTextColor) {
      userThemeInputTextRules.push(`color: ${control.textColor};`);
    }

    if (hasBackgroundColor) {
      userThemeInputRules.push(`background-color: ${control.backgroundColor};`);
    }

    if (hasDropShadow) {
      userThemeInputRules.push(`box-shadow: ${control.dropShadow};`);
    }

    if (hasBorderRadius) {
      notLeftOrRightRules.push(`border-radius: ${control.borderRadius}px;`);
      primaryButtonRules.push(
        `border-top-right-radius: ${control.borderRadius}px;`,
        `border-bottom-right-radius: ${control.borderRadius}px;`
      );
      rightInputRules.push(
        `border-top-right-radius: ${control.borderRadius}px;`,
        `border-bottom-right-radius: ${control.borderRadius}px;`
      );
      leftInputRules.push(
        `border-top-left-radius: ${control.borderRadius}px;`,
        `border-bottom-left-radius: ${control.borderRadius}px;`
      );
      leftSelectRules.push(
        `border-top-left-radius: ${control.borderRadius}px;`,
        `border-bottom-left-radius: ${control.borderRadius}px;`
      );
    }

    if (hasDropShadow) {
      primaryButtonRules.push(`box-shadow: ${control.dropShadow};`);
      leftSelectRules.push(`box-shadow: ${control.dropShadow};`);
    }

    if (hasBorderColor) {
      userThemeInputRules.push(`border-color: ${control.borderColor};`);
      leftInputRules.push(`border-color: ${control.borderColor};`);
      rightInputRules.push(`border-color: ${control.borderColor};`);
    }

    // Concat non empty rule sets
    let classes = '';

    if (userThemeInputRules.length) {
      const innerSelector = '.user-theme-input';
      classes += `
        ${selector} ${innerSelector} {
          ${userThemeInputRules.join(' ')}
        }
      `;
    }

    if (userThemeInputTextRules.length) {
      const innerSelector = '.user-theme-input .user-theme-input-text';
      classes += `
        ${selector} ${innerSelector} {
          ${userThemeInputTextRules.join(' ')}
        }
      `;
    }

    if (notLeftOrRightRules.length) {
      const innerSelector =
        '.user-theme-input:not(.user-theme-right-input):not(.user-theme-left-input)';
      classes += `
        ${selector} ${innerSelector} {
          ${notLeftOrRightRules.join(' ')}
        }
      `;
    }

    if (primaryButtonRules.length) {
      const innerSelector =
        '.user-theme-input-group .user-theme-button-primary';
      classes += `
        ${selector} ${innerSelector} {
          ${primaryButtonRules.join(' ')}
        }
      `;
    }

    if (rightInputRules.length) {
      const innerSelector = '.user-theme-input-group .user-theme-right-input';
      classes += `
        ${selector} ${innerSelector} {
          ${rightInputRules.join(' ')}
        }
      `;
    }

    if (leftInputRules.length) {
      const innerSelector = '.user-theme-input-group .user-theme-left-input';
      classes += `
        ${selector} ${innerSelector} {
          ${leftInputRules.join(' ')}
        }
      `;
    }

    if (leftSelectRules.length) {
      const innerSelector = '.user-theme-input-group .user-theme-left-select';
      classes += `
        ${selector} ${innerSelector} {
          ${leftSelectRules.join(' ')}
        }
      `;
    }

    return classes;
  }

  // Generate CSS for tooltips
  // Use border color for tooltip arrow and fall back to background color
  private _generateTooltipCSS(control: IBaseElementStyle, selector: string) {
    const hasTextColor = !!control?.textColor;
    const hasBackgroundColor = !!control?.backgroundColor;
    const hasBorderColor = !!control?.borderColor;
    const hasDropShadow = !!control?.dropShadow;
    const hasBorderRadius = this._isNumberValid(control?.borderRadius);

    // Assemble rule sets
    const tooltipRules = [`border-width: ${!!control?.borderColor ? 1 : 0}px;`];
    const tooltipTopArrowRules = [];
    const tooltipBottomArrowRules = [];

    if (hasTextColor) {
      tooltipRules.push(`color: ${control.textColor};`);
    }

    if (hasBackgroundColor) {
      tooltipRules.push(`background-color: ${control.backgroundColor};`);

      // Use background color for arrow if border isn't available
      if (!hasBorderColor) {
        tooltipTopArrowRules.push(
          `border-color: transparent transparent ${control.backgroundColor} transparent;`
        );
        tooltipBottomArrowRules.push(
          `border-color: ${control.backgroundColor} transparent transparent transparent;`
        );
      }
    }

    if (hasBorderColor) {
      tooltipRules.push(`border-color: ${control.borderColor};`);
      tooltipTopArrowRules.push(
        `border-color: transparent transparent ${control.borderColor} transparent;`
      );
      tooltipBottomArrowRules.push(
        `border-color: ${control.borderColor} transparent transparent transparent;`
      );
    }

    if (hasBorderRadius) {
      tooltipRules.push(`border-radius: ${control.borderRadius}px;`);
    }

    if (hasDropShadow) {
      tooltipRules.push(`box-shadow: ${control.dropShadow};`);
    }

    // Concat non empty rule sets
    let classes = '';

    if (tooltipRules.length) {
      const innerSelector = '.user-theme-tooltip';
      classes += `
        ${selector} ${innerSelector} {
          ${tooltipRules.join(' ')}
        }
      `;
    }

    if (tooltipTopArrowRules.length) {
      const innerSelector =
        '.user-theme-tooltip-top-arrow-wrapper .user-theme-tooltip-top-arrow';
      classes += `
        ${selector}${innerSelector} {
          ${tooltipTopArrowRules.join(' ')}
        }
      `;
    }

    if (tooltipBottomArrowRules.length) {
      const innerSelector =
        '.user-theme-tooltip-bottom-arrow-wrapper .user-theme-tooltip-bottom-arrow';
      classes += `
        ${selector}${innerSelector} {
          ${tooltipBottomArrowRules.join(' ')}
        }
      `;
    }

    return classes;
  }

  // Generate CSS for checkboxes
  private _generateCheckboxCSS(control: IBaseElementStyle, selector: string) {
    const hasTextColor = !!control?.textColor;
    const hasBackgroundColor = !!control?.backgroundColor;
    const hasBorderColor = !!control?.borderColor;
    const hasDropShadow = !!control?.dropShadow;
    const hasBorderRadius = this._isNumberValid(control?.borderRadius);

    // Assemble rule sets
    const checkboxInnerRules = [];
    const checkedCheckboxInnerRules = [];
    const checkedCheckboxInnerAfterRules = [];
    const checkboxWrapperHoverInnerRules = [];
    const checkboxInputFocusInnerRules = [];
    const checkedCheckboxAfterRules = [];

    if (hasTextColor) {
      checkedCheckboxInnerAfterRules.push(
        `border-color: ${control.textColor} !important;`
      );
    }

    if (hasBackgroundColor) {
      checkedCheckboxInnerRules.push(
        `background-color: ${control.backgroundColor} !important;`,
        `border-color: ${control.backgroundColor} !important;`
      );
    }

    if (hasBorderRadius) {
      checkboxInnerRules.push(
        `border-radius: ${control.borderRadius}px !important;`
      );
    }

    if (hasDropShadow) {
      checkboxInnerRules.push(`box-shadow: ${control.dropShadow};`);
    }

    if (hasBorderColor) {
      checkboxWrapperHoverInnerRules.push(
        `border-color: ${control.borderColor} !important;`
      );
      checkboxInputFocusInnerRules.push(
        `border-color: ${control.borderColor} !important;`
      );
      checkedCheckboxAfterRules.push(
        `border-color: ${control.borderColor} !important;`
      );
    }

    if (hasBorderRadius) {
      checkedCheckboxAfterRules.push(
        `border-radius: ${control.borderRadius}px !important;`
      );
    }

    // Concat non empty rule sets
    let classes = '';

    if (checkboxInnerRules.length) {
      const innerSelector = '.user-theme-checkbox-inner';
      classes += `
        ${selector} ${innerSelector} {
          ${checkboxInnerRules.join(' ')}
        }
      `;
    }

    if (checkedCheckboxInnerRules.length) {
      const innerSelector =
        '.user-theme-checkbox-checked .user-theme-checkbox-inner';
      classes += `
        ${selector} ${innerSelector} {
          ${checkedCheckboxInnerRules.join(' ')}
        }
      `;
    }

    if (checkedCheckboxInnerAfterRules.length) {
      const innerSelector =
        '.user-theme-checkbox-checked .user-theme-checkbox-inner:after ';
      classes += `
        ${selector} ${innerSelector} {
          ${checkedCheckboxInnerAfterRules.join(' ')}
        }
      `;
    }

    if (checkboxWrapperHoverInnerRules.length) {
      const innerSelector =
        '.user-theme-checkbox-wrapper:hover .user-theme-checkbox-inner';
      classes += `
        ${selector} ${innerSelector} {
          ${checkboxWrapperHoverInnerRules.join(' ')}
        }
      `;
    }

    if (checkboxInputFocusInnerRules.length) {
      const innerSelector =
        '.user-theme-checkbox-input:focus + .user-theme-checkbox-inner';
      classes += `
        ${selector} ${innerSelector} {
          ${checkboxInputFocusInnerRules.join(' ')}
        }
      `;
    }

    if (checkedCheckboxAfterRules.length) {
      const innerSelector = '.user-theme-checkbox-checked:after';
      classes += `
        ${selector} ${innerSelector} {
          ${checkedCheckboxAfterRules.join(' ')}
        }
      `;
    }

    return classes;
  }

  // Generate CSS for card elements
  // Card styles are used for:
  // - Content blocks
  // - Modals (for now)
  // - Text and Image positioning for Links, which default to left and top respectively if no theme is set
  private _generateCardCSS(card: ICardStyle, selector: string) {
    const hasTextColor = !!card?.textColor;
    const hasBackgroundColor = !!card?.backgroundColor;
    const hasBorderRadius = this._isNumberValid(card?.borderRadius);
    const hasTextPosition = !!card?.textPosition;
    const hasImagePosition = !!card?.imagePosition;

    let classes = '';

    if (hasBackgroundColor) {
      classes += `
        ${selector} .card-style-on-hover:hover {
          background-color: ${card.backgroundColor};
        }

        ${selector} .user-theme-button-ghost:hover {
          background-color: ${card.backgroundColor};
        }
      `;
    }

    if (hasTextColor) {
      classes += `
        ${selector} .card-style-on-hover:hover {
          color: ${card.textColor};
        }

        ${selector} .card-text-color,
        ${selector} .card-text-color p,
        ${selector} .card-text-color ul,
        ${selector} .card-text-color ol,
        ${selector} .card-text-color h1,
        ${selector} .card-text-color h2,
        ${selector} .card-text-color h3,
        ${selector} .card-text-color h4,
        ${selector} .card-text-color h5,
        ${selector} .card-text-color h6,
        ${selector} .user-theme-tag-text,
        ${selector} form,
        ${selector} .user-theme-purchase-complete,
        ${selector} .payment-form-container,
        ${selector} .payment-form-container label {
          color: ${card.textColor};
        }

        ${selector} .user-theme-button-ghost:hover {
          color: ${card.textColor};
        }

        ${selector} .user-theme-button-card-ghost:hover {
          background-color: ${
            card.textColor === '#ffffff' && card?.backgroundColor
              ? card.backgroundColor
              : ''
          };
        }

        ${selector} path.user-theme-card-text-color-fill {
          fill: ${card.textColor};
        }

        ${selector} path.user-theme-card-text-color-stroke,
        ${selector} rect.user-theme-card-text-color-stroke {
          stroke: ${card.textColor};
        }
      `;
    }

    if (hasBorderRadius) {
      classes += `
        ${selector} .user-theme-alert {
          border-radius: ${card.borderRadius}px;
        }
      `;
    }

    const containerRuleSet = this.generateCardCSSProperties(card);

    if (containerRuleSet.length) {
      classes += `
        ${selector} .user-theme-card,
        ${selector} .user-theme-tag,
        ${selector} .modal-overlay-panel,
        ${selector} .card-border-style {
          ${containerRuleSet.join(' ')}
        }
      `;
    }

    // In order to preserve fallback of having the image on the left
    // when position is undefined, we need a hardcoded 16px / @margin-md
    // to make right alignment work correctly
    if (hasImagePosition) {
      switch (card.imagePosition) {
        case 'top':
          classes += `
            ${selector} .user-theme-card-image-position {
              flex-direction: column;
              align-items: stretch;
            }
            ${selector} .user-theme-card-avatar {
              flex: 0 0 auto;
              margin: 0;
            }
          `;
          break;
        case 'right':
          classes += `
            ${selector} .user-theme-card-image-position {
              flex-direction: row-reverse;
            }
            ${selector} .user-theme-card-avatar {
              margin-right: 16px;
              margin-left: 0;
            }
          `;
          break;
        case 'bottom':
          classes += `
            ${selector} .user-theme-card-image-position {
              flex-direction: column-reverse;
              align-items: stretch;
            }
            ${selector} .user-theme-card-avatar {
              flex: 0 0 auto;
              margin: 0;
            }
          `;
          break;
      }
    }

    if (hasTextPosition) {
      switch (card.textPosition) {
        case 'left':
          classes += `
            ${selector} .user-theme-card-text-alignment {
              text-align: left;
            }
          `;
          break;
        case 'right':
          classes += `
            ${selector} .user-theme-card-text-alignment {
              text-align: right;
            }
          `;
          break;
        case 'center':
          classes += `
            ${selector} .user-theme-card-text-alignment {
              text-align: center;
            }
          `;
          break;
      }
    }

    return classes;
  }

  // Generate CSS for a given font level (h1, h2, h3, etc)
  private _generateFontSectionCSS(
    control: IFont,
    selectors: string[],
    theme: Theme
  ) {
    if (!control || !selectors?.length) {
      return '';
    }

    const rules = [];

    if (!!control?.fontSize) {
      rules.push(`font-size: ${control.fontSize}px;`);
    }

    if (!!control?.fontFamily) {
      rules.push(`font-family: ${control.fontFamily};`);
    } else if (theme?.fonts?.font?.fontFamily) {
      rules.push(`font-family: ${theme?.fonts?.font?.fontFamily};`);
    }

    return rules.length ? `${selectors.join(' ')} { ${rules.join('')} }` : '';
  }

  // Generate CSS for all typography elements
  private _generateFontCSS(theme: Theme, selector: string) {
    let classes: string = '';
    const fonts = theme?.fonts;

    if (fonts) {
      const sections = Object.keys(fonts).reduce((arr, key) => {
        if (key !== 'font') {
          arr.push(
            this._generateFontSectionCSS(fonts[key], [selector, key], theme)
          );
        }

        return arr;
      }, []);

      classes = `
        ${sections.length ? sections.join('') : ''}
        ${this._generateFontSectionCSS(fonts.p, [selector, 'ul'], theme)}
        ${this._generateFontSectionCSS(fonts.p, [selector, 'ol'], theme)}
      `;
    }

    return classes;
  }

  // Generate CSS for text buttons
  private _generateGhostButtonCSS(theme: Theme, selector: string) {
    const hasBorderRadius = this._isNumberValid(theme?.button?.borderRadius);
    const hasCardTextColor = !!theme?.card?.textColor;
    const hasTextColor = !!theme?.text?.color;

    const buttonCardGhostRuleSet = [];
    const buttonGhostRuleSet = [];

    if (hasBorderRadius) {
      buttonCardGhostRuleSet.push(
        `border-radius: ${theme.button.borderRadius}px;`
      );
      buttonGhostRuleSet.push(`border-radius: ${theme.button.borderRadius}px;`);
    }

    if (hasCardTextColor) {
      buttonCardGhostRuleSet.push(`color: ${theme.card.textColor};`);
    }

    if (hasTextColor) {
      buttonGhostRuleSet.push(`color: ${theme?.text?.color};`);
    }

    let classes = '';

    if (buttonCardGhostRuleSet.length) {
      const innerSelector = '.user-theme-button-card-ghost';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonCardGhostRuleSet.join(' ')}
        }
      `;
    }

    if (buttonGhostRuleSet.length) {
      const innerSelector = '.user-theme-button-ghost';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonGhostRuleSet.join(' ')}
        }
      `;
    }

    return classes;
  }

  // Generate CSS for regular text color
  private _generateTextColorCSS(color: string, selector: string) {
    let classes = '';

    if (!!color) {
      classes = `
        ${selector} .text-color,
        ${selector} .text-color p,
        ${selector} .text-color ul,
        ${selector} .text-color ol,
        ${selector} .text-color h1,
        ${selector} .text-color h2,
        ${selector} .text-color h3,
        ${selector} .text-color h4,
        ${selector} .text-color h5,
        ${selector} .text-color h6 {
          color: ${color};
        }

        ${selector} path.user-theme-text-color-fill {
          fill: ${color};
        }
      `;
    }

    return classes;
  }

  // Generate CSS for link color and hover state
  private _generateLinkCSS(theme: Theme, selector: string) {
    let classes = '';

    if (!!theme?.link?.textColor) {
      classes += `
        ${selector} p a,
        ${selector} h1 a,
        ${selector} h2 a,
        ${selector} h3 a,
        ${selector} h4 a,
        ${selector} h5 a,
        ${selector} h6 a,
        ${selector} ul > a,
        ${selector} ol > a {
          color: ${theme.link.textColor};
        }
      `;
    }

    if (!!theme?.link?.hoverTextColor) {
      classes += `
        ${selector} p a:hover,
        ${selector} h1 a:hover,
        ${selector} h2 a:hover,
        ${selector} h3 a:hover,
        ${selector} h4 a:hover,
        ${selector} h5 a:hover,
        ${selector} h6 a:hover,
        ${selector} ul > a:hover,
        ${selector} ol > a:hover {
          color: ${theme.link.hoverTextColor};
        }
      `;
    }

    return classes;
  }

  // Generate CSS for primary buttons
  // Includes logic for use in input groups
  private _generatePrimaryButtonCSS(button: IButtonStyle, selector: string) {
    const hasTextColor = !!button?.textColor;
    const hasBackgroundColor = !!button?.backgroundColor;
    const hasBorderColor = !!button?.borderColor;
    const hasDropShadow = !!button?.dropShadow;
    const hasBorderRadius = this._isNumberValid(button?.borderRadius);
    const hasBorderWidth = this._isNumberValid(button?.borderWidth);
    const hasHoverBackgroundColor = !!button?.hoverBackgroundColor;
    const hasHoverBorderColor = !!button?.hoverBorderColor;
    const hasHoverTextColor = !!button?.hoverTextColor;

    // Assemble rule sets
    const notAdjacentInputButtonPrimary = [];
    const buttonPrimary = [];
    const buttonPrimaryHoverNotDisabled = [];
    const buttonPrimaryText = [];
    const buttonPrimaryHoverNotDisabledText = [];
    const inputGroupLeftInputHover = [];
    const inputGroupRightInputHover = [];

    if (hasTextColor) {
      buttonPrimaryText.push(`color: ${button.textColor};`);
    }

    if (hasHoverTextColor) {
      buttonPrimaryHoverNotDisabledText.push(
        `color: ${button.hoverTextColor};`
      );
    }

    if (hasBackgroundColor) {
      buttonPrimary.push(`background-color: ${button.backgroundColor};`);
    }

    if (hasHoverBackgroundColor) {
      buttonPrimaryHoverNotDisabled.push(
        `background-color: ${button.hoverBackgroundColor};`
      );
    }

    if (hasBorderRadius) {
      notAdjacentInputButtonPrimary.push(
        `border-radius: ${button.borderRadius}px;`
      );
    }

    if (hasBorderColor) {
      buttonPrimary.push(`border-color: ${button.borderColor};`);
    }

    if (hasHoverBorderColor) {
      buttonPrimaryHoverNotDisabled.push(
        `border-color: ${button.hoverBorderColor};`
      );
      inputGroupLeftInputHover.push(
        `border-color: ${button.hoverBorderColor};`
      );
      inputGroupRightInputHover.push(
        `border-color: ${button.hoverBorderColor};`
      );
    }

    if (hasDropShadow) {
      notAdjacentInputButtonPrimary.push(`box-shadow: ${button.dropShadow};`);
    }

    if (hasBorderWidth) {
      buttonPrimary.push(`border-width: ${button.borderWidth};`);
    }

    // Concat non empty rule sets
    let classes = '';

    if (notAdjacentInputButtonPrimary.length) {
      const innerSelector =
        '.user-theme-button:not(.user-theme-button-adjacent-input) > .user-theme-button-primary';
      classes += `
        ${selector} ${innerSelector} {
          ${notAdjacentInputButtonPrimary.join(' ')}
        }
      `;
    }

    if (buttonPrimary.length) {
      const innerSelector = '.user-theme-button-primary';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonPrimary.join(' ')}
        }
      `;
    }

    if (buttonPrimaryHoverNotDisabled.length) {
      const innerSelector = '.user-theme-button-primary:hover:not(:disabled)';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonPrimaryHoverNotDisabled.join(' ')}
        }
      `;
    }

    if (buttonPrimaryText.length) {
      const innerSelector =
        '.user-theme-button-primary > .user-theme-button-text';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonPrimaryText.join(' ')}
        }
      `;
    }

    if (buttonPrimaryHoverNotDisabledText.length) {
      const innerSelector =
        '.user-theme-button-primary:hover:not(:disabled) > .user-theme-button-text';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonPrimaryHoverNotDisabledText.join(' ')}
        }
      `;
    }

    if (inputGroupLeftInputHover.length) {
      const innerSelector =
        '.user-theme-input-group .user-theme-left-input:hover';
      classes += `
        ${selector} ${innerSelector} {
          ${inputGroupLeftInputHover.join(' ')}
        }
      `;
    }

    if (inputGroupRightInputHover.length) {
      const innerSelector =
        '.user-theme-input-group .user-theme-right-input:hover ';
      classes += `
        ${selector} ${innerSelector} {
          ${inputGroupRightInputHover.join(' ')}
        }
      `;
    }

    return classes;
  }

  // Generate CSS for secondary buttons
  private _generateSecondaryButtonCSS(
    secondaryButton: IButtonStyle,
    selector: string
  ) {
    const hasTextColor = !!secondaryButton?.textColor;
    const hasBackgroundColor = !!secondaryButton?.backgroundColor;
    const hasBorderColor = !!secondaryButton?.borderColor;
    const hasDropShadow = !!secondaryButton?.dropShadow;
    const hasBorderRadius = this._isNumberValid(secondaryButton?.borderRadius);
    const hasBorderWidth = this._isNumberValid(secondaryButton?.borderWidth);
    const hasHoverBackgroundColor = !!secondaryButton?.hoverBackgroundColor;
    const hasHoverBorderColor = !!secondaryButton?.hoverBorderColor;
    const hasHoverTextColor = !!secondaryButton?.hoverTextColor;

    // Assemble rule sets
    const buttonSecondary = [];
    const buttonSecondaryHoverNotDisabled = [];
    const buttonSecondaryText = [];
    const buttonSecondaryHoverNotDisabledText = [];

    if (hasBackgroundColor) {
      buttonSecondary.push(
        `background-color: ${secondaryButton.backgroundColor} !important;`
      );
    }

    if (hasHoverBackgroundColor) {
      buttonSecondaryHoverNotDisabled.push(
        `background-color: ${secondaryButton.hoverBackgroundColor} !important;`
      );
    }

    if (hasTextColor) {
      buttonSecondaryText.push(`color: ${secondaryButton.textColor};`);
    }

    if (hasHoverTextColor) {
      buttonSecondaryHoverNotDisabledText.push(
        `color: ${secondaryButton.hoverTextColor};`
      );
    }

    if (hasHoverBorderColor) {
      buttonSecondaryHoverNotDisabled.push(
        `border-color: ${secondaryButton.hoverBorderColor};`
      );
    }

    if (hasBorderColor) {
      buttonSecondary.push(`border-color: ${secondaryButton.borderColor};`);
    }

    if (hasBorderWidth) {
      buttonSecondary.push(
        `border-width: ${secondaryButton.borderWidth} !important;`
      );
    }

    if (hasDropShadow) {
      buttonSecondary.push(`box-shadow: ${secondaryButton.dropShadow};`);
    }

    if (hasBorderRadius) {
      buttonSecondary.push(`border-radius: ${secondaryButton.borderRadius}px;`);
    }

    // Concat non empty rule sets
    let classes = '';

    if (buttonSecondary.length) {
      const innerSelector = '.user-theme-button-secondary';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonSecondary.join(' ')}
        }
      `;
    }

    if (buttonSecondaryHoverNotDisabled.length) {
      const innerSelector = '.user-theme-button-secondary:hover:not(:disabled)';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonSecondaryHoverNotDisabled.join(' ')}
        }
      `;
    }

    if (buttonSecondaryText.length) {
      const innerSelector =
        '.user-theme-button-secondary > .user-theme-button-text ';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonSecondaryText.join(' ')}
        }
      `;
    }

    if (buttonSecondaryHoverNotDisabledText.length) {
      const innerSelector =
        '.user-theme-button-secondary:hover:not(:disabled) > .user-theme-button-text';
      classes += `
        ${selector} ${innerSelector} {
          ${buttonSecondaryHoverNotDisabledText.join(' ')}
        }
      `;
    }

    return classes;
  }

  // Generate CSS for a standard control:
  // - Selects
  // - Dropdowns
  // - Snackbars
  private _generateControlCSS(control: IBaseElementStyle, selectors: string[]) {
    if (!control || !selectors?.length) {
      return '';
    }

    const rules = Object.keys(control).reduce((arr, key) => {
      if (!!control[key]) {
        let rule = `${CSS_MAP[key]}: ${control[key]}`;
        rule += key === 'borderRadius' ? 'px;' : ';';
        arr.push(rule);
      }

      return arr;
    }, []);

    return rules.length ? `${selectors.join(' ')} { ${rules.join('')} }` : '';
  }

  private _updateFontRegistry(theme: Theme): boolean {
    if (!theme?.fonts || this._fontHashRegistry.has(theme.fonts.hash)) {
      return false;
    }

    const allFonts: IFont[] = [
      'font',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p'
    ].map((f) => theme.fonts[f]);

    let dirty = false;
    for (let i = 0; i < allFonts.length; i++) {
      const f = allFonts[i];
      if (!f?.importUrl) {
        continue;
      } else if (
        f.importUrl.startsWith('https://fonts.googleapis.com') &&
        !this._googleFonts[f.importUrl]
      ) {
        this._googleFonts[f.importUrl] = f;
        dirty = true;
      } else if (!this._customFonts[f.importUrl]) {
        const existingFont = Object.values(this._customFonts).find((font) => {
          return f.displayName === font.displayName;
        });
        if (existingFont) {
          delete this._customFonts[existingFont.importUrl];
        }
        this._customFonts[f.importUrl] = f;
        dirty = true;
      }
    }

    this._fontHashRegistry.add(theme.fonts.hash);
    return dirty;
  }

  // Build font imports and custom font definitions
  // - Google Fonts just need to be imported
  // - Custom (user uploaded) fonts need to be defined as a font face
  private _generateFontDefinitions(theme: Theme): string {
    const fontImports = Object.values(this._googleFonts).map(
      (font) => `@import url('${font?.importUrl}');`
    );

    const fontFaces = Object.values(this._customFonts).map(
      (font) =>
        `@font-face {
          font-family: "${
            font?.fontFamily?.replace(/'/g, '') ?? 'Custom font'
          }";
          src: url('${font?.importUrl}');
        }`
    );

    return `${fontImports.join(' ')} ${fontFaces.join(' ')}`
      .trim()
      .replace(/\s+/g, ' ');
  }

  generateCardTextColor(card: ICardStyle): string {
    return !!card?.textColor ? `color: ${card.textColor};` : '';
  }

  generateCardCSSProperties(card: ICardStyle): string[] {
    const hasBackgroundColor = !!card?.backgroundColor;
    const hasBorderColor = !!card?.borderColor;
    const hasDropShadow = !!card?.dropShadow;
    const hasBorderRadius = this._isNumberValid(card?.borderRadius);
    const hasBorderWidth = this._isNumberValid(card?.borderWidth);

    const containerRuleSet = [];

    if (hasBackgroundColor) {
      containerRuleSet.push(`background-color: ${card.backgroundColor};`);
    }

    if (hasBorderRadius) {
      containerRuleSet.push(
        `border-radius: ${card.borderRadius}px !important;`
      );
    }

    if (hasBorderColor) {
      containerRuleSet.push(`border-color: ${card.borderColor} !important;`);
    }

    if (hasDropShadow) {
      containerRuleSet.push(`box-shadow: ${card.dropShadow};`);
    }

    if (hasBorderWidth) {
      containerRuleSet.push(`border-width: ${card.borderWidth}px !important;`);
    }

    return containerRuleSet;
  }

  generateButtonCSSProperties(button: IButtonStyle): string[] {
    const hasBackgroundColor = !!button?.backgroundColor;
    const hasBorderColor = !!button?.borderColor;
    const hasTextColor = !!button?.textColor;
    const hasDropShadow = !!button?.dropShadow;
    const hasBorderRadius = this._isNumberValid(button?.borderRadius);
    const hasBorderWidth = this._isNumberValid(button?.borderWidth);

    const containerRuleSet = [];

    if (hasBackgroundColor) {
      containerRuleSet.push(`background-color: ${button.backgroundColor};`);
    }

    if (hasTextColor) {
      containerRuleSet.push(`color: ${button.textColor};`);
    }

    if (hasBorderRadius) {
      containerRuleSet.push(
        `border-radius: ${button.borderRadius}px !important;`
      );
    }

    if (hasBorderColor) {
      containerRuleSet.push(`border-color: ${button.borderColor} !important;`);
    }

    if (hasDropShadow) {
      containerRuleSet.push(`box-shadow: ${button.dropShadow};`);
    }

    if (hasBorderWidth) {
      containerRuleSet.push(
        `border-width: ${button.borderWidth}px !important;`
      );
    }

    return containerRuleSet;
  }

  removeCachedTheme(selector: string) {
    delete this.themes[selector];
  }

  // Generate all CSS styles based on the values in the supplied theme
  generateCSSFromTheme(
    theme: Theme,
    selector: string = '.norby-themed'
  ): string {
    if (!theme || !selector?.length) {
      return '';
    }

    if (!this.themes[selector]) {
      this.themes[selector] = theme;
    }

    let fontString = '';
    let bgImageString = '';
    let bgColorString = '';

    const mainFont = theme.fonts?.font;
    const overlaySelector = selector.replace(/\s/g, '');

    if (mainFont && mainFont.importUrl && mainFont.fontFamily) {
      fontString += `
        ${selector} h1,
        ${selector} h2,
        ${selector} h3,
        ${selector} h4,
        ${selector} h5,
        ${selector} h6,
        ${selector} p,
        ${selector} ul,
        ${selector} ol,
        ${selector} input,
        ${selector} select,
        ${selector} textarea,
        ${selector} div,
        ${selector} .user-theme-button-text,
        ${overlaySelector} .user-theme-tooltip-text,
        ${selector} .user-theme-tag,
        ${selector} .user-theme-card,
        ${selector} .user-theme-alert {
          font-family: ${mainFont.fontFamily};
        }
      `;
    }

    const dropdownString = this._generateControlCSS(theme?.dropdown, [
      overlaySelector,
      '.user-theme-dropdown'
    ]);
    const selectString = this._generateControlCSS(theme?.select, [
      selector,
      '.user-theme-select'
    ]);
    const snackbarString = this._generateControlCSS(theme?.snackbar, [
      overlaySelector,
      '.user-theme-snackbar'
    ]);

    return `
      ${fontString}
      ${bgImageString || bgColorString}
      ${dropdownString}
      ${selectString}
      ${snackbarString}
      ${this._generateTextInputCSS(theme?.textInput, selector)}
      ${this._generateTooltipCSS(theme?.tooltip, overlaySelector)}
      ${this._generateCheckboxCSS(theme?.checkbox, selector)}
      ${this._generateCardCSS(theme?.card, selector)}
      ${this._generateFontCSS(theme, selector)}
      ${this._generateGhostButtonCSS(theme, selector)}
      ${this._generateTextColorCSS(theme?.text?.color, selector)}
      ${this._generateLinkCSS(theme, selector)}
      ${this._generatePrimaryButtonCSS(theme?.button, selector)}
      ${this._generateSecondaryButtonCSS(theme?.secondaryButton, selector)}
    `.replace(/\s+/g, ' ');
  }

  updateFontImportsAndDefinitions(theme: Theme) {
    if (!theme?.fonts || !this._document || !this._documentHead) {
      return;
    }

    const didUpdateFontRegistry = this._updateFontRegistry(theme);
    if (didUpdateFontRegistry) {
      const newCss = this._generateFontDefinitions(theme);
      const newChild = this._document.createTextNode(newCss);
      this.fontStyleNode.appendChild(newChild);

      while (
        this.fontStyleNode.firstChild &&
        this.fontStyleNode.firstChild !== newChild
      ) {
        this.fontStyleNode.removeChild(this.fontStyleNode.firstChild);
      }
    }
  }
}
