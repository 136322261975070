import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbySelectComponent } from '../components/molecules/norby-select';
import { NorbyFormcontrolLabelModule } from './norby-formcontrol-label.module';
import { NorbyHelpertextModule } from './norby-helpertext.module';

@NgModule({
  declarations: [NorbySelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyFormcontrolLabelModule,
    NorbyHelpertextModule
  ],
  exports: [NorbySelectComponent]
})
export class NorbySelectModule {}
