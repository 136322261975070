import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ResultContentDirective,
  ResultExtraDirective,
  ResultSubtitleDirective,
  ResultIconDirective,
  ResultTitleDirective
} from '../directives';
import { ResultComponent } from '../root-components/result/result.component';
import { TemplateOutletModule } from './template-outlet.module';

@NgModule({
  declarations: [
    ResultComponent,
    ResultTitleDirective,
    ResultSubtitleDirective,
    ResultIconDirective,
    ResultContentDirective,
    ResultExtraDirective
  ],
  imports: [CommonModule, TemplateOutletModule],
  exports: [
    ResultComponent,
    ResultTitleDirective,
    ResultSubtitleDirective,
    ResultIconDirective,
    ResultContentDirective,
    ResultExtraDirective
  ]
})
export class ResultModule {}
