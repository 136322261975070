import { CalendarBase } from './CalendarBase';
import { URL } from './constants';
import { toQueryString } from './utils/data';
import { getRrule } from './utils/ics';

/**
 * Generates a Google Calendar url.
 *
 * @example
 *  import { GoogleCalendar } from 'datebook'
 *
 *  const google = new GoogleCalendar({
 *    title: 'Happy Hour',
 *    location: 'The Bar, New York, NY',
 *    description: 'Let\'s blow off some steam from our weekly deployments to enjoy a tall cold one!',
 *    start: '20190704T190000',
 *    end: '20190704T210000',
 *    recurrence: {
 *      frequency: 'WEEKLY'
 *      interval: 2
 *    }
 *  })
 *
 */
export class GoogleCalendar extends CalendarBase {
  /**
   * @inheritDoc
   */
  constructor(options) {
    super(options);
  }

  /**
   * Generates the Google Calendar url.
   */
  render() {
    const params = {
      action: 'TEMPLATE',
      text: this.title,
      details: this.description,
      location: this.location,
      dates: `${this.start}/${this.end}`,
      ...(this.recurrence && { recur: `RRULE:${getRrule(this.recurrence)}` })
    };

    const baseUrl = URL.GOOGLE;
    const queryString = toQueryString(params);

    return `${baseUrl}?${queryString}`;
  }
}
