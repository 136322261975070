<!-- Show share button if the event/drop is published -->

<root-button
  *ngIf="content?.published && showText"
  type="secondary"
  [size]="size"
  [disabled]="isDisabled"
  aria-label="Share"
  role="button"
  name="share"
  class="share-button card-text-color"
  [rootDropdown]="shareMenu"
  (visibleChange)="handleVisibleChanged($event)"
  [listener]="subMenu.dropdownStateChanged"
  position="center"
  text="Share"
  ><root-icon *ngIf="showIcon" name="share"></root-icon>
</root-button>

<root-button
  *ngIf="content?.published && !showText"
  type="ghost"
  [isOnCard]="true"
  [size]="size"
  [disabled]="isDisabled"
  aria-label="Share"
  role="button"
  name="share"
  class="share-button card-text-color"
  [rootDropdown]="shareMenu"
  (visibleChange)="handleVisibleChanged($event)"
  [listener]="subMenu.dropdownStateChanged"
  position="center"
  ><root-icon name="share"></root-icon>
</root-button>

<!-- SHARE MENU -->
<root-dropdown-menu #shareMenu>
  <ul rootDropdownMenu *ngIf="isLoading">
    <li rootDropdownMenuItem>
      <div class="animate-spin">
        <root-icon name="loader"></root-icon>
      </div>
      Loading...
    </li>
  </ul>
  <ul rootDropdownMenu *ngIf="!isLoading">
    <li
      rootDropdownMenuItem
      *ngIf="canUseSystemShare"
      (click)="doShare()"
      [logEvent]="userTappedShare"
      [cubeData]="{
        entityType: content?.isEvent ? 'event' : content?.isSignup ? 'drop' : '',
        entityId: content?.contentId
      }"
    >
      <root-icon name="share"></root-icon>
      Share...
    </li>
    <li
      rootDropdownMenuItem
      [cdkCopyToClipboard]="
        userContent?.shortLinks?.referralPageShortLink || content?.shareUrl
      "
      (click)="handleCopy()"
    >
      <root-icon name="link"></root-icon>
      Copy link
    </li>

    <li
      *ngIf="content?.isEvent || content?.isSignup"
      rootDropdownMenuItem
      [rootDropdown]="subMenu"
      position="right"
      isSubmenuHost="true"
    >
      <root-icon name="code"></root-icon>
      Copy embed code
      <root-icon name="chevron_right"></root-icon>
    </li>
  </ul>
</root-dropdown-menu>

<!-- COPY EMBED CODE MENU -->
<root-submenu #subMenu>
  <ul rootDropdownMenu *ngIf="content?.isEvent">
    <li
      rootDropdownMenuItem
      [cdkCopyToClipboard]="embedStringForType(eventCardVariant)"
      (click)="handleCopy()"
    >
      Card
    </li>
    <li
      rootDropdownMenuItem
      [cdkCopyToClipboard]="embedStringForType(eventMainVariant)"
      (click)="handleCopy()"
    >
      Register button
    </li>
    <li
      rootDropdownMenuItem
      [cdkCopyToClipboard]="embedStringForType(eventShareVariant)"
      (click)="handleCopy()"
    >
      Share button
    </li>
  </ul>
  <ul rootDropdownMenu *ngIf="content?.isSignup">
    <li
      rootDropdownMenuItem
      [cdkCopyToClipboard]="embedStringForType(dropCardVariant)"
      (click)="handleCopy()"
    >
      Card
    </li>
    <li
      rootDropdownMenuItem
      [cdkCopyToClipboard]="embedStringForType(dropMainVariant)"
      (click)="handleCopy()"
    >
      Register button
    </li>
    <li
      rootDropdownMenuItem
      [cdkCopyToClipboard]="embedStringForType(dropShareVariant)"
      (click)="handleCopy()"
    >
      Share button
    </li>
    <li
      rootDropdownMenuItem
      [cdkCopyToClipboard]="embedStringForType(dropScrollVariant)"
      (click)="handleCopy()"
    >
      Scroll-triggered popup
    </li>
  </ul>
</root-submenu>
