<root-spin [spinning]="isLoading">
  <div class="steps-content">
    <form [formGroup]="formGroup">
      <div rootFormItem *ngFor="let prompt of prompts; let i = index">
        <label class="form-label inline-block pb-2 text-xs" attr.for="i">
          {{ prompt?.required ? '*' : '' }}{{ prompt?.prompt }}
        </label>

        <norby-input
          *ngIf="!prompt?.type || prompt?.type == 'text'"
          class="prompt-input"
          [formControlName]="i"
          placeholder="Your answer..."
          [isDisabled]="isDisabled"
        ></norby-input>

        <norby-select
          *ngIf="prompt?.type == 'select'"
          prompt="Select an option"
          [formControlName]="i"
          [isDisabled]="isDisabled"
        >
          <option
            *ngFor="let option of prompt?.options | keyvalue"
            [ngValue]="option?.value"
          >
            {{ option?.value }}
          </option>
        </norby-select>

        <root-checkbox-wrapper
          *ngIf="prompt?.type == 'checkbox'"
          (onHandleChange)="handleDidCheckCheckbox($event, i)"
        >
          <div *ngFor="let option of prompt?.options">
            <label
              root-checkbox
              [value]="option"
              [checked]="isChecked(i, option)"
              [disabled]="isDisabled"
              >{{ option }}</label
            >
          </div>
        </root-checkbox-wrapper>
        <norby-date-input
          *ngIf="prompt?.type == 'datePicker'"
          [formControlName]="i"
        >
        </norby-date-input>
      </div>
    </form>
  </div>
</root-spin>
