import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import { AuthService, UserService } from '../../../services';
import {
  ContentRegisterable,
  IUserContent,
  IUserMetadata,
  IUserPublicMetadata
} from 'models';
import { CaptchaComponent } from 'uikit';

@Component({
  selector: 'lib-content-registration-profile',
  templateUrl: './content-registration-profile.component.html',
  styleUrls: ['./content-registration-profile.component.less']
})
export class ContentRegistrationProfileComponent
  extends CaptchaComponent
  implements OnInit, OnChanges
{
  @Input() needsName: boolean;
  @Input() needsEmail: boolean;
  @Input() hasLoadedProfile: boolean;
  @Input() isOnFinalScreen: boolean;
  @Input() userMetadata: IUserMetadata;
  @Input() content: ContentRegisterable;
  @Input() stages = [];
  @Input() userProfile: IUserPublicMetadata;
  @Input() userContent: IUserContent = {};

  @Output() unsubscribeUserSubscriptions = new EventEmitter<void>();
  @Output() handleNext = new EventEmitter<void>();
  @Output() handleSetError = new EventEmitter<any>();

  formGroup: UntypedFormGroup;
  isLoading: boolean;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _auth: AuthService,
    private _user: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formGroup = this._formBuilder.group({
      email: [
        this.userMetadata?.email,
        this.needsEmail ? [Validators.required, Validators.email] : []
      ],
      displayName: [
        this.userProfile?.displayName,
        this.needsName ? [Validators.required] : []
      ],
      photoURL: [this.userProfile?.photoURL, []],
      hasOptedIntoMarketingEmails: [true]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.userMetadata) {
      this.formGroup?.patchValue({
        email: this.userMetadata?.email,
        displayName: this.userProfile?.displayName,
        photoURL: this.userProfile?.photoURL
      });
      this.formGroup?.updateValueAndValidity();
    }
  }

  get marketingOptInLabel(): string {
    return (
      this.content?.buttonLabels?.newsletter ?? 'Subscribe to email updates.'
    );
  }

  get shouldShowMarketingOptIn(): boolean {
    return this.needsEmail && (this.content?.isEvent ?? false);
  }

  async submitProfile() {
    if (!this.formGroup.valid) return;
    this.isLoading = true;
    this.unsubscribeUserSubscriptions.emit();
    const { displayName, email, hasOptedIntoMarketingEmails } =
      this.formGroup.value;
    const needsNameAndUserHasNoneOrEntered =
      this.needsName && displayName !== this.userProfile?.displayName;

    const promises: Promise<any>[] = [
      needsNameAndUserHasNoneOrEntered &&
        this._user.setUserProfile({ displayName })
    ];

    // Marketing email opt in
    if (hasOptedIntoMarketingEmails) {
      this.userContent.hasOptedIntoEmailMarketing = true;
    }

    /*
     * The below flow is not entirely necessary. This is only userful if we want to allow
     * users to change their email address in the profile section. But truthfully, this is unneccesary
     * as selecting the email is baked into the auth flow. I(Steven): Only left this in because I did not
     * want to incidentally break the state machine.
     */
    const verifiedEmails = [
      ...(this.userMetadata?.verifiedEmails ?? []),
      ...(this.userMetadata?.email && this.userMetadata?.emailVerified
        ? [this.userMetadata?.email]
        : [])
    ];
    const captchaResponse = await this.executeCaptcha();

    if (email && verifiedEmails.includes(email)) {
      // If the email address is a federated identifier. We promote
      // it to the primary.
      promises.push(this._auth.promoteSecondOrderEmail({ email }));
    } else if (email && email !== this.userMetadata?.email) {
      // If the user decides to change the email in the profile section and the email
      // differs from what's on the account currently. We can send a non-blocking
      // magic link.
      promises.push(
        this._auth.initiateEmailSignInOrLinkingFlow({
          email,
          recaptchaToken: captchaResponse,
          sendMagicLink: true
        })
      );
    } else if (
      !this.stages.includes('email') &&
      this.userMetadata?.email &&
      !this.userMetadata?.emailVerified
    ) {
      // If the email stages were skipped and the email on the user record is not verified
      // that means that the 'target' auth user already had an unauthorized email on file
      // we now issue a magic link to ask them to verify their email address.
      promises.push(
        this._auth.initiateEmailSignInOrLinkingFlow({
          email: this.userMetadata?.email,
          recaptchaToken: captchaResponse,
          sendMagicLink: true
        })
      );
    }

    Promise.all(promises)
      .then(([a, b]) => this.handleNext.emit())
      .catch((e) => this.handleSetError.emit(e))
      .finally(() => (this.isLoading = false));
  }
}
