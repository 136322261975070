import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'root-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.less']
})
export class ResultComponent {
  @Input() iconTpl?: string | TemplateRef<void>;
  @Input() titleTpl?: string | TemplateRef<void>;
  @Input() subtitleTpl?: string | TemplateRef<void>;
  @Input() extraTpl?: string | TemplateRef<void>;

  constructor() {}
}
