import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from '../root-components/dropdown/dropdown.component';
import { SubmenuComponent } from '../root-components/submenu/submenu.component';
import {
  DropdownDirective,
  DropdownMenuDirective,
  DropdownMenuItemDirective,
  DropdownMenuItemDividerDirective
} from '../directives';

@NgModule({
  declarations: [
    DropdownComponent,
    SubmenuComponent,
    DropdownDirective,
    DropdownMenuDirective,
    DropdownMenuItemDirective,
    DropdownMenuItemDividerDirective
  ],
  imports: [CommonModule],
  exports: [
    DropdownComponent,
    SubmenuComponent,
    DropdownDirective,
    DropdownMenuDirective,
    DropdownMenuItemDirective,
    DropdownMenuItemDividerDirective
  ]
})
export class DropdownModule {}
