import { ConnectedPosition } from '@angular/cdk/overlay';

export const THEME_CLASS = 'theme_class_token';
export const THEME_CLASSES = 'theme_classes_token';
export const USE_RAW_IMAGES = 'use_raw_images';
export const DO_NOT_TRACK_ANALYTICS = 'do_not_track_analytics';

export const POSITION_MAP: { [key: string]: ConnectedPosition } = {
  bottomMiddle: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 5
  },
  topMiddle: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -5
  },
  bottomMiddleTooltip: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 8,
    panelClass: 'user-theme-tooltip-top-arrow-wrapper'
  },
  topMiddleTooltip: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -8,
    panelClass: 'user-theme-tooltip-bottom-arrow-wrapper'
  },
  bottomExtendRight: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: 5
  },
  topExtendRight: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: -5
  },
  bottomExtendLeft: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetY: 5
  },
  topExtendLeft: {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetY: -5
  },
  rightLower: {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
    offsetX: 5
  },
  rightUpper: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetX: 5
  },
  leftLower: {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
    offsetX: -5
  },
  leftUpper: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetX: -5
  },
  rightLowerOffset10: {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
    offsetX: 10
  },
  rightUpperOffset10: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetX: 10
  }
};

export const MONTH_NAMES: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const MONTH_SHORT_NAMES: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const WEEKDAYS: string[] = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
];

export enum MessageType {
  SUCCESS = 'success',
  ERROR = 'error'
}

export const DEVICE_TOGGLE_ICON_NAMES: string[] = ['smartphone', 'monitor'];

export const SPOTIFY_IFRAME_ID_PREFIX = 'spotify-iframe-';
export const SPOTIFY_IFRAME_API_SELECTOR = '__NORBY_SPOTIFY_IFRAME_API';
export const YOUTUBE_IFRAME_ID_PREFIX = 'youtube-iframe-';
export const YOUTUBE_IFRAME_API_SELECTOR = '__NORBY_YOUTUBE_IFRAME_API';
