import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyIconModule } from './norby-icon.module';
import { NorbyIconRadioButtonGroupComponent } from '../components/molecules/norby-icon-radio-button-group/norby-icon-radio-button-group.component';
import { NorbyIconRadioButtonModule } from './norby-icon-radio-button.module';

@NgModule({
  declarations: [NorbyIconRadioButtonGroupComponent],
  imports: [CommonModule, NorbyIconModule, NorbyIconRadioButtonModule],
  exports: [NorbyIconRadioButtonGroupComponent]
})
export class NorbyIconRadioButtonGroupModule {}
