import { IThemeDTO, Theme } from '../types';

export const compareThemes = (
  ...[themeOne, themeTwo]: (Theme | IThemeDTO)[]
): boolean => {
  if (!themeOne && !themeTwo) {
    return true;
  } else if (!themeOne || !themeTwo) {
    return false;
  }

  themeOne = themeOne instanceof Theme ? themeOne : Theme.fromObject(themeOne);
  themeTwo = themeTwo instanceof Theme ? themeTwo : Theme.fromObject(themeTwo);

  return (themeOne as Theme).hash === (themeTwo as Theme).hash;
};
