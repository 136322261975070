import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyMobileContentComponent } from '../components/atoms/norby-mobile-content/norby-mobile-content.component';

@NgModule({
  declarations: [NorbyMobileContentComponent],
  imports: [CommonModule],
  exports: [NorbyMobileContentComponent]
})
export class NorbyMobileContentModule {}
