<button
  type="button"
  (click)="handleButtonClicked()"
  class="flex flex-col align-items-center w-14 items-center cursor-pointer group"
>
  <div
    class="flex flex-col justify-center items-center w-10 h-10 group-hover:opacity-70 bg-green-light text-neutral-900 rounded-sm mb-1"
  >
    <norby-icon [name]="iconName"></norby-icon>
  </div>
  <label class="text-xs text-neutral-800 font-normal cursor-pointer">
    {{ label }}
  </label>
</button>
