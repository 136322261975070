<div
  [class.creator]="style === 'creator'"
  [class.dangling-avatar]="dangleAvatar"
  [style.background]="backgroundColor"
  [style.height]="height"
  class="items-center relative flex justify-center h-header-avatar-size"
  [ngClass]="{ 'mb-half-header-avatar-size': dangleAvatar }"
>
  <img
    *ngIf="bgImage?.src || bgImage?.srcset?.length"
    class="object-cover absolute w-full"
    [src]="bgImage?.src"
    [srcset]="bgImage?.srcset"
    [style.height]="height"
    alt="Background"
  />

  <div
    class="w-full relative z-10 flex flex-col justify-center items-center"
    [style.height]="
      shouldDisplayMenuIcon && isMenuCenter
        ? sizeInPixelsWithMenu
        : sizeInPixels
    "
  >
    <div
      *ngIf="avatarImage?.src || avatarImage?.srcset?.length"
      class="relative z-999 overflow-hidden"
      [ngClass]="{
        'mt-half-header-avatar-size': dangleAvatar,
        'shadow-none bg-white': dangleAvatar && !block?.maskUrl,
        'self-center w-header-avatar-size h-header-avatar-size':
          style === 'creator',
        'rounded-full shadow-2': style === 'creator' && !block?.maskUrl
      }"
      [style.height]="sizeInPixels"
      [style.width]="style === 'creator' ? sizeInPixels : null"
    >
      <a
        [href]="href"
        [attr.intercept]="isRelative || null"
        *ngIf="avatarImage?.srcset"
      >
        <img
          [src]="avatarImage?.src"
          [srcset]="avatarImage?.srcset"
          [style]="imageInlineStyle"
          [style.height]="sizeInPixels"
          [style.width]="style === 'creator' ? sizeInPixels : null"
          class="mask-centered"
          [alt]="alt"
        />
      </a>

      <a
        [href]="href"
        *ngIf="!avatarImage?.srcset"
        [attr.intercept]="isRelative || null"
      >
        <img
          [src]="avatarImage?.src"
          class="mask-centered"
          [style]="imageInlineStyle"
          [style.height]="sizeInPixels"
          [style.width]="style === 'creator' ? sizeInPixels : null"
          [alt]="alt"
        />
      </a>
    </div>
    <div
      class="mt-2 cursor-pointer relative z-50"
      *ngIf="shouldDisplayMenuIcon && isMenuCenter"
    >
      <root-icon
        [style.color]="hamburgerMenuColor"
        *ngIf="!menuOpen"
        name="menu"
        size="large"
        (click)="toggleMenu()"
      ></root-icon>

      <root-icon
        [style.color]="hamburgerMenuColor"
        *ngIf="menuOpen"
        name="x"
        size="large"
        (click)="toggleMenu()"
      ></root-icon>
    </div>
  </div>
  <div
    *ngIf="shouldDisplayMenuIcon && !isMenuCenter"
    class="h-full absolute flex items-center z-50 text-2xl cursor-pointer"
    [ngClass]="{
      'left-8': menuAlignment === 'left',
      'right-8': menuAlignment === 'right'
    }"
  >
    <root-icon
      [style.color]="hamburgerMenuColor"
      *ngIf="!menuOpen"
      name="menu"
      size="large"
      (click)="toggleMenu()"
    ></root-icon>

    <root-icon
      [style.color]="hamburgerMenuColor"
      *ngIf="menuOpen"
      name="x"
      size="large"
      (click)="toggleMenu()"
    ></root-icon>
  </div>
</div>

<div
  *ngIf="shouldDisplayMenuOptions"
  class="w-full relative z-50"
  [style.background]="backgroundColor"
>
  <div style="display: flex; flex-direction: column">
    <a
      *ngFor="let item of menuOptions"
      [href]="item.url"
      class="w-full p-4 border border-b-black cursor-pointer"
    >
      {{ item.title }}
    </a>
  </div>
</div>
