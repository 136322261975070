<div class="w-full flex flex-col group z-0">
  <div
    class="border-b collapsible-container appearance-none list-none px-0 py-2 flex flex-row items-center cursor-pointer text-sm text-neutral-800 hover:text-mustard leading-6 justify-between"
    (click)="handleToggleContent()"
  >
    <span class="text-sm">{{ title }}</span>
    <norby-icon
      name="chevron_down"
      [@openCloseCaret]="opened ? 'open' : 'closed'"
    ></norby-icon>
  </div>
  <div
    class="collapsible-footer py-3 origin-top text-neutral-600"
    [ngClass]="{
      'border-b': opened,
      'overflow-hidden': !opened
    }"
    [@openClose]="opened ? 'open' : 'closed'"
  >
    <ng-content></ng-content>
  </div>
</div>
