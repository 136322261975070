import { Injectable } from '@angular/core';
import { IUploadService } from 'models';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Injectable({
  providedIn: 'root'
})
export class MockFileUploadService implements IUploadService {
  async uploadFile(file: File): Promise<string> {
    return 'https://api.lorem.space/image/face?w=200&h=200';
  }

  fileSizeValidation(file: NzUploadFile, attachments: NzUploadFile[]) {
    return null;
  }
}
