import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject, startWith, takeUntil } from 'rxjs';

// Libs
import { IPrompt, IPromptResponse, PromptResponseChanges } from 'models';
import { BaseComponent } from '../../models';

@Component({
  selector: 'lib-drop-form-prompts-view',
  templateUrl: './drop-form-prompts-view.component.html',
  styleUrls: ['./drop-form-prompts-view.component.less']
})
export class DropFormPromptsViewComponent
  extends BaseComponent
  implements OnChanges, OnDestroy
{
  private _initFormNotifier$ = new Subject<void>();

  @Input() prompts: IPrompt[];
  @Input() promptResponses?: IPromptResponse[] = [];
  @Input() isDisabled?: boolean = false;
  @Input() isLoading?: boolean = false;

  @Output() onPromptResponseChanges: EventEmitter<PromptResponseChanges> =
    new EventEmitter<PromptResponseChanges>();

  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.prompts) {
      this._initForm();
    }

    if (changes.promptResponses && this.isDisabled) {
      this._updateFormValues();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._initFormNotifier$.next();
    this._initFormNotifier$.complete();
  }

  private _updateFormValues(): void {
    this.formGroup.patchValue(
      this.promptResponses?.reduce(
        (responses, promptResponse, index) => ({
          ...responses,
          [index]: promptResponse?.response
        }),
        {}
      )
    );
  }

  private _initForm(): void {
    this._initFormNotifier$.next();

    this.formGroup = this._formBuilder.group(
      this.prompts?.reduce(
        (prompts, prompt, index) => ({
          ...prompts,
          [index]: ['', prompt?.required ? Validators.required : []]
        }),
        {}
      ) ?? {}
    );

    this.formGroup.valueChanges
      .pipe(startWith({}), takeUntil(this._initFormNotifier$))
      .subscribe((value) => {
        const prompts = this.prompts ?? [];
        const responses = Object.values(value) as string[];
        const promptResponses = prompts.map<IPromptResponse>((prompt, idx) => ({
          prompt: prompt?.prompt,
          response: responses?.[idx] ?? ''
        }));

        this.onPromptResponseChanges.emit({
          promptResponses,
          valid: this.formGroup.valid,
          touched: this.formGroup.touched
        });
      });
  }

  handleDidCheckCheckbox(checkedBoxes: string[], promptIdx) {
    this.formGroup?.get(`${promptIdx}`)?.setValue(checkedBoxes.join(', '));
  }

  isChecked(index: number, option: string): boolean {
    const controlValue = this.formGroup.get(index.toString())?.value;
    if (Array.isArray(controlValue)) {
      return controlValue.includes(option);
    } else if (typeof controlValue === 'string') {
      return controlValue.split(', ').includes(option);
    }
    return false;
  }
}
