<ng-template>
  <div
    @slideMotion
    (@slideMotion.start)="handleSlideStarted($event)"
    (@slideMotion.done)="handleSlideFinished($event)"
    class="bg-white max-h-[50vh] w-full overflow-auto rounded-sm shadow-ant-default user-theme-dropdown"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
