import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyDateTimePickerComponent } from '../components/organisms/norby-date-time-picker';
import { NorbyDateInputModule } from './norby-date-input.module';
import { NorbyTimePickerModule } from './norby-time-picker.module';
import { NorbyHelpertextModule } from './norby-helpertext.module';

@NgModule({
  declarations: [NorbyDateTimePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyDateInputModule,
    NorbyTimePickerModule,
    NorbyHelpertextModule
  ],
  exports: [NorbyDateTimePickerComponent]
})
export class NorbyDateTimePickerModule {}
