import { EventsPrivateFilterArgs } from 'models';

// Util function to sort an array of events based on the supplied parameters
//
// Sort order depends on which field we are ordering by (event start date or
// modified at timestamp) and whether we are sorting in asc or desc order
//
// Code structured such that:
// - Order by is assumed to be startDate unless explicitly specified as modifiedAt
// - Sort is assumed to be asc unless explicitly specified as desc
// - If one or both of a and b is null, sort order is nondeterministic

export const eventSortFn = (args: EventsPrivateFilterArgs) => (a, b) => {
  if (!!a !== !!b || !a) {
    return 1;
  }

  const cursorKey =
    args.orderBy === 'modifiedAt' ? 'modifiedAtCursor' : 'startDateCursor';
  const aComp = a[cursorKey];
  const bComp = b[cursorKey];

  if (aComp === bComp) {
    return 0;
  } else if (args.sort === 'desc') {
    return aComp > bComp ? -1 : 1;
  } else {
    return aComp < bComp ? -1 : 1;
  }
};
