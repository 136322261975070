import { Injectable, Injector, Inject } from '@angular/core';
import { GlobalPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import {
  MessageData,
  MESSAGE_CONFIG_TOKEN,
  MessageConfig,
  MessageComponent,
  MessageRef
} from '../../root-components/message';
import { IMessageService } from './message.service.interface';

@Injectable({
  providedIn: 'root'
})
export class MessageService implements IMessageService {
  private _lastMessage: MessageRef;

  constructor(
    private _overlay: Overlay,
    private _parentInjector: Injector,
    @Inject(MESSAGE_CONFIG_TOKEN) private _messageConfig: MessageConfig
  ) {}

  show(data: MessageData, themeClasses?: string[]): MessageRef {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this._overlay.create({
      positionStrategy,
      panelClass: themeClasses
    });

    const messageRef = new MessageRef(overlayRef);
    this._lastMessage = messageRef;

    const injector = this.getInjector(data, messageRef, this._parentInjector);
    const messagePortal = new ComponentPortal(MessageComponent, null, injector);

    overlayRef.attach(messagePortal);

    return messageRef;
  }

  getPositionStrategy(): GlobalPositionStrategy {
    return this._overlay
      .position()
      .global()
      .top(this.getPosition())
      .centerHorizontally();
  }

  getPosition(): string {
    const lastMessageIsVisible =
      this._lastMessage && this._lastMessage.isVisible();
    let position = lastMessageIsVisible
      ? this._lastMessage.getPosition().bottom
      : this._messageConfig.position.top;

    position += 8;

    return position + 'px';
  }

  getInjector(
    data: MessageData,
    messageRef: MessageRef,
    parentInjector: Injector
  ): Injector {
    return Injector.create({
      parent: parentInjector,
      providers: [
        { provide: MessageRef, useValue: messageRef },
        { provide: MessageData, useValue: data }
      ]
    });
  }
}
