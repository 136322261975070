import { APP_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// Libs
import {
  getSharedProviders,
  getAntProviders,
  DeviceService,
  ShareService,
  ApiService,
  AnalyticsService
} from 'shared';
import {
  MockDeviceService,
  MockShareService,
  MockApiService,
  MockAnalyticsService
} from 'uikit';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserAnimationsModule, HttpClientModule, AppRoutingModule],
  providers: [
    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),

    // App
    ...getSharedProviders(environment),
    ...getAntProviders(environment),
    { provide: APP_ID, useValue: 'status' },

    {
      provide: MockDeviceService,
      useExisting: DeviceService
    },
    {
      provide: MockShareService,
      useExisting: ShareService
    },
    {
      provide: MockApiService,
      useExisting: ApiService
    },
    {
      provide: MockAnalyticsService,
      useExisting: AnalyticsService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
