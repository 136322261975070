<div class="flex">
  <div *ngIf="avatar">
    <ng-template [ngTemplateOutlet]="avatar"></ng-template>
  </div>
  <div *ngIf="title || description" class="pl-4">
    <div *ngIf="title">
      {{ title }}
    </div>
    <div class="text-neutral-600" *ngIf="description">
      {{ description }}
    </div>
  </div>
</div>
