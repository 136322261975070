import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventCardViewComponent } from '../views/event-card-view';
import { AlertModule } from './alert.module';
import { CardModule } from './card.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { EventMainButtonViewModule } from './event-main-button-view.module';
import { IconModule } from './icon.module';
import { SkeletonModule } from './skeleton.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { TagModule } from './tag.module';
import { TooltipModule } from './tooltip.module';

@NgModule({
  declarations: [EventCardViewComponent],
  imports: [
    CommonModule,
    EventMainButtonViewModule,
    EventDropShareButtonViewModule,
    SoftImageViewModule,
    TagModule,
    TooltipModule,
    IconModule,
    CardModule,
    AlertModule,
    SkeletonModule
  ],
  exports: [EventCardViewComponent]
})
export class EventCardViewModule {}
