<div class="vimeo-embed-wrapper" *ngIf="safeEmbedCode">
  <div [innerHtml]="safeEmbedCode"></div>
</div>

<root-card
  class="vimeo-embed-placeholder card-background-color"
  *ngIf="!safeEmbedCode"
>
  <root-card-meta
    description="Publish to view the rendered Vimeo video."
    [avatar]="avatarTemplate"
  >
  </root-card-meta>
</root-card>

<ng-template #avatarTemplate>
  <root-avatar>
    <root-icon name="video"></root-icon>
  </root-avatar>
</ng-template>
