export const DEFAULT_PLACEHOLDER_HEIGHT = 42;
export const DEFAULT_HEADER_HEIGHT = 75;
export const DEFAULT_LOGO_HEIGHT = 50;
export const DEFAULT_BLOCK_PADDING = 16;
export const DEFAULT_BLOCK_FONT_SIZE = 16;
export const DEFAULT_BLOCK_ATTRIBUTION_FONT_SIZE = 11;
export const DEFAULT_IMAGE_ALIGNMENT = 'center';
export const DRAG_CONTAINER_WIDTH = 688;
export const FALLBACK_HEADER_IMAGE_URL =
  'https://norby.imgix.net/https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fmagic-sauce.appspot.com%2Fo%2FdefaultOnboardingAvatar.jpeg%3Falt%3Dmedia%26token%3D27e5fd1a-4bd1-47c3-ad28-2a83e74ad751?ixlib=js-2.3.2&fit=clip&w=1600&auto=compress%2Cformat&fm=webp%2Cjp2&s=4412b27394247426f14629dc6f8e0c9d';
