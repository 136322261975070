import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbySwitchButtonComponent } from '../components/molecules/norby-switch-button';
import { NorbyFormcontrolLabelModule } from './norby-formcontrol-label.module';
import { NorbyHelpertextModule } from './norby-helpertext.module';

@NgModule({
  declarations: [NorbySwitchButtonComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyFormcontrolLabelModule,
    NorbyHelpertextModule
  ],
  exports: [NorbySwitchButtonComponent]
})
export class NorbySwitchButtonModule {}
