<div
  class="flex flex-col items-center justify-center text-color"
  *ngIf="accountInfo"
>
  <!-- TITLE -->
  <h2
    class="self-stretch text-center mb-3"
    *ngIf="accountInfo?.title && block?.showTitle != false"
  >
    {{ accountInfo?.title }}
  </h2>

  <!-- PRONOUNS -->
  <div
    class="self-stretch text-center italic text-sm mb-3"
    *ngIf="accountInfo?.pronouns && block?.showPronouns != false"
  >
    {{ accountInfo?.pronouns }}
  </div>

  <!-- BIO -->
  <div
    *ngIf="accountInfo?.bio && block?.showBio != false"
    class="mb-3"
    [innerHTML]="accountInfo?.bio | safeHtml"
  ></div>

  <!-- TAGS -->
  <div
    *ngIf="accountInfo?.location && block?.showLocation != false"
    class="m-0"
  >
    <root-tag>
      <root-icon size="small" name="map_pin"></root-icon>
      <span>{{ accountInfo.location }}</span>
    </root-tag>
  </div>
</div>

<div
  class="flex flex-col items-center justify-center text-color"
  *ngIf="!accountInfo"
>
  <root-skeleton></root-skeleton>
</div>
