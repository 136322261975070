import { stripHtml } from '../tools';

export const estimateSegmentCount = (text: string) => {
  if (!text) {
    return '';
  }
  const htmlStrippedText = stripHtml(text);

  const hasDefaultValueMatcher = /{{([^{}]+)}}{{\^(\1)}}(.*){{\/\1}}/g;
  const variableMatcher = /{{.*?}}/g;

  let transformed = htmlStrippedText;
  transformed = transformed.replace(hasDefaultValueMatcher, '1234567890');
  transformed = transformed.replace(variableMatcher, '1234567890');
  return transformed;
};
