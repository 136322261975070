import { TransformFnParams } from 'class-transformer';
import { Timestamp } from '@angular/fire/firestore';
import dayjs from 'dayjs';

// Utility to transform Timestamp to date
export const transformTimestampToDate = (val: TransformFnParams): Date => {
  const value = val?.value;

  if (typeof value === 'string') {
    return new Date(value);
  }

  const seconds: number = value?.seconds ?? value?._seconds;

  if (seconds) {
    const nanoseconds: number =
      (value?.nanoseconds ?? value?._nanoseconds) || 0;
    return new Timestamp(seconds, nanoseconds).toDate();
  }

  return value;
};

export const transformTimestampToDay = (
  val: TransformFnParams
): dayjs.Dayjs => {
  const value = val?.value;
  const seconds: number = value?.seconds ?? value?._seconds;
  const nanoseconds: number = (value?.nanoseconds ?? value?._nanoseconds) || 0;
  const date = seconds ? new Timestamp(seconds, nanoseconds).toDate() : value;
  return date ? dayjs(date) : null;
};
