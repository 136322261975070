<button
  type="button"
  *ngIf="buttonType === 'default'"
  (click)="handleButtonClick($event)"
  [disabled]="isDisabled"
  class="ant-btn-animation flex items-center justify-center flex-1 leading-5 whitespace-nowrap rounded-sm px-4 py-2 border"
  [ngClass]="{
    'bg-white text-neutral-900 hover:bg-neutral-700 hover:border-neutral-700 hover:text-white focus:bg-white focus:text-neutral-900 focus:border-neutral-900':
      !isDisabled,
    'border-neutral-600 bg-neutral-200 text-neutral-600 cursor-not-allowed':
      isDisabled,
    'border-neutral-900': !isJoinedLeft && !isJoinedRight,
    'rounded-l-sm rounded-r-none': isJoinedRight && !isJoinedLeft,
    'rounded-r-sm rounded-l-none': isJoinedLeft && !isJoinedRight,
    'rounded-none': isJoinedLeft && isJoinedRight,
    'h-input-height': size === 'medium',
    'h-input-height-sm ': size === 'small',
    'border-neutral-300': isJoinedLeft || isJoinedRight
  }"
>
  <div class="flex items-center" *ngIf="iconPlacement === 'left'">
    <norby-icon [name]="iconName"></norby-icon
    ><span class="pr-1">{{ buttonLabel }}</span>
  </div>
  <div class="flex items-center" *ngIf="iconPlacement === 'right'">
    <span class="pl-1">{{ buttonLabel }}</span
    ><norby-icon [name]="iconName"></norby-icon>
  </div>
</button>

<button
  type="button"
  *ngIf="buttonType === 'primary'"
  (click)="handleButtonClick($event)"
  [disabled]="isDisabled"
  class="flex items-center justify-center flex-1 leading-5 whitespace-nowrap rounded-sm px-4 py-2 border"
  [ngClass]="{
    'bg-neutral-900 text-white hover:bg-neutral-800 hover:border-neutral-800':
      !isDisabled,
    'border-neutral-600 bg-neutral-200 text-neutral-600 cursor-not-allowed':
      isDisabled,
    'border-neutral-900': !isJoinedLeft && !isJoinedRight,
    'rounded-l-sm rounded-r-none': isJoinedRight && !isJoinedLeft,
    'rounded-r-sm rounded-l-none': isJoinedLeft && !isJoinedRight,
    'rounded-none': isJoinedLeft && isJoinedRight,
    'h-input-height': size === 'medium',
    'h-input-height-sm ': size === 'small',
    'border-neutral-300': isJoinedLeft || isJoinedRight
  }"
>
  <div class="flex items-center" *ngIf="iconPlacement === 'left'">
    <norby-icon [name]="iconName"></norby-icon
    ><span class="pr-1">{{ buttonLabel }}</span>
  </div>
  <div class="flex items-center" *ngIf="iconPlacement === 'right'">
    <span class="pl-1">{{ buttonLabel }}</span
    ><norby-icon [name]="iconName"></norby-icon>
  </div>
</button>
