<div class="browse">

    <div class="browse-wrapper">
        <div class="browse-container" *ngIf="events?.length > 0">
            <div class="browse-item-section"
                *ngFor="let bucket of eventBuckets | keyvalue: preserveOrder; let i = index; trackBy: bucketTrackBy">
                <div class="browse-item-header">
                    <div class="week-header-container">
                        <h6 class="week card-border-style card-background-color card-text-color">
                            <span>{{ bucket?.key || '• • • • •' }}</span>
                        </h6>
                    </div>
                </div>
                <div class="browse-item-wrapper">
                    <div class="panel-wrapper"
                        *ngFor="let event of bucket?.value; let n = index; trackBy: eventTrackBy">
                        <lib-event-drop-card [content]="event" (cardClick)="handleCardClick($event)">
                        </lib-event-drop-card>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>