import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from '../root-components/card/card.component';
import { CardMetaComponent } from '../root-components/card/card-meta.component';

@NgModule({
  declarations: [CardComponent, CardMetaComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [CardComponent, CardMetaComponent]
})
export class CardModule {}
