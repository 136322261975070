export const rootSoundcloud = {
  name: 'soundcloud',
  data: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" stroke="currentColor" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
  <g id="surface1">
  <path style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-opacity:1;stroke-miterlimit:4;" d="M 14.848958 9.901042 L 14.848958 22.197917 C 14.848958 22.75 15.296875 23.197917 15.848958 23.197917 L 26.401042 23.197917 C 28.28125 22.708333 29.588542 20.989583 29.552083 19.046875 C 29.520833 17.098958 28.161458 15.427083 26.260417 15 L 24 15 C 24.026042 11.65625 21.34375 8.927083 18 8.901042 L 15.848958 8.901042 C 15.296875 8.901042 14.848958 9.348958 14.848958 9.901042 Z M 14.848958 9.901042 " transform="matrix(0.75,0,0,0.75,0,0)"/>
  <path style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-opacity:1;stroke-miterlimit:4;" d="M 10.890625 8.901042 L 10.890625 23.197917 " transform="matrix(0.75,0,0,0.75,0,0)"/>
  <path style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-opacity:1;stroke-miterlimit:4;" d="M 6.932292 12.8125 L 6.932292 23.458333 " transform="matrix(0.75,0,0,0.75,0,0)"/>
  <path style="fill:none;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-opacity:1;stroke-miterlimit:4;" d="M 2.75 16.770833 L 2.75 23.197917 " transform="matrix(0.75,0,0,0.75,0,0)"/>
  </g>
  </svg>`
};
