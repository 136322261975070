<div class="flex flex-col user-theme-card-image-position-vertical">
  <div *ngIf="cardCover" class="user-theme-card-image-vertical">
    <ng-template [ngTemplateOutlet]="cardCover"></ng-template>
  </div>
  <div>
    <div class="p-4" [ngStyle]="bodyStyle">
      <ng-content></ng-content>
    </div>
    <ul *ngIf="cardActions.length">
      <li
        *ngFor="let action of cardActions"
        [style.width.%]="100 / cardActions.length"
      >
        <span><ng-template [ngTemplateOutlet]="action"></ng-template></span>
      </li>
    </ul>
  </div>
</div>
