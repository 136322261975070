<div class="w-full">
  <norby-formcontrol-label
    [label]="label"
    [infoTooltip]="infoTooltip"
  ></norby-formcontrol-label>
  <label class="flex items-center cursor-pointer relative">
    <input
      #checkbox
      type="checkbox"
      class="sr-only"
      [disabled]="isDisabled"
      [attr.checked]="val ? true : null"
      (change)="handleValueChange(checkbox.checked)"
    />
    <div
      class="toggle-bg border-2 opacity-90 h-6 min-w-13 w-13 rounded-full"
      [ngClass]="{
        'bg-neutral-50 border-neutral-50 opacity-100 cursor-not-allowed':
          isDisabled,
        'bg-neutral-300 border-neutral-300 hover:bg-neutral-400 hover:border-neutral-400':
          !isDisabled
      }"
      [@buttonClicked]="animationState"
    ></div>
    <span class="ml-3 text-neutral-800 text-sm font-medium">{{
      rightLabel
    }}</span>
  </label>
  <norby-helpertext [helperText]="helperText"></norby-helpertext>
</div>
