<button
  *ngIf="framed"
  class="h-10 w-full pl-4 border hover:text-neutral-800 rounded-sm flex flex-row flex-1 leading-5 items-center whitespace-nowrap outline-1 checkbox-button"
  [ngClass]="{
    'bg-mustard-200 border-mustard-400 text-neutral-800 font-medium': val,
    'bg-white border-neutral-300 text-neutral-700': !val
  }"
  (click)="toggleChecked()"
>
  <label root-checkbox [checked]="val" [radio]="false" [disabled]="isDisabled">
    <span>{{ label }}</span>
  </label>
</button>

<div class="flex flex-row items-center" *ngIf="!framed">
  <input
    class="h-4 w-4 border border-neutral-400 accent-mustard rounded-sm bg-white checked:border-mustard checked:text-white focus:outline-none mr-3 cursor-pointer"
    [ngClass]="{
      'cursor-none checked:bg-neutral-200 checked:border-neutral-200 checked:text-neutral-400':
        isDisabled
    }"
    type="checkbox"
    [checked]="val"
    [disabled]="isDisabled"
    (change)="toggleChecked()"
  />
  <label
    class="form-check-label inline-block text-neutral-900 cursor-pointer text-sm leading-sm"
    [ngClass]="{
      'cursor-none text-neutral-500': isDisabled
    }"
    (click)="toggleChecked()"
  >
    {{ label }}
  </label>
</div>
