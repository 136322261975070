import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdaptableBlockViewComponent } from '../views/adaptable-block-view';
import { EventDropCardModule } from './event-drop-card.module';
import { UpcomingEventsModule } from './upcoming-events.module';
import {
  AbstractEventViewModule,
  AbstractLinkViewModule,
  AbstractSignupViewModule,
  EmbedViewModule,
  ImageBlockViewModule,
  SocialIconsViewModule,
  YoutubeEmbedViewModule,
  VimeoEmbedViewModule,
  SpotifyEmbedViewModule,
  CalendlyEmbedViewModule,
  ProfileViewModule,
  SpacerViewModule,
  LinkCardViewModule,
  HeaderViewModule,
  LiveThemedDirectiveModule,
  NewsletterSignupViewModule
} from 'uikit';

@NgModule({
  declarations: [AdaptableBlockViewComponent],
  imports: [
    CommonModule,
    LinkCardViewModule,
    EventDropCardModule,
    ImageBlockViewModule,
    SpacerViewModule,
    UpcomingEventsModule,
    EmbedViewModule,
    YoutubeEmbedViewModule,
    VimeoEmbedViewModule,
    SpotifyEmbedViewModule,
    CalendlyEmbedViewModule,
    NewsletterSignupViewModule,
    HeaderViewModule,
    ProfileViewModule,
    SocialIconsViewModule,
    AbstractEventViewModule,
    AbstractLinkViewModule,
    AbstractSignupViewModule,
    LiveThemedDirectiveModule
  ],
  exports: [AdaptableBlockViewComponent]
})
export class AdaptableBlockViewModule {}
