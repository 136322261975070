<div class="steps-content max-w-md">
  <form [formGroup]="formGroup" (ngSubmit)="submitPhone()">
    <h2 rootFormItem>Enter your number</h2>

    <p *ngIf="loginCopy" rootFormItem>{{ loginCopy }}</p>

    <div rootFormItem>
      <div rootInputGroup>
        <norby-select
          class="phone-number-select"
          formControlName="countryCode"
          [isJoinedRight]="true"
          (onHandleChange)="handleCountryCodeChanged($event)"
        >
          <option
            *ngFor="let country of COUNTRY_CODES | keyvalue : preserveOrder"
            [value]="country.key"
          >
            {{ country.value?.flag }} +{{ country.value?.value }}
          </option>
        </norby-select>

        <norby-input
          class="phone-number-input"
          type="tel"
          [placeholder]="phonePlaceholder"
          formControlName="phoneNumber"
          [isRequired]="true"
          [isJoinedLeft]="true"
        ></norby-input>
      </div>
    </div>

    <div
      rootFormItem
      *ngIf="shouldShowMarketingOptIn && needsMarketingCheckBox"
    >
      <label root-checkbox size="small" formControlName="hasOptedIntoMarketing">
        <div class="text-xs" [innerHtml]="marketingOptInLabel"></div>
      </label>
    </div>

    <div rootFormItem *ngIf="shouldShowTermsOptIn">
      <label
        root-checkbox
        size="small"
        formControlName="hasAgreedToCustomerTerms"
      >
        <div
          class="text-xs"
          (click)="handleTermsLabelClick($event)"
          [innerHtml]="termsOptInLabel"
        ></div>
      </label>
    </div>

    <div rootFormItem>
      <root-button
        type="primary"
        size="large"
        [block]="true"
        [disabled]="!formGroup.valid"
        [loading]="isLoading"
        text="Next"
      >
      </root-button>
    </div>

    <div
      class="text-xxs text-center opacity-60"
      *ngIf="shouldShowMarketingOptIn && !needsMarketingCheckBox"
      [innerHtml]="marketingOptInLabel"
    ></div>
  </form>
</div>
