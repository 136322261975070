export type ChameleonPluginConfig = {
  apiKey: string;
  fastUrl: string;
};

const c = 'chmln';

function chameleonPlugin(config: ChameleonPluginConfig) {
  return {
    name: 'chameleon',
    config,
    initialize: ({ config }) => {
      const { fastUrl, apiKey } = config;

      // Shouldn't have gotten this far if we don't have env variables
      if (!fastUrl || !apiKey) {
        throw new Error('No Chameleon tokens defined');
      }

      (function (d, w) {
        var t = apiKey,
          i = d.createElement('script');
        if ((w[c] || (w[c] = {}), !w[c].root)) {
          (w[c].accountToken = t),
            (w[c].location = w.location.href.toString()),
            (w[c].now = new Date()),
            (w[c].fastUrl = fastUrl);
          var m =
            'identify alias track clear set show on off custom help _data'.split(
              ' '
            );
          for (var s = 0; s < m.length; s++) {
            (function () {
              var t = (w[c][m[s] + '_a'] = []);
              w[c][m[s]] = function () {
                t.push(arguments);
              };
            })();
          }
          (i.src = w[c].fastUrl + 'messo/' + t + '/messo.min.js'),
            (i.async = !0),
            d.head.appendChild(i);
        }
      })(document, window);
    },
    identify: ({ payload }) => {
      const { userId, traits } = payload;
      if (typeof userId === 'string') {
        window[c].identify(userId, traits);
      }
    },
    track: ({ payload }) => {
      window[c].track(payload.event, payload.properties);
    },
    loaded: () => {
      return !!window[c];
    }
  };
}

export default chameleonPlugin;
