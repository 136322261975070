import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import {
  MessageData,
  MESSAGE_CONFIG_TOKEN,
  MessageConfig,
  MessageAnimation
} from './message-config';
import { MessageRef } from './message-ref';

import { messageAnimations, MessageAnimationState } from '../../animations';
import { IconService } from '../../services/icon';
import { rootCheckCircle, rootXCircle } from '../../../icons/build';

@Component({
  selector: 'root-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.less'],
  animations: [messageAnimations.slideMessage]
})
export class MessageComponent implements OnInit, OnDestroy {
  animationState: MessageAnimationState = 'default';
  iconType: string;
  animation: MessageAnimation;
  displayData: MessageData;

  private _intervalId: number;

  constructor(
    private _data: MessageData,
    private _ref: MessageRef,
    private _iconService: IconService,
    @Inject(MESSAGE_CONFIG_TOKEN) messageConfig: MessageConfig
  ) {
    this._iconService.registerIcons([rootCheckCircle, rootXCircle]);
    this.displayData = _data;
    this.animation = messageConfig.animation;
    this.iconType = _data.type;
  }

  ngOnInit() {
    this._intervalId = window.setTimeout(
      () => (this.animationState = 'closing'),
      5000
    );
  }

  ngOnDestroy() {
    clearTimeout(this._intervalId);
  }

  @HostListener('mouseenter')
  keepOpen() {
    clearTimeout(this._intervalId);
  }

  @HostListener('mouseleave')
  close() {
    this._intervalId = window.setTimeout(
      () => (this.animationState = 'closing'),
      2000
    );
  }

  onSlideFinished(event: AnimationEvent) {
    const { toState } = event;
    const isSlideOut = (toState as MessageAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isSlideOut && itFinished) {
      this._ref.close();
    }
  }
}
