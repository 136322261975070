import {
  Exclude,
  Expose,
  Transform,
  Type,
  instanceToInstance,
  instanceToPlain,
  plainToInstance
} from 'class-transformer';
import dayjs from 'dayjs';
import { hashString } from '../tools';
import { NotificationMediums } from './general';
import { EmailMarketingCommunicationTypes } from './single-sends';

export class ISegmentFilterGroupDTO {
  readonly filterOperator: FilterGroupOperator;
  readonly filters: ISegmentFilterDTO[];
}

export type FilterGroupOperator = 'AND' | 'OR';

export type EditFilterGroupEvent = {
  groupIndex: number;
  filter?: SegmentFilter;
  filterOperator?: FilterGroupOperator;
};

export type FilterGroupControlType = {
  id: string;
  controlInstance: string;
  isNew?: boolean;
  filters: SegmentFilter[];
};

export class SegmentFilterGroup extends ISegmentFilterGroupDTO {
  @Exclude()
  private _hash: number;

  @Type(() => SegmentFilter)
  readonly filters: SegmentFilter[];

  static fromObject(obj: Partial<ISegmentFilterGroupDTO>) {
    return plainToInstance(SegmentFilterGroup, obj);
  }

  get hash() {
    if (!this._hash) {
      const props = `
        ${this.filterOperator}
        ${this.filters.map((filter) => filter.hash).join(',')}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  toCreateDTO(): ISegmentFilterGroupDTO {
    return {
      filterOperator: this.filterOperator,
      filters: this.filters.map((filter) => filter.toCreateDTO())
    };
  }

  updateProperties(properties: Partial<ISegmentFilterGroupDTO>) {
    for (const key in properties) {
      this[key] = properties[key];
    }

    this._hash = null;
  }

  addFilter(filter: SegmentFilter) {
    this.filters.push(filter);
    this._hash = null;
  }

  removeFilterAtIndex(idx: number) {
    this.filters.splice(idx, 1);
    this._hash = null;
  }
}

export class ISegmentFilterDTO {
  readonly type: SegmentFilterType;
  readonly contentId?: string;
  readonly sendId?: string;
  readonly contactListId?: string;
  readonly landingPageId?: string;
  readonly operationType?: 'before' | 'after' | 'on';
  readonly date?: Date;
  readonly prompt?: string;
  readonly response?: string;
  readonly lowerBoundDate?: Date;
  readonly upperLowerDate?: Date;
  readonly tag?: string;
  readonly rawUrl?: string;
  readonly medium?: string;

  contentTitle?: string;
  contactListName?: string;
  singleSendDisplay?: string;
  landingPageTitle?: string;
}

export class SegmentFilter extends ISegmentFilterDTO {
  @Exclude()
  private _hash: number;

  @Exclude()
  private _displayTitle: string;

  @Exclude({ toPlainOnly: true })
  contentTitle?: string;

  @Exclude({ toPlainOnly: true })
  contactListName?: string;

  @Exclude({ toPlainOnly: true })
  singleSendDisplay: string;

  @Exclude({ toPlainOnly: true })
  landingPageTitle: string;

  static fromObject(obj: Partial<ISegmentFilterDTO>) {
    return plainToInstance(SegmentFilter, obj);
  }

  get hash() {
    if (!this._hash) {
      const props = `
        ${this.type}
        ${this.contentId}
        ${this.operationType}
        ${this.date?.toString()}
        ${this.prompt}
        ${this.response}
        ${this.lowerBoundDate}
        ${this.upperLowerDate}
        ${this.tag}
        ${this.contactListId}
        ${this.sendId}
        ${this.rawUrl}
        ${this.landingPageId}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  toCreateDTO(): ISegmentFilterDTO {
    const {
      type,
      contentId,
      contactListId,
      landingPageId,
      sendId,
      rawUrl,
      operationType,
      date,
      prompt,
      response,
      lowerBoundDate,
      upperLowerDate,
      tag,
      medium
    } = this.toObject();
    return {
      ...(type && { type }),
      ...(contentId && { contentId }),
      ...(contactListId && { contactListId }),
      ...(landingPageId && { landingPageId }),
      ...(sendId && { sendId }),
      ...(operationType && { operationType }),
      ...(date && { date }),
      ...(prompt && { prompt }),
      ...(response && { response }),
      ...(lowerBoundDate && { lowerBoundDate }),
      ...(upperLowerDate && { upperLowerDate }),
      ...(tag && { tag }),
      ...(rawUrl && { rawUrl }),
      ...(medium && { medium })
    };
  }

  get displayTitle(): string {
    if (!this._displayTitle) {
      switch (this.type) {
        case SegmentFilterTypes.REGISTERED:
          this._displayTitle = 'Registered for ' + this.contentTitle;
          break;
        case SegmentFilterTypes.SKIPPED:
          this._displayTitle = 'Skipped ' + this.contentTitle;
          break;
        case SegmentFilterTypes.ATTENDED:
          this._displayTitle = 'Attended ' + this.contentTitle;
          break;
        case SegmentFilterTypes.RESPONDED_TO_PROMPT:
          this._displayTitle = `${this.contentTitle} "${this.prompt}" responded "${this.response}"`;
          break;
        case SegmentFilterTypes.RESPONDED_TO_DATE_PICKER_PROMPT:
          this._displayTitle = this.contentTitle;
          if (this.date) {
            this._displayTitle += ` "${this.prompt}" is ${
              this.operationType !== 'on' ? this.operationType + ' ' : ''
            }${dayjs(this.date).format('MMM D YYYY')}`;
          }
          break;
        case SegmentFilterTypes.REGISTERED_DATE:
          this._displayTitle = 'Registered for ' + this.contentTitle;
          if (this.date) {
            this._displayTitle += ` ${this.operationType} `;
            this._displayTitle += dayjs(this.date).format('MMM D YYYY');
          }
          break;
        case SegmentFilterTypes.TAGGED_CONTACTS:
          this._displayTitle = 'Tagged with ' + this.tag;
          break;
        case SegmentFilterTypes.NOT_TAGGED_CONTACTS:
          this._displayTitle = 'Not tagged with ' + this.tag;
          break;
        case SegmentFilterTypes.CONTACT_LIST:
          this._displayTitle = 'In ' + this.contactListName;
          break;
        case SegmentFilterTypes.OPENED_SPECIFIC_EMAIL:
          this._displayTitle = 'Opened ' + this.singleSendDisplay;
          break;
        case SegmentFilterTypes.NOT_OPENED_SPECIFIC_EMAIL:
          this._displayTitle = 'Did not open ' + this.singleSendDisplay;
          break;
        case SegmentFilterTypes.CLICKED_SPECIFIC_SEND_LINK:
          this._displayTitle = `Clicked ${this.rawUrl}${
            this.singleSendDisplay ? ' in ' + this.singleSendDisplay : ''
          }`;
          break;
        case SegmentFilterTypes.CLICKED_ANY_SEND_LINK:
          this._displayTitle = `Clicked a link${
            this.singleSendDisplay ? ' in ' + this.singleSendDisplay : ''
          }`;
          break;
        case SegmentFilterTypes.VISITED_CONTENT_PAGE:
          this._displayTitle = `Visited ${this.contentTitle}`;
          break;
        case SegmentFilterTypes.VISITED_LANDING_PAGE:
          this._displayTitle = `Visited ${this.landingPageTitle}`;
          break;
        case SegmentFilterTypes.MEDIUM_CONTACTS:
          this._displayTitle = `${this.medium} contacts`;
          break;
      }
    }

    return this._displayTitle;
  }

  toObject() {
    return instanceToPlain(this) as ISegmentFilterDTO;
  }
}

export enum SegmentFilterTypes {
  REGISTERED = 'registered',
  REGISTERED_DATE = 'registeredDate',
  REGISTERED_DATE_RANGE = 'registeredDateRange',
  SKIPPED = 'skipped',
  ATTENDED = 'attended',
  RESPONDED_TO_PROMPT = 'respondedToPrompt',
  RESPONDED_TO_DATE_PICKER_PROMPT = 'respondedToDatePickerPrompt',
  TAGGED_CONTACTS = 'taggedContacts',
  NOT_TAGGED_CONTACTS = 'notTaggedContacts',
  ALL_CONTACTS = 'allContacts',
  CONTACT_LIST = 'contactList',
  OPENED_SPECIFIC_EMAIL = 'openedSpecificEmail',
  NOT_OPENED_SPECIFIC_EMAIL = 'notOpenedSpecificEmail',
  CLICKED_ANY_SEND_LINK = 'clickedAnySendLink',
  CLICKED_SPECIFIC_SEND_LINK = 'clickedSpecificSendLink',
  VISITED_LANDING_PAGE = 'visitedLandingPage',
  VISITED_CONTENT_PAGE = 'visitedContentPage',
  MEDIUM_CONTACTS = 'mediumContacts'
}

export type SegmentFilterType = `${SegmentFilterTypes}`;

export const EVENT_FILTERS = new Set<SegmentFilterType>([
  SegmentFilterTypes.REGISTERED,
  SegmentFilterTypes.REGISTERED_DATE,
  SegmentFilterTypes.REGISTERED_DATE_RANGE,
  SegmentFilterTypes.SKIPPED,
  SegmentFilterTypes.ATTENDED,
  SegmentFilterTypes.RESPONDED_TO_PROMPT
]);

export type SegmentPreviewDTO = {
  medium?: NotificationMediums;
  marketingCommunicationType?: EmailMarketingCommunicationTypes;
  filterGroups: ISegmentFilterGroupDTO[];
  excludedContactIds: string[];
  includedContactIds: string[];
  fullContacts?: boolean;
  includeNonMarketingContacts?: boolean;
};

export type SegmentPreviewResponseDTO = {
  previewContacts: SegmentContactPreview[];
  segmentPreviewCount: number;
  segmentExceedsPreviewLimit: boolean;
  medium: NotificationMediums;
};

export type SegmentContactPreview = {
  contactId: string;
  displayName?: string;
  nickname?: string;
  name?: string;
  primaryEmail?: string;
  primaryPhoneNumber?: string;
};

export class ISegment {
  id: string;
  slug: string;
  includedContacts: SegmentContactPreview[];
  excludedContacts: SegmentContactPreview[];
  filterGroups: ISegmentFilterGroupDTO[];
  isActive: true;
  excludedContactIds: string[];
  includedContactIds: string[];
  initializationStartedAt: string;
  initializationCompletedAt: string;
  modifiedAt: string;
  modifiedAtCursor: string;
  createdAt: string;
  createdAtCursor: string;
}

export class Segment extends ISegment {
  @Exclude()
  private _filterGroupsHash: number;

  @Exclude()
  private _includedContactsHash: number;

  @Exclude()
  private _excludedContactsHash: number;

  @Exclude()
  private _hash: number;

  // Ensure filterGroups is always initialized even if not present on source object
  @Expose()
  @Transform(
    (o) => (o?.value || []).map((fG) => SegmentFilterGroup.fromObject(fG)),
    { toClassOnly: true }
  )
  readonly filterGroups: SegmentFilterGroup[];

  // Ensure includedContacts is always initialized even if not present on source object
  @Expose()
  @Transform((o) => o?.value || [], { toClassOnly: true })
  readonly includedContacts: SegmentContactPreview[];

  // Ensure excludedContacts is always initialized even if not present on source object
  @Expose()
  @Transform((o) => o?.value || [], { toClassOnly: true })
  readonly excludedContacts: SegmentContactPreview[];

  // Automatically populate excludedContactIds from excludedContacts when
  // transforming to plain object
  @Transform((o) => o.obj['excludedContacts'].map((c) => c.contactId), {
    toPlainOnly: true
  })
  readonly excludedContactIds: string[];

  // Automatically populate includedContactIds from includedContacts when
  // transforming to plain object
  @Transform((o) => o.obj['includedContacts'].map((c) => c.contactId), {
    toPlainOnly: true
  })
  readonly includedContactIds: string[];

  static new() {
    return plainToInstance(Segment, {
      filterGroups: [],
      includedContactIds: [],
      excludedContactIds: []
    });
  }

  static fromObject(obj: Partial<ISegment>) {
    return plainToInstance(Segment, obj);
  }

  get includedContactsHash() {
    if (!this._includedContactsHash) {
      const includedContactsStr = this.includedContacts.reduce(
        (acc, curr) => `${acc}${curr.contactId}`,
        ''
      );
      this._includedContactsHash = hashString(includedContactsStr);
    }

    return this._includedContactsHash;
  }

  get excludedContactsHash() {
    if (!this._excludedContactsHash) {
      const excludedContactsStr = this.excludedContacts.reduce(
        (acc, curr) => `${acc}${curr.contactId}`,
        ''
      );
      this._excludedContactsHash = hashString(excludedContactsStr);
    }

    return this._excludedContactsHash;
  }

  get filterGroupsHash() {
    if (!this._filterGroupsHash) {
      const filterGroupsStr = this.filterGroups.reduce(
        (acc, curr) => `${acc}${curr.hash}`,
        ''
      );
      this._filterGroupsHash = hashString(filterGroupsStr);
    }

    return this._filterGroupsHash;
  }

  get hash() {
    if (!this._hash) {
      const props = `
        ${this.includedContactsHash}
        ${this.excludedContactsHash}
        ${this.filterGroupsHash}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  updateProperties(properties: Partial<ISegment>) {
    for (const key in properties) {
      this[key] = properties[key];
    }

    if (properties?.includedContacts) {
      this._includedContactsHash = null;
    }

    if (properties?.excludedContacts) {
      this._excludedContactsHash = null;
    }

    if (properties?.filterGroups) {
      this._filterGroupsHash = null;
    }

    if (Object.keys(properties).length) {
      this._hash = null;
    }
  }

  clone(withSegment?: Partial<Segment>) {
    const clone = instanceToInstance(this);

    if (withSegment) {
      clone.updateProperties(withSegment);
    }

    return clone;
  }

  updateFilterGroupAtIndex(idx: number, filterGroup: SegmentFilterGroup) {
    this.filterGroups[idx] = filterGroup;

    this._filterGroupsHash = null;
    this._hash = null;
  }

  updateFilterGroupOperatorAtIndex(
    idx: number,
    filterOperator: FilterGroupOperator
  ) {
    this.filterGroups[idx].updateProperties({ filterOperator });

    this._filterGroupsHash = null;
    this._hash = null;
  }

  removeAllFilterGroups() {
    this.filterGroups.splice(0, this.filterGroups.length);
    this._filterGroupsHash = null;
    this._hash = null;
  }

  removeFilterGroupAtIndex(idx: number) {
    this.filterGroups.splice(idx, 1);
    this._filterGroupsHash = null;
    this._hash = null;
  }

  addIncludedContact(contact: SegmentContactPreview) {
    this.includedContacts.push(contact);
    this._includedContactsHash = null;
    this._hash = null;
  }

  removeIncludedContact(contactId: string) {
    const index = this.includedContacts.findIndex(
      (c) => c.contactId === contactId
    );

    if (index > -1) {
      this.includedContacts.splice(index, 1);
      this._includedContactsHash = null;
      this._hash = null;
    }
  }

  addExcludedContact(contact: SegmentContactPreview) {
    this.excludedContacts.push(contact);
    this._excludedContactsHash = null;
    this._hash = null;
  }

  removeExcludedContact(contactId: string) {
    const index = this.excludedContacts.findIndex(
      (c) => c.contactId === contactId
    );

    if (index > -1) {
      this.excludedContacts.splice(index, 1);
      this._excludedContactsHash = null;
      this._hash = null;
    }
  }

  toObject() {
    return instanceToPlain(this) as ISegment;
  }
}
