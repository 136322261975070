import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyFileUploadComponent } from '../components/molecules/norby-file-upload';
import { NorbyIconModule } from './norby-icon.module';
import { NorbyFormcontrolLabelModule } from './norby-formcontrol-label.module';
import { NorbyButtonModule } from './norby-button.module';
import { NorbyImageEditModule } from './norby-image-edit.module';

@NgModule({
  declarations: [NorbyFileUploadComponent],
  imports: [
    CommonModule,
    NorbyIconModule,
    NorbyFormcontrolLabelModule,
    NorbyButtonModule,
    NorbyImageEditModule
  ],
  exports: [NorbyFileUploadComponent]
})
export class NorbyFileUploadModule {}
