import { Injectable } from '@angular/core';

// 3rd party
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

// Lib
import { DeviceService } from '../device';
import { ApiService } from '../api';
import { ErrorService } from 'uikit';
import {
  Content,
  ApiSurfaces,
  ContentEvent,
  EventsPrivateFilterArgs,
  IContentPrivateListingResults,
  IQueryResult,
  PaginatedQuerySummary,
  objectIdFromDate
} from 'models';
import {
  ObservableDataService,
  RealtimeSocketEventHandler
} from '../observable-data';
import {
  RealtimeServerSocketMessage,
  CONTENT_PRIVATE_UPDATED,
  CONTENT_PRIVATE_DELETED,
  CONTENT_PRIVATE_CREATED_IN_SLUG,
  CONTENT_PRIVATE_UPDATED_IN_SLUG,
  CONTENT_PRIVATE_DELETED_IN_SLUG
} from '../socket';
import { ContentService, eventSortFn } from '../content';

/*
  Content retrieval
*/

@Injectable({
  providedIn: 'root'
})
export class CustomerContentService extends ContentService {
  constructor(
    protected _error: ErrorService,
    protected _api: ApiService,
    protected _device: DeviceService,
    protected _ods: ObservableDataService
  ) {
    super(_error, _api, _device, _ods);
  }

  getContent$<T extends Content>(contentId: string): Observable<T> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: CONTENT_PRIVATE_UPDATED,
        payload: {
          resourceId: contentId
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          Content.fromObject(event?.data)
      },
      {
        event: CONTENT_PRIVATE_DELETED,
        payload: {
          resourceId: contentId
        },
        transformer: (event: RealtimeServerSocketMessage) => null
      }
    ];

    return this._ods
      .document$<T>({
        handlers,
        lookup: this.getContent,
        args: contentId
      })
      .pipe(
        map((summary) => summary?.data),
        filter((content, index) => !!(content || index))
      );
  }

  getContent = async <T extends Content>(id: string): Promise<T> => {
    if (!id?.length) {
      return null as T;
    }

    try {
      const ret = await this._api.get<T>(ApiSurfaces.API, `v2/content/${id}`);
      return Content.fromObject(ret) as T;
    } catch (e) {
      this._error?.displayError?.(e);
      throw e;
    }
  };

  getEvents = async (
    args: EventsPrivateFilterArgs
  ): Promise<IContentPrivateListingResults> => {
    // Default to showing all future events including today's unless showing suggested content
    if (args.after === undefined && args.orderBy !== 'modifiedAt') {
      args.after = objectIdFromDate(dayjs().startOf('day').toDate());
    }

    if (args.orderBy === undefined) {
      args.orderBy = 'startDate';
    }

    if (args.sort === undefined) {
      args.sort = 'asc';
    }

    try {
      const ret = await this._api.get<IContentPrivateListingResults>(
        ApiSurfaces.API,
        'v2/event',
        args
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getEventsForCurrentSlug$(
    args: EventsPrivateFilterArgs
  ): Observable<PaginatedQuerySummary<ContentEvent>> {
    // Function used to fetch events
    const lookup = this.getEvents;

    // Function used to transform lookup results and
    // incorporate them into the stream
    const transformer = (res: IQueryResult, currentValue: ContentEvent[]) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const events =
        res?.edges?.map((edge) => ContentEvent.fromObject(edge?.node)) ?? [];

      return {
        items: [...(currentValue ?? []), ...events],
        cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
      };
    };

    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: CONTENT_PRIVATE_CREATED_IN_SLUG,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          message: RealtimeServerSocketMessage,
          currentValue: ContentEvent[]
        ) => {
          if (message?.data?.contentType !== 'event' || !currentValue) {
            return currentValue;
          }

          const event = ContentEvent.fromObject(message.data);
          const cursorKey =
            args.orderBy === 'modifiedAt'
              ? 'modifiedAtCursor'
              : 'startDateCursor';
          const isEventPublishStatusCorrect =
            !!event.published === !!args.published;
          const isEventStartAfterCorrect =
            !args.after ||
            (args.sort === 'desc'
              ? event[cursorKey] < args.after
              : event[cursorKey] > args.after);

          if (!isEventPublishStatusCorrect || !isEventStartAfterCorrect) {
            return currentValue;
          }

          currentValue.push(event);
          currentValue.sort(eventSortFn(args));
          return [...currentValue];
        }
      },
      {
        event: CONTENT_PRIVATE_UPDATED_IN_SLUG,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          message: RealtimeServerSocketMessage,
          currentValue: ContentEvent[]
        ) => {
          if (message?.data?.contentType !== 'event' || !currentValue) {
            return currentValue;
          }

          const updatedEvent = ContentEvent.fromObject(message.data);
          const currentIdx = currentValue.findIndex(
            (node) => node?.contentId === updatedEvent?.contentId
          );

          const currentEvent =
            currentIdx > -1 ? currentValue[currentIdx] : null;
          const hasExistingEvent = !!currentEvent;
          const publishedStateChanged =
            currentEvent?.published !== updatedEvent.published;
          const updatedEventMatchesFilters =
            args.published === updatedEvent.published;

          // If we have an existing event and the published state did not change
          // just update it in place
          if (
            hasExistingEvent &&
            !publishedStateChanged &&
            updatedEventMatchesFilters
          ) {
            currentValue[currentIdx] = updatedEvent;
          }

          // If we have an existing event and the published state changed
          // and it no longer matches the filter criteria, remove it
          else if (
            hasExistingEvent &&
            publishedStateChanged &&
            !updatedEventMatchesFilters
          ) {
            currentValue.splice(currentIdx, 1);
          }

          // If we don't have an existing event but the updated event
          // does match the filter criteria, add it
          else if (!hasExistingEvent && updatedEventMatchesFilters) {
            currentValue.push(updatedEvent);
            currentValue.sort(eventSortFn(args));
          }

          // If no changes were made, no need to copy currentValue
          else {
            return currentValue;
          }

          return [...currentValue];
        }
      },
      {
        event: CONTENT_PRIVATE_DELETED_IN_SLUG,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          message: RealtimeServerSocketMessage,
          currentValue: ContentEvent[]
        ) => {
          if (message?.data?.contentType !== 'event' || !currentValue) {
            return currentValue;
          }

          const event = ContentEvent.fromObject(message.data);
          const currentIdx = currentValue.findIndex(
            (node) => node?.contentId === event?.contentId
          );

          if (currentIdx > -1) {
            currentValue.splice(currentIdx, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ];

    return this._ods.query$<ContentEvent>({
      args,
      lookup,
      transformer,
      handlers
    });
  }
}
