import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[rootInputGroupSuffix]'
})
export class InputGroupSuffixDirective {
  @HostBinding('class')
  elementClass =
    'relative right-px w-px whitespace-nowrap table-cell align-top';
  constructor() {}
}
