import { Inject, Injectable, Optional } from '@angular/core';

// 3rd party
import { Analytics, AnalyticsInstance } from 'analytics';

// App
import chameleonPlugin from './plugins/chameleon-plugin';
import profitWellPlugin from './plugins/profitwell-plugin';
import googleTagManager from '@analytics/google-tag-manager';
import {
  IAnalyticsServiceInterface,
  CHAMELEON_API_KEY,
  CHAMELEON_FAST_URL,
  PROFITWELL_API_KEY,
  ANALYTICS_TRACKING_DEBUG_MODE,
  GTM_ID
} from 'models';

/*
  Analytics tracking via Analytics.js

  Tracking is patched through to relevant services via
  the Analytics plugin architecture. Plugins are
  conditionally included based on the availability of
  different environment variables.
*/

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements IAnalyticsServiceInterface {
  private _analytics: AnalyticsInstance;

  constructor(
    @Optional() @Inject(CHAMELEON_API_KEY) private _chameleonApiKey: string,
    @Optional() @Inject(CHAMELEON_FAST_URL) private _chameleonFastUrl: string,
    @Optional() @Inject(PROFITWELL_API_KEY) private _profitwellApiKey: string,
    @Optional() @Inject(GTM_ID) private _gtmId: string,
    @Optional()
    @Inject(ANALYTICS_TRACKING_DEBUG_MODE)
    private _analyticsDebugMode: boolean
  ) {
    this._analytics = Analytics({
      app: 'norby',
      debug: !!this._analyticsDebugMode,
      plugins: [
        ...(this._chameleonApiKey && this._chameleonFastUrl
          ? [
              chameleonPlugin({
                apiKey: this._chameleonApiKey,
                fastUrl: this._chameleonFastUrl
              })
            ]
          : []),
        ...(this._profitwellApiKey
          ? [profitWellPlugin({ apiKey: this._profitwellApiKey })]
          : []),
        ...(this._gtmId ? [googleTagManager({ containerId: this._gtmId })] : [])
      ]
    });
  }

  /**
   * Track a custom event
   * @param eventName Event name
   * @param payload Event payload
   * @param options Event options
   * @param callback Callback to fire after tracking completes
   */
  async track(
    eventName: string,
    payload?: any,
    options?: any,
    callback?: (...params) => any
  ) {
    return this._analytics?.track(eventName, payload, options, callback);
  }

  /**
   * Identify a visitor
   * @param userId Unique ID of user
   * @param traits Object of user traits
   * @param options Options to pass to identify call
   * @param callback Callback function after identify completes
   */
  identify(
    userId: string,
    traits?: any,
    options?: any,
    callback?: (...params) => any
  ) {
    this._analytics?.identify(userId, traits, options, callback);
  }
}
