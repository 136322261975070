<!-- Show join button if event is happening -->
<div *ngIf="event?.published && event?.isHappening && !event?.isCanceled">
  <a
    *ngIf="userEvent?.rsvpEnabled && userEvent?.urls?.clickThroughRaw"
    [href]="userEvent.urls.clickThroughRaw"
    target="_blank"
    rel="noreferrer"
  >
    <root-button
      type="primary"
      [block]="true"
      size="large"
      [ariaLabel]="midLabel"
      [name]="midLabel"
      [loading]="isLoading"
      (handleClick)="handleJoinClick()"
      [text]="midLabel"
    >
    </root-button>
  </a>

  <!-- If the user hasn't registered or isn't logged in, still show the join button but handle as an RSVP -->
  <div
    *ngIf="!userEvent?.rsvpEnabled"
    [logEvent]="userClickedPreEventCta"
    [cubeData]="{ entityType: 'event', entityId: event?.contentId }"
  >
    <root-button
      type="primary"
      [block]="true"
      size="large"
      [ariaLabel]="midLabel"
      [name]="midLabel"
      [loading]="isLoading"
      [disabled]="isDisabled"
      (handleClick)="handleRsvpClick()"
      [text]="midLabel"
    >
    </root-button>
  </div>
</div>

<!-- Show recap button if event is over -->
<div
  *ngIf="
    event?.published &&
    event?.isOver &&
    userEvent?.urls?.recapRaw &&
    !event?.isCanceled
  "
  [logEvent]="userClickedPostEventCta"
  [cubeData]="{ entityType: 'event', entityId: event?.contentId }"
>
  <a [href]="userEvent?.urls?.recapRaw" target="_blank" rel="noreferrer">
    <root-button
      type="primary"
      [block]="true"
      [ariaLabel]="postLabel"
      [name]="postLabel"
      size="large"
      [loading]="isLoading"
      [text]="postLabel"
    >
    </root-button>
  </a>
</div>

<!-- Show RSVP button if event is future, published, and there's no user event or rsvp is disabled -->
<div
  *ngIf="
    event?.published &&
    event?.isFuture &&
    !userEvent?.rsvpEnabled &&
    !event?.isCanceled
  "
  [logEvent]="userClickedPreEventCta"
  [cubeData]="{ entityType: 'event', entityId: event?.contentId }"
>
  <root-button
    type="primary"
    [block]="true"
    size="large"
    [ariaLabel]="preLabel"
    [name]="preLabel"
    [loading]="isLoading"
    [disabled]="isDisabled"
    (handleClick)="handleRsvpClick()"
    [text]="preLabel"
  >
  </root-button>
</div>

<!-- Show disabled RSVP button if creating a new event -->
<div *ngIf="!event?.published">
  <root-button
    type="primary"
    [block]="true"
    size="large"
    [ariaLabel]="preLabel"
    [name]="preLabel"
    [loading]="isLoading"
    [disabled]="true"
    [text]="preLabel"
  >
  </root-button>
</div>

<!-- Show confirmed if it's published, future, and we've rsvped -->
<div
  *ngIf="
    event?.published &&
    event?.isFuture &&
    userEvent?.rsvpEnabled &&
    !event?.isCanceled
  "
>
  <root-button
    type="primary"
    [block]="true"
    size="large"
    aria-label="Menu"
    role="button"
    name="Menu"
    [loading]="isLoading"
    [text]="preConfirmedLabel"
    [rootDropdown]="rsvpMenu"
  >
    <root-icon name="chevron_down"></root-icon>
  </root-button>
</div>

<!-- REMOVE RSVP MENU -->
<root-dropdown-menu #rsvpMenu>
  <ul rootDropdownMenu>
    <li
      *ngIf="
        !userEvent?.receiveNotifications?.sms && event?.hasPhoneRequirement
      "
      (click)="handleEnableSMS()"
      rootDropdownMenuItem
    >
      <root-icon name="message_circle"></root-icon>
      Turn on texts
    </li>

    <li
      *ngIf="userEvent?.receiveNotifications?.sms"
      (click)="handleDisableSMS()"
      rootDropdownMenuItem
    >
      <root-icon name="x_circle"></root-icon>
      Turn off texts
    </li>

    <li
      *ngIf="
        !userEvent?.receiveNotifications?.email && event?.hasEmailRequirement
      "
      (click)="handleEnableEmail()"
      rootDropdownMenuItem
    >
      <root-icon name="mail"></root-icon>
      Turn on emails
    </li>

    <li
      *ngIf="userEvent?.receiveNotifications?.email"
      (click)="handleDisableEmail()"
      rootDropdownMenuItem
    >
      <root-icon name="x_circle"></root-icon>
      Turn off emails
    </li>

    <li
      rootDropdownMenuItemDivider
      *ngIf="
        (event?.hasEmailRequirement || event?.hasPhoneRequirement) &&
        !event?.isPaid
      "
    ></li>

    <li
      *ngIf="!event?.isPaid"
      (click)="handleRemoveRSVP()"
      rootDropdownMenuItem
    >
      <root-icon name="trash"></root-icon>
      Remove registration
    </li>
  </ul>
</root-dropdown-menu>
