import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendlyEmbedViewComponent } from '../views/calendly-embed-view';
import { AvatarModule } from './avatar.module';
import { CardModule } from './card.module';
import { IconModule } from './icon.module';

@NgModule({
  declarations: [CalendlyEmbedViewComponent],
  imports: [CommonModule, CardModule, AvatarModule, IconModule],
  exports: [CalendlyEmbedViewComponent]
})
export class CalendlyEmbedViewModule {}
