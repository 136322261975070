export const CONTENT_EVENTS = {
  // Links
  userBeganCreateLinkFlow: 'userBeganCreateLinkFlow',
  userFinishedCreateLinkFlow: 'userFinishedCreateLinkFlow',
  userBeganEditLinkFlow: 'userBeganEditLinkFlow',
  userFinishedEditLinkFlow: 'userFinishedEditLinkFlow',
  userBeganDuplicateLinkFlow: 'userBeganDuplicateLinkFlow',
  userFinishedDuplicateLinkFlow: 'userFinishedDuplicateLinkFlow',

  // Events
  userBeganCreateEventFlow: 'userBeganCreateEventFlow',
  userFinishedCreateEventFlow: 'userFinishedCreateEventFlow',
  userBeganEditEventFlow: 'userBeganEditEventFlow',
  userFinishedEditEventFlow: 'userFinishedEditEventFlow',
  userBeganDuplicateEventFlow: 'userBeganDuplicateEventFlow',
  userFinishedDuplicateEventFlow: 'userFinishedDuplicateEventFlow',

  // Pages
  userBeganCreateCollectionFlow: 'userBeganCreateCollectionFlow',
  userFinishedCreateCollectionFlow: 'userFinishedCreateCollectionFlow',
  userBeganEditCollectionFlow: 'userBeganEditCollectionFlow',
  userFinishedEditCollectionFlow: 'userFinishedEditCollectionFlow',
  userBeganDuplicateCollectionFlow: 'userBeganDuplicateCollectionFlow',
  userFinishedDuplicateCollectionFlow: 'userFinishedDuplicateCollectionFlow',

  // Signups
  userBeganCreateDropFlow: 'userBeganCreateDropFlow',
  userFinishedCreateDropFlow: 'userFinishedCreateDropFlow',
  userBeganEditDropFlow: 'userBeganEditDropFlow',
  userFinishedEditDropFlow: 'userFinishedEditDropFlow',
  userBeganDuplicateDropFlow: 'userBeganDuplicateDropFlow',
  userFinishedDuplicateDropFlow: 'userFinishedDuplicateDropFlow',

  // Newsletters
  userBeganCreateNewsletterFlow: 'userBeganCreateNewsletterFlow',
  userFinishedCreateNewsletterFlow: 'userFinishedCreateNewsletterFlow',
  userBeganEditNewsletterFlow: 'userBeganEditNewsletterFlow',
  userFinishedEditNewsletterFlow: 'userFinishedEditNewsletterFlow',
  userBeganDuplicateNewsletterFlow: 'userBeganDuplicateNewsletterFlow',
  userFinishedDuplicateNewsletterFlow: 'userFinishedDuplicateNewsletterFlow',

  // Campaigns
  userBeganEditSingleSendFlow: 'userBeganEditSingleSendFlow',
  userFinishedEditSingleSendFlow: 'userFinishedEditSingleSendFlow',

  // Attachments
  userUploadedMmsAttachmentsToSignup: 'userUploadedMmsAttachmentsToSignup',
  userUploadedMmsAttachmentsToEvent: 'userUploadedMmsAttachmentsToEvent',
  userUploadedMmsAttachmentsToSingleSend:
    'userUploadedMmsAttachmentsToSingleSend',
  userUploadedMmsAttachmentsToContentNotification:
    'userUploadedMmsAttachmentsToContentNotification'
};

export const BLOCK_EVENTS = {
  // Text block
  userBeganCreateTextBlockFlow: 'userBeganCreateTextBlockFlow',
  userFinishedCreateTextBlockFlow: 'userFinishedCreateTextBlockFlow',

  // Newsletter listing block
  userBeganCreateNewsletterListingBlockFlow:
    'userBeganCreateNewsletterListingBlockFlow',
  userFinishedCreateNewsletterListingBlockFlow:
    'userFinishedCreateNewsletterListingBlockFlow',

  // Newsletter signup
  userBeganCreateNewsletterSignupBlockFlow:
    'userBeganCreateNewsletterSignupBlockFlow',
  userFinishedCreateNewsletterSignupBlockFlow:
    'userFinishedCreateNewsletterSignupBlockFlow',

  // Header block
  userBeganCreateHeaderBlockFlow: 'userBeganCreateHeaderBlockFlow',
  userFinishedCreateHeaderBlockFlow: 'userFinishedCreateHeaderBlockFlow',

  // Upcoming events block
  userBeganCreateUpcomingEventsBlockFlow:
    'userBeganCreateUpcomingEventsBlockFlow',
  userFinishedCreateUpcomingEventsBlockFlow:
    'userFinishedCreateUpcomingEventsBlockFlow',

  // Youtube block
  userBeganCreateYoutubeBlockFlow: 'userBeganCreateYoutubeBlockFlow',
  userFinishedCreateYoutubeBlockFlow: 'userFinishedCreateYoutubeBlockFlow',

  // Vimeo block
  userBeganCreateVimeoBlockFlow: 'userBeganCreateVimeoBlockFlow',
  userFinishedCreateVimeoBlockFlow: 'userFinishedCreateVimeoBlockFlow',

  // Spotify block
  userBeganCreateSpotifyBlockFlow: 'userBeganCreateSpotifyBlockFlow',
  userFinishedCreateSpotifyBlockFlow: 'userFinishedCreateSpotifyBlockFlow',

  // Twitter block
  userBeganCreateTwitterBlockFlow: 'userBeganCreateTwitterBlockFlow',
  userFinishedCreateTwitterBlockFlow: 'userFinishedCreateTwitterBlockFlow',

  // Image block
  userBeganCreateImageBlockFlow: 'userBeganCreateImageBlockFlow',
  userFinishedCreateImageBlockFlow: 'userFinishedCreateImageBlockFlow',

  // Profile block
  userBeganCreateProfileBlockFlow: 'userBeganCreateProfileBlockFlow',
  userFinishedCreateProfileBlockFlow: 'userFinishedCreateProfileBlockFlow',

  // Instagram block
  userBeganCreateInstagramBlockFlow: 'userBeganCreateInstagramBlockFlow',
  userFinishedCreateInstagramBlockFlow: 'userFinishedCreateInstagramBlockFlow',

  // Spacer block
  userBeganCreateSpacerBlockFlow: 'userBeganCreateSpacerBlockFlow',
  userFinishedCreateSpacerBlockFlow: 'userFinishedCreateSpacerBlockFlow',

  // Social icons block
  userBeganCreateSocialIconsBlockFlow: 'userBeganCreateSocialIconsBlockFlow',
  userFinishedCreateSocialIconsBlockFlow:
    'userFinishedCreateSocialIconsBlockFlow',

  // Html block
  userBeganCreateHtmlBlockFlow: 'userBeganCreateHtmlBlockFlow',
  userFinishedCreateHtmlBlockFlow: 'userFinishedCreateHtmlBlockFlow'
};

export const SETTINGS_EVENTS = {
  // Expert inquiry
  userBeganAskAnExpertFlow: 'userBeganAskAnExpertFlow',
  userFinishedAskAnExpertFlow: 'userFinishedAskAnExpertFlow',

  // Settings
  userUpdatedSlug: 'userUpdatedSlug',
  userBeganIntegrationFlow: 'userBeganIntegrationFlow',
  addedZoomToEvent: 'addedZoomToEvent',
  removedZoomFromEvent: 'removedZoomFromEvent',
  userAppliedTheme: 'userAppliedTheme',

  // Team management
  userBeganInviteTeamMemberFlow: 'userBeganInviteTeamMemberFlow',
  userFinishedInviteTeamMemberFlow: 'userFinishedInviteTeamMemberFlow',

  // Contacts
  userUploadedContactsCsv: 'userUploadedContactsCsv'
};

export const INBOX_EVENTS = {
  userUploadedMmsAttachmentsInInbox: 'userUploadedMmsAttachmentsInInbox'
};
