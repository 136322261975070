import { rng } from './rng';
import { stringify } from './stringify';

export function uuidv4(options?, buf?, offset?): string {
  options = options || {};

  const rnds = options.random || (options.rng || rng)();

  // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
  // tslint:disable-next-line: no-bitwise
  rnds[6] = (rnds[6] & 0x0f) | 0x40;
  // tslint:disable-next-line: no-bitwise
  rnds[8] = (rnds[8] & 0x3f) | 0x80;

  // Copy bytes to buffer, if provided
  if (buf) {
    offset = offset || 0;

    for (let i = 0; i < 16; ++i) {
      buf[offset + i] = rnds[i];
    }

    return buf;
  }

  return stringify(rnds);
}
