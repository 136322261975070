import { Component, Input, EventEmitter, Output } from '@angular/core';
import { buttonClicked } from '../../../animations';

@Component({
  selector: 'norby-button',
  templateUrl: './norby-button.component.html',
  styleUrls: ['./norby-button.component.less'],
  animations: [buttonClicked]
})
export class NorbyButtonComponent {
  @Input() text?: string;
  @Input() isDisabled?: boolean = false;
  @Input() commandName?: string;
  @Input() buttonType?: 'primary' | 'secondary' | 'minimal' | 'feature' =
    'primary';
  @Input() isLoading?: boolean;

  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  animationState = false;

  constructor() {}

  handleButtonClick(event: Event): void {
    const command = this.commandName || this.text.split(' ').join('_');
    this.buttonClicked.emit(command);
    this.animationState = !this.animationState;
  }

  // TODO: add icon - curretly not used anywhere
}
