<root-card [bodyStyle]="{ padding: '0px' }">
  <a
    [href]="href"
    target="_blank"
    rel="noreferrer"
    (click)="handleClick($event)"
    [class.cursor-pointer]="event"
    class="group"
  >
    <lib-soft-image-view
      *ngIf="!event || event?.image?.url"
      [imageUrl]="event?.image?.url"
      [image]="event?.image"
      [imgixImage]="event?.imgixImage"
      [alt]="title"
    >
    </lib-soft-image-view>
    <div class="p-4 [&>*]:mb-3 [&>*:last-child]:mb-0" *ngIf="!event">
      <root-skeleton
        [title]="false"
        [paragraph]="{ rows: 2, width: 150 }"
        class=""
      >
      </root-skeleton>
    </div>
    <div class="p-4 [&>*]:mb-3 [&>*:last-child]:mb-0" *ngIf="event">
      <div
        *ngIf="
          event?.isFull ||
          (event?.hasCapacity && event?.rsvpRestrictions?.displayLimit) ||
          event?.isCanceled ||
          event?.registrationCloseDate
        "
      >
        <root-tag
          *ngIf="event?.isFull || event?.isPastRegistrationDeadline"
          class="mr-3"
        >
          <root-icon size="small" name="lock"></root-icon>
          <span>Registration is closed</span>
        </root-tag>
        <root-tag
          *ngIf="
            event?.registrationCloseDate &&
            !event?.isPastRegistrationDeadline &&
            !event?.isFull
          "
          class="mr-3"
        >
          <root-icon size="small" name="calendar"></root-icon>
          <span *ngIf="isCurrentDate(registrationCloseDate)">
            Register by {{ registrationCloseTime }}</span
          >
          <span
            *ngIf="!isCurrentDate(registrationCloseDate)"
            rootTooltip="{{ 'at ' + registrationCloseTime }}"
          >
            Register by {{ registrationCloseDate }}
          </span>
        </root-tag>
        <root-tag
          *ngIf="event?.hasCapacity && event?.rsvpRestrictions?.displayLimit"
        >
          <root-icon size="small" name="unlock"></root-icon>
          <span>{{ event?.rsvpRestrictions?.remaining }} left</span>
        </root-tag>
        <root-tag *ngIf="event?.isCanceled">
          <root-icon size="small" name="x_circle"></root-icon>
          <span>Canceled</span>
        </root-tag>
      </div>
      <h3 class="group-hover:underline card-text-color" *ngIf="title">
        {{ title }}
      </h3>
      <h4 class="subtitle card-text-color" *ngIf="event?.subtitle">
        {{ event?.subtitle }}
      </h4>
      <p *ngIf="event?.summary" class="card-text-color">
        {{ event.summary }}
      </p>
      <div *ngIf="priceStr" class="price card-text-color">
        <root-alert [message]="priceStr"> </root-alert>
      </div>
    </div>
  </a>

  <div class="p-0 px-4 pb-4">
    <div class="flex items-center" *ngIf="event?.published">
      <div class="flex-auto mr-3">
        <lib-event-main-button-view
          [event]="event"
          [userEvent]="userEvent"
          [isLoading]="isLoadingUserContent"
          [forceDisabled]="isDisabled"
        >
        </lib-event-main-button-view>
      </div>
      <lib-event-drop-share-button-view
        [content]="event"
        [userContent]="userEvent"
        [isDisabled]="isDisabled"
      >
      </lib-event-drop-share-button-view>
    </div>
    <root-skeleton
      *ngIf="!event?.published"
      [paragraph]="false"
    ></root-skeleton>
  </div>
</root-card>
