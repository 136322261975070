import { Injectable, Inject, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// 3rd party
import { BehaviorSubject, firstValueFrom, from, Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

// Firebase
import {
  Auth,
  authState,
  signInAnonymously,
  signInWithCustomToken,
  User,
  UserCredential,
  signOut,
  user
} from '@angular/fire/auth';
import {
  collection,
  collectionData,
  Firestore,
  getDocs,
  orderBy,
  query,
  where
} from '@angular/fire/firestore';

// Lib
import { ApiService } from '../api';
import {
  EmailVerificationPayload,
  ILoginRequestStatus,
  PhoneVerificationPayload
} from './types';
import {
  IUserRole,
  ENDPOINTS,
  ApiSurfaces,
  COOKIE_PREFIX_TOKEN,
  REFRESH_TOKEN_FLAG_NAME,
  SOCIAL_COOKIE_FLAG_NAME,
  IS_NORBY_NEXT
} from 'models';
import { IAuthService } from './auth.service.interface';
import { getCookie } from '../../tools/cookie';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {
  private _currentUser: User;
  private _isAttemptingReauthFlow$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(COOKIE_PREFIX_TOKEN) private _cookiePrefix: string,
    @Optional() @Inject(IS_NORBY_NEXT) private _isNorbyNext: boolean,
    private _auth: Auth,
    private _firestore: Firestore,
    private _api: ApiService
  ) {
    user(this._auth)
      .pipe(
        tap((user) => {
          this._initUser(user);
          // if it was attempting the reauth flow before but now a user came in,
          // mark attempt as done
          if (!!this._isAttemptingReauthFlow$.value) {
            this._isAttemptingReauthFlow$.next(false);
          }
        }),
        distinctUntilChanged((x, y) => x?.uid === y?.uid),
        filter((user) => {
          if (!user) return true;
          const refreshTokenFlag = getCookie(
            this._document,
            `${this._cookiePrefix}${REFRESH_TOKEN_FLAG_NAME}`
          );
          const refreshTokenFlagEnabled = refreshTokenFlag === '1';
          return refreshTokenFlagEnabled && user?.isAnonymous;
        })
      )
      .subscribe(() => this._defaultLogin());

    this._auth.useDeviceLanguage();
  }

  private _initUser(user: User) {
    this._currentUser = user ?? null; // Cache the updated user
    this._api.setCurrentUser(user); // Set current API user
  }

  private async _defaultLogin(): Promise<UserCredential> {
    this._isAttemptingReauthFlow$.next(true);
    const user = await this._signInWithRefreshToken();

    if (user) {
      return user;
    }

    // mark reauth flow is done if there was no user
    this._isAttemptingReauthFlow$.next(false);
    return this.loginAnonymously();
  }

  get auth(): Auth {
    return this._auth;
  }

  private _getUserRoleQueryForId(uid: string) {
    return query(
      collection(this._firestore, 'userRoles'),
      where('userId', '==', uid ?? 0),
      where('isNorbyNext', '==', !!this._isNorbyNext),
      orderBy('createdAtCursor')
    );
  }

  // Query user roles from userRoles table
  userRoles$(uid?: string): Observable<IUserRole[]> {
    if (uid?.length) {
      return collectionData(this._getUserRoleQueryForId(uid)) as Observable<
        IUserRole[]
      >;
    }

    return this.user$.pipe(
      switchMap((user) =>
        user?.uid && !user.isAnonymous
          ? collectionData(this._getUserRoleQueryForId(user.uid))
          : from([[]])
      )
    );
  }

  async userRoles(uid?: string): Promise<IUserRole[]> {
    const roles = await getDocs(
      this._getUserRoleQueryForId(uid ?? this.currentUser?.uid ?? '')
    );

    return roles.docs.map((role) => role.data() as IUserRole);
  }

  async loginAnonymously(): Promise<UserCredential> {
    if (!this.currentUser) {
      return signInAnonymously(this._auth);
    }
  }

  private async _signInWithRefreshToken(): Promise<UserCredential | null> {
    const newTokens: {
      accessToken: string;
      refreshToken: string;
    } | null = await this._api
      .post<{
        refreshToken: string;
        accessToken: string;
      }>(ApiSurfaces.AUTH, ENDPOINTS.auth.refresh)
      .catch((e) => null);

    if (!newTokens) {
      return null;
    }

    const refreshTokenUserCredential: UserCredential | null =
      await signInWithCustomToken(this._auth, newTokens.accessToken)
        .then((res) => res)
        .catch((e) => null);

    return refreshTokenUserCredential;
  }

  async logout(): Promise<void> {
    // Signal for the server to kill the refresh token cookie.
    await this._api.post(ApiSurfaces.AUTH, ENDPOINTS.auth.logout);
    await signOut(this._auth);
  }

  get currentUser(): User {
    return this._currentUser;
  }

  get user$(): Observable<User> {
    return user(this._auth);
  }

  get authState$(): Observable<User> {
    return authState(this._auth);
  }

  get userLoggedIn() {
    const user = this.currentUser;
    return user && !user.isAnonymous;
  }

  get userPhoneLoggedIn() {
    const user = this.currentUser;
    return !!user?.phoneNumber;
  }

  get isAttemptingReauthFlow$() {
    return this._isAttemptingReauthFlow$.asObservable();
  }

  initiateGoogleAuth(onboarding = false): Promise<boolean> {
    let endpoint = ENDPOINTS.auth.google;
    let cookieName = REFRESH_TOKEN_FLAG_NAME;

    if (onboarding) {
      endpoint = ENDPOINTS.auth.onboarding.google;
      cookieName = SOCIAL_COOKIE_FLAG_NAME;
    }

    const url = this._api.constructApiUrl(endpoint, ApiSurfaces.AUTH);
    const left = window.innerWidth / 2 - 200;
    const options = `width=400,height=600,left=${left},top=${100}`;
    const popupWindow = window.open(url, 'Continue with Google', options);

    return new Promise((resolve) => {
      const popupInterval = window.setInterval(() => {
        try {
          if (!popupWindow || popupWindow.closed) {
            const cookie = getCookie(
              this._document,
              `${this._cookiePrefix}${cookieName}`
            );
            window.clearInterval(popupInterval);
            resolve(!!cookie);
          }
        } catch (e) {}
      }, 100);
    });
  }

  async completeGoogleLogin(): Promise<UserCredential | null> {
    const cookie = getCookie(
      this._document,
      `${this._cookiePrefix}${REFRESH_TOKEN_FLAG_NAME}`
    );

    if (!cookie) {
      return;
    }

    return this._signInWithRefreshToken();
  }

  // Step one in auth flow
  // Returns a confirmation result that can be used to
  // either log in or link the account, depending on whether
  // the user already exists or not
  async initiatePhoneSignInFlow(
    phoneNumber: string,
    recaptchaToken: string,
    skipUserStatusCheck = false
  ): Promise<boolean> {
    if (this.userPhoneLoggedIn && !skipUserStatusCheck) {
      throw new Error('User already logged in');
    }

    const ret = await this._api.post<boolean>(
      ApiSurfaces.AUTH,
      ENDPOINTS.auth.login.phone.request,
      {
        phoneNumber
      },
      null,
      recaptchaToken
    );
    return ret;
  }

  private async _confirmPhoneVerificationCode(input: PhoneVerificationPayload) {
    const { phoneNumber, verificationCode, invertMergeFlow } = input;
    return this._api.post<{
      accessToken: string;
      refreshToken: string;
      invertMergeFlow: boolean;
    }>(ApiSurfaces.AUTH, ENDPOINTS.auth.login.phone.verify, {
      phoneNumber,
      verificationCode,
      invertMergeFlow: !!invertMergeFlow
    });
  }

  // Step two in auth flow if user already exists
  // Links new phone number to anonymous account
  async completePhoneLogin(
    input: PhoneVerificationPayload
  ): Promise<UserCredential> {
    const { accessToken } = await this._confirmPhoneVerificationCode(input);
    const cred = await signInWithCustomToken(this._auth, accessToken);
    await firstValueFrom(this.user$);
    return cred;
  }

  // Provide email
  // Returns a credential if the backend authorizes and optimistic login(supplies accessToken)
  async initiateEmailSignInOrLinkingFlow({
    email,
    recaptchaToken,
    sendMagicLink,
    isBlocking = false
  }: {
    email: string;
    recaptchaToken: string;
    sendMagicLink?: boolean;
    isBlocking?: boolean;
  }) {
    const response = await this._api.post<ILoginRequestStatus>(
      ApiSurfaces.AUTH,
      ENDPOINTS.auth.login.email.request,
      {
        email,
        // Magic links issue authorization with a verification uri instead of verification codes.
        // If there is a currently authenticated user, the generated magic link will merge any eligible accounts.
        sendMagicLink: !!sendMagicLink,
        blocking: isBlocking
      },
      null,
      recaptchaToken
    );

    const { accessToken } = response;
    if (accessToken) {
      // If the email address is free the backend either places it directly onto the currently authorized user,
      // or a hull account is created and the credentials are returned. In either case we must let the caller
      // know that the user has been authorized and does not need to go through the email verification code step.
      const credential = await signInWithCustomToken(this._auth, accessToken);
      return {
        ...response,
        credential
      };
    }

    return {
      ...response,
      credential: null
    };
  }
  /**
   * Promotes a second order email address to the primary slot
   */
  async promoteSecondOrderEmail({ email }: { email: string }) {
    const response = await this._api.post<boolean>(
      ApiSurfaces.AUTH,
      ENDPOINTS.auth.email.promote,
      {
        email
      }
    );
    return response;
  }

  // Step two in auth flow if user already exists
  // Links new phone number to anonymous account
  async completeEmailLogin(
    input: EmailVerificationPayload
  ): Promise<UserCredential> {
    const response = await this._api.post<{
      accessToken: string;
      refreshToken: string;
    }>(ApiSurfaces.AUTH, ENDPOINTS.auth.login.email.verify, input);
    const { accessToken } = response;
    const credential = await signInWithCustomToken(this._auth, accessToken);
    await firstValueFrom(this.user$);
    return credential;
  }
}
