import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[rootDropdownMenu]'
})
export class DropdownMenuDirective {
  @HostBinding('class')
  elementClass = 'bg-inherit rounded-md shadow-ant-default m-0';
  constructor() {}
}
