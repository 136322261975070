// App
import { SOCIAL_LINKS, VALID_COUNTRY_CODES } from '../constants';
import { CARDS } from './card-helpers';
import { CardValidation } from '../types/billing';
import { Content } from '../types/content';
import { IAccountInfo, Theme } from '../types/ISlug';
import {
  LandingPage,
  SocialIconsSettings,
  VisibleLink
} from '../types/landing-pages';
import { Segment, SegmentFilterGroup } from '../types';

export const isBrowser = typeof window !== 'undefined';

type cleaner = <t>(obj: t) => t;
export const cleanRecursive: cleaner = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') cleanRecursive(obj[key]);
    else if (
      !obj[key] &&
      typeof obj[key] !== 'boolean' &&
      typeof obj[key] !== 'number'
    )
      delete obj[key];
    // This means its a boolean or number so we do nothing
    else if (!obj[key]) cleanRecursive(obj[key]);
    else if (typeof obj[key] === 'object' && obj[key].length === 0)
      delete obj[key];
  });
  return obj;
};

export function stripHtml(input: string) {
  return input?.length > 0 ? input.replace(/<.*?>/g, '') : input;
}

export const buildSocialLinksObjectFromKeys = (
  key: string,
  accountInfo: IAccountInfo
): { key: string; icon: string; label: string; url: string } => {
  return {
    key,
    icon: SOCIAL_LINKS[key]?.icon,
    label: SOCIAL_LINKS[key]?.label,
    url: accountInfo?.links?.[key]
  };
};

export const buildSocialLinksObjectFromBlock = (
  setting: SocialIconsSettings
): VisibleLink => {
  const linkType = Object.keys(setting)[0];
  return {
    key: linkType,
    icon: SOCIAL_LINKS[linkType]?.icon,
    label: SOCIAL_LINKS[linkType]?.label || 'Unknown',
    url: setting?.[linkType] || ''
  };
};

export const extractPageBackground = (
  page: LandingPage,
  theme: Theme
): string => {
  const collectionHasColor = !!page?.theme?.layout?.backgroundColor;
  const collectionHasGradient = !!page?.theme?.layout?.backgroundGradient;
  const collectionHasImage = !!page?.theme?.layout?.backgroundImage?.url;
  const themeHasColor = !!theme?.layout?.backgroundColor;
  const themeHasGradient = !!theme?.layout?.backgroundGradient;
  const themeHasImage = !!theme?.layout?.backgroundImage?.url;

  if (collectionHasColor) {
    return `background-color: ${page.theme.layout.backgroundColor}`;
  } else if (collectionHasGradient) {
    return `background: ${page.theme.layout.backgroundGradient}`;
  } else if (collectionHasImage) {
    return `background-image: url(${page.theme.layout.backgroundImage.url})`;
  } else if (themeHasColor) {
    return `background-color: ${theme.layout.backgroundColor}`;
  } else if (themeHasGradient) {
    return `background: ${theme.layout.backgroundGradient}`;
  } else if (themeHasImage) {
    return `background-image: url(${theme.layout.backgroundImage.url})`;
  }

  return '';
};

export const extractPageBodyBackgroundColor = (
  page: LandingPage,
  theme: Theme
): string => {
  const collectionHasColor = !!page?.theme?.layout?.backgroundColor;
  const collectionHasGradient = !!page?.theme?.layout?.backgroundGradient;
  const collectionHasImage = !!page?.theme?.layout?.backgroundImage?.url;
  const themeHasColor = !!theme?.layout?.backgroundColor;
  const themeHasGradient = !!theme?.layout?.backgroundGradient;
  const themeHasImage = !!theme?.layout?.backgroundImage?.url;

  if (collectionHasColor) {
    return page.theme.layout.backgroundColor;
  } else if (collectionHasGradient) {
    return extractRGBFromGradient(page.theme.layout.backgroundGradient);
  } else if (collectionHasImage) {
    return page.theme.layout.backgroundImage.palettes?.[0] ?? 'white';
  } else if (themeHasColor) {
    return theme.layout.backgroundColor;
  } else if (themeHasGradient) {
    return extractRGBFromGradient(theme.layout.backgroundGradient);
  } else if (themeHasImage) {
    return theme.layout.backgroundImage.palettes?.[0] ?? 'white';
  }

  return 'white';
};

export const extractContentBackground = (
  content: Content,
  theme: Theme
): string => {
  const contentThemeHasColor = !!content?.theme?.layout?.backgroundColor;
  const contentThemeHasGradient = !!content?.theme?.layout?.backgroundGradient;
  const contentThemeHasImage = !!content?.theme?.layout?.backgroundImage?.url;
  const themeHasColor = !!theme?.layout?.backgroundColor;
  const themeHasGradient = !!theme?.layout?.backgroundGradient;
  const themeHasImage = !!theme?.layout?.backgroundImage?.url;

  if (contentThemeHasColor) {
    return `background-color: ${content.theme.layout.backgroundColor}`;
  } else if (contentThemeHasGradient) {
    return `background: ${content.theme.layout.backgroundGradient}`;
  } else if (contentThemeHasImage) {
    return `background-image: url(${content.theme.layout.backgroundImage.url})`;
  } else if (themeHasColor) {
    return `background-color: ${theme.layout.backgroundColor}`;
  } else if (themeHasGradient) {
    return `background: ${theme.layout.backgroundGradient}`;
  } else if (themeHasImage) {
    return `background-image: url(${theme.layout.backgroundImage.url})`;
  }

  return '';
};

export const extractContentBodyBackgroundColor = (
  content: Content,
  theme: Theme
): string => {
  const contentThemeHasColor = !!content?.theme?.layout?.backgroundColor;
  const contentThemeHasGradient = !!content?.theme?.layout?.backgroundGradient;
  const contentThemeHasImage = !!content?.theme?.layout?.backgroundImage?.url;
  const themeHasColor = !!theme?.layout?.backgroundColor;
  const themeHasGradient = !!theme?.layout?.backgroundGradient;
  const themeHasImage = !!theme?.layout?.backgroundImage?.url;

  if (contentThemeHasColor) {
    return content.theme.layout.backgroundColor;
  } else if (contentThemeHasGradient) {
    return extractRGBFromGradient(content.theme.layout.backgroundGradient);
  } else if (contentThemeHasImage) {
    return content.theme.layout.backgroundImage.palettes?.[0] ?? 'white';
  } else if (themeHasColor) {
    return theme.layout.backgroundColor;
  } else if (themeHasGradient) {
    return extractRGBFromGradient(theme.layout.backgroundGradient);
  } else if (themeHasImage) {
    return theme.layout.backgroundImage.palettes?.[0] ?? 'white';
  }
  return '';
};

const extractRGBFromGradient = (gradient): string => {
  const rgbColorMatch = gradient.match(
    /rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/
  );

  if (rgbColorMatch) {
    const red = parseInt(rgbColorMatch[1]);
    const green = parseInt(rgbColorMatch[2]);
    const blue = parseInt(rgbColorMatch[3]);

    return `rgb(${red}, ${green}, ${blue})`;
  } else {
    return 'white';
  }
};

export const formatPhoneNumber = (
  phoneNumber: string,
  countryCode: string
): string => {
  const adjustedNumber = phoneNumber.replace(/[^0-9]+/g, '');
  const countryPrefix = VALID_COUNTRY_CODES[countryCode]?.value;
  return `+${countryPrefix}${adjustedNumber}`;
};

export const luhnCheck = (cardNumber: string): boolean => {
  if (!cardNumber.length) {
    return false;
  }

  // Remove all whitespaces from card number.
  cardNumber = cardNumber.replace(/\s/g, '');

  // 1. Remove last digit;
  const lastDigit = Number(cardNumber[cardNumber.length - 1]);

  // 2. Reverse card number
  const reverseCardNumber = cardNumber
    .slice(0, cardNumber.length - 1)
    .split('')
    .reverse()
    .map((x) => Number(x));
  let sum = 0;

  // 3. + 4. Multiply by 2 every digit on odd position. Subtract 9 if digit > 9
  for (let i = 0; i <= reverseCardNumber.length - 1; i += 2) {
    reverseCardNumber[i] = reverseCardNumber[i] * 2;
    if (reverseCardNumber[i] > 9) {
      reverseCardNumber[i] = reverseCardNumber[i] - 9;
    }
  }

  // 5. Make the sum of obtained values from step 4.
  sum = reverseCardNumber.reduce((acc, currValue) => acc + currValue, 0);

  // 6. Calculate modulo 10 of the sum from step 5. and the last digit. If it's 0, you have a valid card number :)
  return (sum + lastDigit) % 10 === 0;
};

export const getValidationConfigFromCardNo = (
  rawValue: string
): CardValidation =>
  CARDS.find((card) => {
    const patterns = card.patterns.map(
      (pattern) => new RegExp(`^${pattern}`, 'g')
    );
    const matchResult = patterns
      .map((pattern) => rawValue.match(pattern))
      .filter((result) => result);

    return !!matchResult.length;
  }) || null;

export const truncateStringDisplay = (input: string) => {
  return input?.substring(0, 60) || '';
};

export const concatenateFilterGroups = (
  tabSegment: Segment,
  segment: Segment
) => {
  const isDuplicate = (
    group1: SegmentFilterGroup,
    group2: SegmentFilterGroup
  ) => {
    return group1.hash === group2.hash;
  };

  const combinedFilterGroups = [
    ...segment.filterGroups,
    ...tabSegment.filterGroups.filter(
      (tabFilterGroup) =>
        !segment.filterGroups.some((segmentFilterGroup) =>
          isDuplicate(segmentFilterGroup, tabFilterGroup)
        )
    )
  ];

  const joinedSegment = Segment.fromObject({
    ...segment,
    filterGroups: combinedFilterGroups
  });

  return joinedSegment;
};
