<div class="steps-content">
  <form [formGroup]="formGroup" (ngSubmit)="submitPrompts()">
    <h2 rootFormItem>Complete your registration</h2>

    <div rootFormItem *ngFor="let prompt of content.prompts; let i = index">
      <label class="form-label inline-block pb-2 text-xs" attr.for="i">
        {{ prompt?.required ? '*' : '' }}{{ prompt.prompt }}
      </label>

      <norby-input
        *ngIf="!prompt?.type || prompt?.type == 'text'"
        class="prompt-input"
        [formControlName]="i"
        placeholder="Your answer..."
      ></norby-input>

      <norby-select
        *ngIf="prompt?.type == 'select'"
        prompt="Select an option"
        [formControlName]="i"
      >
        <option
          *ngFor="let option of prompt?.options | keyvalue"
          [ngValue]="option.value"
        >
          {{ option.value }}
        </option>
      </norby-select>
      <root-checkbox-wrapper
        *ngIf="prompt?.type == 'checkbox'"
        (onHandleChange)="handleDidCheckCheckbox($event, i)"
      >
        <div *ngFor="let option of prompt?.options">
          <label root-checkbox [value]="option">{{ option }}</label>
        </div>
      </root-checkbox-wrapper>
      <norby-date-input
        *ngIf="prompt?.type == 'datePicker'"
        [formControlName]="i"
      >
      </norby-date-input>
    </div>

    <div rootFormItem>
      <root-button
        type="primary"
        size="large"
        [block]="true"
        [disabled]="!formGroup.valid"
        [loading]="isLoading"
        text="Submit"
      >
      </root-button>
    </div>
  </form>
</div>
