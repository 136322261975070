import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyCollapsibleComponent } from '../components/molecules/norby-collapsible/norby-collapsible.component';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyCollapsibleComponent],
  imports: [CommonModule, NorbyIconModule],
  exports: [NorbyCollapsibleComponent]
})
export class NorbyCollapsibleModule {}
