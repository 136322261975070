import { AbstractControl } from '@angular/forms';
import { URL_PATTERN_FOR_LINKS } from '../constants';

export function UrlValidator(control: AbstractControl) {
  const urlPattern = URL_PATTERN_FOR_LINKS;

  const validUrl = urlPattern.test(control.value);

  return validUrl || !control.value
    ? null
    : { invalidUrl: { value: control.value } };
}
