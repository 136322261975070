import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyImageEditDialogComponent } from '../components/molecules/norby-image-edit-dialog/norby-image-edit-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NorbyButtonModule } from './norby-button.module';
import { NorbyIconModule } from './norby-icon.module';
import { NorbyTextAreaModule } from './norby-text-area.module';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

@NgModule({
  declarations: [NorbyImageEditDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyIconModule,
    NorbyTextAreaModule,
    NorbyButtonModule,
    NzPopconfirmModule
  ],
  exports: [NorbyImageEditDialogComponent]
})
export class NorbyImageEditDialogModule {}
