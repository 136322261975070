import { Injectable } from '@angular/core';
import { BehaviorSubject, from, map } from 'rxjs';
import { IDeviceService } from 'models';

@Injectable({
  providedIn: 'root'
})
export class MockDeviceService implements IDeviceService {
  private _slug = 'loading';
  private _slug$ = new BehaviorSubject('loading');
  slugInfo$ = this._slug$.asObservable().pipe(
    map((slug) => ({
      rootUrl: null,
      referUrl: null,
      slug
    }))
  );
  currentSlug$ = this._slug$.asObservable();
  resize$ = from([]);
  scroll$ = from([]);
  isMobile$ = from([false]);

  forceSlug(slug: string) {
    this._slug = slug;
    this._slug$.next(slug);
  }

  get timezone(): string {
    return 'UTC';
  }

  get history(): History {
    return null;
  }

  get hostname(): string {
    return 'Loading';
  }

  get currentSlug(): string {
    return this._slug;
  }

  get currentHost(): string {
    return 'Loading';
  }

  get currentSiteRootURL(): string {
    return 'Loading';
  }
}
