import { IFont } from '../interfaces';

export const CUSTOM_FONTS: Array<IFont> = [
  {
    importUrl: null,
    fontFamily: '',
    fontSize: null,
    displayName: 'Default',
    isCustom: false
  },
  {
    importUrl: 'https://fonts.googleapis.com/css2?family=Abel&display=swap',
    fontFamily: '"Abel", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FAbel.png?alt=media&token=09377074-00b1-442d-a270-42c1d8b758b0',
    displayName: 'Abel',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap',
    fontFamily: '"Abril Fatface", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FAbril%20Fatface.png?alt=media&token=475fe579-892d-4322-8976-c32ed4820adb',
    displayName: 'Abril Fatface',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap',
    fontFamily: '"Akaya Telivigala", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Fakaya.png?alt=media&token=7bf5e777-4526-4302-a28b-998309e7ac90',
    displayName: 'Akaya Telivigala',
    isCustom: false
  },
  {
    importUrl: 'https://fonts.googleapis.com/css2?family=Anton&display=swap',
    fontFamily: '"Anton", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FAnton.png?alt=media&token=a38f9e63-5bd5-4c48-ac0f-b5a5284e43c8',
    displayName: 'Anton',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Antonio:wght@400;700&display=swap',
    fontFamily: '"Antonio", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FAntonio.png?alt=media&token=c5eae4cc-378b-495e-9c74-1f69fe6ad888',
    displayName: 'Antonio',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Arvo", serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FArvo.png?alt=media&token=c8061ac8-a6b7-43f9-868a-8b3515f23fbe',
    displayName: 'Arvo',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Barlow", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FBarlow.png?alt=media&token=6a31d028-62cb-44a0-a7f6-e97023818931',
    displayName: 'Barlow',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
    fontFamily: '"Bebas Neue", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Fbebas.png?alt=media&token=3617be9e-ce5e-42df-b5db-c5b1f01154b1',
    displayName: 'Bebas Neue',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap',
    fontFamily: '"Comfortaa", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FComfortaa.png?alt=media&token=0210eb4a-4689-44c5-bf22-1ec3ddc46cd6',
    displayName: 'Comfortaa',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Cormorant Garamond", serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FCormorant%20Garamond.png?alt=media&token=8d392c8a-e3a8-4fe9-ad7c-19bd109f8e8b',
    displayName: 'Cormorant Garamond',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap',
    fontFamily: '"Dancing Script", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Fdancing.png?alt=media&token=0ee0ef9d-160b-4d38-806b-c1f427b8128a',
    displayName: 'Dancing Script',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,700;1,400;1,600&display=swap',
    fontFamily: '"Fira Sans", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Ffira.png?alt=media&token=2c1ff32c-ff2f-4f3d-841f-70ccb3ed0a6d',
    displayName: 'Fira Sans',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap',
    fontFamily: '"Fugaz One", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FFugaz%20One.png?alt=media&token=4d1b24c1-f096-448f-8590-ca6317efdd31',
    displayName: 'Fugaz One',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap',
    fontFamily: '"Homemade Apple", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Fhomemadeapple.png?alt=media&token=22ed12aa-f21c-45d9-9342-69d64e7a6e15',
    displayName: 'Homemade Apple',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap',
    fontFamily: '"Inconsolata", serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FInconsolata.png?alt=media&token=fa4f7a57-8ce3-461f-b8f0-93f219e65406',
    displayName: 'Inconsolata',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap',
    fontFamily: '"Inter", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FInter.png?alt=media&token=51c2f922-3996-4ed7-85b8-a7d127dbbdca',
    displayName: 'Inter',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Josefin Sans", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FJosefin%20Sans.png?alt=media&token=19ae6a66-68a9-421b-bf7c-e58a06c5ff76',
    displayName: 'Josefin Sans',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Karla", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FKarla.png?alt=media&token=1c5a24d1-a2f8-4221-ac70-ccd68479b885',
    displayName: 'Karla',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Krona+One&display=swap',
    fontFamily: '"Krona One", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FKrona%20One.png?alt=media&token=41b4cd03-832b-451e-a3d1-9841ce60a775',
    displayName: 'Krona One',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Lato", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FLato.png?alt=media&token=44a70189-f5e5-46f2-99bd-677b74369f80',
    displayName: 'Lato',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap',
    fontFamily: '"Lexend", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FLexend.png?alt=media&token=e97a9a9a-5829-4756-b186-96e8eb54cccc',
    displayName: 'Lexend',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap',
    fontFamily: '"Libre Baskerville", serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FLibre%20Baskerville.png?alt=media&token=014e9bbf-6864-4f90-81ad-87a785977ceb',
    displayName: 'Libre Baskerville',
    isCustom: false
  },
  {
    importUrl: 'https://fonts.googleapis.com/css2?family=Lobster&display=swap',
    fontFamily: '"Lobster", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FLobster.png?alt=media&token=3f1aeed9-2c68-4c75-a927-496627bde60b',
    displayName: 'Lobster',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Lora", serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FLora.png?alt=media&token=ccdb344a-3102-4c68-ac3e-ac9b33e7eccc',
    displayName: 'Lora',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Merriweather", serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FMerriweather.png?alt=media&token=aded150f-7dc6-4d27-a389-a85a9dd3bd88',
    displayName: 'Merriweather',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Montserrat", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FMontserrat.png?alt=media&token=d205ba41-b38e-4e0e-8c36-5fddf8eab40f',
    displayName: 'Montserrat',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Nunito", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FNunito.png?alt=media&token=ee056bed-c69f-40ae-9083-d55e3b4af181',
    displayName: 'Nunito',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Open Sans", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FOpen%20Sans.png?alt=media&token=46529e1d-2c13-4d31-968a-189548b01d33',
    displayName: 'Open Sans',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap',
    fontFamily: '"Orbitron", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Forbitron.png?alt=media&token=6397f893-1361-4849-b753-da1646e215a7',
    displayName: 'Orbitron',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Original+Surfer&display=swap',
    fontFamily: '"Original Surfer", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FOriginal%20Surfer.png?alt=media&token=dad9935d-c3dc-4677-af62-2120b235d2b0',
    displayName: 'Original Surfer',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap',
    fontFamily: '"Oswald", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FOswald.png?alt=media&token=cc900521-94e7-4f19-b8d4-62ee13eefe3e',
    displayName: 'Oswald',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Playfair Display", serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FPlayfair%20Display.png?alt=media&token=9bce62fa-2b37-4547-871a-af4cc6b68b14',
    displayName: 'Playfair Display',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Poppins", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FPoppins.png?alt=media&token=b431a3f7-03de-4692-a103-d76a136e32c8',
    displayName: 'Poppins',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Prompt:wght@400;700&display=swap',
    fontFamily: '"Prompt", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FPrompt.png?alt=media&token=68519970-8878-47f5-9a51-a447dc8f2f67',
    displayName: 'Prompt',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"PT Serif", serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FPT%20Serif.png?alt=media&token=740373e6-5efc-4cbd-8f8f-61489c42b348',
    displayName: 'PT Serif',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap',
    fontFamily: '"Quicksand", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FQuicksand.png?alt=media&token=8e1fe0c6-3ae0-4fce-9b6e-a54e3f7aa932',
    displayName: 'Quicksand',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Raleway", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FRaleway.png?alt=media&token=1516eace-4ef6-4ce9-829a-ce38d32147bb',
    displayName: 'Raleway',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Redressed&display=swap',
    fontFamily: '"Redressed", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FRedressed.png?alt=media&token=8c799708-c2e2-4973-b341-b82e82e45f8b',
    displayName: 'Redressed',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Roboto", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FRoboto.png?alt=media&token=731ddc40-d910-45cb-98c9-01c156afdfe7',
    displayName: 'Roboto',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Rubik", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FRubik.png?alt=media&token=7c8f7c8d-f2b6-4c7d-9ef0-1a8383857b76',
    displayName: 'Rubik',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Source Code Pro", monospace',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FSource%20Code%20Pro.png?alt=media&token=99658925-e7d4-41c5-9bd4-0eade1a95b15',
    displayName: 'Source Code Pro',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Titan+One&display=swap',
    fontFamily: '"Titan One", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Ftitan_one.png?alt=media&token=2a239ce2-12d1-412c-9372-e91b0a04ceaa',
    displayName: 'Titan One',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap',
    fontFamily: '"Titillium Web", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Ftitillium.png?alt=media&token=7735b4fd-9542-4cbe-8a72-43c6ecf51f50',
    displayName: 'Titillium Web',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Train+One&display=swap',
    fontFamily: '"Train One", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FTrain%20One.png?alt=media&token=cef34278-619e-421d-98e1-9cfdbe927610',
    displayName: 'Train One',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap',
    fontFamily: '"Varela Round", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2Fvarela.png?alt=media&token=103e5114-085a-4925-8d47-191b5a005d72',
    displayName: 'Varela Round',
    isCustom: false
  },
  {
    importUrl:
      'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap',
    fontFamily: '"Work Sans", sans-serif',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FWork%20Sans.png?alt=media&token=f4e24c33-3e65-4059-84e1-7f628dfbab7e',
    displayName: 'Work Sans',
    isCustom: false
  },
  {
    importUrl: 'https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap',
    fontFamily: '"Zen Dots", cursive',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/fontnames%2FZen%20Dots.png?alt=media&token=0a208f38-3fd0-4f03-88d4-e64217a70d7f',
    displayName: 'Zen Dots',
    isCustom: false
  }
];
