<div class="w-full">
  <div class="mb-3">
    <norby-formcontrol-label
      [label]="label"
      [infoTooltip]="infoTooltip"
      [isRequired]="required"
    ></norby-formcontrol-label>

    <norby-input-base
      type="number"
      [value]="val || ''"
      [isDisabled]="disabled"
      [isJoinedRight]="isJoinedRight"
      [isJoinedLeft]="isJoinedLeft"
      [isRequired]="required"
      [maxLength]="9"
      [placeholder]="placeholder"
      (onInput)="handleInput($event)"
      (keydown)="handleKeydown($event)"
      pattern="^[-+]?[0-9]*\.?[0-9]+$"
    >
    </norby-input-base>

    <norby-helpertext [helperText]="helperText"></norby-helpertext>
  </div>
</div>
