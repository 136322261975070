import { RolesEnum, PageInfo, IQueryResult } from './general';

export type IUserslugRoleStatus = 'active' | 'pending';

export class IUserRole {
  role!: RolesEnum;
  id!: string;
  slug!: string;
  status!: IUserslugRoleStatus;
  userId!: string;
  phoneNumber!: string;
  email?: string;
  createdAtCursor!: string;
  accessExpiresInMinutes?: number;
  accessExpiresAt?: Date;
  accessExpiresAtCursor?: string;
  accessReason?: string;
  isNorbyNext?: boolean;
}

export interface IUserRoleResultEdge {
  cursor?: string;
  node: IUserRole;
}

export interface IUserEventResults extends IQueryResult {
  pageInfo: PageInfo;
  edges: IUserRoleResultEdge[];
}

export type CreateOrUpdateUserRole = {
  userId: string;
  phoneNumber: string;
  email?: string;
  slug: string;
  role: RolesEnum;
  existing?: IUserRole;
  active?: boolean;
};

export type GetMembersInOrganization = {
  slug: string;
  cursor?: string;
  first?: number;
};
