import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { SearchResultNode } from 'models';
import { DropdownComponent } from '../root-components';
import { POSITION_MAP } from '../constants';

@Directive({
  selector: '[searchResults]'
})
export class SearchResultsDirective
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input('searchResults') filterOverlay: DropdownComponent;
  @Input() listener?: EventEmitter<MouseEvent>;
  @Input() results: SearchResultNode[];

  @Output() mouseOnDropdown: EventEmitter<boolean> = new EventEmitter();

  private _overlayRef: OverlayRef;
  private _mouseOnDropdown: boolean = false;
  private _mouseOnHost: boolean = false;
  private _hasListeners: boolean = false;
  private _dropdownInstance: TemplatePortal<any>;
  private _dropdownEnterListener: () => void;
  private _dropdownLeaveListener: () => void;
  private _dropdownClickListener: () => void;
  private _outsideClickListener: () => void;
  private _triggerWidth: number;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platform,
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private _r2: Renderer2
  ) {}

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this._platform)) {
      return;
    }

    setTimeout(() => {
      this._triggerWidth =
        this._elementRef.nativeElement.getBoundingClientRect().width;
      const positionStrategy = this._overlayPositionBuilder
        .flexibleConnectedTo(this._elementRef)
        .withPositions([
          POSITION_MAP.bottomExtendRight,
          POSITION_MAP.topExtendRight,
          POSITION_MAP.bottomExtendLeft,
          POSITION_MAP.topExtendLeft
        ]);

      this._overlayRef = this._overlay.create({
        positionStrategy,
        minWidth: this._triggerWidth,
        scrollStrategy: this._overlay.scrollStrategies.reposition()
      });

      this._dropdownInstance = new TemplatePortal(
        this.filterOverlay?.templateRef,
        this._viewContainerRef
      );
    });
  }

  ngOnDestroy() {
    this._dropdownEnterListener?.();
    this._dropdownLeaveListener?.();
    this._outsideClickListener?.();
    this._dropdownClickListener?.();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.results && changes.results.currentValue?.length > 0) {
      this.show();
    }
  }

  @HostListener('click')
  clickOpen() {
    if (this.results && this.results.length === 0) {
      return;
    }
    this.show();
  }

  @HostListener('mouseenter')
  mouseEnterHost() {
    this._mouseOnHost = true;

    // dropdown menu actions
    if (!this._hasListeners) {
      this._hasListeners = true;
      this._dropdownLeaveListener = this._r2.listen(
        this._overlayRef.overlayElement,
        'mouseleave',
        () => {
          this._mouseOnDropdown = false;
          this.mouseOnDropdown.emit(false);
        }
      );

      this._dropdownEnterListener = this._r2.listen(
        this._overlayRef.overlayElement,
        'mouseenter',
        () => {
          this._mouseOnDropdown = true;
          this.mouseOnDropdown.emit(true);
        }
      );

      this._dropdownClickListener = this._r2.listen(
        this._overlayRef.overlayElement,
        'click',
        (event) => {
          event.preventDefault();
          event.stopPropagation();
          this._mouseOnDropdown = false;
          this.hide();
        }
      );
    }
  }

  @HostListener('mouseleave')
  mouseLeaveHost() {
    this._mouseOnHost = false;
  }

  show() {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this._overlayRef.attach(this._dropdownInstance);

      this._outsideClickListener = this._r2.listen(
        this._document,
        'click',
        () => this.hide()
      );
    }
  }

  hide() {
    if (!this._mouseOnDropdown && !this._mouseOnHost) {
      this._overlayRef.detach();
    }
  }
}
