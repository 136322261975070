// TODO: move animations into uikit/src/lib/animations

import {
  trigger,
  style,
  transition,
  animate,
  query,
  stagger
} from '@angular/animations';
import {
  TRANSITION_BOUNCE_CURVE,
  TRANSITION_CURVE,
  TRANSITION_DISTANCE,
  TRANSITION_DURATION,
  TRANSITION_INTERVAL,
  TRANSITION_SCALE
} from 'uikit';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({ opacity: 1 })
    )
  ]),
  transition(':leave', [
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({ opacity: 0 })
    ),
    style({ position: 'absolute', height: '0px' })
  ])
]);

// fade in on enter from 50 percent opacity
export const fadeInFrom50 = trigger('fadeInFrom50', [
  transition(':enter', [
    style({ opacity: 0.5 }),
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({ opacity: 1 })
    )
  ])
]);

// slides up on enter
export const slideIn = trigger('slideIn', [
  transition(':enter', [
    style({ transform: `translateY(${TRANSITION_DISTANCE})`, opacity: 0 }),
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({ transform: 'translateY(0)', opacity: 1 })
    )
  ])
]);

// slides down on enter, up on leave
export const slideDownInUpOut = trigger('slideDownInUpOut', [
  transition('void => slide', [
    style({ transform: 'translateY(-100%)', opacity: 0 }),
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({ transform: 'translateY(0)', opacity: 1 })
    )
  ]),
  transition('slide => void', [
    animate(
      `${TRANSITION_DURATION}s ${TRANSITION_CURVE}`,
      style({ transform: 'translateY(-100%)' })
    )
  ])
]);

export const popInStaggered = trigger('popInStaggered', [
  transition(':enter', [
    query('.shape > *', [
      style({ transform: `scale(${TRANSITION_SCALE})`, opacity: 0 }),
      stagger(TRANSITION_INTERVAL, [
        animate(
          `${TRANSITION_DURATION}s ${TRANSITION_BOUNCE_CURVE}`,
          style({ transform: 'none', opacity: 1 })
        )
      ])
    ])
  ])
]);
