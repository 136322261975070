import { Injectable } from '@angular/core';
import {
  Content,
  ContentEvent,
  IUserContent,
  LandingPage,
  IShareService,
  ContentRegisterable
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class MockShareService implements IShareService {
  constructor() {}

  get canShare(): boolean {
    return false;
  }

  async share({
    title,
    text,
    url
  }: {
    title: string;
    text?: string;
    url?: string;
  }): Promise<{ shared: boolean; error?: string }> {
    return {
      shared: false,
      error: 'Sharing not supported in server environment.'
    };
  }

  saveToICal(event: ContentEvent, email?: string) {}

  saveToGoogleCalendar(event: ContentEvent) {}

  openContentShareSheet(content: Content, userContent?: IUserContent) {}

  getUrlForLandingPage(page: LandingPage): string {
    return '';
  }

  getEmbedCodeForLandingPage(page: LandingPage): string {
    return '';
  }

  embedStringForType(variant: string, content: ContentRegisterable) {
    return '';
  }
}
