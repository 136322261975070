import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DropFormPromptsViewComponent } from '../views/drop-form-prompts-view/drop-form-prompts-view.component';
import { NorbyInputModule } from './norby-input.module';
import { NorbySelectModule } from './norby-select.module';
import { CheckboxModule } from './checkbox.module';
import { ButtonModule } from './button.module';
import { FormModule } from './form.module';
import { SpinModule } from './spin.module';
import { NorbyDateInputModule } from './norby-date-input.module';

@NgModule({
  declarations: [DropFormPromptsViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormModule,
    NorbyInputModule,
    NorbySelectModule,
    SpinModule,
    ButtonModule,
    CheckboxModule,
    NorbyDateInputModule
  ],
  exports: [DropFormPromptsViewComponent]
})
export class DropFormPromptsViewModule {}
