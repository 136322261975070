import { InjectionToken } from '@angular/core';
import { Request, Response } from 'express';

export const REQUEST = new InjectionToken<Request>('REQUEST');
export const RESPONSE = new InjectionToken<Response>('RESPONSE');
export const ANALYTICS_URL_TOKEN = 'analytics_api_url_token';
export const CLOUD_ANALYTICS_URL_TOKEN = 'cloud_analytics_api_url_token';
export const ROOT_HOST_TOKEN = 'root_host_token';
export const EMBEDS_URL_TOKEN = 'embeds_url_token';
export const COOKIE_PREFIX_TOKEN = 'cookie_prefix_token';
export const STRIPE_CLIENT_KEY_TOKEN = 'stripe_client_key_token';
export const API_URL_TOKEN = 'api_url_token';
export const AUTH_URL_TOKEN = 'auth_api_url_token';
export const GTM_ID = 'gtm_id';
export const CHAMELEON_API_KEY = 'chameleon_api_key';
export const CHAMELEON_FAST_URL = 'chameleon_fast_url';
export const PROFITWELL_API_KEY = 'profitwell_api_key';
export const ANALYTICS_TRACKING_DEBUG_MODE = 'analytics_tracking_debug_mode';
export const SOCKET_SERVER_URL_TOKEN = 'socket_server_url_token';
export const FONT_BUCKET_TOKEN = 'font_bucket_token';
export const CORS_ANYWHERE_URL_TOKEN = 'cors_anywhere_url_token';
export const MMS_ATTACHMENT_BUCKET_TOKEN = 'mms_attachment_bucket_token';
export const BILLING_PORTAL_URL_TOKEN = 'billing_portal_url_token';
export const CLOUD_FUNCTIONS_URL_TOKEN = 'cloud_functions_url_token';
export const ZAPIER_URL_TOKEN = 'zapier_url_token';
export const END_USER_API_URL_TOKEN = 'end_user_api_url_token';
export const ANALYTICS_API_URL_TOKEN = 'analytics_api_url_token';
export const ADMIN_NLQ_TOKEN = 'admin_nlq_api_url_token';
export const SCREENSHOT_URL_TOKEN = 'screenshot_api_url_token';
export const IS_NORBY_NEXT = 'is_norby_next_token';
