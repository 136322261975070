import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[rootListItem]'
})
export class ListItemDirective {
  @HostBinding('class')
  elementClass = 'flex py-3 items-center justify-between';
  constructor() {}
}
