import { Injectable } from '@angular/core';
import {
  IContentModalService,
  ContentSignup,
  ISignupCreateDTO,
  ContentEvent,
  IEventCreateDTO,
  ContentRegisterable,
  ContentLink,
  ISendTestMessageContentData,
  ISlug,
  LandingPage,
  SingleSend,
  INotificationDTO
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class MockContentModalService implements IContentModalService {
  launchEventDropAnalyticsViewer(
    content: ContentRegisterable,
    initialTab?: 'overview' | 'notifications' | 'usage'
  ): Promise<string> {
    return Promise.resolve(null);
  }

  launchCampaignAnalytics(sendId: string): Promise<string> {
    return Promise.resolve(null);
  }

  launchLinkClicksDetail(
    entityId: string,
    interactionValue: string,
    contentNotificationTypes?: string[]
  ) {}

  launchLinkAnalyticsViewer(link: ContentLink): Promise<string> {
    return Promise.resolve(null);
  }

  launchPageAnalyticsViewer(page: LandingPage): Promise<string> {
    return Promise.resolve(null);
  }

  launchSendTestFormDialog(send: SingleSend): Promise<void> {
    return Promise.resolve(null);
  }

  launchContentTestFormDialog(
    content: ISendTestMessageContentData
  ): Promise<void> {
    return Promise.resolve(null);
  }

  launchCreateOrEditSignupFlow({
    signup,
    fromExternalFlow,
    duplicateScaffold,
    showEditButtons
  }: {
    signup?: ContentSignup;
    fromExternalFlow?: boolean;
    duplicateScaffold?: ISignupCreateDTO;
    showEditButtons?: boolean;
  }): Promise<string> {
    return Promise.resolve(null);
  }

  launchEditSendFlow({
    send,
    isDuplicate
  }: {
    send?: SingleSend;
    isDuplicate?: boolean;
  }): Promise<string> {
    return Promise.resolve(null);
  }

  launchCreateOrEditEventFlow({
    event,
    fromExternalFlow,
    duplicateScaffold,
    showEditButtons
  }: {
    event?: ContentEvent;
    fromExternalFlow?: boolean;
    duplicateScaffold?: IEventCreateDTO;
    showEditButtons?: boolean;
  }): Promise<string> {
    return Promise.resolve(null);
  }

  launchCreateOrEditLinkFlow({
    link,
    fromExternalFlow,
    showEditButtons
  }: {
    link?: ContentLink;
    fromExternalFlow?: boolean;
    showEditButtons?: boolean;
  }): Promise<string> {
    return Promise.resolve(null);
  }

  launchCreateOrEditLandingPageFlow({
    page,
    slug
  }: {
    page?: LandingPage;
    slug?: ISlug;
  }): Promise<string> {
    return Promise.resolve(null);
  }

  launchEventCancelMessageBoxDialog(input: { event: ContentEvent }) {}

  launchDropPreview(drop: ContentSignup) {}

  launchCollectionPreview(page: LandingPage) {}

  launchSendPreview({ send, slug }: { send?: SingleSend; slug?: ISlug }) {}

  launchNotificationPreview({
    notification,
    isEmail
  }: {
    notification?: INotificationDTO;
    isEmail?: boolean;
  }) {}

  launchEventPreview(event: ContentEvent) {}

  launchEventPublishedDialog(): Promise<boolean> {
    return Promise.resolve(null);
  }

  launchSignupPublishedDialog(): Promise<boolean> {
    return Promise.resolve(null);
  }

  launchEventAddTicketDialog(event: ContentEvent) {
    return Promise.resolve(null);
  }
}
