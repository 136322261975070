export type ProfitWellPluginConfig = {
  apiKey: string;
};

const profitwell = 'profitwell';
const scriptSrc = 'https://public.profitwell.com/js/profitwell.js';

function profitWellPlugin(config: ProfitWellPluginConfig) {
  return {
    name: 'profitWell',
    config,
    initialize: ({ config }) => {
      const { apiKey } = config;

      // Shouldn't have gotten this far if we don't have env variables
      if (!apiKey) {
        throw new Error('No ProfitWell config defined');
      }

      const scriptElement = document.createElement('script');
      scriptElement.id = 'profitwell-js';
      scriptElement.setAttribute('data-pw-auth', apiKey);

      document.head.appendChild(scriptElement);

      (function (
        i,
        s,
        o: 'profitwell',
        g: 'script',
        r,
        a = s.createElement(g),
        m = s.getElementsByTagName(g)[0]
      ) {
        i[o] =
          i[o] ||
          function () {
            (i[o].q = i[o].q || []).push(arguments);
          };
        a.async = true;
        a.src = `${r}?auth=${apiKey}`;
        m.parentNode?.insertBefore(a, m);
      })(window, document, 'profitwell', 'script', scriptSrc);
    },
    identify: ({ payload }) => {
      const { userId, traits } = payload;

      if (traits.customerId) {
        window[profitwell]('user_id', traits.customerId);
      } else if (traits.email) {
        window[profitwell]('user_email', traits.email);
      } else if (typeof userId === 'string') {
        window[profitwell]('user_id', userId);
      }
    },
    ready(): void {
      window[profitwell]('start', {
        confirmButton: { backgroundColor: '#140936' },
        hoverConfirmButton: { backgroundColor: '#474d66' },
        primaryNotificationButton: { backgroundColor: '#767024' },
        hoverPrimaryNotificationButton: { backgroundColor: '#9e9a6c' },
        secondaryNotificationButton: { backgroundColor: '#d6d4bf' },
        hoverSecondaryNotificationButton: { backgroundColor: '#f1f1ea' }
      });
    },
    loaded: () => {
      return window[profitwell]?.isLoaded;
    }
  };
}

export default profitWellPlugin;
