import { ContentSignup } from '../types';

export const compareSignups = (
  ...[signupOne, signupTwo]: ContentSignup[]
): boolean => {
  if (!signupOne && !signupTwo) {
    return true;
  } else if (!signupOne || !signupTwo) {
    return false;
  }

  signupOne =
    signupOne instanceof ContentSignup
      ? signupOne
      : ContentSignup.fromObject(signupOne);
  signupTwo =
    signupTwo instanceof ContentSignup
      ? signupTwo
      : ContentSignup.fromObject(signupTwo);

  return (
    (signupOne as ContentSignup).fullHash ===
    (signupTwo as ContentSignup).fullHash
  );
};
