<div class="steps-content max-w-md">
  <form [formGroup]="formGroup" (ngSubmit)="submitEmail()">
    <h2 rootFormItem>Enter your email</h2>

    <p *ngIf="emailLoginCopy" rootFormItem>{{ emailLoginCopy }}</p>

    <div rootFormItem>
      <norby-input
        type="email"
        placeholder="bob@example.com"
        formControlName="email"
        [isRequired]="true"
      ></norby-input>
    </div>

    <div rootFormItem>
      <root-button
        type="primary"
        size="large"
        [block]="true"
        [disabled]="!formGroup.valid"
        [loading]="isLoading"
        text="Next"
      >
      </root-button>
    </div>
  </form>
</div>
