import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[rootDropdownMenuItem]',
  host: {
    '[class.opacity-50]': 'isDisabled',
    '[class.cursor-not-allowed]': 'isDisabled'
  }
})
export class DropdownMenuItemDirective {
  @Input() isDisabled = false;
  @Input() isSubmenuHost = false;

  @HostBinding('class')
  elementClass =
    'flex items-center px-3 py-2 cursor-pointer hover:bg-item-hover-bg';
  constructor() {}

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    if (this.isDisabled || this.isSubmenuHost) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
