export const USER_COLORS = [
  'Red',
  'Green',
  'Blue',
  'Brown',
  'Yellow',
  'Orange',
  'Purple',
  'Indigo',
  'Violet',
  'Teal',
  'Pink',
  'Magenta',
  'Aqua',
  'Chartreuse',
  'Crimson',
  'Cyan',
  'Fuchsia',
  'Maroon',
  'Silver',
  'Gold',
  'Turquoise',
  'Navy'
];
