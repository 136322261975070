import { Injectable } from '@angular/core';
import {
  MessageResponse,
  MessageDto,
  IAiService,
  StartConversationDto,
  StartConversationResponseDto,
  ReplyToConversationDto,
  ReplyToConversationResponse,
  PaginatedQueryFilters,
  IConversationResults,
  PaginatedMessageFilters,
  IConversationMessagesResults
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class MockAiService implements IAiService {
  startConversation(
    data: StartConversationDto
  ): Promise<StartConversationResponseDto> {
    return Promise.resolve(null);
  }

  replyToConversation(
    conversationId: string,
    data: ReplyToConversationDto
  ): Promise<ReplyToConversationResponse> {
    return Promise.resolve(null);
  }

  async shortenMessage(data: MessageDto): Promise<MessageResponse> {
    return Promise.resolve(null);
  }

  async getConversations(
    input: PaginatedQueryFilters
  ): Promise<IConversationResults> {
    return Promise.resolve(null);
  }

  async getConversationMessagesById(
    input: PaginatedMessageFilters
  ): Promise<IConversationMessagesResults> {
    return Promise.resolve(null);
  }
}
