import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropMainButtonViewComponent } from '../views/drop-main-button-view';
import { ButtonModule } from './button.module';
import { DropdownModule } from './dropdown.module';
import { IconModule } from './icon.module';
import { LogEventDirectiveModule } from './log-event-directive.module';

@NgModule({
  declarations: [DropMainButtonViewComponent],
  imports: [
    CommonModule,
    ButtonModule,
    IconModule,
    DropdownModule,
    LogEventDirectiveModule
  ],
  exports: [DropMainButtonViewComponent]
})
export class DropMainButtonViewModule {}
