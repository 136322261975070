import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// 3rd party
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'norby-slider',
  templateUrl: './norby-slider.component.html',
  styleUrls: ['./norby-slider.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbySliderComponent),
      multi: true
    }
  ]
})
export class NorbySliderComponent implements ControlValueAccessor, OnInit {
  @Input() value?: number;
  @Input() floor: number = 0;
  @Input() ceil: number = 100;
  @Input() label?: string;
  @Input() helperText?: string = '';
  @Input() infoTooltip?: string = '';
  @Input() isRequired?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() allowNull?: boolean = false;
  @Input() size: 'small' | 'normal' = 'normal';

  options: Options;
  val: number;
  isButtonDisabled = true;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {}

  ngOnInit(): void {
    this.options = {
      floor: this.floor,
      ceil: this.ceil,
      showSelectionBar: true,
      hideLimitLabels: true,
      showTicksValues: false,
      disabled: this.disabled
    };

    if (this.value) {
      this.val = this.value;
    }
  }

  writeValue(value: number) {
    if (value !== null) {
      this.isButtonDisabled = false;
    }

    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  handleValueChange(value) {
    if (value !== this.val) {
      if (this.val === null && value === 0) {
        return;
      }

      this.isButtonDisabled = false;
      this._onChanged(value);
      this._markAsTouched();
    }
  }

  handleResetValueClick(): void {
    this.isButtonDisabled = true;
    this.val = 0;
    this._onChanged(null);
    this._markAsTouched();
  }
}
