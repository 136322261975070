import {
  Content,
  IContentInteractionsService,
  ContentRegisterable,
  IUserContent,
  IPromptResponse
} from 'models';

export class MockContentInteractionsService
  implements IContentInteractionsService
{
  trackEventReferral(contentId: string, trackingParams: any) {
    return Promise.resolve(null);
  }
  toggleSmsForContent(content: Content, sms: boolean, themeClasses?: string[]) {
    return Promise.resolve(null);
  }
  toggleEmailForContent(
    content: Content,
    email: boolean,
    themeClasses?: string[]
  ) {
    return Promise.resolve(null);
  }
  getUserContentForContent(contentId: string): Promise<IUserContent> {
    return Promise.resolve(null);
  }
  subscribeToNewsletter(
    email: string,
    recaptchaToken: string,
    tags?: string[]
  ) {
    return Promise.resolve(null);
  }
  interactWithLink(linkId: string, interactionType: string) {
    return Promise.resolve(null);
  }
  unregisterForContent(content: ContentRegisterable, themeClasses?: string[]) {
    return Promise.resolve(null);
  }
  registerForContent(
    content: ContentRegisterable,
    themeClasses?: string[],
    promptResponses?: IPromptResponse[],
    isMagicLinkHandoff?: boolean
  ) {
    return Promise.resolve(null);
  }
}
