import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NorbyTimePickerComponent } from '../components/molecules/norby-time-picker';
import { NorbyFormcontrolLabelModule } from './norby-formcontrol-label.module';
import { NorbyHelpertextModule } from './norby-helpertext.module';
import { NorbySelectModule } from './norby-select.module';

@NgModule({
  declarations: [NorbyTimePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NorbyFormcontrolLabelModule,
    NorbySelectModule,
    NorbyHelpertextModule
  ],
  exports: [NorbyTimePickerComponent]
})
export class NorbyTimePickerModule {}
