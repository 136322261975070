import { IDropShadowValues } from '../types';

// Parse a CSS drop shadow rule into horizontal and vertical offset,
// blur, and color values
export function parseDropShadowString(fromString?: string): IDropShadowValues {
  const currentValue = (fromString ?? '0px 0px 0px #000000')
    .trim()
    .split(' ')
    .map((token) => token.replace('px', '').trim());

  while (currentValue.length < 4) {
    currentValue.push('');
  }

  const h = +(currentValue[0] || 0);
  const v = +(currentValue[1] || 0);
  const blur = +(currentValue[2] || 0);
  const color = currentValue[3] || '#000000';

  return { h, v, blur, color };
}
