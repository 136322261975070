import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[rootFormItem]'
})
export class FormItemDirective {
  @HostBinding('class')
  elementClass = 'flex-col mb-4';
  constructor() {}
}
