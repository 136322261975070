import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformModule } from '@angular/cdk/platform';
import { BidiModule } from '@angular/cdk/bidi';
import { CarouselComponent } from '../root-components/carousel/carousel.component';
import { CarouselContentDirective } from '../directives/carousel-content.directive';

@NgModule({
  declarations: [CarouselComponent, CarouselContentDirective],
  imports: [CommonModule, PlatformModule, BidiModule],
  exports: [CarouselComponent, CarouselContentDirective]
})
export class CarouselModule {}
