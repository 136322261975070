import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinComponent } from '../root-components/spin/spin.component';
import { IconModule } from './icon.module';

@NgModule({
  declarations: [SpinComponent],
  imports: [CommonModule, IconModule],
  exports: [SpinComponent]
})
export class SpinModule {}
