<a
  [href]="href"
  class="link-card"
  target="_blank"
  rel="noreferrer"
  (click)="handleClick($event)"
>
  <root-card
    [class.cursor-pointer]="link"
    [bodyStyle]="{ padding: '0px' }"
    class="group duration-200 ease-custom"
  >
    <div class="flex items-center user-theme-card-image-position">
      <div
        class="flex-[0_0_60px] m-4 mr-0 user-theme-card-avatar"
        *ngIf="link?.imgixImages?.length > 1 || link?.imageUrl"
      >
        <ng-container
          *ngTemplateOutlet="
            link?.imgixImages?.length > 1
              ? carouselCoverTemplate
              : coverTemplate
          "
        >
        </ng-container>
      </div>
      <div class="flex-auto m-4 user-theme-card-text-alignment">
        <h3 class="group-hover:underline mb-0 card-text-color">
          {{ blockTitle || link?.title || '• • • • •' }}
        </h3>
        <p *ngIf="link?.description" class="mb-0 mt-3 card-text-color">
          {{ link?.description }}
        </p>
      </div>
    </div>
  </root-card>
</a>

<ng-template #coverTemplate>
  <lib-soft-image-view
    [imageUrl]="link?.imageUrl"
    [image]="link?.image"
    [imgixImage]="link?.imgixImage"
    [alt]="blockTitle || link?.title"
  >
  </lib-soft-image-view>
</ng-template>

<ng-template #carouselCoverTemplate>
  <root-carousel [autoPlay]="true" [autoPlaySpeed]="6000">
    <div carousel-content *ngFor="let image of link?.images; index as i">
      <lib-soft-image-view
        [alt]="blockTitle || link?.title"
        [imageUrl]="image?.url"
        [image]="image"
        [imgixImage]="link?.imgixImages[i]"
      ></lib-soft-image-view>
    </div>
  </root-carousel>
</ng-template>
