import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyInputBaseComponent } from '../components/atoms/norby-input-base';
import { NorbyIconModule } from './norby-icon.module';
import { TooltipModule } from './tooltip.module';

@NgModule({
  declarations: [NorbyInputBaseComponent],
  imports: [CommonModule, NorbyIconModule, TooltipModule],
  exports: [NorbyInputBaseComponent]
})
export class NorbyInputBaseModule {}
