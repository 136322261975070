<div class="steps-content max-w-md">
  <form (ngSubmit)="submitCode()" [formGroup]="formGroup">
    <h2 rootFormItem>Enter your verification code</h2>

    <p rootFormItem>Please enter the code we just sent you.</p>

    <div rootFormItem>
      <div rootNestedInputGroup class="py-2">
        <norby-input
          formControlName="verificationCode"
          placeholder="Your verification code"
          [isNestedButton]="true"
          characterLimit="6"
          type="number"
          [isRequired]="true"
        ></norby-input>

        <root-button
          type="primary"
          size="small"
          [loading]="isResendingCode"
          text="Resend"
          formSubmit="button"
          (handleClick)="resendPhoneCode()"
        >
          <root-icon name="rotate_cw"></root-icon>
        </root-button>
      </div>
    </div>

    <div rootFormItem>
      <root-button
        type="primary"
        size="large"
        [block]="true"
        [disabled]="!formGroup.valid"
        [loading]="isLoading"
        [text]="isOnFinalScreen ? 'Submit' : 'Next'"
      >
      </root-button>
    </div>
  </form>
</div>
