import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SoftImageViewComponent } from '../views/soft-image-view';

@NgModule({
  declarations: [SoftImageViewComponent],
  imports: [CommonModule],
  exports: [SoftImageViewComponent]
})
export class SoftImageViewModule {}
