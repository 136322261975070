<div class="steps-content">
  <form [formGroup]="formGroup" (ngSubmit)="submitProfile()">
    <root-spin [spinning]="!hasLoadedProfile">
      <h2 rootFormItem>Confirm your details</h2>

      <div rootFormItem *ngIf="needsName">
        <norby-input
          class="name-input"
          formControlName="displayName"
          label="Name"
          placeholder="Your full name"
          required
        ></norby-input>
      </div>

      <div rootFormItem *ngIf="needsEmail">
        <norby-input
          label="Email"
          class="email-input"
          formControlName="email"
          placeholder="Your email"
          required
        ></norby-input>
      </div>

      <div rootFormItem *ngIf="shouldShowMarketingOptIn">
        <label root-checkbox formControlName="hasOptedIntoMarketingEmails">{{
          marketingOptInLabel
        }}</label>
      </div>

      <div rootFormItem>
        <root-button
          type="primary"
          size="large"
          [block]="true"
          [disabled]="!formGroup.valid || !hasLoadedProfile"
          [loading]="isLoading"
          [text]="isOnFinalScreen ? 'Submit' : 'Next'"
        >
        </root-button>
      </div>
    </root-spin>
  </form>
</div>
