import { ContentEventMetadata } from '../types';

export const compareEventsMetadata = (
  ...[eventOneMetadata, eventTwoMetadata]: ContentEventMetadata[]
): boolean => {
  if (!eventOneMetadata && !eventTwoMetadata) {
    return true;
  } else if (!eventOneMetadata || !eventTwoMetadata) {
    return false;
  }

  eventOneMetadata =
    eventOneMetadata instanceof ContentEventMetadata
      ? eventOneMetadata
      : ContentEventMetadata.fromObject(eventOneMetadata);

  eventTwoMetadata =
    eventTwoMetadata instanceof ContentEventMetadata
      ? eventTwoMetadata
      : ContentEventMetadata.fromObject(eventTwoMetadata);

  return (
    (eventOneMetadata as ContentEventMetadata).fullHash ===
    (eventTwoMetadata as ContentEventMetadata).fullHash
  );
};
