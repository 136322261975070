import { OverlayContainer } from '@angular/cdk/overlay';
import {
  ComponentFactoryResolver,
  ErrorHandler,
  Injector
} from '@angular/core';

// 3rd party
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha-2';

// Lib
import { GlobalErrorHandler } from '../tools';
import { LoadingIndicatorViewComponent } from '../views';
import {
  NorbyOverlayContainer,
  ANALYTICS_URL_TOKEN,
  FONT_BUCKET_TOKEN,
  ROOT_HOST_TOKEN,
  EMBEDS_URL_TOKEN,
  COOKIE_PREFIX_TOKEN,
  STRIPE_CLIENT_KEY_TOKEN,
  MMS_ATTACHMENT_BUCKET_TOKEN,
  API_URL_TOKEN,
  AUTH_URL_TOKEN,
  END_USER_API_URL_TOKEN,
  SOCKET_SERVER_URL_TOKEN,
  CORS_ANYWHERE_URL_TOKEN,
  CLOUD_FUNCTIONS_URL_TOKEN,
  ZAPIER_URL_TOKEN
} from 'models';

const nzConfigFactory = (
  injector: Injector,
  resolver: ComponentFactoryResolver
): NzConfig => {
  const factory = resolver.resolveComponentFactory(
    LoadingIndicatorViewComponent
  );
  const { nzIndicator } = factory.create(injector).instance;
  return {
    spin: {
      nzIndicator
    },
    select: {
      nzSuffixIcon: 'feather/chevron-down'
    },
    datePicker: {
      nzSuffixIcon: 'feather/calendar'
    },
    empty: {
      nzDefaultEmptyContent: 'Nothing here'
    },
    form: {
      nzTooltipIcon: 'feather/help-circle'
    },
    table: {
      nzLoadingIndicator: nzIndicator
    },
    timePicker: {
      nzSuffixIcon: 'feather/clock'
    },
    typography: {
      nzEditIcon: 'feather/edit',
      nzCopyIcons: ['feather/copy', 'feather/check']
    }
  };
};

export const getAntProviders = (environment) => [
  { provide: NZ_I18N, useValue: en_US },
  {
    provide: NZ_CONFIG,
    useFactory: nzConfigFactory,
    deps: [Injector, ComponentFactoryResolver]
  }
];

export const getSharedProviders = (environment) => [
  // Misc
  { provide: ROOT_HOST_TOKEN, useValue: environment.rootHost },
  { provide: COOKIE_PREFIX_TOKEN, useValue: environment.cookiePrefix },

  // Buckets
  {
    provide: MMS_ATTACHMENT_BUCKET_TOKEN,
    useValue: environment.mmsAttachmentBucket
  },
  { provide: FONT_BUCKET_TOKEN, useValue: environment.fontBucket },

  // Services
  { provide: END_USER_API_URL_TOKEN, useValue: environment.endUserUrl },
  { provide: AUTH_URL_TOKEN, useValue: environment.authUrl },
  { provide: API_URL_TOKEN, useValue: environment.apiUrl },
  { provide: ANALYTICS_URL_TOKEN, useValue: environment.analyticsUrl },
  { provide: CORS_ANYWHERE_URL_TOKEN, useValue: environment.corsAnywhereUrl },
  { provide: EMBEDS_URL_TOKEN, useValue: environment.embedsUrl },
  { provide: SOCKET_SERVER_URL_TOKEN, useValue: environment.socketServerUrl },
  {
    provide: CLOUD_FUNCTIONS_URL_TOKEN,
    useValue: environment.cloudFunctionsBaseUrl
  },

  // 3rd party
  { provide: STRIPE_CLIENT_KEY_TOKEN, useValue: environment.stripeClientKey },
  { provide: ZAPIER_URL_TOKEN, useValue: environment.zapierUrl },
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.recaptchaKey
    } as RecaptchaSettings
  },

  // Classes
  { provide: OverlayContainer, useClass: NorbyOverlayContainer },
  { provide: ErrorHandler, useClass: GlobalErrorHandler }
];
