import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyDateInputComponent } from '../components/molecules/norby-date-input';
import { DropdownModule } from './dropdown.module';
import { NorbyCustomFilterDropdownModule } from './norby-custom-filter-dropdown.module';
import { NorbyFormcontrolLabelModule } from './norby-formcontrol-label.module';
import { NorbyHelpertextModule } from './norby-helpertext.module';
import { NorbyInputBaseModule } from './norby-input-base.module';

@NgModule({
  declarations: [NorbyDateInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyFormcontrolLabelModule,
    NorbyInputBaseModule,
    NorbyHelpertextModule,
    NorbyCustomFilterDropdownModule,
    DropdownModule
  ],
  exports: [NorbyDateInputComponent]
})
export class NorbyDateInputModule {}
