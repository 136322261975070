<div
  class="block relative h-auto overflow-hidden flex justify-center items-center"
  [style.minHeight]="!loaded ? initialHeight : '0px'"
  [style.background-color]="backgroundColor"
>
  <img
    class="block w-full h-auto rounded-none transform-none"
    *ngIf="src || srcset?.length > 0"
    [src]="src"
    [srcset]="srcset"
    [alt]="altText"
    [width]="width"
    [height]="height"
    (load)="loaded = true"
  />
</div>
