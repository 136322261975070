import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { YoutubeEmbedViewComponent } from '../views/youtube-embed-view';

@NgModule({
  declarations: [YoutubeEmbedViewComponent],
  imports: [CommonModule],
  exports: [YoutubeEmbedViewComponent]
})
export class YoutubeEmbedViewModule {}
