import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional
} from '@angular/core';

// 3rd party
import { Subscription, combineLatest, tap, filter, switchMap } from 'rxjs';

// App
import {
  AuthService,
  ContentService,
  SlugService,
  UserService
} from '../../services';

// Libs
import {
  DeliveryType,
  ContentRegisterable,
  ISlug,
  ITicket,
  IUserContent,
  IUserMetadata,
  IUserPublicMetadata,
  PRIVACY_URL,
  TERMS_URL,
  BUILT_WITH_NORBY_URL
} from 'models';
import {
  BaseComponent,
  IconService,
  rootHelpCircle,
  rootRotateCw,
  ModalRef,
  MODAL_DATA,
  ModalConfig,
  ErrorService
} from 'uikit';
import { ContentRegistrationPhoneNumberPayload } from '../../components';

type Stages =
  | 'phone'
  | 'code'
  | 'email'
  | 'emailCode'
  | 'profile'
  | 'prompts'
  | 'purchase'
  | 'tickets';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends BaseComponent implements OnInit {
  // Content data passed in if triggered as part of RSVP flow
  content: ContentRegisterable;
  themeClasses: string[];

  // Look up if content is present
  slug: ISlug;

  // User content to return if registering for something
  userContent: IUserContent = {};

  // If coming from a form form variant of a signup, prompts are filled
  // out inline on the underlying page, so that stage should not be
  // included in this flow
  shouldSkipPromptsStage: boolean;

  // Currently displayed error
  error: string;

  // Stepper stages are dynamically generated depending on
  // the prompts and user info required by the content
  stages: Stages[] = [];

  // The currently displayed index in the stepper
  currentIndex = 0;

  // The current stage
  get currentStage() {
    return this.stages?.length > this.currentIndex
      ? this.stages[this.currentIndex]
      : null;
  }

  // Whether the user is on the final stage of the flow
  get isOnFinalScreen() {
    return this.currentIndex >= this.stages.length - 1;
  }

  // The user's cached phone number
  phoneNumber;

  // The user's cached email
  email: string;
  emailVerificationCodeMedium: DeliveryType | 'none';

  // User requirements for the input event
  needsName = false;
  needsEmail = false;

  // Whether the user tapped the Edit button next to
  // their name or email in the profile stage
  isEditingName = false;
  isEditingEmail = false;

  // Ticket selected by the user
  selectedTicket: ITicket;

  // For the button spinners in the UI
  isLoading: boolean;

  // The current user's public profile information
  userProfile: IUserPublicMetadata;

  // The current user's private profile information
  userMetadata: IUserMetadata;

  // Whether the user has already paid for this event (if it's ticketed)
  userHasPaid = false;

  // Whether user profile information has emitted at least once
  hasLoadedProfile = false;

  // Receipt breakdown for ticketed events
  subtotal: number;
  fees: number;
  total: number;

  addedPromoCode: string = '';
  // Constants
  readonly PRIVACY_URL = PRIVACY_URL;
  readonly TERMS_URL = TERMS_URL;
  readonly BUILT_WITH_NORBY_URL = BUILT_WITH_NORBY_URL;

  private _userSubscription: Subscription;

  constructor(
    @Optional() @Inject(MODAL_DATA) public config: ModalConfig,
    private _cdr: ChangeDetectorRef,
    private _user: UserService,
    private _error: ErrorService,
    private _auth: AuthService,
    private _dialogRef: ModalRef,
    private _iconService: IconService,
    private _slug: SlugService,
    private _content: ContentService
  ) {
    super();
    this._iconService.registerIcons([rootHelpCircle, rootRotateCw]);
    if (!!config) {
      this.content = config.content as ContentRegisterable;
      this.shouldSkipPromptsStage = config.shouldSkipPromptsStage;
      this.themeClasses = config.themeClasses;
    }
  }

  ngOnInit(): void {
    this._initTicket();
    this._initUserContent();
    this._initContentRequirements();
    this._initContentLookups();
    this._initStages();
  }

  private _initTicket() {
    this.selectedTicket = this.content?.tickets?.[0];
    this.handleRecalculatePrice();
  }

  private _initContentRequirements() {
    const privateReqs = this.content?.privateUserInfoRequirements;
    const publicReqs = this.content?.userInfoRequirements;
    this.needsName = !!publicReqs?.displayName?.required;
    this.needsEmail = !!privateReqs?.email?.required;
  }

  private _initContentLookups() {
    if (!this.content) {
      return;
    }

    this._user
      .getUserContent$(this.content.contentId)
      .pipe(
        filter((userContent) => !!userContent),
        this.takeUntilDestroy
      )
      .subscribe((userContent) => {
        if (userContent.rsvpStatus === 'active' && userContent.rsvpEnabled) {
          this._dialogRef.close();
          return;
        }

        const status = userContent.userContentPayment?.paymentStatus;
        this.userHasPaid = status === 'confirmed' || status === 'pending';
        if (userContent?.userContentPayment?.ticket) {
          this.selectedTicket = userContent.userContentPayment.ticket;
        }
        this.handleRecalculatePrice(this.addedPromoCode ?? '');

        this._cdr.detectChanges();
      });

    this._slug.getSlug(this.content.primarySlugIdentifier).then((slug) => {
      this.slug = slug;
      this._cdr.detectChanges();
    });
  }

  private _initStages() {
    this._userSubscription = this._auth.user$
      .pipe(
        tap(() => {
          this.userProfile = null;
          this.userMetadata = null;
          this.hasLoadedProfile = false;
          this._cdr.detectChanges();
        }),
        switchMap(() =>
          combineLatest([
            this._user.currentUserProfile$(),
            this._user.currentUserMetadata$()
          ])
        ),
        this.takeUntilDestroy
      )
      .subscribe(([userProfile, userMetadata]) => {
        this.userProfile = userProfile;
        this.userMetadata = userMetadata;
        this.hasLoadedProfile = true;

        if (this.stages.length) {
          this._cdr.detectChanges();
          return;
        }

        const prompts = this.content?.prompts;
        const authedUserHasPhoneNumber = !!userMetadata?.phoneNumber;
        const authedUserHasEmail = !!userMetadata?.email;
        const needsPrompts =
          (prompts?.length ?? 0) > 0 && !this.shouldSkipPromptsStage;
        const needsPurchase = this.content?.isPaid;
        const needsProfile = this.needsName || this.needsEmail;

        if (needsPurchase) {
          this.stages.push('tickets');
        }

        if (this._contentRequiresPhoneNumber && !authedUserHasPhoneNumber) {
          this.stages.push('phone', 'code');
        }

        if (this._contentRequiresEmail && !authedUserHasEmail) {
          this.stages.push('email', 'emailCode');
        }

        if (needsProfile) {
          this.stages.push('profile');
        }

        if (needsPrompts) {
          this.stages.push('prompts');
        }

        if (needsPurchase) {
          this.stages.push('purchase');
        }

        if (this.stages.length === 0) {
          this._dialogRef.close(this.userContent);
        }

        this._cdr.detectChanges();
      });
  }

  private _initUserContent() {
    if (this.content?.isSignup && this._contentRequiresPhoneNumber) {
      this.userContent.hasOptedIntoSMSMarketing = true;
    }
  }

  private get _contentRequiresEmail() {
    return !!this.content?.privateUserInfoRequirements?.email?.required;
  }

  private get _contentRequiresPhoneNumber(): boolean {
    const phoneNumberRequirementExplicitlyEnabled =
      !!this.content?.privateUserInfoRequirements?.phoneNumber?.required;

    // Absence of the phoneNumber requirement is legacy and indicates
    // that the event expects phone number by default.
    const phoneNumberRequirementImplicitlyEnabled =
      this.content?.privateUserInfoRequirements?.phoneNumber === undefined;

    return (
      phoneNumberRequirementExplicitlyEnabled ||
      phoneNumberRequirementImplicitlyEnabled
    );
  }

  // Comparator for keyvalue pipe to force Angular to preserve
  // the order of the country codes map
  preserveOrder = (a, b): number => 0;

  async handleRecalculatePrice(promoCode?: string) {
    if (!this.selectedTicket) {
      return;
    }

    const intent = await this._content.getPaymentIntentEstimate(
      this.content.contentId,
      this.content?.tickets?.[0]?.label,
      this.selectedTicket?.qty || 1,
      promoCode ?? ''
    );

    if (promoCode?.length > 0) {
      if (!intent?.promoCodeIsValid) {
        this._error.displayError({ message: 'Invalid promo code' } as Error);
        this.addedPromoCode = '';
      } else {
        this.addedPromoCode = promoCode;
      }
    } else {
      this.addedPromoCode = '';
    }
    this.fees = intent.fees;
    this.total = intent.amount;
    this.subtotal = this.total - this.fees;
    if (this.total === 0 && this.content?.isPaid && this.selectedTicket) {
      const idx = this.stages.indexOf('purchase');
      if (idx > -1) {
        this.stages.splice(idx, 1);
      }
    }

    this._cdr.detectChanges();
  }

  // Close without approving
  close() {
    this._dialogRef.close(false);
  }

  handlePhoneNumberNext(payload: ContentRegistrationPhoneNumberPayload) {
    this.phoneNumber = payload.phoneNumber;

    if (payload.hasOptedIntoMarketing) {
      this.userContent.hasOptedIntoSMSMarketing = payload.hasOptedIntoMarketing;
    }

    this._advance();
  }

  handleNext() {
    this._advance();
  }

  handleSetError(e: any) {
    this.error = this._error.formatError(e);
    this._cdr.detectChanges();
  }

  handleSkipEmailStage() {
    this.stages = this.stages?.filter(
      (stage) => stage !== 'emailCode' && stage !== 'email'
    );
  }

  handleSkipEmailVerificationStage() {
    this.stages = this.stages?.filter((stage) => stage !== 'emailCode');
  }

  handleSelectedTicketUpdate(ticket: ITicket) {
    if (this.selectedTicket !== ticket) {
      this.selectedTicket = ticket;
      this.handleRecalculatePrice(this.addedPromoCode ?? '');
    }
  }

  unsubscribeUserSubscriptions() {
    this._userSubscription?.unsubscribe();
  }

  // Advance to the next stage in the flow
  private async _advance() {
    if (this.currentIndex < this.stages.length - 1) {
      this.currentIndex++;
      this._cdr.detectChanges();
      return;
    }

    if (this.addedPromoCode && this.total === 0) {
      await this._content.getPaymentIntentSecret(
        this.content.contentId,
        this.selectedTicket?.label,
        this.selectedTicket?.qty,
        this.addedPromoCode
      );
    }

    this._dialogRef.close(this.userContent);
  }

  skipPayment() {
    if (this.currentStage === 'purchase') this._advance();
  }
}
