import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

// 3rd party
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

// Libs
import { ContentEvent, ContentEventMetadata, EVENT_TYPES } from 'models';
import { BaseComponent } from 'uikit';
import { SummaryCardViewItem } from '../../../../views';

@Component({
  selector: 'lib-event-summary-step',
  templateUrl: './event-summary-step.component.html',
  styleUrls: ['./event-summary-step.component.less']
})
export class EventSummaryStepComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() event: ContentEvent;
  @Input() eventMetadata: ContentEventMetadata;
  @Input() isEventValid: boolean;

  @Output() onUpdatedEvent: EventEmitter<ContentEvent> =
    new EventEmitter<ContentEvent>();
  @Output() onGoToStep: EventEmitter<number> = new EventEmitter<number>();

  eventName: string;
  eventLocation: string;
  eventType: string;
  capacity: string;
  startDate: string;
  startTime: string;
  eventLength: string;
  requiredInformation: string;
  customFormFields: string;

  detailsItems: SummaryCardViewItem[] = [];
  registrationItems: SummaryCardViewItem[] = [];

  readonly EVENT_TYPES = EVENT_TYPES;

  constructor() {
    super();
  }

  ngOnChanges(): void {
    this._loadValues();
    this._loadItems();
  }

  private _loadValues() {
    this.eventName = this.event?.title || 'Not set';
    this.eventLocation =
      this.event?.contentLocationType === 'online'
        ? 'Online'
        : this.event?.contentLocationType === 'irl'
          ? (this.eventMetadata?.contentLocation ?? 'Not set')
          : 'Not set';
    this.eventType = EVENT_TYPES[this.event?.eventType] || '';
    this.capacity =
      this.eventMetadata?.rsvpRestrictions?.limit?.toString() || 'No limit';
    this.startDate = dayjs(this.event?.startDate).format('MMM D, YYYY');
    this.startTime = dayjs(this.event?.startDate).format('h:mm a');
    this.eventLength = this.humanReadableTimeDifference(
      this.event?.startDate,
      this.event?.endDate
    );
    this.customFormFields = this.event?.prompts?.length
      ? this.event.prompts.map((p) => p.prompt).join(', ')
      : 'None';

    let infos: string[] = [];
    if (this.event?.userInfoRequirements?.displayName?.required) {
      infos.push('Name');
    }
    if (this.event?.privateUserInfoRequirements?.email?.required) {
      infos.push('Email');
    }
    if (this.event?.privateUserInfoRequirements?.phoneNumber?.required) {
      infos.push('Phone');
    }
    this.requiredInformation = infos.join(', ') || 'None';
  }

  private _loadItems() {
    this.detailsItems = [
      {
        title: 'Name',
        value: this.eventName
      },
      {
        title: 'Location',
        value: this.eventLocation
      },
      {
        title: 'Start date',
        value: this.startDate
      },
      {
        title: 'Start time',
        value: this.startTime
      },
      {
        title: 'Length',
        value: this.eventLength
      }
    ];

    this.registrationItems = [
      {
        title: 'Required information',
        value: this.requiredInformation
      },
      {
        title: 'Custom fields',
        value: this.customFormFields
      },
      {
        title: 'Capacity',
        value: this.capacity
      }
    ];
  }

  humanReadableTimeDifference(startDate: Date, endDate: Date) {
    return dayjs(startDate).from(dayjs(endDate), true);
  }
}
