import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Firestore,
  doc,
  docData,
  setDoc,
  getDoc
} from '@angular/fire/firestore';

// 3rd party
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';

// Lib
import {
  IUserContent,
  IUserPublicMetadata,
  IUserMetadata,
  FIREBASE_COLLECTIONS,
  ApiSurfaces,
  ENDPOINTS,
  UserPublicMetadata
} from 'models';
import { ApiService } from '../api';
import { AuthService } from '../auth';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _beaconToken: string;

  constructor(
    @Inject(DOCUMENT) private _document,
    private _firestore: Firestore,
    private _api: ApiService,
    private _auth: AuthService
  ) {
    this._auth.user$
      .pipe(
        filter((user) => !!user?.uid),
        switchMap(() =>
          this._api.post<{ token: string }>(
            ApiSurfaces.AUTH,
            '/auth/beacon_token'
          )
        )
      )
      .subscribe(({ token }) => (this._beaconToken = token));
  }

  // returns true if successfully marked as seen, false on error
  async markTipSeen(identifier: string): Promise<void> {
    const currentUser = this._auth.currentUser;
    const ref = doc(this._firestore, 'userTooltips', currentUser?.uid);
    return setDoc(
      ref,
      {
        [identifier]: true
      },
      { merge: true }
    );
  }

  async checkTipSeen(identifier: string): Promise<boolean> {
    try {
      const currentUser = this._auth.currentUser;
      const ref = doc(this._firestore, 'userTooltips', currentUser?.uid);
      const snapshot = await getDoc(ref);
      return snapshot.exists() && snapshot.data()[identifier];
    } catch (e) {
      // safer to fall back with the assumption that the user saw a tip than to reshow a tip
      return true;
    }
  }

  userContentForContent(userId: string, contentId: string) {
    return userId?.length && contentId?.length
      ? doc(
          this._firestore,
          FIREBASE_COLLECTIONS.users.content,
          `${userId}_${contentId}`
        )
      : null;
  }

  getUserContent$(contentId: string): Observable<IUserContent> {
    return this._auth.authState$.pipe(
      switchMap((u) =>
        u?.uid?.length && contentId?.length
          ? docData(this.userContentForContent(u.uid, contentId))
          : from([null])
      )
    );
  }

  private get _userMetaDoc() {
    const uid = this._auth.currentUser?.uid;
    if (!uid) {
      return;
    }

    return doc(this._firestore, FIREBASE_COLLECTIONS.users.metadata, uid);
  }

  private get _userProfileDoc() {
    const uid = this._auth.currentUser?.uid;
    if (!uid) {
      return;
    }

    return doc(this._firestore, FIREBASE_COLLECTIONS.users.all, uid);
  }

  async currentUserMetadata(): Promise<IUserMetadata> {
    const data = await getDoc(this._userMetaDoc);
    return (data?.data() as IUserMetadata) ?? null;
  }

  currentUserMetadata$(): Observable<IUserMetadata> {
    return this._auth.authState$.pipe(
      switchMap((u) =>
        u?.uid && !u?.isAnonymous ? docData(this._userMetaDoc) : from([null])
      )
    );
  }

  async currentUserProfile(): Promise<IUserPublicMetadata> {
    const data = await getDoc(this._userProfileDoc);
    return (data?.data() as IUserPublicMetadata) ?? null;
  }

  currentUserProfile$(): Observable<UserPublicMetadata> {
    return this._auth.authState$.pipe(
      switchMap((u) =>
        u?.uid && !u?.isAnonymous ? docData(this._userProfileDoc) : from([null])
      ),
      map((data) => (data ? plainToClass(UserPublicMetadata, data) : null))
    );
  }

  async setUserProfile(user: IUserPublicMetadata) {
    const uid = this._auth.currentUser?.uid;
    if (!uid) {
      return;
    }

    return setDoc(this._userProfileDoc, user, { merge: true });
  }

  sendBeacon({ data, linkId }: { data?: any; linkId?: string }): boolean {
    if (!this._beaconToken) {
      return false;
    }

    // track interaction to link endpoint if linkId is present
    if (linkId) {
      const endpoint = `${
        ENDPOINTS.link
      }/${linkId}/interaction_beacon?interactionType=click&token=${
        this._beaconToken
      }${
        this._document?.referrer
          ? `&domReferrer=${this._document?.referrer}`
          : ''
      }`;
      return !!navigator?.sendBeacon(
        this._api.constructApiUrl(endpoint, ApiSurfaces.END_USER)
      );
    }

    // track interaction through general beacon endpoint otherwise
    const endpoint = `${ENDPOINTS.analytics.beacon}?token=${this._beaconToken}`;
    const beaconUrl = this._api.constructApiUrl(endpoint, ApiSurfaces.API);

    const blob = new Blob(
      [
        JSON.stringify({
          ...data,
          domReferrer: this._document?.referrer
        })
      ],
      { type: 'application/json' }
    );
    return !!navigator?.sendBeacon(beaconUrl, blob);
  }
}
