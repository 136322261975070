export const FIREBASE_COLLECTIONS = {
  pages: 'landingPages',
  announcements: 'announcements',
  contacts: 'contacts',
  themes: 'themes_v2',
  onboardingCopy: 'onboardingCopy',
  static: 'static',
  singleSends: 'singleSends',
  slugs: {
    all: 'slugs',
    metadata: 'slugMetadata',
    requests: 'slugRequests'
  },
  content: {
    all: 'content',
    metadata: 'contentMetadata',
    referrals: 'contentReferrals',
    notifications: 'contentNotifications',
    keywords: 'contentKeywords'
  },
  users: {
    all: 'users',
    metadata: 'userMetadata',
    content: 'userContent'
  }
};
