<div class="py-1">
  <button
    type="button"
    class="h-30 w-full border flex flex-row justify-center items-center outline-1 radio-button rounded-sm"
    [ngClass]="{
      'border-neutral-900 bg-neutral-300 hover:bg-neutral-200 hover:border-neutral-800':
        selectedValue === buttonValue,
      'border-neutral-600 bg-white hover:bg-neutral-100 hover:border-neutral-400':
        selectedValue !== buttonValue
    }"
    (click)="handleButtonClick()"
    [disabled]="isDisabled"
  >
    <img [src]="imageUrl" />
  </button>

  <div class="w-full flex flex-row justify-center">
    <span>{{ buttonLabel }}</span>
  </div>
</div>
