import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-image',
  templateUrl: './norby-image.component.html',
  styleUrls: ['./norby-image.component.less']
})
export class NorbyImageComponent {
  @Input() imageUrl: string;
  @Input() maskUrl: string;
  @Input() imageLabel?: string;
  @Input() width?: string;
  @Input() height?: string;
  @Input() maskWidth?: string;
  @Input() maskHeight?: string;

  constructor() {}

  get maskStyle(): string {
    if (this.maskUrl) {
      const rules = [
        '-webkit-mask-repeat: no-repeat',
        'mask-repeat: no-repeat',
        `-webkit-mask-image: url(${this.maskUrl})`,
        `mask-image: url(${this.maskUrl})`
      ];

      if (this.maskWidth && this.maskHeight) {
        rules.push(
          `-webkit-mask-size: ${this.maskWidth} ${this.maskHeight}`,
          `mask-size: ${this.maskWidth} ${this.maskHeight}`
        );
      }

      if (this.maskWidth) {
        rules.push(`width: ${this.maskWidth}`);
      }

      if (this.maskHeight) {
        rules.push(`height: ${this.maskHeight}`);
      }

      return rules.join(';');
    } else {
      return '';
    }
  }

  get imageStyle(): string {
    const rules = ['object-fit: cover'];

    if (this.width) {
      rules.push(`width: ${this.width}`);
    }

    return rules.join(';');
  }
}
