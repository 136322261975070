import {
  RegisterableLayoutDisplayEnum,
  RegisterableLayoutTypeEnum
} from '../types/content/registerable-layout';

export const REGISTERABLE_LAYOUT_OPTIONS = [
  {
    imgPath: '/assets/img/classic-left.svg',
    type: RegisterableLayoutTypeEnum.CLASSIC_LEFT,
    display: RegisterableLayoutDisplayEnum.CLASSIC_LEFT
  },
  {
    imgPath: '/assets/img/classic-right.svg',
    type: RegisterableLayoutTypeEnum.CLASSIC_RIGHT,
    display: RegisterableLayoutDisplayEnum.CLASSIC_RIGHT
  },
  {
    imgPath: '/assets/img/classic-vertical.svg',
    type: RegisterableLayoutTypeEnum.CLASSIC_VERTICAL,
    display: RegisterableLayoutDisplayEnum.CLASSIC_VERTICAL
  },
  {
    imgPath: '/assets/img/large-image.svg',
    type: RegisterableLayoutTypeEnum.LARGE_IMAGE,
    display: RegisterableLayoutDisplayEnum.LARGE_IMAGE
  }
  // {
  //   imgPath: '/assets/img/full-width-image.png',
  //   type: RegisterableLayoutTypeEnum.FULL_WIDTH_IMAGE,
  //   display: RegisterableLayoutDisplayEnum.FULL_WIDTH_IMAGE
  // },
  // {
  //   imgPath: '/assets/img/info-panel.svg',
  //   type: RegisterableLayoutTypeEnum.INFO_PANEL,
  //   display: RegisterableLayoutDisplayEnum.INFO_PANEL
  // },
  // {
  //   imgPath: '/assets/img/large-header.svg',
  //   type: RegisterableLayoutTypeEnum.LARGE_HEADER,
  //   display: RegisterableLayoutDisplayEnum.LARGE_HEADER
  // }
];
