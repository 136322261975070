import { SegmentedMessage } from './segment-calculator';

export function calculateTotalMessageSegments(message: string): number {
  const segmentedMessage = new SegmentedMessage(message);
  return (segmentedMessage as any).segments.length;
}

export function calculateTotalCharsPerSegment(message: string): number {
  if (message.length > 160) {
    return message.length % 160;
  } else {
    return message.length;
  }
}
