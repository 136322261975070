import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyIconButtonWithLabelComponent } from '../components/molecules/norby-icon-button-with-label';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyIconButtonWithLabelComponent],
  imports: [CommonModule, NorbyIconModule],
  exports: [NorbyIconButtonWithLabelComponent]
})
export class NorbyIconButtonWithLabelModule {}
