<div class="mb-3 w-full">
  <norby-formcontrol-label
    [label]="label"
    [infoTooltip]="infoTooltip"
    [isRequired]="isRequired"
  ></norby-formcontrol-label>

  <textarea
    class="form-control text-base block transition ease-in-out outline-none w-full h-12 px-3 pb-4 pt-3.5 border rounded-sm bg-white disabled:bg-neutral-50 text-neutral-800 disabled:text-neutral-500 border-neutral-300 hover:border-neutral-500 focus:border-mustard disabled:border-neutral-300 min-h-20"
    [value]="val || value || ''"
    [disabled]="isDisabled"
    [required]="isRequired"
    [readonly]="readonly"
    (keyup)="handleKeyup($event.target)"
    [attr.maxlength]="maxLength"
    [placeholder]="placeholder ? placeholder : ''"
    [rows]="rows"
    #textarea
  >
  </textarea>

  <div class="flex justify-between">
    <norby-helpertext [helperText]="helperText"></norby-helpertext>
    <span *ngIf="!!maxLength" class="text-xs text-neutral-700 float-right">
      {{ (val && val.length) || (value && value.length) || 0 }}/{{ maxLength }}
    </span>
  </div>
</div>
