// 3rd party
import {
  Exclude,
  instanceToInstance,
  instanceToPlain,
  plainToClass,
  Type
} from 'class-transformer';
import { customAlphabet } from 'nanoid';
import { BehaviorSubject } from 'rxjs';

// Libs
import { hashString } from '../../tools';
import { IThemeDTO, Theme } from '../ISlug';
import {
  ContentPageBlock,
  EmbedPageBlock,
  HeaderPageBlock,
  ImagePageBlock,
  InstagramPageBlock,
  NewsletterSignupPageBlock,
  PageBlock,
  IPageBlock,
  ProfilePageBlock,
  RichTextPageBlock,
  SocialIconsPageBlock,
  SpacerPageBlock,
  TwitterPageBlock,
  UpcomingEventsPageBlock,
  VimeoPageBlock,
  YoutubePageBlock,
  SpotifyPageBlock,
  CalendlyPageBlock
} from './blocks';

const allowedLabelCharacters =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

const generateLabel = () => customAlphabet(allowedLabelCharacters, 5)();

export interface ILandingPage {
  id: string;
  theme: IThemeDTO;
  slug: string;
  label: string;
  title: string;
  isDefault: boolean;
  description: string;
  entityIds: string[];
  blocks: IPageBlock[];
  published: boolean;
  createdAt: Date;
  modifiedAt: Date;
  createdAtCursor: string;
  modifiedAtCursor: string;
}

export class LandingPage implements ILandingPage {
  @Exclude()
  protected _changes$ = new BehaviorSubject<LandingPage>(null);

  @Exclude()
  readonly changes$ = this._changes$.asObservable();

  @Exclude()
  private _hash: number;

  readonly id: string;
  readonly slug: string;
  readonly label: string;
  readonly title: string;
  readonly isDefault: boolean;
  readonly description: string;
  readonly entityIds: string[];
  readonly published: boolean;
  readonly createdAt: Date;
  readonly modifiedAt: Date;
  readonly createdAtCursor: string;
  readonly modifiedAtCursor: string;

  @Type(() => Theme)
  readonly theme: Theme;

  @Type(() => PageBlock, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'blockType',
      subTypes: [
        { value: ContentPageBlock, name: 'content' },
        { value: ProfilePageBlock, name: 'profile' },
        { value: RichTextPageBlock, name: 'text' },
        { value: ImagePageBlock, name: 'image' },
        { value: SocialIconsPageBlock, name: 'socialIcons' },
        { value: SpacerPageBlock, name: 'spacer' },
        { value: EmbedPageBlock, name: 'embed' },
        { value: TwitterPageBlock, name: 'twitter' },
        { value: VimeoPageBlock, name: 'vimeo' },
        { value: YoutubePageBlock, name: 'youtube' },
        { value: SpotifyPageBlock, name: 'spotify' },
        { value: CalendlyPageBlock, name: 'calendly' },
        { value: UpcomingEventsPageBlock, name: 'upcomingEvents' },
        { value: NewsletterSignupPageBlock, name: 'newsletterSignup' },
        { value: HeaderPageBlock, name: 'header' },
        { value: InstagramPageBlock, name: 'instagram' }
      ]
    }
  })
  readonly blocks: PageBlock[];

  static new() {
    return plainToClass(LandingPage, { blocks: [], label: generateLabel() });
  }

  static fromObject(object: Partial<ILandingPage>) {
    return plainToClass(LandingPage, object);
  }

  clone(withPage?: Partial<ILandingPage>) {
    const clone = instanceToInstance(this);
    if (withPage) {
      clone.updateProperties(withPage);
    }
    return clone;
  }

  toObject() {
    return instanceToPlain(this) as ILandingPage;
  }

  toCreateDTO(published?: boolean): CreateLandingPageDTO {
    const { theme, blocks, slug, label, title, isDefault, description } =
      this.toObject();
    return {
      ...(theme && { theme }),
      ...(blocks && { blocks }),
      ...(slug && { slug }),
      ...(description && { description }),
      title: title ?? 'New page',
      label: label ?? generateLabel(),
      isDefault: isDefault ?? false,
      published: published ?? this.published ?? false
    };
  }

  toUpdateDTO(published?: boolean): UpdateLandingPageDTO {
    const { theme, blocks, label, title, isDefault, description } =
      this.toObject();

    const payload = {
      ...(theme && { theme }),
      ...(blocks && { blocks }),
      ...(label && { label }),
      ...(title && { title }),
      ...(isDefault && { isDefault }),
      ...(description && { description }),
      published: published ?? this.published ?? false
    };

    return payload;
  }

  get hash() {
    if (!this._hash) {
      const blocks = this.blocks.reduce((a, block) => `${a}${block.hash}`, '');
      const theme = this.theme?.hash ?? 0;
      const props = `
        ${blocks}
        ${theme}
        ${this.title}
        ${this.label}
        ${this.description}
        ${this.isDefault}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  updateProperties(properties: Partial<ILandingPage>, emitEvent = true) {
    // Only update props that aren't @Excluded in the toObject transform
    const props =
      properties instanceof LandingPage ? properties.toObject() : properties;

    // Update props
    for (const key in props) {
      this[key] = properties[key];
    }

    // Clear hash and publish changes
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  removeBlockAtIndex(idx: number, emitEvent = true) {
    this.blocks.splice(idx, 1);
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  updateBlockAtIndex(idx: number, block: PageBlock, emitEvent = true) {
    this.blocks[idx] = block;
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  insertBlockAtIndex(idx: number, block: PageBlock, emitEvent = true) {
    if (idx < 0) {
      return;
    } else if (idx > this.blocks.length) {
      this.blocks.push(block);
    } else {
      this.blocks.splice(idx, 0, block);
    }

    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }
}

export class CreateLandingPageDTO {
  theme?: IThemeDTO;
  blocks!: IPageBlock[];
  slug!: string;
  label!: string;
  isDefault!: boolean;
  title?: string;
  description?: string;
  published!: boolean;
}

export class UpdateLandingPageDTO {
  theme?: IThemeDTO;
  blocks!: IPageBlock[];
  label!: string;
  isDefault!: boolean;
  title?: string;
  description?: string;
  published!: boolean;
}
