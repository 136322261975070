import { OverlayRef } from '@angular/cdk/overlay';
import { Inject, Injectable } from '@angular/core';
import { Subject, filter } from 'rxjs';

import { MODAL_DATA } from './modal.service';
import { BaseComponent } from '../../models/base-component';

export interface ModalConfig {
  blocking?: boolean;
  fullscreen?: boolean;
  transparent?: boolean;
  [key: string]: any;
}
/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
@Injectable({
  providedIn: 'root'
})
export class ModalRef extends BaseComponent {
  private _afterClosedSubject$ = new Subject<any>();
  afterClosed$ = this._afterClosedSubject$.asObservable();

  constructor(
    private _overlayRef: OverlayRef,
    private _document: Document,
    @Inject(MODAL_DATA) public config?: ModalConfig
  ) {
    super();

    if (!this.config?.blocking) {
      this._overlayRef._outsidePointerEvents
        .pipe(
          filter(
            (event) =>
              event.type === 'click' &&
              event.target instanceof Element &&
              event.target.classList.contains('cdk-global-overlay-wrapper')
          ),
          this.takeUntilDestroy
        )
        .subscribe(() => this.close());
    }
  }

  addThemeClassWrapperNodes(themeClasses: string[]) {
    themeClasses.forEach((themeClass) => {
      const parent = this._overlayRef.overlayElement;
      const element = parent.firstChild;

      const wrapper = this._document.createElement('div');
      wrapper.classList.add(themeClass);
      parent.replaceChild(wrapper, element);
      wrapper.appendChild(element);
    });
  }

  /**
   * Closes the overlay. You can optionally provide a result.
   */
  close(result?: any) {
    this._overlayRef.removePanelClass(['zoom-enter', 'zoom-enter-active']);
    this._overlayRef.backdropElement.classList.remove(
      'fade-enter',
      'fade-enter-active'
    );
    this._overlayRef.addPanelClass(['zoom-leave', 'zoom-leave-active']);
    this._overlayRef.backdropElement.classList.add(
      'fade-leave',
      'fade-leave-active'
    );
    // Dispose when animation is complete
    setTimeout(() => {
      this._overlayRef.dispose();
      this._afterClosedSubject$.next(result);
      this._afterClosedSubject$.complete();
    }, 200);
  }
}
