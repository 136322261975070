import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NewsletterSignupViewComponent } from '../views/newsletter-signup-view';
import { ButtonModule } from './button.module';
import { CardModule } from './card.module';
import { CarouselModule } from './carousel.module';
import { FormModule } from './form.module';
import { NorbyInputModule } from './norby-input.module';
import { SoftImageViewModule } from './soft-image-view.module';

@NgModule({
  declarations: [NewsletterSignupViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CarouselModule,
    SoftImageViewModule,
    ButtonModule,
    CardModule,
    FormModule,
    NorbyInputModule
  ],
  exports: [NewsletterSignupViewComponent]
})
export class NewsletterSignupViewModule {}
