import { Component, Input } from '@angular/core';

import { IAccountInfo, ProfilePageBlock } from 'models';
import { rootMapPin } from '../../../icons';
import { IconService } from '../../services';

@Component({
  selector: 'lib-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.less']
})
export class ProfileViewComponent {
  @Input() accountInfo: IAccountInfo;
  @Input() block: ProfilePageBlock;

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([rootMapPin]);
  }
}
