import { Injectable } from '@angular/core';

// 3rd party
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

// App
import {
  ModalService,
  ModalRef,
  GlobalLoadingIndicatorViewComponent
} from 'uikit';

/*
  Most of the time chunk loading is fast enough that
  showing an indicator only increases perceived latency,
  but if chunk loading is slow due to network delays, etc,
  a blocking modal loading indicator should be shown after
  this number of milliseconds
*/
const CHUNK_LOADING_INDICATOR_DELAY = 400;

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private _modalRef: ModalRef;
  private _loadingTimerSubscription: Subscription;
  globalIsLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private _modal: ModalService) {}

  setLoading(loading = false) {
    this.globalIsLoading$.next(loading);
    if (loading && !this._loadingTimerSubscription) {
      this._loadingTimerSubscription = timer(CHUNK_LOADING_INDICATOR_DELAY)
        .pipe(take(1))
        .subscribe(
          (_) =>
            (this._modalRef = this._modal.open(
              GlobalLoadingIndicatorViewComponent,
              null,
              { blocking: true, fullscreen: true, transparent: true }
            ))
        );
    } else if (!loading) {
      this._loadingTimerSubscription?.unsubscribe();
      this._modalRef?.close();
      this._loadingTimerSubscription = null;
      this._modalRef = null;
    }
  }
}
