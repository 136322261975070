import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileViewComponent } from '../views/profile-view';
import { IconModule } from './icon.module';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';
import { SkeletonModule } from './skeleton.module';
import { TagModule } from './tag.module';

@NgModule({
  declarations: [ProfileViewComponent],
  imports: [
    CommonModule,
    SafeHtmlPipeModule,
    TagModule,
    IconModule,
    SkeletonModule
  ],
  exports: [ProfileViewComponent]
})
export class ProfileViewModule {}
