import {
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { zoomBigMotion } from '../../animations';

@Component({
  selector: 'root-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less'],
  encapsulation: ViewEncapsulation.None,
  animations: [zoomBigMotion]
})
export class TooltipComponent {
  @Input() tooltipText = '';
  @Input() tooltip: TemplateRef<any>;

  constructor() {}
}
