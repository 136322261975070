import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  ContentClick,
  ContentPageBlock,
  EmbedPageBlock,
  HeaderPageBlock,
  IAccountInfo,
  ImagePageBlock,
  isEmbedBlock,
  isEventBlock,
  isHeaderBlock,
  isImageBlock,
  isLinkBlock,
  isNewsletterSignupBlock,
  isProfileBlock,
  isSignupBlock,
  isSocialIconsBlock,
  isSpacerBlock,
  isTextBlock,
  isUpcomingEventsBlock,
  isVimeoBlock,
  isYoutubeBlock,
  isSpotifyBlock,
  isCalendlyBlock,
  NewsletterSignupPageBlock,
  PageBlock,
  ProfilePageBlock,
  RichTextPageBlock,
  SocialIconsPageBlock,
  SpacerPageBlock,
  UpcomingEventsPageBlock,
  VimeoPageBlock,
  YoutubePageBlock,
  SpotifyPageBlock,
  CalendlyPageBlock
} from 'models';

@Component({
  selector: 'lib-adaptable-block-view',
  templateUrl: './adaptable-block-view.component.html',
  styleUrls: ['./adaptable-block-view.component.less']
})
export class AdaptableBlockViewComponent {
  @Output() contentClick = new EventEmitter<ContentClick>();
  @Input() block: PageBlock;
  @Input() showAsAbstract: boolean;
  @Input() accountInfo: IAccountInfo;
  @Input() pageId: string;

  handleClick(contentClick: ContentClick) {
    if (!contentClick?.content) return;
    this.contentClick.next(contentClick);
  }

  get eventDropBlock(): ContentPageBlock {
    return isEventBlock(this.block) || isSignupBlock(this.block)
      ? this.block
      : null;
  }

  get linkBlock(): ContentPageBlock {
    return isLinkBlock(this.block) ? this.block : null;
  }

  get signupBlock(): ContentPageBlock {
    return isSignupBlock(this.block) ? this.block : null;
  }

  get eventBlock(): ContentPageBlock {
    return isEventBlock(this.block) ? this.block : null;
  }

  get headerBlock(): HeaderPageBlock {
    return isHeaderBlock(this.block) ? this.block : null;
  }

  get textBlock(): RichTextPageBlock {
    return isTextBlock(this.block) ? this.block : null;
  }

  get profileBlock(): ProfilePageBlock {
    return isProfileBlock(this.block) ? this.block : null;
  }

  get socialIconsBlock(): SocialIconsPageBlock {
    return isSocialIconsBlock(this.block) ? this.block : null;
  }

  get imageBlock(): ImagePageBlock {
    return isImageBlock(this.block) ? this.block : null;
  }

  get spacerBlock(): SpacerPageBlock {
    return isSpacerBlock(this.block) ? this.block : null;
  }

  get upcomingEventsBlock(): UpcomingEventsPageBlock {
    return isUpcomingEventsBlock(this.block) ? this.block : null;
  }

  get youtubeBlock(): YoutubePageBlock {
    return isYoutubeBlock(this.block) ? this.block : null;
  }

  get vimeoBlock(): VimeoPageBlock {
    return isVimeoBlock(this.block) ? this.block : null;
  }

  get spotifyBlock(): SpotifyPageBlock {
    return isSpotifyBlock(this.block) ? this.block : null;
  }

  get calendlyBlock(): CalendlyPageBlock {
    return isCalendlyBlock(this.block) ? this.block : null;
  }

  get embedBlock(): EmbedPageBlock {
    return isEmbedBlock(this.block) ? (this.block as EmbedPageBlock) : null;
  }

  get newsletterSignupBlock(): NewsletterSignupPageBlock {
    return isNewsletterSignupBlock(this.block) ? this.block : null;
  }
}
