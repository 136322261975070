import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { RsvpConfirmationViewComponent } from '../views/rsvp-confirmation-view';
import { ButtonModule } from './button.module';
import { FormModule } from './form.module';
import { IconModule } from './icon.module';
import { LogEventDirectiveModule } from './log-event-directive.module';
import { NorbyInputModule } from './norby-input.module';
import { ResultModule } from './result.module';

@NgModule({
  declarations: [RsvpConfirmationViewComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    LogEventDirectiveModule,
    ButtonModule,
    IconModule,
    ResultModule,
    FormModule,
    NorbyInputModule
  ],
  exports: [RsvpConfirmationViewComponent]
})
export class RsvpConfirmationViewModule {}
