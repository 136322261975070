// these are the accepted MMS mime types from twilio
// https://www.twilio.com/docs/sms/accepted-mime-types
export const ACCEPTED_MMS_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'image/png',
  'audio/basic',
  'audio/L24',
  'audio/mp4',
  'audio/mpeg',
  'audio/ogg',
  'audio/vnd.rn-realaudio',
  'audio/vnd.wave',
  'audio/3gpp',
  'audio/3gpp2',
  'audio/ac3',
  'audio/webm',
  'audio/amr-nb',
  'audio/amr',
  'video/mpeg',
  'video/mp4',
  'video/quicktime',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
  'video/3gpp-tt',
  'video/H261',
  'video/H263',
  'video/H263-1998',
  'video/H263-2000',
  'video/H264',
  'image/bmp',
  'image/tiff',
  'text/vcard',
  'text/x-vcard',
  'text/csv',
  'text/rtf',
  'text/richtext',
  'text/calendar',
  'text/directory',
  'application/pdf',
  'application/vcard'
];

export const ACCEPTED_MMS_MIME_TYPES_STR = ACCEPTED_MMS_MIME_TYPES.join(',');

export const ACCEPTED_INSTAGRAM_MMS_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/bmp',
  'image/vnd.microsoft.icon' // ico
];

export const ACCEPTED_INSTAGRAM_MMS_MIME_TYPES_STR =
  ACCEPTED_INSTAGRAM_MMS_MIME_TYPES.join(',');

export const ACCEPTED_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'image/png',
  'image/bmp',
  'image/tiff'
];

export const ACCEPTED_IMAGE_MIME_TYPES_STR =
  ACCEPTED_IMAGE_MIME_TYPES.join(',');
