import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { IconService } from '../../services/icon';
import { rootChevronRight } from '../../../icons/build';
import { slideMotion } from '../../animations';

@Component({
  selector: 'root-dropdown-menu',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideMotion]
})
export class DropdownComponent {
  @Output() onSlideFinished = new EventEmitter<AnimationEvent>();
  @Output() onSlideStarted = new EventEmitter<AnimationEvent>();
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([rootChevronRight]);
  }

  handleSlideFinished(event: AnimationEvent) {
    this.onSlideFinished.emit(event);
  }

  handleSlideStarted(event: AnimationEvent) {
    this.onSlideStarted.emit(event);
  }
}
