import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'norby-radio-button',
  templateUrl: './norby-radio-button.component.html',
  styleUrls: ['./norby-radio-button.component.less']
})
export class NorbyRadioButtonComponent {
  @Input() buttonLabel: string;
  @Input() buttonValue: string;
  @Input() selectedValue?: string;
  @Input() isDisabled?: boolean;
  @Input() isFirst?: boolean;
  @Input() isLast?: boolean;
  @Input() nextButtonValue?: string;
  @Input() size: 'default' | 'large' | 'small' = 'default';

  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('class')
  get class(): string {
    return 'mr-1 sm:mr-0 last:mr-0';
  }

  constructor() {}

  handleButtonClick(): void {
    this.buttonClicked.emit(this.buttonValue);
  }
}
