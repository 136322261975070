<div class="embed-wrapper" *ngIf="!showPlaceholder">
  <div [innerHtml]="safeEmbedCode"></div>
</div>

<root-card *ngIf="showPlaceholder">
  <root-result
    [iconTpl]="placeholderIcon ? iconTpl : null"
    [titleTpl]="titleTpl"
  >
    <ng-template #iconTpl>
      <root-icon [name]="placeholderIcon" class="item"></root-icon>
    </ng-template>
    <ng-template #titleTpl>
      <h2 class="embed-view-placeholder">{{ placeholderText }}</h2>
    </ng-template>
  </root-result>
</root-card>
