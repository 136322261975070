import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyImageEditComponent } from '../components/molecules/norby-image-edit';
import { NorbyIconModule } from './norby-icon.module';
import { NorbyImageEditDialogModule } from './norby-image-edit-dialog.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
  declarations: [NorbyImageEditComponent],
  imports: [
    CommonModule,
    NorbyIconModule,
    NorbyImageEditDialogModule,
    NzPopoverModule
  ],
  exports: [NorbyImageEditComponent]
})
export class NorbyImageEditModule {}
