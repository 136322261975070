<div
  [style]="maskStyle"
  class="bg-neutral-100 flex items-center justify-center"
>
  <img
    [src]="imageUrl"
    [alt]="imageLabel"
    [style]="imageStyle"
    class="bg-neutral-100"
  />
</div>
