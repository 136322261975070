import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// Firestore
import { setDoc } from '@angular/fire/firestore';
import { User } from '@angular/fire/auth';

// 3rd party
import { firstValueFrom } from 'rxjs';

// Shared
import { AnalyticsService } from '../analytics';
import { AuthService } from '../auth';
import { DeviceService } from '../device';
import { ApiService } from '../api';
import { UserService } from '../user';
import { UiService } from '../ui';

// Libs
import { ModalService, MessageService, MessageType, ErrorService } from 'uikit';
import {
  IUserContent,
  Content,
  ContentRegisterable,
  ENDPOINTS,
  ApiSurfaces,
  AUTH_FLOW_EVENTS,
  IContentInteractionsService,
  IPromptResponse,
  USER_CONTENT_KEY,
  CONTENT_KEY
} from 'models';
import { LocalStorageService } from '../local-storage';

/*
  Users interacting with content
*/

@Injectable({
  providedIn: 'root'
})
export class ContentInteractionsService implements IContentInteractionsService {
  // Lazy loaded components
  loginComponent;
  rsvpConfirmationComponent;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _auth: AuthService,
    private _device: DeviceService,
    private _analytics: AnalyticsService,
    private _dialog: ModalService,
    private _message: MessageService,
    private _local: LocalStorageService,
    private _api: ApiService,
    private _error: ErrorService,
    private _user: UserService,
    private _ui: UiService
  ) {
    Promise.all([
      firstValueFrom(this._local.localStorage$),
      firstValueFrom(this._auth.user$)
    ]).then(([storage, user]) => {
      const url = new URL(window.location.href);
      const hasFlag = !!url.searchParams.get('finishRegistration');
      const userContent = storage[USER_CONTENT_KEY]
        ? (JSON.parse(storage[USER_CONTENT_KEY]) as IUserContent)
        : null;
      const content = storage[CONTENT_KEY]
        ? (JSON.parse(storage[CONTENT_KEY]) as ContentRegisterable)
        : null;

      // If the current URL has a flag which tells us we
      // just returned from a payment flow and we find
      // content and user content objects in local storage,
      // then complete the registration
      if (hasFlag && userContent && content && user) {
        this._completeRegistration(userContent, content);
      }

      // Clear temp objects as soon as they've been used
      if (userContent || content) {
        this._local.delete(USER_CONTENT_KEY);
        this._local.delete(CONTENT_KEY);
      }
    });
  }

  trackEventReferral(contentId: string, trackingParams: any) {
    const endpoint = `content/track_referral`;
    try {
      return this._api.post<boolean>(ApiSurfaces.END_USER, endpoint, {
        contentId,
        trackingParams
      });
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async toggleSmsForContent(
    content: Content,
    sms = true,
    themeClasses?: string[]
  ) {
    const userId = this._auth.currentUser?.uid;
    const contentId = content.contentId;
    const payload = { userId, contentId, receiveNotifications: { sms } };
    const doc = this._user.userContentForContent(userId, contentId);
    return setDoc(doc, payload, { merge: true }).then(() => {
      this._message.show(
        {
          text: `Turned ${sms ? 'on' : 'off'} texts.`,
          type: MessageType.SUCCESS
        },
        themeClasses
      );
    });
  }

  async toggleEmailForContent(
    content: Content,
    email = true,
    themeClasses?: string[]
  ) {
    const userId = this._auth.currentUser?.uid;
    const contentId = content.contentId;
    const payload = { userId, contentId, receiveNotifications: { email } };
    const doc = this._user.userContentForContent(userId, contentId);
    return setDoc(doc, payload, { merge: true }).then(() => {
      this._message.show(
        {
          text: `Turned ${email ? 'on' : 'off'} emails.`,
          type: MessageType.SUCCESS
        },
        themeClasses
      );
    });
  }

  async getUserContentForContent(contentId: string): Promise<IUserContent> {
    const endpoint = `content/${contentId}/referral`;
    try {
      return await this._api.post<IUserContent>(ApiSurfaces.END_USER, endpoint);
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async subscribeToNewsletter(
    email: string,
    recaptchaToken: string,
    tags?: string[]
  ) {
    const endpoint = `newsletter/signup`;
    const slug = this._device.currentSlug;
    return this._api.post(
      ApiSurfaces.END_USER,
      endpoint,
      { email, slug, tags },
      null,
      recaptchaToken
    );
  }

  async interactWithLink(linkId: string, interactionType: 'click' = 'click') {
    const endpoint = `${ENDPOINTS.link}/${linkId}/interaction`;
    const domReferrer = this._document?.referrer ?? '';
    try {
      return await this._api.post(ApiSurfaces.END_USER, endpoint, {
        interactionType,
        domReferrer
      });
    } catch (e) {
      console.log(e);
    }
  }

  // Remove registration for event or drop
  async unregisterForContent(
    content: ContentRegisterable,
    themeClasses?: string[]
  ) {
    const userId = this._auth.currentUser?.uid;
    const payload = {
      rsvpEnabled: false,
      receiveNotifications: {
        email: false,
        sms: false
      }
    };
    const doc = this._user.userContentForContent(userId, content.contentId);
    return setDoc(doc, payload, { merge: true }).then(() => {
      this._message.show(
        {
          text: `Removed registration.`,
          type: MessageType.SUCCESS
        },
        themeClasses
      );
    });
  }

  // Register for an event or drop
  async registerForContent(
    content: ContentRegisterable,
    themeClasses?: string[],
    promptResponses?: IPromptResponse[],
    isMagicLinkHandoff: boolean = false
  ) {
    const shouldSkipPromptsStage = (promptResponses?.length || 0) > 0;
    const confirmedRegistration =
      await this._confirmUserMeetsEventRequirementsOrInitiateLoginFlow(
        content,
        shouldSkipPromptsStage,
        themeClasses
      );

    this._completeRegistration(
      confirmedRegistration,
      content,
      themeClasses,
      promptResponses,
      isMagicLinkHandoff
    );
  }

  private async _completeRegistration(
    confirmedRegistration: IUserContent,
    content: ContentRegisterable,
    themeClasses?: string[],
    promptResponses?: IPromptResponse[],
    isMagicLinkHandoff: boolean = false
  ) {
    if (!confirmedRegistration) {
      return;
    }

    const currentUser = this._auth.currentUser;
    const userContentDocRef = this._user.userContentForContent(
      currentUser?.uid,
      content.contentId
    );

    this._ui.setLoading(true);

    const userContent = this._getUserContent(
      confirmedRegistration,
      promptResponses,
      currentUser,
      content,
      isMagicLinkHandoff
    );

    try {
      await setDoc(userContentDocRef, userContent, {
        merge: true
      });
      this._launchRsvpConfirmation(content, userContent, themeClasses);
    } catch (e) {
      this._error.displayError(e);
    }

    this._ui.setLoading(false);
  }

  private _getUserContent(
    registration: IUserContent,
    promptResponses: IPromptResponse[],
    user: User,
    content: ContentRegisterable,
    isMagicLinkHandoff: boolean
  ): IUserContent {
    const requirements = content.privateUserInfoRequirements;
    const hasEmailRequirement = requirements?.email?.required;
    const hasSmsRequirement =
      requirements?.phoneNumber === undefined ||
      requirements?.phoneNumber?.required;

    // Construct optimistic user content object
    return {
      ...registration,
      ...(isMagicLinkHandoff && { hasOptedIntoSMSMarketing: true }),
      ...(promptResponses?.length && { promptResponses }),
      userId: user?.uid,
      contentId: content.contentId,
      contentType: content.contentType,
      rsvpStatus:
        !hasEmailRequirement || user?.emailVerified ? 'active' : 'pending',
      rsvpEnabled: true,
      receiveNotifications: {
        email: hasEmailRequirement ?? true,
        sms: hasSmsRequirement ?? true
      }
    };
  }

  private async _launchRsvpConfirmation(
    content: ContentRegisterable,
    userContent: IUserContent,
    themeClasses?: string[]
  ) {
    if (!this.rsvpConfirmationComponent) {
      const { RsvpConfirmationComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/rsvp-confirmation'
      );

      this.rsvpConfirmationComponent = RsvpConfirmationComponent;
    }

    const component = this.rsvpConfirmationComponent;
    const ret = this._dialog.open(component, themeClasses, {
      content,
      userContent,
      themeClasses
    });

    return firstValueFrom(ret.afterClosed$).then((res) => {
      if (content.urls?.postRegistrationRedirect) {
        this._document.location.href = content.urls.postRegistrationRedirect;
      }
    });
  }

  private async _confirmUserMeetsEventRequirementsOrInitiateLoginFlow(
    content: ContentRegisterable,
    shouldSkipPromptsStage: boolean,
    themeClasses: string[]
  ): Promise<IUserContent> {
    this._analytics.track(
      content?.isEvent
        ? AUTH_FLOW_EVENTS.userBeganRegisterForEventFlow
        : content?.isSignup
          ? AUTH_FLOW_EVENTS.userBeganRegisterForDropFlow
          : AUTH_FLOW_EVENTS.userBeganRegisterForNewsletterFlow
    );

    const prompts = content?.prompts;
    const privateReqs = content?.privateUserInfoRequirements;
    const publicReqs = content?.userInfoRequirements;
    const user = this._auth.currentUser;

    const hasEmailRequirement = privateReqs?.email?.required;
    const hasSmsRequirement =
      privateReqs?.phoneNumber === undefined ||
      privateReqs?.phoneNumber?.required;

    // Only proceed to the login modal if event has no requirements
    // and the user is logged in
    const needsName = publicReqs?.displayName?.required;
    const needsPurchase = content?.isPaid;
    const needsPhone = hasSmsRequirement && !user.phoneNumber;
    const needsPrompts = prompts?.length > 0;

    const contentIsSimple =
      !needsPrompts &&
      !needsPurchase &&
      !hasEmailRequirement &&
      !needsName &&
      !needsPhone;

    if (contentIsSimple) return {};

    if (!this.loginComponent) {
      const { LoginComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/login'
      );

      this.loginComponent = LoginComponent;
    }

    const component = this.loginComponent;

    const dialogRef = this._dialog.open(component, themeClasses, {
      content,
      shouldSkipPromptsStage,
      themeClasses
    });

    const ret = await firstValueFrom(dialogRef.afterClosed$);

    this._analytics.track(
      content?.isEvent
        ? AUTH_FLOW_EVENTS.userFinishedRegisterForEventFlow
        : content?.isSignup
          ? AUTH_FLOW_EVENTS.userFinishedRegisterForDropFlow
          : AUTH_FLOW_EVENTS.userFinishedRegisterForNewsletterFlow
    );

    return ret;
  }
}
