import { AbstractControl } from '@angular/forms';
import { YOUTUBE_URL_PATTERN } from '../constants';

export function YoutubeUrlValidator(control: AbstractControl) {
  const urlPattern = YOUTUBE_URL_PATTERN;

  const validUrl = urlPattern.test(control.value);

  return validUrl || !control.value
    ? null
    : { invalidUrl: { value: control.value } };
}
