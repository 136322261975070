import { Type } from 'class-transformer';
import { EntityType } from './content';
import {
  DowngradeReasonType,
  DowngradeStages,
  DowngradeReason,
  PainPointType,
  OtherSolutions
} from './downgrade';
import { SubscriptionChangeReason } from './billing';

// Event Types
export enum ProductAnalyticsEventTypes {
  CLICKED_UPSELL = 'clickedUpsell',
  USER_SEARCHED = 'userSearched',
  DUPLICATED_ENTITY = 'duplicatedEntity',
  CLICKED_ENTITY = 'clickedEntity',
  CLICKED_ELEMENT = 'clickedElement',
  UPDATED_PROFILE = 'updatedProfile',
  LINK_BLOCK_CREATED = 'linkBlockCreated',
  SIGNUP_BLOCK_CREATED = 'signupBlockCreated',
  EVENT_BLOCK_CREATED = 'eventBlockCreated',
  CONTACTS_IMPORTED = 'contactsImported',
  APPLIED_THEME = 'appliedTheme',
  COMPLETED_PRODUCTIZED_ONBOARDING = 'completedProductizedOnboarding',
  UPDATED_THEME = 'updatedTheme',
  SUBMITTED_DOWNGRADE_REASON = 'submittedDowngradeReason',
  ABORT_DOWNGRADE = 'abortDowngrade',
  SUBMITTED_DOWNGRADE_FOLLOW_UP = 'submittedDowngradeFollowUp'
}

export type ProductAnalyticsEventType = `${ProductAnalyticsEventTypes}`;

// PROPERTIES
export enum ClickedUpsellProductAnalyticsTypes {
  OUT_OF_TEXTS = 'outOfTexts',
  ALMOST_OUT_OF_TEXTS = 'almostOutOfTexts',
  UPGRADE = 'upgrade'
}

export type ClickedUpsellProductAnalyticsType =
  `${ClickedUpsellProductAnalyticsTypes}`;

export enum ClickedUpsellProductAnalyticsLocations {
  LEFT_SIDE_BAR = 'leftSideBar',
  RIGHT_SIDE_BAR = 'rightSideBar',
  DASHBOARD_BANNER = 'dashboardBanner',

  SEARCH_RESULTS = 'searchResults',
  SMS_MESSAGE_BAR_ATTACHMENTS_TOOLTIP = 'smsMessageBarAttachmentsTooltip',
  SMS_MESSAGE_BAR_ALERT = 'smsMessageBarAlert',
  SINGLE_SEND_ATTACHMENTS_TOOLTIP = 'singleSendAttachmentsTooltip',
  EVENT_NOTIFICATIONS_ATTACHMENTS_TOOLTIP = 'eventNotificationsAttachmentsTooltip',
  SIGNUP_NOTIFICATIONS_ATTACHMENTS_TOOLTIP = 'signupNotificationsAttachmentsTooltip',
  SINGLE_SEND_SMS_SENDS = 'singleSendsSmsSendsTooltip',
  CONTENT_NOTIFICATIONS_ATTACHMENTS_TOOLTIP = 'contentNotificationsAttachmentsTooltip',
  DISABLE_NORBY_BRANDING = 'disableNorbyBranding',
  CUSTOMIZE_TERMS_OF_SERVICE = 'customizeTermsOfService',
  CUSTOMIZE_PRIVACY_POLICY = 'customizePrivacyPolicy',
  CUSTOM_CSS = 'customCss',
  ADD_LANDING_PAGES_TOOLTIP = 'addLandingPagesTooltip',
  ZAPIER_INTEGRATION_TOOLTIP = 'zapierIntegrationTooltip',
  FB_PIXEL_TOOLTIP = 'fbPixelTooltip',
  TIKTOK_PIXEL_TOOLTIP = 'tiktokPixelTooltip',
  ADROLL_TOOLTIP = 'adrollTooltip',
  ADD_TEAM_MEMBER_TOOLTIP = 'addTeamMemberTooltip',
  CUSTOM_FAVICON = 'customFavicon',
  HTML_BLOCK = 'htmlBlock',
  USAGE_TRACKER = 'usageTracker',
  ANALYTICS_PAGE = 'analyticsPage',

  SIGNUP_LINKS = 'signupLinks',
  SIGNUP_REFERRERS = 'signupReferrers',
  SIGNUP_CUSTOM_FIELDS = 'signupCustomFields',
  SIGNUP_KEYWORDS = 'signupKeywords',
  SIGNUP_NOTIFICATIONS = 'signupNotifications',

  EVENT_LINKS = 'eventLinks',
  EVENT_REFERRERS = 'eventReferrers',
  EVENT_CUSTOM_FIELDS = 'eventCustomFields',
  EVENT_KEYWORDS = 'eventCustomFields',
  EVENT_CAPACITY = 'eventCustomFields',
  EVENT_NOTIFICATIONS = 'eventNotifications',

  INBOX_MESSAGE_HISTORY = 'inboxMessageHistory'
}

export type ClickedUpsellProductAnalyticsLocation =
  `${ClickedUpsellProductAnalyticsLocations}`;

export class IClickedUpsellProductAnalyticsProperties {
  type!: ClickedUpsellProductAnalyticsType;
  location!: ClickedUpsellProductAnalyticsLocation;
  cta!: string;
}

export class IClickedElementProductAnalyticsProperties {
  elementType!: ClickedElementProductAnalyticsElementType;
  elementCTA!: ClickedElementProductAnalyticsElementCTA;
}

export enum ClickedElementProductAnalyticsElementTypeEnum {
  BUTTON = 'button',
  CARD = 'card',
  ADMIN_NOTIFICATION = 'adminNotification'
}

export type ClickedElementProductAnalyticsElementType =
  `${ClickedElementProductAnalyticsElementTypeEnum}`;

export enum ClickedElementProductAnalyticsElementCTAEnum {
  HOST_EVENT = 'hostAnEvent',
  CREATE_SIGNUP = 'createSignup',
  SEND_TEXT_EMAIL = 'sendTextOrEmail',
  TALK_TO_EXPERT = 'talkToMarketingExpert',
  SKIPPED_SAMPLE_REGISTRATION = 'skippedSampleRegistration',
  COMPLETED_SAMPLE_REGISTRATION = 'completedSampleRegistration',
  OPENED_LAUNCHER = 'openedLauncher',
  DISMISSED_LAUNCHER = 'dismissedLauncher',
  EDIT_LINK_IN_BIO = 'editLinkInBio',
  EDIT_PROFILE = 'editProfile',
  SEND_SMS = 'sendSMS',
  CREATE_EVENT = 'createEvent',
  COPY_NORBY_LINK = 'copyNorbyLink',
  COPY_NORBY_NUMBER = 'copyNorbyNumber',
  COPY_REFERRAL_LINK = 'copyReferralLink',
  KNOWLEDGE_BASE = 'knowledgeBase',
  ASK_A_QUESTION = 'askAQuestion',
  NOTIFICATION_ACTION = 'notificationAction',
  DOWNGRADE_TO_FREE = 'downgradeToFree',
  DOWNGRADE_TO_STARTER = 'downgradeToStarter',
  DOWNGRADE_TO_BASIC = 'downgradeToBasic',
  UPGRADE_TO_BASIC = 'upgradeToBasic',
  UPGRADE_TO_STARTER = 'upgradeToStarter',
  UPGRADE_TO_UNLIMITED = 'upgradeToUnlimited',
  SKIPPED_ONBOARDING_PROFILE = 'skippedOnboardingProfile',
  SKIPPED_ONBOARDING_THEME = 'skippedOnboardingTheme',
  SKIPPED_ONBOARDING_LINK_BLOCK = 'skippedOnboardingLinkBlock',
  SKIPPED_ONBOARDING_SIGNUP_BLOCK = 'skippedOnboardingSignupBlock',
  SKIPPED_ONBOARDING_EVENT_BLOCK = 'skippedOnboardingEventBlock',
  SKIPPED_ONBOARDING_IMPORT_CONTACTS = 'skippedOnboardingImportContacts',
  CLAIM_PHONE_NUMBER = 'claimPhoneNumber',
  SUBMIT_10DLC_USER_INFO = 'submit10DLCUserInfo',
  SUBMIT_10DLC_BUSINESS_INFO = 'submit10DLCBusinessInfo',
  SUBMIT_10DLC_USE_CASE = 'submit10DLCUseCase',
  SUBMIT_10DLC_APPLICATION = 'submit10DLCApplication',
  SUBMIT_TOLLFREE_USER_INFO = 'submitTollFreeUserInfo',
  SUBMIT_TOLLFREE_BUSINESS_INFO = 'submitTollFreeBusinessInfo',
  SUBMIT_TOLLFREE_USE_CASE = 'submitTollFreeUseCase',
  SUBMIT_TOLLFREE_APPLICATION = 'submitTollFreeApplication'
}

export type ClickedElementProductAnalyticsElementCTA =
  `${ClickedElementProductAnalyticsElementCTAEnum}`;

export enum UserSearchedProductAnalyticsSearchTypes {
  CONTENT = 'content',
  MESSAGES = 'messages',
  CONTACTS = 'contacts',
  EVERYTHING = 'everything'
}

export type UserSearchedProductAnalyticsSearchType =
  `${UserSearchedProductAnalyticsSearchTypes}`;

export class IUserSearchedProductAnalyticsProperties {
  searchType!: UserSearchedProductAnalyticsSearchType;
  duration!: number;
  searchResultSelected!: boolean;
}

export class IDuplicatedContentProductAnalyticsProperties {
  entityType!: EntityType;
  entityId!: string;
}

export enum EntityActionTypes {
  EDIT = 'edit',
  DELETE = 'delete',
  VIEW_ANALYTICS = 'viewAnalytics'
}

export type EntityActionType = `${EntityActionTypes}`;

export class IClickedEntityProductAnalyticsProperties {
  action: EntityActionType;
  entityType!: EntityType;
  entityId!: string;
}

export enum UpdatedProfileProductAnalyticsLocations {
  PROFILE_MENU = 'profileMenu',
  ONBOARDING = 'onboarding'
}

export type UpdatedProfileProductAnalyticsLocation =
  `${UpdatedProfileProductAnalyticsLocations}`;

export class IUpdatedProfileProductAnalyticsProperties {
  location!: UpdatedProfileProductAnalyticsLocation;
}

export enum AppliedThemeProductAnalyticsLocations {
  APPEARANCE = 'appearance',
  ONBOARDING = 'onboarding'
}

export type AppliedThemeProductAnalyticsLocation =
  `${AppliedThemeProductAnalyticsLocations}`;

export class IAppliedThemeProductAnalyticsProperties {
  location!: AppliedThemeProductAnalyticsLocation;
  themeName!: string;
  themeId!: string;
}

export enum UpdatedThemeProductAnalyticsLocations {
  APPEARANCE = 'appearance'
}

export type UpdatedThemeProductAnalyticsLocation =
  `${UpdatedThemeProductAnalyticsLocations}`;

export class IUpdatedThemeProductAnalyticsProperties {
  location!: UpdatedThemeProductAnalyticsLocation;
}

export class ISubmittedDowngradingReasonProductAnalyticsProperties
  implements SubscriptionChangeReason
{
  reason!: DowngradeReasonType;
  otherReason?: string;
}

export class IAbortDowngradeProductAnalyticsProperties {
  stage: DowngradeStages;
}

export class DowngradeFollowUpResponseBase {
  type!: DowngradeReasonType;
}

export class IPainPointFollowUpResponse extends DowngradeFollowUpResponseBase {
  type: DowngradeReason.PAIN_POINTS;
  painPoints: PainPointType[];
  otherReason?: string;
}

export type FeatureResponse = {
  reason: 'missingFunctionality' | 'deeperFunctionality' | 'other';
  elaboration?: string;
};

export class IFeaturesFollowUpResponse extends DowngradeFollowUpResponseBase {
  type: DowngradeReason.LACKING_FEATURES;
  featureResponses: FeatureResponse[];
}

export class IDifferentSolutionFollowUpResponse extends DowngradeFollowUpResponseBase {
  type: DowngradeReason.DIFFERENT_SOLUTION;
  competitor: string;
  otherSolutions: OtherSolutions[];
  otherElaboration?: string;
}

export type DowngradeFollowUpResponse =
  | IPainPointFollowUpResponse
  | IFeaturesFollowUpResponse
  | IDifferentSolutionFollowUpResponse;

export class ISubmittedDowngradeFollowUpProductAnalyticsProperties {
  followUpResponse: DowngradeFollowUpResponse;
}

export type ProductAnalyticsProperties =
  | IClickedUpsellProductAnalyticsProperties
  | IUserSearchedProductAnalyticsProperties
  | IDuplicatedContentProductAnalyticsProperties
  | IClickedEntityProductAnalyticsProperties
  | IClickedElementProductAnalyticsProperties
  | IUpdatedProfileProductAnalyticsProperties
  | IAppliedThemeProductAnalyticsProperties
  | IUpdatedThemeProductAnalyticsProperties
  | ISubmittedDowngradingReasonProductAnalyticsProperties
  | IAbortDowngradeProductAnalyticsProperties
  | ISubmittedDowngradeFollowUpProductAnalyticsProperties;

// Event Payloads
export interface IProductAnalyticsEventBase {
  timestamp: Date;
  event: ProductAnalyticsEventType;
  properties: ProductAnalyticsProperties;
}

export class ProductAnalyticsEventBase implements IProductAnalyticsEventBase {
  timestamp!: Date;
  event!: ProductAnalyticsEventType;
  properties!: ProductAnalyticsProperties;
}

export class ClickedUpsellProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.CLICKED_UPSELL;
  properties!: IClickedUpsellProductAnalyticsProperties;
}

export class UserSearchedProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.USER_SEARCHED;
  properties!: IUserSearchedProductAnalyticsProperties;
}

export class ClickedElementProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.CLICKED_ELEMENT;
  properties!: IClickedElementProductAnalyticsProperties;
}

export class DuplicatedContentProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.DUPLICATED_ENTITY;
  properties!: IDuplicatedContentProductAnalyticsProperties;
}

export class ClickedEntityProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.CLICKED_ENTITY;
  properties!: IClickedEntityProductAnalyticsProperties;
}

export class UpdatedProfileProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.UPDATED_PROFILE;
  properties!: IUpdatedProfileProductAnalyticsProperties;
}

export class CompletedProductizedOnboardingProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.COMPLETED_PRODUCTIZED_ONBOARDING;
}

export class UpdatedThemeProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.UPDATED_THEME;
}

export class AppliedThemeProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.APPLIED_THEME;
}

export class SubmittedDowngradingReasonProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.SUBMITTED_DOWNGRADE_REASON;
}

export class AbortDowngradeProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.ABORT_DOWNGRADE;
}

export class SubmittedDowngradingFollowUpProductAnalyticsEvent extends ProductAnalyticsEventBase {
  event!: ProductAnalyticsEventTypes.SUBMITTED_DOWNGRADE_FOLLOW_UP;
}

export type ProductAnalyticsEvent =
  | ClickedUpsellProductAnalyticsEvent
  | UserSearchedProductAnalyticsEvent
  | DuplicatedContentProductAnalyticsEvent
  | ClickedEntityProductAnalyticsEvent
  | ClickedElementProductAnalyticsEvent
  | UpdatedProfileProductAnalyticsEvent
  | CompletedProductizedOnboardingProductAnalyticsEvent
  | UpdatedThemeProductAnalyticsEvent
  | AppliedThemeProductAnalyticsEvent
  | SubmittedDowngradingReasonProductAnalyticsEvent
  | AbortDowngradeProductAnalyticsEvent
  | SubmittedDowngradingFollowUpProductAnalyticsEvent;

export class TrackProductAnalytics {
  domReferrer?: string;
  @Type(() => ProductAnalyticsEventBase, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'event',
      subTypes: [
        {
          value: ClickedUpsellProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.CLICKED_UPSELL
        },
        {
          value: UserSearchedProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.USER_SEARCHED
        },
        {
          value: DuplicatedContentProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.DUPLICATED_ENTITY
        },
        {
          value: ClickedEntityProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.CLICKED_ENTITY
        },
        {
          value: ClickedElementProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.CLICKED_ELEMENT
        },
        {
          value: UpdatedProfileProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.UPDATED_PROFILE
        },
        {
          value: CompletedProductizedOnboardingProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.COMPLETED_PRODUCTIZED_ONBOARDING
        },
        {
          value: UpdatedThemeProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.UPDATED_THEME
        },
        {
          value: AppliedThemeProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.APPLIED_THEME
        },
        {
          value: SubmittedDowngradingReasonProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.SUBMITTED_DOWNGRADE_REASON
        },
        {
          value: AbortDowngradeProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.ABORT_DOWNGRADE
        },
        {
          value: SubmittedDowngradingFollowUpProductAnalyticsEvent,
          name: ProductAnalyticsEventTypes.SUBMITTED_DOWNGRADE_FOLLOW_UP
        }
      ]
    }
  })
  data!: ProductAnalyticsEvent;
}
