<!-- WITH CARD -->
<root-card
  *ngIf="!imageBlock?.hideCard"
  [bodyStyle]="{ padding: '0px' }"
  class="overflow-hidden"
>
  <ng-container
    [ngTemplateOutlet]="
      imageBlock?.images?.length == 1 ? singleImageTemplate : carouselTemplate
    "
  ></ng-container>
</root-card>

<!-- WITHOUT CARD -->
<div *ngIf="imageBlock?.hideCard" class="image-wrapper">
  <ng-container
    [ngTemplateOutlet]="
      imageBlock?.images?.length == 1 ? singleImageTemplate : carouselTemplate
    "
  ></ng-container>
</div>

<!-- TPL FOR SINGLE IMAGE BLOCK -->
<ng-template #singleImageTemplate>
  <lib-soft-image-view
    backgroundColor="transparent"
    [imageUrl]="imageUrl"
    [image]="image"
    [imgixImage]="imgixImage"
    [alt]="altText"
  >
  </lib-soft-image-view>
</ng-template>

<!-- TPL FOR MULTIPLE IMAGE CAROUSEL -->
<ng-template #carouselTemplate>
  <root-carousel [autoPlay]="true" [autoPlaySpeed]="6000">
    <div carousel-content *ngFor="let image of imageBlock.images; index as i">
      <lib-soft-image-view
        backgroundColor="transparent"
        [imageUrl]="image.url"
        [imgixImage]="
          imageBlock.responsiveImages?.length > i
            ? imageBlock.responsiveImages[i]
            : null
        "
        [alt]="altText"
      >
      </lib-soft-image-view>
    </div>
  </root-carousel>
</ng-template>
