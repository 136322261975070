import { Injectable } from '@angular/core';
import {
  Firestore,
  doc,
  docData,
  getDoc,
  DocumentReference
} from '@angular/fire/firestore';

// 3rd party
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

// App
import { DeviceService } from '../device/device.service';
import { FIREBASE_COLLECTIONS, ISlug, ISlugMetadata } from 'models';

@Injectable({
  providedIn: 'root'
})
export class SlugService {
  constructor(
    private _firestore: Firestore,
    private _device: DeviceService
  ) {}

  getCurrentSlug$(): Observable<ISlug> {
    return this._device.currentSlug$.pipe(
      switchMap((slug) =>
        docData<ISlug>(
          doc(
            this._firestore,
            FIREBASE_COLLECTIONS.slugs.all,
            slug
          ) as DocumentReference<ISlug>
        )
      ),
      map((slug) => ISlug.fromObject(slug))
    );
  }

  async getCurrentSlug(): Promise<ISlug> {
    return this.getSlug(this._device.currentSlug);
  }

  async getCurrentSlugMetadata(): Promise<ISlugMetadata> {
    try {
      const slug = this._device.currentSlug;
      const ref = doc(
        this._firestore,
        FIREBASE_COLLECTIONS.slugs.metadata,
        slug
      );
      const snapshot = await getDoc(ref);
      return snapshot.exists()
        ? plainToClass(ISlugMetadata, snapshot.data())
        : null;
    } catch (e) {
      return null;
    }
  }

  async getSlug(slug: string) {
    try {
      const ref = doc(this._firestore, FIREBASE_COLLECTIONS.slugs.all, slug);
      const snapshot = await getDoc(ref);
      return snapshot.exists()
        ? ISlug.fromObject(snapshot.data() as any)
        : null;
    } catch (e) {
      return null;
    }
  }
}
