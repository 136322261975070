import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyIconModule } from './norby-icon.module';
import { CheckboxModule } from './checkbox.module';
import { NorbyImageRadioButtonComponent } from '../components/molecules/norby-image-radio-button';

@NgModule({
  declarations: [NorbyImageRadioButtonComponent],
  imports: [CommonModule, NorbyIconModule, CheckboxModule],
  exports: [NorbyImageRadioButtonComponent]
})
export class NorbyImageRadioButtonModule {}
