<div
  class="bg-white border border-neutral-200 whitespace-nowrap flex flex-row p-4 rounded mb-4 user-theme-alert"
  [ngClass]="{
    'bg-latte border-0': type === 'success',
    'bg-azure-800 border-0': type === 'info' || type === 'simple',
    'bg-red-light border-0': type === 'alert',
    'bg-terracota-400 border-0': type === 'error'
  }"
>
  <div
    class="mr-4"
    *ngIf="type !== 'simple' && iconName?.length"
    [ngClass]="{
      'text-neutral-900': type !== 'alert' && type !== 'error',
      'text-terracota': type === 'alert' || type === 'error'
    }"
  >
    <norby-icon [name]="iconName" size="large"></norby-icon>
  </div>
  <div class="flex flex-col text-neutral-900">
    <h3 class="font-medium" *ngIf="title">{{ title }}</h3>
    <div class="text-left whitespace-normal text-sm">
      <ng-content></ng-content>
    </div>
  </div>
</div>
