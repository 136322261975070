import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyInputComponent } from '../components/molecules/norby-input';
import { NorbyFormcontrolLabelModule } from './norby-formcontrol-label.module';
import { NorbyHelpertextModule } from './norby-helpertext.module';
import { TooltipModule } from './tooltip.module';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyFormcontrolLabelModule,
    NorbyHelpertextModule,
    NorbyIconModule,
    TooltipModule
  ],
  exports: [NorbyInputComponent]
})
export class NorbyInputModule {}
