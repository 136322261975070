import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageBlockViewComponent } from '../views/image-block-view';
import { SoftImageViewModule } from './soft-image-view.module';
import { CardModule } from './card.module';
import { CarouselModule } from './carousel.module';

@NgModule({
  declarations: [ImageBlockViewComponent],
  imports: [CommonModule, SoftImageViewModule, CardModule, CarouselModule],
  exports: [ImageBlockViewComponent]
})
export class ImageBlockViewModule {}
