import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyButtonComponent } from '../components/atoms/norby-button';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyButtonComponent],
  imports: [CommonModule, NorbyIconModule],
  exports: [NorbyButtonComponent]
})
export class NorbyButtonModule {}
