<button
  type="button"
  *ngIf="buttonType === 'primary'"
  class="ant-btn-animation flex items-center justify-center rounded-sm px-5 py-2 border flex-1 leading-5 whitespace-nowrap button-clicked-animation h-9"
  [ngClass]="{
    'border-neutral-600 bg-neutral-200 text-neutral-600 cursor-not-allowed':
      isDisabled || isLoading,
    'border-neutral-900 bg-neutral-900 text-white active:bg-neutral-900 active:border-neutral-900 focus:bg-dark-navy focus:border-dark-navy focus:shadow-btn-glow hover:bg-neutral-800 hover:border-neutral-800':
      !isDisabled && !isLoading
  }"
  (click)="handleButtonClick($event)"
  [disabled]="isDisabled || isLoading"
  [@buttonClicked]="animationState"
>
  <div
    *ngIf="isLoading"
    class="animate-spin flex justify-center items-center max-h-4"
  >
    <norby-icon name="loader"></norby-icon>
  </div>
  {{ text }}
</button>

<button
  type="button"
  *ngIf="buttonType === 'secondary'"
  class="ant-btn-animation flex items-center justify-center rounded-sm px-5 py-2 border flex-1 leading-5 whitespace-nowrap button-clicked-animation relative h-9"
  [ngClass]="{
    'border-neutral-600 bg-neutral-200 text-neutral-600 cursor-not-allowed':
      isDisabled || isLoading,
    'border-neutral-900 bg-white text-neutral-900 hover:bg-neutral-700 hover:border-neutral-700 hover:text-white focus:shadow-btn-glow':
      !isDisabled && !isLoading
  }"
  (click)="handleButtonClick($event)"
  [disabled]="isDisabled || isLoading"
  [@buttonClicked]="animationState"
>
  <div
    *ngIf="isLoading"
    class="animate-spin flex justify-center items-center max-h-4"
  >
    <norby-icon name="loader"></norby-icon>
  </div>
  {{ text }}
</button>

<button
  type="button"
  *ngIf="buttonType === 'minimal'"
  class="ant-btn-animation flex items-center justify-center rounded-sm px-5 py-2 border border-white bg-white flex-1 leading-5 whitespace-nowrap text-mustard h-9"
  [ngClass]="{
    'cursor-not-allowed text-mustard-600': isDisabled || isLoading,
    'focus:bg-mustard-400 focus:shadow-btn-glow hover:bg-mustard-200':
      !isDisabled && !isLoading
  }"
  (click)="handleButtonClick($event)"
  [disabled]="isDisabled || isLoading"
>
  <div
    *ngIf="isLoading"
    class="animate-spin flex justify-center items-center max-h-4"
  >
    <norby-icon name="loader"></norby-icon>
  </div>
  {{ text }}
</button>

<button
  type="button"
  *ngIf="buttonType === 'feature'"
  class="ant-btn-animation flex items-center justify-center px-17 py-4.5 rounded-lg border-2 flex-1 leading-5 whitespace-nowrap underline"
  [ngClass]="{
    'border-neutral-600 bg-neutral-200 text-neutral-600 cursor-not-allowed':
      isDisabled || isLoading,
    'border-neutral-900 bg-white text-neutral-900 active:bg-neutral-800 active:border-white active:text-white focus:bg-white focus:border-neutral-900 focus:text-neutral-900 hover:text-white hover:bg-neutral-700 hover:border-neutral-700':
      !isDisabled && !isLoading
  }"
  (click)="handleButtonClick($event)"
  [disabled]="isDisabled || isLoading"
>
  <div
    *ngIf="isLoading"
    class="animate-spin flex justify-center items-center max-h-4 text-neutral-900"
  >
    <norby-icon name="loader"></norby-icon>
  </div>
  {{ text }}
</button>
