<div class="relative">
  <img
    *ngIf="imageCopy?.url"
    [src]="imageCopy?.url"
    class="object-cover w-24 h-24 rounded"
  />
  <div
    *ngIf="!imageCopy?.url"
    class="w-24 h-24 rounded bg-neutral-300 animate-pulse"
  ></div>
  <div
    class="absolute top-2 left-16 z-5 bg-white rounded-full cursor-pointer"
    nz-popover
    nzPopoverTrigger="click"
    [nzPopoverContent]="contentTemplate"
    nzPopoverOverlayClassName="popover-flush"
  >
    <norby-icon name="edit" size="small" class="text-neutral-700"> </norby-icon>
  </div>
</div>

<ng-template #contentTemplate>
  <norby-image-edit-dialog
    [image]="imageCopy"
    (onImageChange)="handleImageChange($event)"
    (onImageDelete)="handleImageDelete($event)"
    [isDisabled]="isDisabled"
    [isAltTextDisabled]="isAltTextDisabled"
  >
  </norby-image-edit-dialog>
</ng-template>
