import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { fromEvent, Observable, Subscriber } from 'rxjs';

import { CarouselContentDirective } from '../../directives/carousel-content.directive';
import { BaseComponent } from '../../models/base-component';
import { CarouselBaseStrategy } from './strategies/base-strategy';
import { CarouselOpacityStrategy } from './strategies/opacity-strategy';
import { FromToInterface, CarouselDotPosition } from './types';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'root-carousel',
  preserveWhitespaces: false,
  styleUrls: ['./carousel.component.less'],
  templateUrl: './carousel.component.html'
})
export class CarouselComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy, OnChanges, OnInit
{
  @ContentChildren(CarouselContentDirective)
  carouselContents!: QueryList<CarouselContentDirective>;

  @ViewChild('slickList', { static: true }) slickList!: ElementRef<HTMLElement>;
  @ViewChild('slickTrack', { static: true })
  slickTrack!: ElementRef<HTMLElement>;

  @Input() dotRender?: TemplateRef<{ $implicit: number }>;
  @Input() autoPlay: boolean = false;
  @Input() dots: boolean = true;
  @Input() autoPlaySpeed: number = 3000;
  @Input() transitionSpeed = 500;

  @Input()
  set dotPosition(value: CarouselDotPosition) {
    this._dotPosition = value;
    this.vertical = value === 'left' || value === 'right';
  }

  get dotPosition(): CarouselDotPosition {
    return this._dotPosition;
  }

  private _dotPosition: CarouselDotPosition = 'bottom';

  @Output() readonly beforeChange = new EventEmitter<FromToInterface>();
  @Output() readonly afterChange = new EventEmitter<number>();

  activeIndex = 0;
  el: HTMLElement;
  slickListEl!: HTMLElement;
  slickTrackEl!: HTMLElement;
  strategy?: CarouselBaseStrategy;
  vertical = false;
  transitionInProgress: ReturnType<typeof setTimeout> = null;

  private _isTransiting = false;
  private _touchstartX = 0;
  private _touchendX = 0;
  private _listenTouchStartFunc: () => void;
  private _listenTouchEndFunc: () => void;

  @HostBinding('class')
  elementClass =
    'block relative overflow-hidden w-full h-full box-border m-0 p-0 list-none';

  constructor(
    private _elementRef: ElementRef,
    public ngZone: NgZone,
    private _renderer: Renderer2,
    private _cdr: ChangeDetectorRef,
    private _platform: Platform,
    @Inject(PLATFORM_ID) private _platformId
  ) {
    super();
    this.el = this._elementRef.nativeElement;
    this._addSwipeEventListeners();
  }

  ngOnInit(): void {
    this.slickListEl = this.slickList!.nativeElement;
    this.slickTrackEl = this.slickTrack!.nativeElement;

    this.ngZone.runOutsideAngular(() => {
      fromEvent<KeyboardEvent>(this.el, 'keydown')
        .pipe(this.takeUntilDestroy)
        .subscribe((event) => {
          const { key } = event;

          if (key !== 'ArrowLeft' && key !== 'ArrowRight') {
            return;
          }

          event.preventDefault();

          this.ngZone.run(() => {
            if (key === 'ArrowLeft') {
              this._pre();
            } else {
              this._next();
            }
            this._cdr.markForCheck();
          });
        });
    });
  }

  ngAfterViewInit(): void {
    this.strategy = new CarouselOpacityStrategy(
      this,
      this._cdr,
      this._renderer,
      this._platform
    );
    this._layout();
    this._initResizeSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.autoPlay || !this.autoPlaySpeed) {
      this._clearScheduledTransition();
    } else {
      this._scheduleNextTransition();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearScheduledTransition();
    if (this.strategy) {
      this.strategy.dispose();
    }
    this._listenTouchStartFunc?.();
    this._listenTouchEndFunc?.();
  }

  handleLiClick = (index: number): void => {
    this._goTo(index);
  };

  private _next(): void {
    this._goTo(this.activeIndex + 1);
  }

  private _pre(): void {
    this._goTo(this.activeIndex - 1);
  }

  private _goTo(index: number): void {
    if (
      this.carouselContents &&
      this.carouselContents.length &&
      !this._isTransiting
    ) {
      const length = this.carouselContents.length;
      const from = this.activeIndex;
      const to = (index + length) % length;
      this._isTransiting = true;
      this.beforeChange.emit({ from, to });
      this.strategy!.switch(this.activeIndex, index).subscribe(() => {
        this._scheduleNextTransition();
        this.afterChange.emit(to);
        this._isTransiting = false;
      });
      this._markContentActive(to);
      this._cdr.markForCheck();
    }
  }

  private _scheduleNextTransition(): void {
    this._clearScheduledTransition();
    if (this.autoPlay && this.autoPlaySpeed > 0 && this._platform.isBrowser) {
      this.transitionInProgress = setTimeout(() => {
        this._goTo(this.activeIndex + 1);
      }, this.autoPlaySpeed);
    }
  }

  private _clearScheduledTransition(): void {
    if (this.transitionInProgress) {
      clearTimeout(this.transitionInProgress);
      this.transitionInProgress = null;
    }
  }

  private _markContentActive(index: number): void {
    this.activeIndex = index;

    if (this.carouselContents) {
      this.carouselContents.forEach((slide, i) => {
        slide.isActive = index === i;
      });
    }

    this._cdr.markForCheck();
  }

  private _initResizeSubscription(): void {
    if (!isPlatformBrowser(this._platformId)) {
      return;
    }

    new Observable((subscriber: Subscriber<ResizeObserverEntry[]>) => {
      const resizeObserver = new ResizeObserver(
        (entries: ResizeObserverEntry[]) => subscriber.next(entries)
      );
      resizeObserver.observe(this.el);
      return () => resizeObserver.disconnect();
    })
      .pipe(this.takeUntilDestroy)
      .subscribe((res) => {
        if (res[0]?.contentBoxSize[0]?.inlineSize) {
          this._layout();
        }
      });
  }

  private _addSwipeEventListeners(): void {
    if (!isPlatformBrowser(this._platformId)) {
      return;
    }

    this._listenTouchStartFunc = this._renderer.listen(
      this.el,
      'touchstart',
      (event) => (this._touchstartX = event.changedTouches[0].screenX)
    );
    this._listenTouchEndFunc = this._renderer.listen(
      this.el,
      'touchend',
      (event) => {
        this._touchendX = event.changedTouches[0].screenX;
        this._handleSwipe();
      }
    );
  }

  private _handleSwipe(): void {
    if (!isPlatformBrowser(this._platformId)) {
      return;
    }

    if (this._touchendX < this._touchstartX) {
      this._next();
    }
    if (this._touchendX > this._touchstartX) {
      this._pre();
    }
  }

  private _layout(): void {
    if (!isPlatformBrowser(this._platformId)) {
      return;
    }

    if (this.strategy) {
      this.strategy.withCarouselContents(this.carouselContents);
    }
  }
}
