import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from '../root-components/tooltip/tooltip.component';
import { TooltipDirective } from '../directives';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  imports: [CommonModule],
  exports: [TooltipComponent, TooltipDirective]
})
export class TooltipModule {}
