import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VimeoEmbedViewComponent } from '../views';
import { AvatarModule } from './avatar.module';
import { CardModule } from './card.module';
import { IconModule } from './icon.module';

@NgModule({
  declarations: [VimeoEmbedViewComponent],
  imports: [CommonModule, CardModule, AvatarModule, IconModule],
  exports: [VimeoEmbedViewComponent]
})
export class VimeoEmbedViewModule {}
