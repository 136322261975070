<div class="relative">
  <div
    *ngIf="isLoading"
    class="absolute w-full h-full max-h-96 min-h-[32px] flex justify-center items-center"
  >
    <div class="animate-spin">
      <root-icon name="loader"></root-icon>
    </div>
  </div>
  <div [ngClass]="{ 'opacity-0 pointer-events-none': isLoading }">
    <ng-content></ng-content>
  </div>
</div>
