import { Component } from '@angular/core';
import { rootLoader } from '../../../icons';
import { IconService } from '../../services';

@Component({
  selector: 'lib-global-loading-indicator-view',
  templateUrl: './global-loading-indicator-view.component.html',
  styleUrls: ['./global-loading-indicator-view.component.less']
})
export class GlobalLoadingIndicatorViewComponent {
  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([rootLoader]);
  }
}
