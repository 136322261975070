import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { ApiSurfaces, IApiService } from 'models';

@Injectable({
  providedIn: 'root'
})
export class MockApiService implements IApiService {
  constructor() {}

  setCurrentUser(user: User) {
    return null;
  }

  get currentUser() {
    return null;
  }

  constructApiUrl(uri: string, service: ApiSurfaces) {
    return '';
  }

  async post<T>(
    service: ApiSurfaces,
    route: string,
    body?: any,
    additionalHeaders?: any
  ) {
    return null;
  }

  async put<T>(service: ApiSurfaces, route: string, body?: any) {
    return null;
  }

  async patch<T>(service: ApiSurfaces, route: string, body?: any) {
    return null;
  }

  async delete<T>(service: ApiSurfaces, route: string, params?: any) {
    return null;
  }

  async get<T>(service: ApiSurfaces, route: string, params?: any) {
    return null;
  }

  get$<T>(service: ApiSurfaces, route: string, params?: any) {
    return null;
  }
}
