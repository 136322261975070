import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RsvpConfirmationComponent } from './rsvp-confirmation.component';
import { RsvpConfirmationViewModule } from 'uikit';

@NgModule({
  declarations: [RsvpConfirmationComponent],
  imports: [CommonModule, RsvpConfirmationViewModule],
  exports: [RsvpConfirmationComponent]
})
export class RsvpConfirmationModule {}
