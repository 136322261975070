export const URL_PATTERN =
  /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
export const URL_PATTERN_FOR_LINKS =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,24}(:[0-9]{1,5})?(\/.*)?$/;
export const US_CANADA_PHONE_NUM_REGEX_WITHOUT_COUNTRY_CODE =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const US_CANADA_PHONE_NUM_REGEX_WITH_OPTIONAL_COUNTRY_CODE =
  /^((\+1)?[\s-]?)?\(?[2-9]\d\d\)?[\s-]?[2-9]\d\d[\s-]?\d\d\d\d$/;
export const TEN_DIGIT_PHONE_NUMBER = /^\d{8,14}$/;
export const EMAIL_PATTERN =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

/**
 * REF: https://support.twilio.com/hc/en-us/articles/223181348-Alphanumeric-Sender-ID-for-Twilio-Programmable-SMS
 */
export const ALPHA_SENDER_REGEX = /^(?=.*[a-zA-Z \-])([a-zA-Z0-9 \-]{1,11})$/gm; ///^[a-zA-Z0-9 ]+$/;

export const SLUG_PATTERN = /^\w{1,16}$/;

export const YOUTUBE_URL_PATTERN =
  /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

export const YOUTUBE_SELECT_VIDEO_ID_REGEX = /v=([a-zA-Z0-9_-]+)/;

export const ALPHANUMERIC = /^[a-zA-Z0-9\s]+$/;
