// 3rd party
import {
  Exclude,
  instanceToInstance,
  plainToClass,
  Transform,
  Type
} from 'class-transformer';

// Libs
import { hashString, stripHtml } from '../../tools';
import {
  Content,
  AvatarClickThroughUrlType,
  SignupType,
  ContentSignup
} from '../content';
import { IImage, IResponsiveImage } from '../IImage';
import { IThemeDTO, Theme } from '../ISlug';
import { SocialIconsSettings, PageBlockType } from './types';
import { CalendlyPageBlockDTOEmbedStyle } from './calendly-page-block-embed-style';

// Page blocks should always be treated as immutable
// Hash is generated lazily on demand and then never updated

export interface IPageBlock {
  blockType: PageBlockType;
  theme?: IThemeDTO;
}

export type HeaderStyle = 'creator' | 'brand';

export type MenuAlignment = 'left' | 'center' | 'right';

// Base class for all landing page blocks
export abstract class PageBlock implements IPageBlock {
  @Exclude()
  private _hash: number;

  readonly blockType: PageBlockType;

  @Type(() => Theme)
  readonly theme: Theme;

  static clone(block: PageBlock) {
    return instanceToInstance(block);
  }

  static fromObject<T extends IPageBlock>(object: T) {
    switch (object?.blockType) {
      case 'content':
        return plainToClass(ContentPageBlock, object);
      case 'profile':
        return plainToClass(ProfilePageBlock, object);
      case 'text':
        return plainToClass(RichTextPageBlock, object);
      case 'socialIcons':
        return plainToClass(SocialIconsPageBlock, object);
      case 'image':
        return plainToClass(ImagePageBlock, object);
      case 'spacer':
        return plainToClass(SpacerPageBlock, object);
      case 'upcomingEvents':
        return plainToClass(UpcomingEventsPageBlock, object);
      case 'youtube':
        return plainToClass(YoutubePageBlock, object);
      case 'spotify':
        return plainToClass(SpotifyPageBlock, object);
      case 'calendly':
        return plainToClass(CalendlyPageBlock, object);

      case 'vimeo':
        return plainToClass(VimeoPageBlock, object);
      case 'twitter':
        return plainToClass(TwitterPageBlock, object);
      case 'embed':
        return plainToClass(EmbedPageBlock, object);
      case 'newsletterSignup':
        return plainToClass(NewsletterSignupPageBlock, object);
      case 'header':
        return plainToClass(HeaderPageBlock, object);
      case 'instagram':
        return plainToClass(InstagramPageBlock, object);
      default:
        return null;
    }
  }

  // Subclasses must implement a stringify function so their
  // current state can be easily compared
  protected abstract _stringify(): string;

  // Hashes are generated lazily and cached on the object
  // Since they're never updated, PageBlocks are immutable
  get hash(): number {
    if (!this._hash) {
      const props = this._stringify();
      const theme = this.theme?.hash ?? 0;
      this._hash = hashString(`${theme}${props}`);
    }

    return this._hash;
  }

  // Icon for display in landing page editor
  abstract get icon(): string;

  // Title for display in landing page editor
  abstract get headline(): string;

  // Is block empty
  abstract get isEmpty(): boolean;
}

// Event, drop, link blocks
export class ContentPageBlock extends PageBlock {
  readonly blockType!: 'content';
  readonly contentId: string;
  readonly title: string;
  readonly description: string;
  readonly images: IImage[];
  readonly signupType?: SignupType;

  @Exclude({ toPlainOnly: true })
  readonly responsiveImages: IResponsiveImage[];

  @Transform((val) => Content.fromObject(val.value), { toClassOnly: true })
  @Exclude({ toPlainOnly: true })
  readonly content: Content;

  protected _stringify(): string {
    return `
        ${this.contentId}
        ${this.blockType}
        ${this.title}
        ${this.signupType}
        ${this.content?.title}
        ${this.content?.subtitle}
        ${this.content?.description}
        ${(this.content as ContentSignup)?.signupType}
        ${this.content?.images
          ?.map((image) => `${image?.url || ''}${image?.altText || ''}`)
          ?.join()}
    `;
  }

  get icon(): string {
    return this.content?.isLink
      ? 'link'
      : this.content?.isSignup
        ? 'edit'
        : this.content?.isEvent
          ? 'calendar'
          : null;
  }

  get headline(): string {
    const s = this.content?.contentType ?? 'content';
    const title = this.title || this.content?.title;
    return title
      ? title
      : this.content?.isLink
        ? 'Link'
        : this.content?.isSignup
          ? 'Signup'
          : this.content?.isEvent
            ? 'Event'
            : `${s[0].toUpperCase()}${s.substring(1)}`;
  }

  get hasDefaultTitle(): boolean {
    return !this.title;
  }

  get summary(): string {
    return this.description ?? this.content?.summary;
  }

  get isEmpty(): boolean {
    return !this.contentId;
  }
}

// Shows a simple email signup block with before / after states
export class NewsletterSignupPageBlock extends PageBlock {
  readonly blockType!: 'newsletterSignup';
  readonly title: string;
  readonly subtitle: string;
  readonly postSubmitTitle: string;
  readonly postSubmitMessage: string;
  readonly buttonLabel: string;
  readonly images: IImage[];
  readonly tags: string[];

  @Exclude({ toPlainOnly: true })
  readonly responsiveImages: IResponsiveImage[];

  get imageUrl(): string {
    return (
      this.responsiveImage?.large?.url ??
      this.responsiveImage?.medium?.url ??
      this.image?.url
    );
  }

  get imagesUrls(): string[] {
    return this.responsiveImages.map(
      (img, idx) =>
        img?.large?.url ??
        img?.medium?.url ??
        this.image[idx]?.url ??
        this.image?.url
    );
  }

  get image(): IImage {
    return (
      this.images?.[0] ?? (this.images?.length && { url: this.images[0]?.url })
    );
  }

  get responsiveImage(): IResponsiveImage {
    return (
      this.responsiveImages?.[0] ??
      (this.images?.length && {
        thumbnail: { url: this.images[0]?.url },
        small: { url: this.images[0]?.url },
        medium: { url: this.images[0]?.url },
        large: { url: this.images[0]?.url }
      })
    );
  }

  get icon(): string {
    return 'user-plus';
  }

  get headline(): string {
    return 'Newsletter signup';
  }

  get isEmpty(): boolean {
    return false;
  }

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.title}
        ${this.subtitle}
        ${this.postSubmitTitle}
        ${this.postSubmitMessage}
        ${this.buttonLabel}
        ${this.images?.reduce((p, k) => `${p}${k?.url}`, '')}
        ${this.tags?.join()}
      `;
  }
}

// Shows a list of upcoming events, optionally filtered
export class UpcomingEventsPageBlock extends PageBlock {
  readonly blockType!: 'upcomingEvents';
  readonly groupBy: 'default' | 'day' | 'week' | 'month' | null;
  readonly tag: string;
  readonly limit: number;
  readonly reverse: boolean;
  readonly startAt: Date;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.groupBy}
        ${this.tag}
        ${this.limit}
        ${this.reverse}
        ${this.startAt?.toString()}
      `;
  }

  get icon(): string {
    return 'server';
  }

  get isEmpty(): boolean {
    return false;
  }

  get headline(): string {
    const count = this.limit ?? 10;
    const order = this.reverse ? 'past' : 'upcoming';
    return `Up to ${count} ${order} event${count === 1 ? '' : 's'}`;
  }
}

// Shows a header with an avatar or logo, optionally with a shape mask
export class HeaderPageBlock extends PageBlock {
  readonly blockType!: 'header';
  readonly style: HeaderStyle;
  readonly avatarImage: IImage;
  readonly avatarClickThroughUrl: string;
  readonly avatarClickThroughUrlType?: AvatarClickThroughUrlType;
  readonly backgroundImage: IImage;
  readonly height: number;
  readonly backgroundColor: string;
  readonly maskUrl: string;
  readonly backgroundGradient: string;
  readonly hideCard?: boolean;
  readonly imageSize: number;
  readonly headerType: HeaderType;
  readonly menuOptions: MenuOption[];
  readonly menuAlignment: MenuAlignment;
  readonly hamburgerMenuColor: string;

  @Exclude({ toPlainOnly: true })
  readonly responsiveAvatarImage?: IResponsiveImage;

  @Exclude({ toPlainOnly: true })
  readonly responsiveBackgroundImage?: IResponsiveImage;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.style}
        ${this.avatarImage?.url}
        ${this.avatarImage?.altText}
        ${this.height}
        ${this.backgroundColor}
        ${this.backgroundGradient}
        ${this.backgroundImage?.url}
        ${this.maskUrl}
        ${this.avatarClickThroughUrlType}
        ${this.avatarClickThroughUrl}
        ${this.imageSize}
        ${this.headerType}
        ${this.menuOptions?.reduce(
          (p, k) => `${p}${k?.contentId}${k?.title}${k?.disabled}`,
          ''
        )}
        ${this.menuAlignment}
        ${this.hamburgerMenuColor}
      `;
  }

  get icon(): string {
    return 'home';
  }

  get isEmpty(): boolean {
    return false;
  }

  get headline(): string {
    const s = this.style ?? 'brand';
    return this.style === 'brand' ? 'Logo only header' : 'Avatar header';
  }
}

// Shows an embedded Youtube video
export class YoutubePageBlock extends PageBlock {
  readonly blockType!: 'youtube';
  readonly url: string;
  readonly width: number;
  readonly height: number;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.url}
        ${this.width}
        ${this.height}
      `;
  }

  get icon(): string {
    return 'youtube';
  }

  get headline(): string {
    return 'YouTube video';
  }

  get isEmpty(): boolean {
    return !this.url;
  }
}

// Shows an embedded vimeo video
export class VimeoPageBlock extends PageBlock {
  readonly blockType!: 'vimeo';
  readonly url: string;
  readonly width: number;
  readonly height: number;
  readonly embedCode: string;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.url}
        ${this.width}
        ${this.height}
        ${this.embedCode}
      `;
  }

  get icon(): string {
    return 'video';
  }

  get headline(): string {
    return 'Vimeo video';
  }

  get isEmpty(): boolean {
    return !this.url;
  }
}

// Shows an embedded Spotify player
export class SpotifyPageBlock extends PageBlock {
  readonly blockType!: 'spotify';
  readonly url: string;
  readonly width: number;
  readonly height: number;
  readonly hideColor: boolean;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.url}
        ${this.width}
        ${this.height}
        ${this.hideColor}
      `;
  }

  get icon(): string {
    return 'spotify';
  }

  get headline(): string {
    return 'Spotify embed';
  }

  get isEmpty(): boolean {
    return !this.url;
  }
}

// Shows an embedded Calendly
export class CalendlyPageBlock extends PageBlock {
  readonly blockType!: 'calendly';
  readonly url: string;
  readonly embedCode: string;
  readonly bookingPageBackgroundColor?: string;
  readonly bookingPageTextColor?: string;
  readonly bookingPageButtonLinkColor?: string;
  readonly hidePageDetails?: boolean;
  readonly hideCookieBanner?: boolean;
  readonly embedStyle: CalendlyPageBlockDTOEmbedStyle;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.url}
        ${this.bookingPageBackgroundColor}
        ${this.bookingPageTextColor}
        ${this.bookingPageButtonLinkColor}
        ${this.hidePageDetails}
        ${this.hideCookieBanner}
      `;
  }

  get icon(): string {
    return 'calendly';
  }

  get headline(): string {
    return 'Calendly embed';
  }

  get isEmpty(): boolean {
    return !this.url;
  }
}

// Shows an embedded tweet
export class TwitterPageBlock extends PageBlock {
  readonly blockType!: 'twitter';
  readonly url: string;
  readonly embedCode: string;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.url}
        ${this.embedCode}
      `;
  }

  get icon(): string {
    return 'twitter';
  }

  get headline(): string {
    return 'Tweet';
  }

  get isEmpty(): boolean {
    return !this.url;
  }
}

// Shows an embedded Instagram post
export class InstagramPageBlock extends PageBlock {
  readonly blockType!: 'instagram';
  readonly url: string;
  readonly embedCode: string;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.url}
        ${this.embedCode}
      `;
  }

  get icon(): string {
    return 'instagram';
  }

  get headline(): string {
    return 'Instagram Post';
  }

  get isEmpty(): boolean {
    return !this.url;
  }
}

// Generic HTML embed block
export class EmbedPageBlock extends PageBlock {
  readonly blockType!: 'embed';
  readonly embedCode: string;

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.embedCode}
      `;
  }

  get icon(): string {
    return 'code';
  }

  get headline(): string {
    return 'Embedded HTML';
  }

  get isEmpty(): boolean {
    return !this.embedCode;
  }
}

// Shows a profile section with default values pulled from account
export class ProfilePageBlock extends PageBlock {
  readonly blockType!: 'profile';
  readonly showTitle: boolean;
  readonly showPronouns: boolean;
  readonly showBio: boolean;
  readonly showLocation: boolean;

  @Exclude()
  private _items: string[];

  protected _stringify(): string {
    return this.items?.join();
  }

  get items(): string[] {
    if (!this._items) {
      this._items = [
        ...(this.showTitle ? ['name'] : []),
        ...(this.showPronouns ? ['pronouns'] : []),
        ...(this.showBio ? ['bio'] : []),
        ...(this.showLocation ? ['location'] : [])
      ];
    }

    return this._items;
  }

  get icon(): string {
    return 'user';
  }

  get headline(): string {
    const items = this.items;
    return `Your ${items.length > 0 ? items.join(', ') : 'profile'}`;
  }

  get isEmpty(): boolean {
    return false;
  }
}

// Shows a rich text block
export class RichTextPageBlock extends PageBlock {
  readonly body: string;
  readonly blockType!: 'text';

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.body}
      `;
  }

  get icon(): string {
    return 'type';
  }

  get headline(): string {
    return stripHtml(this.body);
  }

  get isEmpty(): boolean {
    return !this.body;
  }
}

// Shows a user-defined subset of social icon links
export class SocialIconsPageBlock extends PageBlock {
  readonly blockType!: 'socialIcons';
  readonly settingsV2: SocialIconsSettings[];

  protected _stringify(): string {
    return `
      ${this.blockType}
      ${JSON.stringify(this.settingsV2)}
    `;
  }

  get icon(): string {
    return 'circle';
  }

  get headline(): string {
    return 'Social Icons';
  }

  get isEmpty(): boolean {
    return false;
  }
}

// Shows an image, optionally with a card background
export class ImagePageBlock extends PageBlock {
  readonly blockType!: 'image';
  readonly hideCard: boolean;
  readonly images: IImage[];

  @Exclude({ toPlainOnly: true })
  readonly responsiveImages: IResponsiveImage[];

  protected _stringify(): string {
    return `
        ${this.blockType}
        ${this.images
          ?.map((image) => `${image?.url || ''}${image?.altText || ''}`)
          ?.join()}
        ${this.hideCard}
      `;
  }

  get icon(): string {
    return 'image';
  }

  get headline(): string {
    const isSingular = this.images?.length === 1;
    return `${this.images?.length || 0} image${isSingular ? '' : 's'}`;
  }

  get isEmpty(): boolean {
    return !this.images || this.images?.length === 0;
  }
}

// Vertical spacer block
export class SpacerPageBlock extends PageBlock {
  readonly blockType!: 'spacer';
  readonly height: number;

  protected _stringify(): string {
    return `spacer${this.height}`;
  }

  get icon(): string {
    return 'square';
  }

  get headline(): string {
    return 'Spacer';
  }

  get isEmpty(): boolean {
    return false;
  }
}

export enum HeaderTypeEnum {
  IMAGE_AND_MENU = 'imageAndMenu',
  IMAGE_ONLY = 'imageOnly'
}

export type HeaderType = `${HeaderTypeEnum}`;

export class MenuOption {
  contentId: string;
  title: string;
  disabled: boolean;
}
