import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-image-radio-button',
  templateUrl: './norby-image-radio-button.component.html',
  styleUrls: ['./norby-image-radio-button.component.less']
})
export class NorbyImageRadioButtonComponent {
  @Input() imageUrl?: string;
  @Input() buttonLabel: string;
  @Input() buttonValue: string;
  @Input() selectedValue?: string;
  @Input() isDisabled?: boolean;
  @Input() nextButtonValue?: string;

  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleButtonClick(): void {
    this.buttonClicked.emit(this.buttonValue);
  }
}
