import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import { ContentRegisterable, IPromptResponse, IUserContent } from 'models';

@Component({
  selector: 'lib-content-registration-prompts',
  templateUrl: './content-registration-prompts.component.html',
  styleUrls: ['./content-registration-prompts.component.less']
})
export class ContentRegistrationPromptsComponent implements OnInit {
  @Input() content: ContentRegisterable;
  @Input() userContent: IUserContent = {};
  @Output() handleNext = new EventEmitter<IUserContent>();

  formGroup: UntypedFormGroup;
  isLoading: boolean;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group(
      this.content?.prompts?.reduce((prompts, prompt, index) => {
        return {
          ...prompts,
          [index]: ['', prompt?.required ? Validators.required : []]
        };
      }, {}) ?? {}
    );
  }

  handleDidCheckCheckbox(checkedBoxes: string[], promptIdx) {
    this.formGroup.get(`${promptIdx}`).setValue(checkedBoxes.join(', '));
  }

  async submitPrompts() {
    if (!this.formGroup.valid) return;
    this.isLoading = true;
    const prompts = this.content?.prompts ?? [];
    const responses = Object.values(this.formGroup.value) as string[];
    this.userContent.promptResponses = prompts.map<IPromptResponse>(
      (prompt, idx) => {
        return {
          prompt: prompt?.prompt,
          response: responses[idx]
        };
      }
    );

    this.isLoading = false;
    this.handleNext.emit(this.userContent);
  }
}
