import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';

// Libs
import {
  rootLock,
  rootCalendar,
  rootUnlock,
  rootXCircle
} from '../../../icons';
import { IconService, MockDeviceService } from '../../services';
import { ContentClick, ContentEvent, IUserContent } from 'models';

// 3rd party
import dayjs from 'dayjs';

@Component({
  selector: 'lib-event-card-view',
  templateUrl: './event-card-view.component.html',
  styleUrls: ['./event-card-view.component.less']
})
export class EventCardViewComponent implements OnChanges {
  @Output() cardClick = new EventEmitter<ContentClick>();
  @Input() event: ContentEvent;
  @Input() userEvent: IUserContent;
  @Input() isLoadingEvent = false;
  @Input() isLoadingUserContent = false;
  @Input() isDisabled = false;
  @Input() overrideTitle: string;
  registrationCloseDate: string;
  registrationCloseTime: string;

  constructor(
    private _device: MockDeviceService,
    private _iconService: IconService
  ) {
    this._iconService.registerIcons([
      rootLock,
      rootCalendar,
      rootUnlock,
      rootXCircle
    ]);
  }

  ngOnChanges(): void {
    this.registrationCloseDate = this.event?.registrationCloseDate
      ? dayjs(this.event.registrationCloseDate).format('MMM DD, YYYY')
      : null;
    this.registrationCloseTime = this.event?.registrationCloseDate
      ? dayjs(this.event?.registrationCloseDate).format('h:mm A')
      : null;
  }

  isCurrentDate(registrationCloseDate: string): boolean {
    return registrationCloseDate === dayjs().format('MMM DD, YYYY');
  }

  handleClick(event): void {
    if (this.isDisabled) {
      event.preventDefault();
    } else {
      this.cardClick.next({ content: this.event, event });
    }
  }

  get title(): string {
    return this.overrideTitle || this.event?.title;
  }

  get priceStr(): string {
    if (this.event?.tickets?.length > 0) {
      const price = this.event.tickets[0].price / 100;
      return `From $${price}`;
    }

    return null;
  }

  get href(): string {
    return `https://${this._device.currentSiteRootURL}/event/${this.event?.contentId}`;
  }
}
