import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropCardViewComponent } from '../views/drop-card-view';
import { DropMainButtonViewModule } from './drop-main-button-view.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { AlertModule } from './alert.module';
import { CardModule } from './card.module';
import { IconModule } from './icon.module';
import { SkeletonModule } from './skeleton.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { TagModule } from './tag.module';
import { DropFormPromptsViewModule } from './drop-form-prompts-view.module';
import { TooltipModule } from './tooltip.module';

@NgModule({
  declarations: [DropCardViewComponent],
  imports: [
    CommonModule,
    DropMainButtonViewModule,
    EventDropShareButtonViewModule,
    SoftImageViewModule,
    TagModule,
    IconModule,
    CardModule,
    AlertModule,
    SkeletonModule,
    DropFormPromptsViewModule,
    TooltipModule
  ],
  exports: [DropCardViewComponent]
})
export class DropCardViewModule {}
