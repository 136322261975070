<norby-select
  class="text-base"
  [value]="val"
  (onHandleChange)="handleValueChange($event)"
  [prompt]="prompt"
  [isDisabled]="isDisabled"
>
  <option *ngFor="let time of validTimes" [value]="time">{{ time }}</option>
</norby-select>
<norby-helpertext
  [errorText]="errorText"
  [helperText]="helperText"
></norby-helpertext>
