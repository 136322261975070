import { CountryCode } from 'libphonenumber-js';
import { Transform, Type } from 'class-transformer';
import dayjs from 'dayjs';

import {
  ProjectPeriod,
  ProjectTiers,
  ISubscriptionSchedule,
  Funnel
} from './billing';
import { transformTimestampToDate, transformTimestampToDay } from '../tools';
import { ChargeType, ISlugGeneralSettings } from './general';
import { Intent } from './intents';

export class IStripeSlugMetadata {
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  stripePaymentMethodIds?: string[];
  stripeDefaultPaymentMethodId?: string;
  stripePaymentMethods?: IStripePaymentMethod[];
  stripePriceMetadata: StripePriceItemMetadata;
}

export interface StripePriceItemMetadata {
  projectTier?: ProjectTiers;
  feeType?: 'base' | 'usage' | 'addon';
  projectPeriod?: ProjectPeriod;
  slug?: string;
  landingPageLimit?: string;
  teamMemberLimit?: string;
  smsBaseQuota?: string;
  mmsBaseQuota?: string;
  emailBaseQuota?: string;
  smsUnitAmountDecimal?: string;
  mmsUnitAmountDecimal?: string;
  emailUnitAmountDecimal?: string;
}

export class IStripePaymentMethod {
  id?: string;
  card: IStripeCard;
}

export class IStripeCard {
  brand?: string;
  last4?: string;
  exp_year?: number;
  exp_month?: number;
  country?: number;
}

export class IFacebookSlugMetadata {
  accessToken?: string;
  longLiveAccessToken?: string;
  instagramBusinessAccountIds?: string[];
  pageAccessTokens?: string[];
  pageIds?: string[];
  profile?: {
    displayName?: string;
  };
}

export class IBillingInfoAutomaticSurcharges {
  sms?: boolean;
  email?: boolean;
}

export class IBillingInfoUsage {
  sms?: number;
  mms?: number;
  email?: number;
  internationalSms?: number;
  inboundSms?: number;
  inboundMms?: number;
}

export class IBillingInfo {
  projectTier?: ProjectTiers;
  projectPeriod?: ProjectPeriod;
  automaticSurcharges?: IBillingInfoAutomaticSurcharges;
  usage?: IBillingInfoUsage;
  subscriptionDiscount?: {
    id: string;
    object: string;
    promotion_code: string;
    start: number;
    subscription: string;
    coupon?: {
      duration: string;
      name: string;
      percent_off: number;
      valid: boolean;
    };
  };
  address?: IBillingInfoAddress;
  funnel?: Funnel;

  subscriptionSchedules: ISubscriptionSchedule[];

  @Transform(transformTimestampToDate, { toClassOnly: true })
  invoicePaymentLastFailedAt?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  projectStartDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  billingPeriodStartDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  billingPeriodEndDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  usagePeriodStartDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  usagePeriodEndDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  subscriptionCancelAtDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  subscriptionCanceledAtDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  retentionPromoLastRedeemedAt?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  trialStartDate?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  trialEndDate?: Date;

  trialPeriod?: '2w' | 'month';
  trialUsageWarnings?: {
    [key in ChargeType]?: boolean;
  };

  usageLimitLastApproachedAt?: {
    [key in ChargeType]?: Date;
  };
  redeemedRetentionPromo?: boolean;
  redeemedUnlimitedPlanFirstYearPromo?: boolean;
}

export class IBillingInfoAddress {
  city!: string;
  countryCode!: CountryCode;
  addressLine1!: string;
  addressLine2?: string;
  postalCode!: string;
  state!: string;
  firstName!: string;
  lastName!: string;
}

export class ISlugMailingMetadata {
  replyTo?: string;
}

class ISlugMetadataFeaturePermission {
  permittedAt?: Date | null;
  permitted!: boolean;
}

export class ISlugMetadataFeaturePermissions {
  inbox?: ISlugMetadataFeaturePermission;
  instagramIntegration?: ISlugMetadataFeaturePermission;
  signupsFlowForm?: ISlugMetadataFeaturePermission;
  newsletterV2?: ISlugMetadataFeaturePermission;
  chatRecommendations?: ISlugMetadataFeaturePermission;
  langchainPoweredChat?: ISlugMetadataFeaturePermission;
}

export enum BrandRegistrationStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  COMPLETE = 'complete'
}

export type BrandRegistrationStatusType = `${BrandRegistrationStatus}`;

export type BrandRegistration = {
  status: BrandRegistrationStatusType;
  failureMessage?: string;
};

export enum TwilioBrandTypeEnum {
  STANDARD = 'STANDARD',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP'
}

export type TwilioBrandType = `${TwilioBrandTypeEnum}`;

export enum TollFreeRegistrationStatusEnum {
  PENDING = 'pending',
  FAILED = 'failed',
  COMPLETE = 'complete',
  IN_REVIEW = 'inReview'
}

export type TollFreeRegistrationStatusType =
  `${TollFreeRegistrationStatusEnum}`;

export class ISlugSmsSettings {
  primaryPhoneNumber?: string;
  phoneNumbers?: string[];
  phoneNumberDisabled?: boolean;
  needsOtpVerification?: boolean;
  alphaSender?: string;
  contactCard?: string;
  brandRegistrationType?: TwilioBrandType;
  brandRegistrationStatus?: BrandRegistrationStatus;
  brandRegistrationFailureMessage?: string;
  tollFreeRegistrationStatus?: TollFreeRegistrationStatusType;
  tollFreeRegistrationFailureMessage?: string;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  tollFreeProvisionedAt?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  tollFreeRegistrationCompletedAt?: dayjs.Dayjs;
}

export class ISlugReferrer {
  slug: string;
  avatarUrl?: string;
  name: string;
}

export class ISlugMetadata {
  slug!: string;
  apiKey?: string;
  stripe?: IStripeSlugMetadata;
  facebook?: IFacebookSlugMetadata;
  mailing?: ISlugMailingMetadata;
  sms?: ISlugSmsSettings;
  general?: ISlugGeneralSettings;
  featurePermissions?: ISlugMetadataFeaturePermissions;
  slugRequestId?: string;
  referrer?: ISlugReferrer;
  onboardingInfo?: IOnboardingInfo;

  @Type(() => IBillingInfo)
  billingInfo?: IBillingInfo;

  get hasPaymentMethod(): boolean {
    return !!this.stripe?.stripeDefaultPaymentMethodId;
  }
}

export class IOnboardingInfo {
  category?: string;
  customCategory?: string;
  journeyStage?: string;
  customJourneyStage?: string;
  intent?: Intent;
  customIntent?: string;
  funnel?: string;
  cid?: string;
}
