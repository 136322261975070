<div class="flex flex-col relative">
  <norby-formcontrol-label
    [label]="label"
    [infoTooltip]="infoTooltip"
    [isRequired]="isRequired"
  ></norby-formcontrol-label>

  <input
    type="file"
    class="hidden-input"
    (change)="handleFilesSelected($event)"
    [accept]="acceptedFileTypes"
    [attr.multiple]="allowMultiple ? 'multiple' : null"
    #fileInput
  />

  <button
    type="button"
    class="ant-btn-animation flex items-center rounded-sm px-5 py-2 border border-neutral-900 bg-white focus:bg-white flex-1 leading-5 whitespace-nowrap text-neutral-900 mb-3 justify-center"
    [ngClass]="{
      'border-neutral-600 bg-neutral-200 cursor-not-allowed': isDisabled,
      'hover:bg-neutral-700 hover:text-white': !isDisabled
    }"
    (click)="fileInput.click()"
    [disabled]="isDisabled"
  >
    <norby-icon name="upload"></norby-icon
    >{{ allowMultiple ? 'Upload files' : 'Upload file' }}
  </button>

  <div class="flex flex-row flex-wrap gap-4 justify-start">
    <div *ngFor="let image of imagesCopy; index as index" class="relative">
      <norby-image-edit
        [image]="image"
        (deleteImage)="handleDeleteImage($event)"
        (imageChanged)="handleImageChanged($event, index)"
        [isDisabled]="isDisabled"
        [isAltTextDisabled]="isAltTextDisabled"
      ></norby-image-edit>
    </div>
  </div>
</div>
