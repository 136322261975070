import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyTabComponent } from '../components/atoms/norby-tab';

@NgModule({
  declarations: [NorbyTabComponent],
  imports: [CommonModule],
  exports: [NorbyTabComponent]
})
export class NorbyTabModule {}
