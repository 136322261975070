import {
  colorFromHash,
  generateRandomName,
  hashString,
  transformTimestampToDate
} from '../tools';
import { IQueryResult, PageInfo } from './general';
import { IContactNote } from './IContactNote';
import { IInboxItem, IInboxMedium } from './IInboxItem';

// 3rd party
import { Transform, Type } from 'class-transformer';

// Do we need this?
export class IInboxMessageActivityMediumMetadata {
  @Transform(transformTimestampToDate, { toClassOnly: true })
  last_updated_at!: Date;
}

// Do we need this?
export class IInboxMessageActivityMetadata {
  sms?: IInboxMessageActivityMediumMetadata;
  email?: IInboxMessageActivityMediumMetadata;
}

export type InstagramAccount = {
  id: string;
  username: string;
  messaging_disabled_at: Date;
  messaging_disabled: boolean;
  messages_synced_starting_at: Date;
};

export class IPSQLContact {
  private _randomName: string;
  private _randomColor: string;

  id!: string;
  slug!: string;
  display_name?: string | null;
  nickname?: string | null;
  photo_url?: string | null;
  alt_photo_url?: string;
  primary_phone_number?: string | null;
  phone_numbers?: string[] | null;
  visible_phone_numbers?: string[] | null;
  primary_email?: string | null;
  primary_email_verified?: boolean | null;
  emails?: string[] | null;
  tags?: string[] | null;
  verified_emails?: string[] | null;
  has_opted_into_email_marketing!: boolean;
  has_opted_into_sms_marketing!: boolean;
  deleted!: boolean;
  inbox_sms_message_activity_last_updated_at?: Date | null;
  inbox_email_message_activity_last_updated_at?: Date | null;
  instagram_accounts: InstagramAccount[] | null;
  mostRecentInboxItem?: IInboxItem;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  last_sent_contact_card_at?: Date | null;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  last_informed_about_keywords_at?: Date | null;

  inbox_seen!: boolean;
  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_last_seen_at?: Date | null;
  inbox_last_seen_at_cursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_last_updated_by_contact_at?: Date | null;
  inbox_last_updated_by_contact_at_cursor?: string | null;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_message_activity_last_updated_at?: Date | null;
  inbox_message_activity_last_updated_at_cursor?: string | null;

  inbox_email_message_activity_last_updated_at_cursor?: string | null;
  inbox_sms_message_activity_last_updated_at_cursor?: string | null;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_last_updated_at?: Date | null;
  inbox_last_updated_at_cursor?: string | null;

  mediums!: IInboxMedium[];

  instagram_handles: string[] | null;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  modified_at!: Date;
  modified_at_cursor!: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  created_at!: Date;
  created_at_cursor!: string;

  get name(): string {
    let name = this.nickname ?? this.display_name ?? this._randomName;

    if (!name) {
      const { label, color } = generateRandomName(this.id);
      this._randomColor = color;
      name = this._randomName = label;
    }

    return name;
  }

  get avatarUrl(): string {
    return this.alt_photo_url ?? this.photo_url;
  }

  get avatarColor(): string {
    if (!this._randomColor) {
      const { label, color } = generateRandomName(this.id);
      this._randomName = label;
      this._randomColor = color;
    }

    return this._randomColor;
  }
}

export class IPSQLContactResponseDto {
  id!: string;
  slug!: string;
  display_name?: string;
  nickname?: string;
  name?: string;
  photo_url?: string;
  alt_photo_url?: string;
  primary_phone_number?: string | null;
  phone_numbers?: string[] | null;
  visible_phone_numbers?: string[];
  primary_email?: string;
  primary_email_verified?: boolean;
  emails?: string[];
  verified_emails?: string[];
  has_opted_into_email_marketing!: boolean;
  has_opted_into_sms_marketing!: boolean;
  deleted!: boolean;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_sms_message_activity_last_updated_at?: Date;
  inbox_sms_message_activity_last_updated_at_cursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_email_message_activity_last_updated_at?: Date;
  inbox_email_message_activity_last_updated_at_cursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  last_sent_contact_card_at?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  last_informed_about_keywords_at?: Date;

  inbox_seen?: boolean;
  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_last_seen_at?: Date;
  inbox_last_seen_at_cursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_last_updated_by_contact_at?: Date;
  inbox_last_updated_by_contact_at_cursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_message_activity_last_updated_at?: Date;
  inbox_message_activity_last_updated_at_cursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  inbox_last_updated_at?: Date;
  inbox_last_updated_at_cursor?: string;

  mediums!: IInboxMedium[];
  @Transform(transformTimestampToDate, { toClassOnly: true })
  modified_at!: Date;
  modified_at_cursor!: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  created_at!: Date;
  created_at_cursor!: string;

  has_phone_number!: boolean;

  instagram_handles: string[] | null;

  @Type(() => IInboxItem)
  mostRecentInboxItem?: IInboxItem;
}

export class IPSQLContactResultEdge {
  cursor?: string;
  node!: IPSQLContactResponseDto;
  mostRecentInboxItem?: IInboxItem;
}

export class IPSQLContactResults extends IQueryResult {
  pageInfo!: PageInfo;
  edges!: IPSQLContactResultEdge[];
}

export class IPSQLContactCreate {
  primaryEmailAddress: string;
  emailAddresses: string[];
  nickname: string;
  optIntoEmailMarketing: boolean;
  altPhotoUrl?: string;
  tags?: string[];
}

export class IPSQLContactUpdate {
  nickname?: string | null;
  altPhotoUrl?: string;
  primaryEmailAddress?: string | null;
  emailAddressesToAdd?: string[];
  emailAddressesToRemove?: string[];
  optIntoEmailMarketing?: boolean;
  tags?: string[];
}

export class IContactSummaryInfo {
  totalUnseenContacts!: number;
  totalContacts!: number;
}

export class IContactSummaryResponseDto {
  all!: IContactSummaryInfo;
  sms!: IContactSummaryInfo;
  email!: IContactSummaryInfo;
}

export interface IContactNoteResults extends IQueryResult {
  pageInfo: PageInfo;
  edges: IContactNoteResultEdge[];
}

export interface IContactNoteResultEdge {
  cursor?: string;
  node: IContactNote;
}

export type IContactCsvResponse = { url: string };
