import { USER_COLORS, USER_ANIMALS } from '../constants';

export const generateRandomName = (
  userId: string
): { label: string; color: string } => {
  const hash = hashString(userId || 'Anonymous');
  const animal = animalFromHash(hash);
  const color = colorFromHash(hash);
  return { label: `Anonymous ${color} ${animal}`, color: color.toLowerCase() };
};

export const colorFromHash = (hash: number) => {
  const colorIdx = hash % (USER_COLORS.length - 1);
  return USER_COLORS[colorIdx];
};

export const animalFromHash = (hash: number) => {
  const animalIdx = hash % (USER_ANIMALS.length - 1);
  return USER_ANIMALS[animalIdx];
};

export const hashString = (input: string) => {
  let hash = 0;
  for (let i = 0; i < input?.length; i++) {
    const chr = input.charCodeAt(i);
    // tslint:disable-next-line: no-bitwise
    hash = (hash << 5) - hash + chr;
    // tslint:disable-next-line: no-bitwise
    hash |= 0;
  }
  return Math.abs(hash);
};
