import {
  Exclude,
  Expose,
  instanceToInstance,
  instanceToPlain,
  plainToInstance,
  Transform,
  Type
} from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

import { IImage, IResponsiveImage } from '../IImage';
import { IAccessControlSettings } from '../accessControl';
import {
  ContentNotificationType,
  ContentEventMetadata,
  ContentSignupMetadata,
  IZoomEventMetadata
} from './metadata';
import { DeliveryType, MmsAttachment } from '../general';
import { TimeZone } from '../timezone';
import { hashString, transformTimestampToDate } from '../../tools';
import { IThemeDTO, Theme } from '../ISlug';
import { EventType } from '../eventType';
import { RegisterableLayoutType } from './registerable-layout';

export abstract class Content {
  @Exclude()
  protected _changes$ = new BehaviorSubject<Content>(null);

  @Exclude()
  readonly changes$ = this._changes$.asObservable();

  @Exclude()
  protected _hash: number;

  @Expose()
  readonly contentType!: ContentType;

  @Expose()
  readonly images?: IImage[];

  @Expose()
  readonly published!: boolean;

  @Expose()
  readonly description?: string;

  @Expose()
  readonly subtitle?: string;

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['id'], { toClassOnly: true })
  readonly contentId!: string;

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['responsiveImages'], {
    toClassOnly: true
  })
  readonly imgixImages?: IResponsiveImage[];

  @Expose()
  @Type(() => Theme)
  readonly theme?: Theme;

  @Expose()
  @Transform(transformTimestampToDate, { toClassOnly: true })
  readonly createdAt!: Date;

  @Expose()
  readonly createdAtCursor?: string;

  @Expose()
  @Transform(transformTimestampToDate, { toClassOnly: true })
  readonly modifiedAt?: Date;

  @Expose()
  readonly modifiedAtCursor?: string;

  @Expose()
  readonly title!: string;

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['slug'], {
    toClassOnly: true
  })
  readonly primarySlugIdentifier!: string;

  abstract get hash(): number;
  abstract clone(withContent?: Partial<Content>);
  abstract updateProperties(properties: Partial<Content>);
  abstract toObject(): Content;

  @Exclude()
  get imageUrl(): string {
    return (
      this.imgixImage?.large?.url ??
      this.imgixImage?.medium?.url ??
      this.image?.url
    );
  }

  @Exclude()
  get imagesUrls(): string[] {
    return this.imgixImages.map(
      (img, idx) =>
        img?.large?.url ??
        img?.medium?.url ??
        this.image[idx]?.url ??
        this.image?.url
    );
  }

  @Exclude()
  get image(): IImage {
    return this.images?.[0];
  }

  @Exclude()
  get imgixImage(): IResponsiveImage {
    return this.imgixImages?.[0];
  }

  @Exclude()
  get isEvent(): boolean {
    return isEvent(this);
  }

  @Exclude()
  get isSignup(): boolean {
    return isSignup(this);
  }

  @Exclude()
  get isLink(): boolean {
    return isLink(this);
  }

  @Exclude()
  get routerPath(): string {
    return '/';
  }

  @Exclude()
  get primaryColor(): string {
    return this.image?.palettes?.[0];
  }

  @Exclude()
  get shareUrl(): string {
    if (isSignup(this) || isEvent(this))
      return this.urls?.pageShortLink ?? this.urls?.pageRaw;
    else if (isLink(this)) return this.url;
  }

  @Exclude()
  get summary(): string {
    if (!this.modifiedAt && !this.createdAt) return 'Not saved';
    const date = dayjs(this.modifiedAt ?? this.createdAt);
    return `Updated ${date.fromNow()}`;
  }

  @Exclude()
  get contentName(): string {
    return this.isSignup
      ? 'Signup'
      : this.isEvent
        ? 'Event'
        : this.isLink
          ? 'Link'
          : 'Content';
  }

  static fromObject(object: any, excludeExtraneousValues = false): Content {
    switch (object?.contentType) {
      case 'event':
        return ContentEvent.fromObject(object, excludeExtraneousValues);
      case 'link':
        return ContentLink.fromObject(object, excludeExtraneousValues);
      case 'drop':
        return ContentSignup.fromObject(object, excludeExtraneousValues);
      default:
        return null;
    }
  }
}

export abstract class ContentRegisterable extends Content {
  @Exclude()
  protected _fullHash: number; // Full object hash

  @Exclude()
  protected _promptsHash: number; // Prompts  hash

  @Expose()
  @Transform(
    (obj) =>
      obj?.value ?? {
        email: {
          required: obj?.obj?.['registrationRequirements']?.email ?? false
        },
        phoneNumber: {
          required: obj?.obj?.['registrationRequirements']?.sms ?? false
        },
        displayName: {
          required: obj?.obj?.['registrationRequirements']?.displayName ?? false
        }
      },
    {
      toClassOnly: true
    }
  )
  readonly userInfoRequirements?: IUserInfoRequirements;

  @Expose()
  @Transform(
    (obj) =>
      obj?.value ?? {
        email: {
          required: obj?.obj?.['registrationRequirements']?.email ?? false
        },
        phoneNumber: {
          required: obj?.obj?.['registrationRequirements']?.sms ?? false
        },
        displayName: {
          required: obj?.obj?.['registrationRequirements']?.displayName ?? false
        }
      },
    {
      toClassOnly: true
    }
  )
  readonly privateUserInfoRequirements?: IPrivateUserInfoRequirements;

  @Expose()
  @Transform(
    (obj) =>
      transformTimestampToDate(obj?.value ?? obj.obj?.['metadata']?.canceledAt),
    { toClassOnly: true }
  )
  readonly canceledAt?: Date;

  @Expose()
  @Transform(transformTimestampToDate, { toClassOnly: true })
  readonly registrationCloseDate?: Date;

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['registrationRestrictions'], {
    toClassOnly: true
  })
  readonly rsvpRestrictions?: IEventDropRsvpRestrictions;

  @Expose()
  readonly tickets?: ITicket[];

  @Expose()
  readonly buttonLabels?: ButtonLabels;

  @Expose()
  readonly promoCodes?: IPromoCode[];

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['metadata']?.isCanceled, {
    toClassOnly: true
  })
  readonly isCanceled?: boolean;

  @Expose()
  readonly tags?: string[];

  @Expose()
  readonly urls?: IPublicUrls;

  @Expose()
  readonly platform?: string;

  @Expose()
  readonly subtitle?: string;

  @Expose()
  readonly body?: string;

  @Expose()
  readonly signedBody?: string;

  @Expose()
  readonly people?: any;

  @Expose()
  readonly bodyType?: any;

  @Expose()
  readonly prompts?: IPrompt[];

  @Expose()
  readonly layout: RegisterableLayoutType;

  abstract get fullHash(): number;
  abstract get promptsHash(): number;

  @Exclude()
  get isFull() {
    return this.rsvpRestrictions && this.rsvpRestrictions?.remaining < 1;
  }

  @Exclude()
  get hasCapacity() {
    return this.rsvpRestrictions?.remaining > 0;
  }

  @Exclude()
  get isPaid() {
    return this.tickets?.length > 0;
  }

  @Exclude()
  get hasPromoCodes() {
    return this.promoCodes?.length > 0;
  }

  @Exclude()
  get hasEmailRequirement(): boolean {
    return this.privateUserInfoRequirements?.email?.required;
  }

  @Exclude()
  get hasPhoneRequirement(): boolean {
    return this.privateUserInfoRequirements?.phoneNumber?.required;
  }

  @Exclude()
  get primaryColor(): string {
    return this.image?.palettes?.[0] ?? this.theme?.button?.backgroundColor;
  }

  @Exclude()
  get isPastRegistrationDeadline(): boolean {
    const currentMoment = dayjs();
    return (
      this.registrationCloseDate &&
      currentMoment.isAfter(dayjs(this.registrationCloseDate))
    );
  }
}

export type ContentLocationType = 'online' | 'irl';
export const ContentLocationTypes: ContentLocationType[] = ['online', 'irl'];

export class ContentEvent extends ContentRegisterable {
  @Expose()
  readonly contentType!: 'event';

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['metadata']?.contentLocation, {
    toClassOnly: true
  })
  readonly contentLocation?: string;

  @Expose()
  @Transform(
    (obj) => obj?.value ?? obj?.obj?.['metadata']?.contentLocationType,
    {
      toClassOnly: true
    }
  )
  readonly contentLocationType?: ContentLocationType;

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['metadata']?.eventType, {
    toClassOnly: true
  })
  readonly eventType?: EventType;

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['isZoom'], {
    toClassOnly: true
  })
  readonly shouldGenerateZoomLink?: boolean;

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['urls']?.clickThroughRaw, {
    toClassOnly: true
  })
  readonly contentUrl?: string;

  @Expose()
  @Transform(transformTimestampToDate, { toClassOnly: true })
  readonly startDate!: Date;

  @Expose()
  readonly startDateCursor?: string;

  @Expose()
  @Transform(transformTimestampToDate, { toClassOnly: true })
  readonly endDate?: Date;

  clone(withContent?: Partial<ContentEvent>, excludeExtraneousValues = false) {
    const clone = instanceToInstance(this, { excludeExtraneousValues });
    if (withContent) {
      clone.updateProperties(withContent);
    }
    return clone;
  }

  updateProperties(properties: Partial<ContentEvent>, emitEvent = true) {
    // Only update props that aren't @Excluded in the toObject transform
    const props =
      properties instanceof ContentEvent ? properties.toObject() : properties;

    // Update props
    for (const key in props) {
      this[key] = properties[key];
    }

    // Clear hashes
    this._hash = null;
    this._fullHash = null;

    // Only clear prompts hash if prompts were updated
    if (!!properties['prompts']) {
      this._promptsHash = null;
    }

    // Publish changes
    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  static new() {
    return plainToInstance(ContentEvent, { contentType: 'event' });
  }

  static fromObject(object: any, excludeExtraneousValues = false) {
    return plainToInstance(
      ContentEvent,
      {
        contentType: 'event',
        ...object
      },
      { excludeExtraneousValues }
    );
  }

  toObject() {
    return instanceToPlain(this) as ContentEvent;
  }

  toCreateDTO(
    withMetadata: ContentEventMetadata,
    published = true
  ): IEventCreateDTO {
    const {
      title,
      contentLocationType,
      eventType,
      startDate,
      endDate,
      privateUserInfoRequirements,
      userInfoRequirements,
      registrationCloseDate,
      prompts,
      subtitle,
      body,
      images,
      buttonLabels,
      theme,
      tickets,
      promoCodes,
      urls,
      tags,
      shouldGenerateZoomLink,
      layout
    } = this.toObject();

    const {
      notifications,
      managedNotifications,
      password,
      keywords,
      rsvpRestrictions,
      contentLocation,
      contentLocationPublic
    } = withMetadata;

    const fixedContentLocation =
      contentLocationType == 'irl' ? contentLocation : '';
    const donateUrl = urls?.donateRaw;
    const postRegistrationRedirectUrl = urls?.postRegistrationRedirect;
    const { limit, displayLimit } = rsvpRestrictions || {};
    const contentUrl =
      contentLocationType == 'online' ? withMetadata.urls?.clickThroughRaw : '';

    return {
      contentType: 'event',
      published,
      title,
      ...(eventType && { eventType }),
      ...(contentLocationType && { contentLocationType }),
      ...(contentLocationType == 'irl' && {
        contentLocation: fixedContentLocation
      }),
      ...{ contentLocationPublic: contentLocationPublic || false },
      ...(startDate && { startDate: startDate.toISOString() }),
      ...(endDate && { endDate: endDate.toISOString() }),
      ...(contentLocationType == 'online' && { contentUrl }),
      rsvpRestrictions: limit >= 0 ? { limit, displayLimit } : null,
      ...(privateUserInfoRequirements && { privateUserInfoRequirements }),
      ...(userInfoRequirements && { userInfoRequirements }),
      ...(prompts && { prompts }),
      ...(keywords && { keywords }),
      ...(postRegistrationRedirectUrl && { postRegistrationRedirectUrl }),
      ...(registrationCloseDate && {
        registrationCloseDate: registrationCloseDate.toISOString()
      }),
      ...(password && { password }),
      ...(subtitle && { subtitle }),
      ...(body && { body }),
      ...(images && { images }),
      ...(buttonLabels && { buttonLabels }),
      ...(theme && { theme }),
      ...(layout && { layout }),

      // Notifications should only be included if they are NOT server managed
      ...(notifications &&
        !managedNotifications && { notifications: notifications || [] }),

      //  Monetization
      ...(tickets && { tickets: tickets || [] }),
      ...(promoCodes && { promoCodes: promoCodes || [] }),
      donateUrl,
      ...(tags && { tags: tags || [] }),
      ...(shouldGenerateZoomLink && { zoomGeneration: { generateZoom: true } })
    };
  }

  toUpdateDTO(
    withMetadata: ContentEventMetadata,
    published = true
  ): IEventUpdateDTO {
    const {
      title,
      eventType,
      contentLocationType,
      startDate,
      endDate,
      privateUserInfoRequirements,
      userInfoRequirements,
      registrationCloseDate,
      prompts,
      subtitle,
      body,
      images,
      buttonLabels,
      theme,
      tickets,
      promoCodes,
      urls,
      tags,
      shouldGenerateZoomLink,
      layout
    } = this.toObject();

    const {
      notifications,
      managedNotifications,
      password,
      keywords,
      rsvpRestrictions,
      contentLocation,
      contentLocationPublic,
      zoom
    } = withMetadata;

    const fixedContentLocation =
      contentLocationType == 'irl' ? contentLocation : '';
    const { limit, displayLimit } = rsvpRestrictions || {};
    const donateUrl = urls?.donateRaw;
    const postRegistrationRedirectUrl = urls?.postRegistrationRedirect;
    const contentUrl =
      contentLocationType == 'online' ? withMetadata.urls?.clickThroughRaw : '';

    return {
      published,
      title,
      ...(eventType && { eventType }),
      ...{
        contentLocation: fixedContentLocation
      },
      ...(contentLocationType && { contentLocationType }),
      ...{ contentLocationPublic: contentLocationPublic || false },

      ...(startDate && {
        startDate:
          typeof startDate === 'string' ? startDate : startDate.toISOString()
      }),
      ...(endDate && {
        endDate: typeof endDate === 'string' ? endDate : endDate.toISOString()
      }),
      contentUrl,
      rsvpRestrictions: limit >= 0 ? { limit, displayLimit } : null,
      ...(privateUserInfoRequirements && { privateUserInfoRequirements }),
      ...(userInfoRequirements && { userInfoRequirements }),
      ...(prompts && { prompts }),
      ...(keywords && { keywords }),
      postRegistrationRedirectUrl,
      ...{
        registrationCloseDate:
          typeof registrationCloseDate === 'string'
            ? registrationCloseDate
            : registrationCloseDate?.toISOString() || null
      },
      password,
      subtitle,
      body: body || null,
      ...(images && { images }),
      ...(buttonLabels && { buttonLabels }),
      ...(theme && { theme }),
      ...(layout && { layout }),

      // Notifications should only be included if they are NOT server managed
      ...(notifications &&
        !managedNotifications && { notifications: notifications || [] }),

      //  Monetization
      ...(tickets && { tickets: tickets || [] }),
      ...(promoCodes && { promoCodes: promoCodes || [] }),
      donateUrl,
      ...(tags && { tags: tags || [] }),
      ...(!shouldGenerateZoomLink &&
        zoom?.meetingId && { zoomGeneration: { generateZoom: false } }),
      ...(shouldGenerateZoomLink &&
        !zoom?.meetingId && { zoomGeneration: { generateZoom: true } })
    };
  }

  static fromDTO(scaffold: IEventCreateDTO): ContentEvent {
    const {
      title,
      eventType,
      contentLocation,
      contentLocationPublic,
      contentUrl,
      startDate,
      endDate,
      layout,

      privateUserInfoRequirements,
      userInfoRequirements,
      postRegistrationRedirectUrl,
      registrationCloseDate,
      prompts,
      rsvpRestrictions,

      subtitle,
      body,
      images,
      buttonLabels,
      theme,

      tickets,
      promoCodes,
      donateUrl,
      keywords,
      tags,
      zoomGeneration
    } = scaffold;

    const contentLocationType = !!contentLocation ? 'irl' : 'online';

    return this.fromObject({
      contentType: 'event',
      title,
      ...(eventType && { eventType }),
      ...(contentLocation && contentLocationPublic && { contentLocation }),
      contentLocationType,
      ...(startDate && { startDate: new Date(startDate) }),
      ...(endDate && { endDate: new Date(endDate) }),

      ...(privateUserInfoRequirements && { privateUserInfoRequirements }),
      ...(userInfoRequirements && { userInfoRequirements }),
      ...(registrationCloseDate && {
        registrationCloseDate: new Date(registrationCloseDate)
      }),
      ...(prompts?.length && { prompts }),
      ...(layout && { layout }),
      ...(rsvpRestrictions && {
        rsvpRestrictions: {
          ...rsvpRestrictions,
          remaining: rsvpRestrictions.limit
        }
      }),

      subtitle,
      body,
      ...(images && { images }),
      ...(buttonLabels && { buttonLabels }),
      ...(theme && { theme }),
      ...(postRegistrationRedirectUrl && {
        urls: { postRegistrationRedirect: postRegistrationRedirectUrl }
      }),
      ...(tickets?.length && { tickets }),
      ...(promoCodes?.length && { promoCodes }),
      ...(donateUrl && { urls: { donateRaw: donateUrl } }),

      ...(tags?.length && { tags }),
      ...(keywords?.length && { keywords }),
      ...(zoomGeneration && { shouldGenerateZoomLink: true }),
      ...(zoomGeneration && { contentUrl: '' }),
      ...(!zoomGeneration && { contentUrl })
    });
  }

  @Exclude()
  get hash() {
    if (!this._hash) {
      const theme = this.theme?.hash ?? 0;
      const imagesStr =
        this.images?.map((image) => `${image.url}${image.altText}`) || '';

      const promoCodeStr =
        this.promoCodes?.map(
          (code) => `${code.promoCode}${code.discountPercentage}`
        ) || '';
      const props = `
        ${theme}
        ${this.title}
        ${this.startDate}
        ${this.endDate}
        ${this.registrationCloseDate}
        ${this.contentLocation}
        ${this.contentLocationType}
        ${this.subtitle}
        ${this.body}
        ${imagesStr}
        ${this.buttonLabels}
        ${this.rsvpRestrictions?.displayLimit}
        ${this.rsvpRestrictions?.limit}
        ${this.rsvpRestrictions?.remaining}
        ${this.layout}
        ${this.promptsHash}
        ${promoCodeStr}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  @Exclude()
  get fullHash() {
    if (!this._fullHash) {
      this._fullHash = hashString(JSON.stringify(this.toObject()));
    }

    return this._fullHash;
  }

  @Exclude()
  get promptsHash() {
    if (!this._promptsHash) {
      this._promptsHash = hashString(JSON.stringify(this.prompts));
    }

    return this._promptsHash;
  }

  @Exclude()
  get isOnline(): boolean {
    // Legacy may not have this populated
    return this.contentLocationType !== 'irl';
  }

  @Exclude()
  get isIrl(): boolean {
    return this.contentLocationType === 'irl';
  }

  @Exclude()
  get routerPath(): string {
    return `/event/${this.contentId}`;
  }

  @Exclude()
  get hours() {
    return this.endDate
      ? this.startMoment().diff(this.endMoment(), 'hours')
      : 1;
  }

  @Exclude()
  get summary(): string {
    const platform = this.platform ? ` on ${this.platform}` : '';
    const startMoment = this.startMoment();
    const endMoment = this.endMoment();
    const isToday = this.isToday;
    const daySpan =
      startMoment && endMoment
        ? Math.abs(startMoment.diff(endMoment, 'days'))
        : 0;

    if (daySpan > 0) {
      if (isToday) {
        return `Today through ${endMoment.format('MMMM D, YYYY')}${platform}`;
      }

      return `${startMoment.format('MMMM D, YYYY')} to ${endMoment.format(
        'MMMM D, YYYY'
      )}${platform}`;
    }

    if (isToday) {
      const isHappening = this.isHappening;
      const isOver = this.isOver;

      if (isOver) {
        return `Ended ${startMoment.fromNow()}${platform}`;
      } else if (isHappening) {
        return `Started ${startMoment.fromNow()}${platform}`;
      } else {
        return `${startMoment.format('h:mm a')} to ${endMoment.format(
          'h:mm a z'
        )}${platform}`;
      }
    }

    return startMoment
      ? `${startMoment.calendar(null, {
          lastDay: '[Yesterday at] h:mm a z',
          sameDay: '[Today at] h:mm a z',
          nextDay: '[Tomorrow at] h:mm a z',
          lastWeek: '[Last] dddd [at] h:mm a z',
          nextWeek: 'dddd [at] h:mm a z',
          sameElse: 'LL [at] h:mm a z'
        })}${platform}`
      : '';
  }

  @Exclude()
  get isToday(): boolean {
    return this.startMoment()?.isSame(dayjs(), 'day') || false;
  }

  @Exclude()
  get isHappening(): boolean {
    const now = dayjs();
    return (
      (this.startMoment()?.isBefore(now) || false) &&
      (this.endMoment()?.isAfter(now) || false)
    );
  }

  @Exclude()
  get isOver(): boolean {
    return this.endMoment()?.isBefore(dayjs()) || false;
  }

  @Exclude()
  get isFuture(): boolean {
    return this.startMoment()?.isAfter(dayjs()) || false;
  }

  startMoment(): dayjs.Dayjs {
    return this.startDate ? dayjs(this.startDate) : null;
  }

  endMoment(): dayjs.Dayjs {
    return this.endDate ? dayjs(this.endDate) : this.startMoment();
  }

  registrationCloseDateMoment(): dayjs.Dayjs {
    return this.registrationCloseDate
      ? dayjs(this.registrationCloseDate)
      : null;
  }
}

export function isEvent(x: Content): x is ContentEvent {
  return x?.contentType === 'event';
}

export class INotificationDTO {
  @Expose()
  type!: ContentNotificationType;

  @Expose()
  offset!: number;

  @Expose()
  deliveryType!: DeliveryType;

  @Expose()
  message?: string;

  @Expose()
  subject?: string;

  @Expose()
  attachments?: MmsAttachment[];

  @Expose()
  attachCalendarInvite?: boolean;
}

export class ISignupNotificationDTO extends INotificationDTO {
  @Expose()
  type!: ContentNotificationType;
}

export class IEventNotificationDTO extends INotificationDTO {
  @Expose()
  type!: ContentNotificationType;
}

export class IEventDropRsvpRestrictions {
  @Expose()
  remaining!: number;

  @Expose()
  limit?: number;

  @Expose()
  displayLimit?: boolean;
}

export class IRsvpRestrictions {
  @Expose()
  limit!: number;

  @Expose()
  displayLimit?: boolean;
}

export class IUserInfoRequirement {
  @Expose()
  required!: boolean;
}

export class IUserInfoRequirements {
  @Expose()
  displayName!: IUserInfoRequirement;
}

export class IPrivateUserInfoRequirement {
  @Expose()
  required!: boolean;
}
export class IPrivateUserInfoRequirements {
  @Expose()
  email?: IPrivateUserInfoRequirement;

  @Expose()
  phoneNumber?: IPrivateUserInfoRequirement;
}

export type RefundPolicy = 'noRefunds' | 'allowRequests';

export class ITicket {
  @Expose()
  label!: string;

  @Expose()
  refundPolicy?: RefundPolicy;

  @Expose()
  price!: number;

  @Expose()
  description?: string;

  @Expose()
  statementDescription?: string;

  @Expose()
  qty?: number;

  @Expose()
  maxQty: number;
}

export class IPromoCode {
  @Expose()
  id!: string;

  @Expose()
  promoCode!: string;

  @Expose()
  discountPercentage!: number;
}

export class IPublicUrls {
  @Expose()
  donateRaw?: string;

  @Expose()
  pageRaw!: string;

  @Expose()
  pageShortLink!: string;

  @Expose()
  postRegistrationRedirect!: string;
}

export class IPrompt {
  @Expose()
  prompt!: string;

  @Expose()
  required!: boolean;

  @Expose()
  type?: string;

  @Expose()
  options?: string[];
}

/// CREATION PAYLOADS
export type ZoomApprovalType = 'automatic' | 'manual' | 'noreg';

export class IZoomGeneration {
  @Expose()
  generateZoom!: boolean;

  @Expose()
  'host_video'?: boolean;

  @Expose()
  'participant_video'?: boolean;

  @Expose()
  'cn_meeting'?: boolean;

  @Expose()
  'in_meeting'?: boolean;

  @Expose()
  'join_before_host'?: boolean;

  @Expose()
  'mute_upon_entry'?: boolean;

  @Expose()
  'watermark'?: boolean;

  @Expose()
  'approval_type'?: ZoomApprovalType;

  @Expose()
  'audio'?: string;

  @Expose()
  'auto_recording'?: string;

  @Expose()
  'global_dial_in_countries'?: string[];

  @Expose()
  'registrants_email_notification'?: boolean;

  @Expose()
  timezone?: TimeZone;
}

export class ButtonLabels {
  @Expose()
  pre?: string | null; // Before registering

  @Expose()
  preConfirmed?: string | null; // After registering

  @Expose()
  mid?: string | null; // During event

  @Expose()
  post?: string | null; // After event

  @Expose()
  newsletter?: string | null; // Newsletter opt in label
}

export type ContentKeywordAction = 'rsvp';
export const ContentKeywordActions: ContentKeywordAction[] = ['rsvp'];

export class IContentKeywordDTO {
  @Expose()
  keyword!: string;

  @Expose()
  action!: ContentKeywordAction;
}

export class IContentKeyword {
  @Expose()
  id!: string;

  @Expose()
  slug!: string;

  @Expose()
  keyword!: string;

  @Expose()
  action!: ContentKeywordAction;

  @Expose()
  contentId!: string;

  @Expose()
  contentType!: ContentType;

  @Expose()
  createdAt!: Date;

  @Expose()
  createdAtCursor!: string;
}

export class IContentRegisterableCreateDTO {
  contentType!: SubscribableContentType;
  title!: string;
  subtitle?: string;
  summary?: string;
  contentUrl?: string;
  donateUrl?: string;
  body?: string;
  type?: string;
  published?: boolean;
  images?: IImage[];
  tags?: string[];
  bodyType?: string;
  people?: any;
  notifications?: INotificationDTO[];
  prompts?: IPrompt[];
  userInfoRequirements?: IUserInfoRequirements;
  privateUserInfoRequirements?: IPrivateUserInfoRequirements;
  rsvpRestrictions?: IRsvpRestrictions;
  tickets?: ITicket[];
  promoCodes?: IPromoCode[];
  accessControlSettings?: IAccessControlSettings;
  registrationCloseDate?: string;
  password?: string | null;
  buttonLabels?: ButtonLabels;
  collectionIds?: string[];
  keywords?: IContentKeywordDTO[];
  theme?: IThemeDTO;
  postRegistrationRedirectUrl?: string;
  primarySlug?: string;
  slugs?: string[];
  layout?: RegisterableLayoutType;
}

export type SubscribableContentType = 'event' | 'drop';

export class IContentRegisterableUpdateDTO {
  title?: string;
  subtitle?: string;
  summary?: string;
  contentUrl?: string;
  donateUrl?: string;
  body?: string;
  type?: string;
  published?: boolean;
  images?: IImage[];
  tags?: string[];
  bodyType?: any;
  people?: any;
  notifications?: INotificationDTO[];
  prompts?: IPrompt[];
  userInfoRequirements?: IUserInfoRequirements;
  privateUserInfoRequirements?: IPrivateUserInfoRequirements;
  rsvpRestrictions?: IRsvpRestrictions;
  tickets?: ITicket[];
  promoCodes?: IPromoCode[];
  accessControlSettings?: IAccessControlSettings;
  registrationCloseDate?: string | null;
  password?: string | null;
  buttonLabels?: ButtonLabels;
  collectionIds?: string[];
  keywords?: IContentKeywordDTO[] | null;
  theme?: IThemeDTO;
  layout?: RegisterableLayoutType;
  postRegistrationRedirectUrl?: string;
}

// CRUD DTOs
export class IEventCreateDTO extends IContentRegisterableCreateDTO {
  contentType: 'event';
  notifications?: IEventNotificationDTO[];
  zoomGeneration?: IZoomGeneration;
  type?: string;
  startDate!: string;
  endDate?: string;
  contentLocation?: string;
  contentLocationPublic?: boolean;
  eventType?: EventType;
}

export class IEventUpdateDTO extends IContentRegisterableUpdateDTO {
  notifications?: IEventNotificationDTO[];
  zoomGeneration?: IZoomGeneration;
  type?: string;
  startDate?: string;
  endDate?: string | null;
  contentLocation?: string;
  contentLocationPublic?: boolean;
  eventType?: EventType;
}

export class ISignupCreateDTO extends IContentRegisterableCreateDTO {
  contentType: 'drop';
  notifications?: ISignupNotificationDTO[];
  signupType: SignupType;
}

export class ISignupUpdateDTO extends IContentRegisterableUpdateDTO {
  notifications?: ISignupNotificationDTO[];
  signupType?: SignupType;
}

export enum ContentTypeEnum {
  EVENT = 'event',
  LINK = 'link',
  SIGNUP = 'drop'
}

export enum SignupTypeEnum {
  FLOW = 'flow',
  FORM = 'form'
}

export type SignupType = `${SignupTypeEnum}`;

export enum EntityTypeEnum {
  SINGLE_SEND = 'singleSend',
  LANDING_PAGE = 'collection'
}

export type ContentType = `${ContentTypeEnum}`;

export type EntityType = ContentType | 'singleSend' | 'collection';

export class ContentSignup extends ContentRegisterable {
  @Expose()
  readonly contentType!: 'drop';

  @Expose()
  readonly signupType: SignupType;

  static new() {
    return plainToInstance(ContentSignup, {
      contentType: 'drop',
      signupType: 'flow'
    });
  }

  toObject() {
    return instanceToPlain(this) as ContentSignup;
  }

  clone(withContent?: Partial<ContentSignup>, excludeExtraneousValues = false) {
    const clone = instanceToInstance(this, { excludeExtraneousValues });
    if (withContent) {
      clone.updateProperties(withContent);
    }
    return clone;
  }

  updateProperties(properties: Partial<ContentSignup>, emitEvent = true) {
    // Only update props that aren't @Excluded in the toObject transform
    const props =
      properties instanceof ContentSignup ? properties.toObject() : properties;

    // Update props
    for (const key in props) {
      this[key] = properties[key];
    }

    // Clear hashes
    this._hash = null;
    this._fullHash = null;

    // Only clear prompts hash if prompts were updated
    if (!!properties['prompts']) {
      this._promptsHash = null;
    }

    // Publish changes
    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  static fromObject(object: any, excludeExtraneousValues = false) {
    return plainToInstance(
      ContentSignup,
      {
        contentType: 'drop',
        ...object
      },
      { excludeExtraneousValues }
    );
  }

  @Exclude()
  get hash() {
    if (!this._hash) {
      const theme = this.theme?.hash ?? 0;
      const images = this.images?.reduce(
        (acc, curr) => `${acc}${curr.url}${curr.altText}`,
        ''
      );

      const promoCodeStr =
        this.promoCodes?.map(
          (code) => `${code.promoCode}${code.discountPercentage}`
        ) || '';

      const props = `
        ${theme}
        ${this.title}
        ${this.signupType}
        ${this.registrationCloseDate}
        ${this.subtitle}
        ${this.promptsHash}
        ${this.body}
        ${images}
        ${this.buttonLabels}
        ${this.rsvpRestrictions?.displayLimit}
        ${this.rsvpRestrictions?.limit}
        ${this.rsvpRestrictions?.remaining}
        ${this.layout}
        ${promoCodeStr}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  @Exclude()
  get fullHash() {
    if (!this._fullHash) {
      this._fullHash = hashString(JSON.stringify(this.toObject()));
    }

    return this._fullHash;
  }

  @Exclude()
  get promptsHash() {
    if (!this._promptsHash) {
      this._promptsHash = hashString(JSON.stringify(this.prompts));
    }

    return this._promptsHash;
  }

  @Exclude()
  get routerPath(): string {
    return `/signup/${this.contentId}`;
  }

  registrationCloseDateMoment(): dayjs.Dayjs {
    return this.registrationCloseDate
      ? dayjs(this.registrationCloseDate)
      : null;
  }

  toCreateDTO(
    withMetadata: ContentSignupMetadata,
    published = true
  ): ISignupCreateDTO {
    const {
      title,
      privateUserInfoRequirements,
      userInfoRequirements,
      registrationCloseDate,
      prompts,
      subtitle,
      body,
      images,
      buttonLabels,
      theme,
      tickets,
      promoCodes,
      urls,
      tags,
      signupType,
      layout
    } = this.toObject();

    const {
      notifications,
      managedNotifications,
      password,
      keywords,
      rsvpRestrictions
    } = withMetadata;

    const donateUrl = urls?.donateRaw;
    const postRegistrationRedirectUrl = urls?.postRegistrationRedirect;
    const { limit, displayLimit } = rsvpRestrictions || {};

    return {
      contentType: 'drop',
      signupType: signupType || 'flow',
      published,
      title,
      rsvpRestrictions: limit >= 0 ? { limit, displayLimit } : null,
      ...(privateUserInfoRequirements && { privateUserInfoRequirements }),
      ...(userInfoRequirements && { userInfoRequirements }),
      ...(prompts && { prompts }),
      ...(keywords && { keywords }),
      ...(postRegistrationRedirectUrl && { postRegistrationRedirectUrl }),
      ...(registrationCloseDate && {
        registrationCloseDate: registrationCloseDate.toISOString()
      }),
      ...(password && { password }),
      ...(subtitle && { subtitle }),
      ...(body && { body }),
      ...(images && { images }),
      ...(buttonLabels && { buttonLabels }),
      ...(theme && { theme }),

      // Notifications should only be included if they are NOT server managed
      ...(notifications &&
        !managedNotifications && { notifications: notifications || [] }),

      ...(tickets && { tickets: tickets || [] }),
      ...(promoCodes && { promoCodes: promoCodes || [] }),
      donateUrl,
      ...(tags && { tags: tags || [] }),
      ...(layout && { layout })
    };
  }

  toUpdateDTO(
    withMetadata: ContentSignupMetadata,
    published = true
  ): ISignupUpdateDTO {
    const {
      title,
      privateUserInfoRequirements,
      userInfoRequirements,
      registrationCloseDate,
      prompts,
      subtitle,
      body,
      images,
      buttonLabels,
      theme,
      tickets,
      promoCodes,
      signupType,
      urls,
      tags,
      layout
    } = this.toObject();

    const {
      notifications,
      managedNotifications,
      password,
      keywords,
      rsvpRestrictions
    } = withMetadata;

    const { limit, displayLimit } = rsvpRestrictions || {};
    const donateUrl = urls?.donateRaw;
    const postRegistrationRedirectUrl = urls?.postRegistrationRedirect;

    return {
      published,
      title,
      signupType: signupType || 'flow',
      rsvpRestrictions: limit >= 0 ? { limit, displayLimit } : null,
      ...(privateUserInfoRequirements && { privateUserInfoRequirements }),
      ...(userInfoRequirements && { userInfoRequirements }),
      ...(prompts && { prompts }),
      ...(keywords && { keywords }),
      postRegistrationRedirectUrl,
      ...{
        registrationCloseDate:
          typeof registrationCloseDate === 'string'
            ? registrationCloseDate
            : registrationCloseDate?.toISOString() || null
      },
      password,
      subtitle,
      body: body || null,
      ...(images && { images }),
      ...(buttonLabels && { buttonLabels }),
      ...(theme && { theme }),

      // Notifications should only be included if they are NOT server managed
      ...(notifications &&
        !managedNotifications && { notifications: notifications || [] }),

      ...(tickets && { tickets: tickets || [] }),
      ...(promoCodes && { promoCodes: promoCodes || [] }),
      donateUrl,
      ...(tags && { tags: tags || [] }),
      ...(layout && { layout })
    };
  }

  static fromDTO(scaffold: ISignupCreateDTO): ContentSignup {
    const {
      title,
      privateUserInfoRequirements,
      userInfoRequirements,
      postRegistrationRedirectUrl,
      registrationCloseDate,
      prompts,
      rsvpRestrictions,
      subtitle,
      body,
      images,
      buttonLabels,
      signupType,
      theme,
      tickets,
      keywords,
      donateUrl,
      tags,
      layout
    } = scaffold;

    return this.fromObject({
      contentType: 'drop',
      title,
      signupType,
      ...(privateUserInfoRequirements && { privateUserInfoRequirements }),
      ...(userInfoRequirements && { userInfoRequirements }),
      ...(registrationCloseDate && {
        registrationCloseDate: new Date(registrationCloseDate)
      }),
      ...(postRegistrationRedirectUrl && {
        urls: { postRegistrationRedirect: postRegistrationRedirectUrl }
      }),
      ...(prompts?.length && { prompts }),
      ...(rsvpRestrictions && {
        rsvpRestrictions: {
          ...rsvpRestrictions,
          remaining: rsvpRestrictions.limit
        }
      }),
      subtitle,
      body,
      ...(images && { images }),
      ...(buttonLabels && { buttonLabels }),
      ...(theme && { theme }),
      ...(tickets?.length && { tickets }),
      ...(keywords?.length && { keywords }),
      ...(donateUrl && { urls: { donateRaw: donateUrl } }),
      ...(tags?.length && { tags }),
      ...(layout && { layout })
    });
  }
}

export function isSignup(x: Content): x is ContentSignup {
  return x?.contentType === 'drop';
}

export class ILinkColors {
  @Expose()
  text?: string | null;

  @Expose()
  card?: string | null;
}

export type LinkType =
  | 'absolute'
  | 'collection'
  | 'email'
  | 'phoneNumber'
  | 'callablePhoneNumber'
  | 'instagramDm';

export type AvatarClickThroughUrlType = 'absolute' | 'collection';

export class ContentLink extends Content {
  @Expose()
  readonly contentType!: 'link';

  @Expose()
  readonly url!: string;

  @Expose()
  readonly description?: string;

  @Expose()
  readonly colors?: ILinkColors;

  @Expose()
  readonly type!: LinkType;

  @Expose()
  @Transform((obj) => obj?.value ?? obj?.obj?.['clickThroughUrls'], {
    toClassOnly: true
  })
  readonly urls?: ILinkUrls;

  clone(withContent?: Partial<ContentLink>, excludeExtraneousValues = false) {
    const clone = instanceToInstance(this, { excludeExtraneousValues });
    if (withContent) {
      clone.updateProperties(withContent);
    }
    return clone;
  }

  updateProperties(properties: Partial<ContentLink>, emitEvent = true) {
    // Only update props that aren't @Excluded in the toObject transform
    const props =
      properties instanceof ContentLink ? properties.toObject() : properties;

    // Update props
    for (const key in props) {
      this[key] = properties[key];
    }

    // Clear hash and publish changes
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  @Exclude()
  get routerPath(): string {
    return `/link/${this.contentId}`;
  }

  @Exclude()
  get hash() {
    if (!this._hash) {
      const theme = this.theme?.hash ?? 0;
      const imagesStr = this.images?.map((i) => i.url) || '';
      const props = `
        ${theme}
        ${this.title}
        ${this.description}
        ${this.url}
        ${imagesStr}
      `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  static new() {
    return plainToInstance(ContentLink, { contentType: 'link' });
  }

  static fromObject(object: any, excludeExtraneousValues = false) {
    return plainToInstance(
      ContentLink,
      {
        ...object
      },
      { excludeExtraneousValues }
    );
  }

  toObject() {
    return instanceToPlain(this) as ContentLink;
  }
}

export class ILinkUrls {
  @Expose()
  clickThroughRaw?: string;

  @Expose()
  clickThroughShortLink?: string;
}

export class ICreateLinkDTO {
  contentType: 'link';
  primarySlugIdentifier!: string;
  title!: string;
  description!: string;
  url!: string;
  published!: boolean;
  images?: string[];
  colors?: ILinkColors;
  type?: LinkType;
  collectionIds?: string[];
  theme?: IThemeDTO;
}

export class IUpdateLinkDTO {
  title?: string;
  description?: string;
  url?: string;
  published?: boolean;
  images?: string[];
  colors?: ILinkColors;
  type?: LinkType;
  collectionIds?: string[];
  theme?: IThemeDTO;
}

export type LinkInteractionType = 'click';

export class ITrackLinkInteraction {
  @Expose()
  interactionType!: LinkInteractionType;
}

export function isLink(x: Content): x is ContentLink {
  return x?.contentType === 'link';
}

export class ISetDefaultCollection {
  @Expose()
  id?: string;

  @Expose()
  slug!: string;
}

export class CancelationNotificationSettings {
  @Expose()
  deliveryType!: DeliveryType;

  @Expose()
  message!: string;

  @Expose()
  subject?: string;
}

export class IEmailCancelationNotificationSettings extends CancelationNotificationSettings {
  @Expose()
  deliveryType!: 'email';

  @Expose()
  message!: string;

  @Expose()
  subject!: string;
}

export class ISmsCancelationNotificationSettings extends CancelationNotificationSettings {
  @Expose()
  deliveryType!: 'sms';

  @Expose()
  message!: string;

  @Expose()
  subject?: string;
}

export class IContentCancelation {
  @Expose()
  @Type(() => CancelationNotificationSettings, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'deliveryType',
      subTypes: [
        { value: IEmailCancelationNotificationSettings, name: 'email' },
        { value: ISmsCancelationNotificationSettings, name: 'sms' }
      ]
    }
  })
  cancelationNotificationSettings!: CancelationNotificationSettings;

  @Expose()
  unpublish!: boolean;
}

export type AnnouncementTypeEnum = 'success' | 'info' | 'warning' | 'error';

export class IAnnouncementDTO {
  id!: string;
  active!: boolean;
  title: string;
  body: string;
  buttonUrl!: string;
  buttonText!: string;
  icon!: string;
  type!: AnnouncementTypeEnum;
  owner!: boolean;
  administrator!: boolean;
  editor!: boolean;
  contributor!: boolean;
  free!: boolean;
  starter!: boolean;
  basic!: boolean;
  unlimited!: boolean;
  enterprise!: boolean;
}

export type ContentClick = {
  content: Content;
  event: Event;
};

export type ContentResponseType = {
  contentId: string;
  contentType: string;
  status: boolean;
  zoom?: IZoomEventMetadata;
  content?: Content;
};
