import {
  ISendTestMessageSingleSendData,
  ISendTestMessageContentData,
  ISendTestMessage,
  ContentType,
  UpdateLandingPageDTO,
  CreateLandingPageDTO,
  IEventCreateDTO,
  ISignupCreateDTO,
  IContentRegisterableCreateDTO,
  NotificationDefaults,
  IUpdateLinkDTO,
  ICreateLinkDTO,
  IEventUpdateDTO,
  ISignupUpdateDTO,
  IContentReferralSummary,
  IContentReferral,
  IUserContentResultsV2,
  IContentCancelation,
  UserContentNotifications,
  IContentMetadata,
  LandingPage,
  ContentLink,
  ContentSignup,
  ContentResponseType,
  IContentManagementService
} from 'models';
import { Observable, from } from 'rxjs';

export class MockContentManagementService implements IContentManagementService {
  removeUserFromContent(contentId: string, userId: string) {
    return null;
  }

  // messages
  sendTestSingleSendMessage({
    data
  }: {
    data: ISendTestMessageSingleSendData;
  }): Promise<string> {
    return Promise.resolve(null);
  }

  sendTestContentMessage({
    data
  }: {
    data: ISendTestMessageContentData;
  }): Promise<string> {
    return Promise.resolve(null);
  }

  sendTestMessage({ body }: { body: ISendTestMessage }): Promise<string> {
    return Promise.resolve(null);
  }

  generateSampleCopy(body: {
    title: string;
    type: ContentType;
  }): Promise<{ title: string; subtitle: string; body: string }> {
    return Promise.resolve(null);
  }

  // Landing Pages
  editLandingPage(
    id: string,
    payload: Partial<UpdateLandingPageDTO>,
    showConfirmation
  ): Promise<boolean> {
    return Promise.resolve(null);
  }

  createLandingPage(payload: CreateLandingPageDTO): Promise<{ id: string }> {
    return Promise.resolve(null);
  }

  deleteLandingPage(id: string) {
    return null;
  }

  // Content
  requestEventDuplicate(id: string): Promise<IEventCreateDTO> {
    return Promise.resolve(null);
  }

  requestDropDuplicate(id: string): Promise<ISignupCreateDTO> {
    return Promise.resolve(null);
  }

  toggleUserJoinStatus(userId: string, contentId: string, joined) {
    return null;
  }

  fetchNotificationDefaults(
    payload: IContentRegisterableCreateDTO
  ): Promise<NotificationDefaults> {
    return Promise.resolve(null);
  }

  editLink(
    linkId: string,
    payload: IUpdateLinkDTO
  ): Promise<ContentResponseType> {
    return Promise.resolve(null);
  }

  createLink(
    payload: ICreateLinkDTO,
    showConfirmation: boolean
  ): Promise<ContentResponseType> {
    return Promise.resolve(null);
  }

  deleteLink(linkId: string) {
    return null;
  }

  editEvent(
    contentId: string,
    payload: IEventUpdateDTO
  ): Promise<ContentResponseType> {
    return Promise.resolve(null);
  }

  createEvent(payload: IEventCreateDTO): Promise<ContentResponseType> {
    return Promise.resolve(null);
  }
  deleteEvent(eventId: string) {
    return null;
  }

  editSignup(
    contentId: string,
    payload: ISignupUpdateDTO,
    showConfirmation: boolean
  ): Promise<ContentResponseType> {
    return Promise.resolve(null);
  }
  createSignup(payload: ISignupCreateDTO): Promise<ContentResponseType> {
    return Promise.resolve(null);
  }
  deleteSignup(dropId: string) {
    return Promise.resolve(null);
  }
  getContentReferrerSummaries$(
    contentId: string
  ): Observable<IContentReferralSummary[]> {
    return from([null]);
  }
  getContentReferrals$({
    contentId,
    referrerId
  }: {
    contentId: string;
    referrerId: string;
  }): Observable<IContentReferral[]> {
    return from([null]);
  }

  getRsvpCsvUrl({ contentId }: { contentId: string }) {
    return null;
  }

  getRsvps(input: {
    contentId: string;
    cursor?: string;
    limit?: number;
    offset?: number;
  }): Promise<IUserContentResultsV2> {
    return Promise.resolve(null);
  }

  cancelDrop(dropId: string, body: IContentCancelation) {
    return null;
  }

  cancelEvent(eventId: string, body: IContentCancelation) {
    return null;
  }

  setContentRegistrationSenderSettings(
    contentId: string,
    userId: string,
    sendNotifications: UserContentNotifications
  ) {
    return null;
  }
  getContentMetadata$<T extends IContentMetadata>(id: string): Observable<T> {
    return from([null]);
  }

  getContentMetadata<T extends IContentMetadata>(id: string): Promise<T> {
    return Promise.resolve(null);
  }

  getSlugCollection$({
    limit,
    published,
    startAfter
  }: {
    limit?: number;
    published?: boolean;
    startAfter?: string;
  }) {
    return from([null]);
  }

  // Force round trip
  getLandingPagesForCurrentSlug({
    limit,
    published
  }: {
    limit?: number;
    published?: boolean;
  }): Promise<LandingPage[]> {
    return Promise.resolve(null);
  }

  getLandingPagesForCurrentSlug$({
    limit,
    published,
    startAfter
  }: {
    limit?: number;
    published?: boolean;
    startAfter?: string;
  }): Observable<LandingPage[]> {
    return from([null]);
  }

  getLinksCollection$({
    published,
    descending,
    limit,
    startAfter
  }: {
    published?: boolean;
    descending?: boolean;
    limit?: number;
    startAfter?: string;
  }) {
    return from([null]);
  }

  getLinksForCurrentSlug$({
    published,
    descending,
    limit,
    startAfter
  }: {
    published?: boolean;
    descending?: boolean;
    limit?: number;
    startAfter?: string;
  }): Observable<ContentLink[]> {
    return from([null]);
  }

  getDropsCollection$({
    published,
    limit,
    startAfter
  }: {
    published?: boolean;
    limit?: number;
    startAfter?: string;
  }) {
    return from([null]);
  }

  getDropsForCurrentSlug$({
    published,
    limit,
    startAfter
  }: {
    published?: boolean;
    limit?: number;
    startAfter?: string;
  }): Observable<ContentSignup[]> {
    return from([null]);
  }

  uploadImageFromUrl(fileUrl: string, notifyUser: boolean): Promise<string> {
    return Promise.resolve(null);
  }
}
