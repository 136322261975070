<div class="send-detail-view-container-wrapper">
  <div class="send-detail-view-container">
    <div class="space-wrapper">
      <div
        class="unthemed-card-styles block overflow-hidden border-solid border border-border-color-split rounded-none"
        *ngIf="isEmail"
      >
        <div class="header">
          <div class="meta-wrapper">
            <div *ngIf="send" class="meta">
              <p>
                From: <strong>{{ fromName }}</strong>
              </p>
              <p>
                Reply to: <strong>{{ replyTo }}</strong>
              </p>
              <p *ngIf="send.subject">
                Subject: <strong>{{ send.subject }}</strong>
              </p>
            </div>

            <root-skeleton
              *ngIf="!send"
              [title]="false"
              [paragraph]="{ rows: 3, width: 150 }"
            >
            </root-skeleton>
          </div>
        </div>
      </div>

      <div
        class="unthemed-card-styles block overflow-hidden border-solid border border-border-color-split rounded-none"
      >
        <div class="send-body ql-readonly-body text-base">
          <div *ngIf="isEmail && !send?.hasBeenSent">
            <div
              *ngFor="
                let block of send?.blocks;
                let i = index;
                trackBy: trackBy
              "
              class="flex justify-center break-all w-full"
              [innerHtml]="
                block.getHtml(slug, send?.theme, send?.contentBlockMap)
                  | safeHtml
              "
            ></div>
          </div>
          <div
            *ngIf="isEmail && send?.hasBeenSent"
            [innerHtml]="send?.renderedBody | safeHtml"
          ></div>
        </div>
      </div>

      <div *ngIf="isSms">
        <div
          *ngIf="send?.attachments?.length > 0"
          class="flex flex-row flex-wrap"
        >
          <div
            *ngFor="
              let attachment of send.attachments;
              let i = index;
              trackBy: attachmentTrackBy
            "
            class="mr-3 mb-3 w-24 h-24 rounded-lg bg-neutral-200 flex justify-center items-center overflow-hidden"
          >
            <lib-soft-image-view
              [imageUrl]="attachment.source"
              [alt]="attachment.filename"
            >
            </lib-soft-image-view>
          </div>
        </div>

        <div
          *ngIf="send?.blocks?.length > 0 && send.firstTextBlockContent"
          class="rounded-tl-lg rounded-tr-lg rounded-br-lg bg-neutral-200 text-lg text-neutral-900 p-6 mr-6 whitespace-pre-wrap"
        >
          {{ send.firstTextBlockContent }}
        </div>
      </div>
    </div>
  </div>
</div>
