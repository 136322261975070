import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyIconModule } from './norby-icon.module';
import { CheckboxModule } from './checkbox.module';
import { NorbyIconRadioButtonComponent } from '../components/molecules/norby-icon-radio-button';

@NgModule({
  declarations: [NorbyIconRadioButtonComponent],
  imports: [CommonModule, NorbyIconModule, CheckboxModule],
  exports: [NorbyIconRadioButtonComponent]
})
export class NorbyIconRadioButtonModule {}
