<div class="flex flex-1 justify-start">
  <div class="mb-3 w-full">
    <norby-formcontrol-label
      [label]="label"
      [infoTooltip]="infoTooltip"
      [isRequired]="isRequired"
    ></norby-formcontrol-label>

    <select
      (change)="handleSelectChange($event)"
      [value]="val"
      [disabled]="isDisabled"
      rows="5"
      class="select-icon text-base appearance-none m-0 cursor-pointer user-theme-select form-control block transition ease-in-out outline-none w-full border bg-white disabled:bg-neutral-50 text-neutral-800 disabled:text-neutral-500"
      [ngClass]="{
        'border-red-base cursor-not-allowed': showError,
        'border-neutral-300 hover:border-neutral-500 focus:border-mustard disabled:border-neutral-300 ':
          !showError,
        'text-neutral-500': isPlaceholderSelected,
        'rounded-sm': !isJoinedRight && !isJoinedLeft,
        'rounded-l-sm rounded-r-none user-theme-left-select':
          isJoinedRight && !isJoinedLeft,
        'rounded-r-sm rounded-l-none user-theme-right-select':
          isJoinedLeft && !isJoinedRight,
        'rounded-none': isJoinedLeft && isJoinedRight,
        'h-12 pl-3 pr-4 leading-5': size === 'medium',
        'h-9 px-3': size === 'small'
      }"
    >
      <option *ngIf="!!prompt" [ngValue]="null" selected disabled class="z-10">
        {{ prompt }}
      </option>
      <ng-content></ng-content>
    </select>

    <norby-helpertext [helperText]="helperText"></norby-helpertext>
  </div>
</div>
