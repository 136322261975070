import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  Optional
} from '@angular/core';
import { IconService } from '../../services/icon';

@Component({
  selector: 'root-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  private _svgIcon: SVGElement;

  @Input()
  @HostBinding('class')
  size: 'small' | 'large';

  @Input()
  set name(iconName: string) {
    if (this._svgIcon) {
      this._element.nativeElement.removeChild(this._svgIcon);
    }

    const svgData = this._iconService.getIcon(iconName);
    this._svgIcon = this._svgElementFromString(svgData);
    this._element.nativeElement.appendChild(this._svgIcon);
  }

  constructor(
    private _element: ElementRef,
    private _iconService: IconService,
    @Optional() @Inject(DOCUMENT) private _document: any
  ) {}

  private _svgElementFromString(svgContent: string): SVGElement {
    const div = this._document.createElement('DIV');
    div.innerHTML = svgContent;

    return (
      div.querySelector('svg') ||
      this._document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
  }
}
