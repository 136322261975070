import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NorbySliderComponent } from '../components/molecules/norby-slider';
import { NorbyFormcontrolLabelModule } from './norby-formcontrol-label.module';
import { NorbyHelpertextModule } from './norby-helpertext.module';
import { NorbyButtonModule } from './norby-button.module';

@NgModule({
  declarations: [NorbySliderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NorbyFormcontrolLabelModule,
    NorbyHelpertextModule,
    NorbyButtonModule
  ],
  exports: [NorbySliderComponent]
})
export class NorbySliderModule {}
