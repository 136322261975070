import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { DropdownComponent } from '../root-components/dropdown';
import { POSITION_MAP } from '../constants';

@Directive({
  selector: '[customFilter]'
})
export class CustomFilterDirective
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input('customFilter') filterOverlay: DropdownComponent;
  @Input() position: 'center' | 'right' = 'center';
  @Input() mouseOnChild: boolean = false;
  @Input() closeOnClick: boolean = false;
  @Input() keepOpen: boolean = false;
  @Input() addFilter: boolean = false;
  @Input() tagText: string;
  @Input() isVisible: boolean;

  @Output() mouseOnDropdown: EventEmitter<boolean> = new EventEmitter();
  @Output() onOverlayClosed = new EventEmitter<void>();

  private _overlayRef: OverlayRef;
  private _mouseOnDropdown: boolean = false;
  private _mouseOnHost: boolean = false;
  private _hasListeners: boolean = false;
  private _dropdownInstance: TemplatePortal<any>;
  private _dropdownEnterListener: () => void;
  private _dropdownLeaveListener: () => void;
  private _dropdownClickListener: () => void;
  private _outsideClickListener: () => void;
  private _addFilterClickListener: () => void;
  private _triggerWidth: number;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platform,
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private _r2: Renderer2
  ) {}

  getPosition(): any[] {
    switch (this.position) {
      case 'center':
        return [
          POSITION_MAP.bottomExtendRight,
          POSITION_MAP.topExtendRight,
          POSITION_MAP.bottomExtendLeft,
          POSITION_MAP.topExtendLeft
        ];
      case 'right':
        return [
          POSITION_MAP.rightLowerOffset10,
          POSITION_MAP.rightUpperOffset10,
          POSITION_MAP.leftLower,
          POSITION_MAP.leftUpper,
          POSITION_MAP.bottomExtendRight,
          POSITION_MAP.topExtendRight,
          POSITION_MAP.bottomExtendLeft,
          POSITION_MAP.topExtendLeft
        ];
      default:
        return [POSITION_MAP.bottomMiddle, POSITION_MAP.topMiddle];
    }
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this._platform)) {
      return;
    }

    setTimeout(() => {
      this._triggerWidth =
        this._elementRef.nativeElement.getBoundingClientRect().width;
      const positionStrategy = this._overlayPositionBuilder
        .flexibleConnectedTo(this._elementRef)
        .withPositions(this.getPosition());

      this._overlayRef = this._overlay.create({
        positionStrategy,
        minWidth: this._triggerWidth / 2,
        scrollStrategy: this._overlay.scrollStrategies.reposition()
      });

      this._dropdownInstance = new TemplatePortal(
        this.filterOverlay?.templateRef,
        this._viewContainerRef
      );
    });
  }

  ngOnDestroy() {
    this._dropdownEnterListener?.();
    this._dropdownLeaveListener?.();
    this._outsideClickListener?.();
    this._dropdownClickListener?.();
    this._addFilterClickListener?.();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tagText && changes.tagText.currentValue?.length > 0) {
      this.show();
    }
    if (changes.isVisible && this._overlayRef) {
      if (changes.isVisible.currentValue) {
        this.clickOpen();
      } else {
        this._overlayRef.detach();
        this.onOverlayClosed.emit();
      }
    }
  }

  @HostListener('click')
  clickOpen() {
    this.mouseEnterHost();
    if (this.tagText === '') {
      return;
    }
    this.show();
  }

  @HostListener('mouseenter')
  mouseEnterHost() {
    this._mouseOnHost = true;

    // dropdown menu actions
    if (!this._hasListeners && this._overlayRef) {
      this._hasListeners = true;
      this._dropdownLeaveListener = this._r2.listen(
        this._overlayRef.overlayElement,
        'mouseleave',
        () => {
          this._mouseOnDropdown = false;
          this.mouseOnDropdown.emit(false);
        }
      );

      this._dropdownEnterListener = this._r2.listen(
        this._overlayRef.overlayElement,
        'mouseenter',
        () => {
          this._mouseOnDropdown = true;
          this.mouseOnDropdown.emit(true);

          const addButton =
            this._document.getElementsByClassName('add-filter-button')[0];

          if (this.addFilter && addButton) {
            this._addFilterClickListener = this._r2.listen(
              addButton,
              'click',
              () => {
                this._mouseOnDropdown = false;
                this.mouseOnDropdown.emit(false);
                this.hide();
              }
            );
          }
        }
      );

      if (this.closeOnClick) {
        this._dropdownClickListener = this._r2.listen(
          this._overlayRef.overlayElement,
          'click',
          (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (!this.keepOpen) {
              this._mouseOnDropdown = false;
              this.hide();
            }
          }
        );
      }
    }
  }

  @HostListener('mouseleave')
  mouseLeaveHost() {
    this._mouseOnHost = false;
  }

  show() {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this._overlayRef.attach(this._dropdownInstance);

      this._outsideClickListener = this._r2.listen(
        this._document,
        'click',
        () => this.hide()
      );
    }
  }

  hide() {
    if (!this._mouseOnDropdown && !this._mouseOnHost && !this.mouseOnChild) {
      this._overlayRef.detach();
      this.onOverlayClosed.emit();
    }
  }
}
