import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { User } from '@angular/fire/auth';
import { firstValueFrom, from, switchMap } from 'rxjs';

// App
import { DeviceService } from '../device';
import {
  IApiService,
  ApiSurfaces,
  API_URL_TOKEN,
  AUTH_URL_TOKEN,
  BILLING_PORTAL_URL_TOKEN,
  END_USER_API_URL_TOKEN,
  ANALYTICS_API_URL_TOKEN,
  ADMIN_NLQ_TOKEN
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements IApiService {
  private _currentUser: User;

  constructor(
    @Inject(API_URL_TOKEN) private _apiUrl: string,
    @Inject(AUTH_URL_TOKEN) private _authUrl: string,
    @Optional() @Inject(BILLING_PORTAL_URL_TOKEN) private _billingUrl: string,
    @Optional() @Inject(END_USER_API_URL_TOKEN) private _endUserApiUrl: string,
    @Optional()
    @Inject(ANALYTICS_API_URL_TOKEN)
    private _analyticsApiUrl: string,
    @Optional()
    @Inject(ADMIN_NLQ_TOKEN)
    private _adminNlqApiUrl: string,
    private _http: HttpClient,
    private _device: DeviceService
  ) {}

  // Called by auth service to let us know the current user
  // TODO: Move headers to interceptor that can subscribe
  // to auth state
  setCurrentUser(user: User) {
    this._currentUser = user;
  }

  get currentUser() {
    return this._currentUser;
  }

  constructApiUrl(uri: string, service: ApiSurfaces) {
    let base;
    const hasShortendDomain = this._device.hostname.includes('nor.by');

    switch (service) {
      case ApiSurfaces.API:
        base = hasShortendDomain
          ? this._apiUrl.replace('norby.live', 'nor.by')
          : this._apiUrl;
        break;
      case ApiSurfaces.AUTH:
        base = hasShortendDomain
          ? this._authUrl.replace('norby.live', 'nor.by')
          : this._authUrl;
        break;
      case ApiSurfaces.BILLING:
        // billing injector not provided in root app so throw if undefined
        if (this._billingUrl) {
          base = this._billingUrl;
        } else {
          throw new Error('Cannot access Billing API from here.');
        }
        break;
      case ApiSurfaces.END_USER:
        // end user injector not provided outside root app so throw if undefined
        if (this._endUserApiUrl) {
          base = this._endUserApiUrl;
        } else {
          throw new Error('Cannot access End User API from here.');
        }
        break;
      case ApiSurfaces.ANALYTICS:
        // only provided in super admin for now
        if (this._analyticsApiUrl) {
          base = this._analyticsApiUrl;
        } else {
          throw new Error('Cannot access Analytics API from here.');
        }
        break;
      case ApiSurfaces.ADMIN_NLQ:
        // only provided in super admin for now
        if (this._adminNlqApiUrl) {
          base = this._adminNlqApiUrl;
        } else {
          throw new Error('Cannot access Admin NLQ API from here.');
        }
        break;
      default:
        throw new Error(`API surface '${service}' not recognized.`);
    }

    const url = new URL(uri, base);
    return url.href;
  }

  private async _constructHttpOpts(args?: {
    params?: any;
    additionalHeaders?: any;
    recaptchaToken?: string;
  }) {
    const { params, additionalHeaders, recaptchaToken } = args ?? {};
    return {
      withCredentials: true,
      headers: await this._contructHeaders(additionalHeaders, recaptchaToken),
      ...(params && { params })
    };
  }

  private async _contructHeaders(
    additionalHeaders?: any,
    recaptchaToken?: string
  ) {
    const accessToken = await this._currentUser?.getIdToken();
    const headers = {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      'x-slug': this._device.currentSlug,
      'x-client-tz': this._device.timezone,
      ...(additionalHeaders && additionalHeaders),
      ...(recaptchaToken && { 'x-recaptcha-token': recaptchaToken })
    };

    return headers;
  }

  async post<T>(
    service: ApiSurfaces,
    route: string,
    body?: any,
    additionalHeaders?: any,
    recaptchaToken?: string
  ) {
    const opts = await this._constructHttpOpts({
      additionalHeaders,
      recaptchaToken
    });
    const url = this.constructApiUrl(route, service);
    return firstValueFrom<T>(this._http.post<T>(url, body, opts));
  }

  async put<T>(service: ApiSurfaces, route: string, body?: any) {
    const opts = await this._constructHttpOpts();
    const url = this.constructApiUrl(route, service);
    return firstValueFrom<T>(this._http.put<T>(url, body, opts));
  }

  async patch<T>(service: ApiSurfaces, route: string, body?: any) {
    const opts = await this._constructHttpOpts();
    const url = this.constructApiUrl(route, service);
    return firstValueFrom<T>(this._http.patch<T>(url, body, opts));
  }

  async delete<T>(service: ApiSurfaces, route: string, params?: any) {
    const opts = await this._constructHttpOpts({ params });
    const url = this.constructApiUrl(route, service);
    return firstValueFrom<T>(this._http.delete<T>(url, opts));
  }

  async get<T>(
    service: ApiSurfaces,
    route: string,
    params?: any,
    additionalHeaders?: any
  ) {
    return firstValueFrom<T>(
      this.get$<T>(service, route, params, additionalHeaders)
    );
  }

  get$<T>(
    service: ApiSurfaces,
    route: string,
    params?: any,
    additionalHeaders?: any
  ) {
    const url = this.constructApiUrl(route, service);
    return from(this._constructHttpOpts({ params, additionalHeaders })).pipe(
      switchMap((opts) => this._http.get<T>(url, opts))
    );
  }
}
