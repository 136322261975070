import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyFlyoutMenuComponent } from '../components/organisms/norby-flyout-menu/norby-flyout-menu.component';
import { NorbyFlyoutOptionsGroupModule } from './norby-flyout-options-group.module';

@NgModule({
  declarations: [NorbyFlyoutMenuComponent],
  imports: [CommonModule, NorbyFlyoutOptionsGroupModule],
  exports: [NorbyFlyoutMenuComponent]
})
export class NorbyFlyoutMenuModule {}
