import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DateRangeValidators {
  static dateRange(
    startDate: string,
    endDate: string,
    validatorField: { [key: string]: boolean }
  ): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const date1 = new Date(c.get(startDate).value);
      const date2 = new Date(c.get(endDate).value);

      if (date1 && date2 && date1 >= date2) {
        return validatorField;
      }

      return null;
    };
  }
}
