import { Content } from './content';
import { Theme } from './ISlug';
import { IFont } from '../interfaces';

export type SelectorValueType = Content | Theme | IFont | string;

export enum NorbySelectorTypesEnum {
  CONTENT_SELECTOR = 'content-selector',
  THEME_SELECTOR = 'theme-selector',
  SOCIAL_LINKS_SELECTOR = 'social-links-selector',
  FONT_SELECTOR = 'font-selector',
  LAYOUT_SELECTOR = 'layout-selector',
  CONTENT_SELECTOR_BY_ID = 'content-selector-by-id'
}
