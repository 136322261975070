import { Directive, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

// 3rd party
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

/**
 * Remember to call super.ngOnDestroy() if extending and
 * overriding ngOnDestroy or super.ngOnChanges() if
 * extending and overriding ngOnChanges
 */
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class BaseComponent implements OnDestroy, OnChanges {
  private _destroy$?: Subject<void>;
  private _change$?: Subject<void>;

  ngOnChanges(changes?: SimpleChanges) {
    this._change$?.next();
  }

  ngOnDestroy() {
    this._destroy$?.next();
    this._destroy$?.complete();
    this._change$?.next();
    this._change$?.complete();
  }

  protected takeUntilDestroy = <T>(source: Observable<T>): Observable<T> => {
    // Destroy subject created lazily
    if (!this._destroy$) this._destroy$ = new Subject<void>();

    return source.pipe(takeUntil(this._destroy$));
  };

  protected takeUntilChanges = <T>(source: Observable<T>): Observable<T> => {
    // Changes subject created lazily
    if (!this._change$) this._change$ = new Subject<void>();

    return source.pipe(takeUntil(this._change$));
  };
}
