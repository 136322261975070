import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyRadioButtonGroupComponent } from '../components/molecules/norby-radio-button-group/norby-radio-button-group.component';
import { NorbyIconModule } from './norby-icon.module';
import { NorbyRadioButtonModule } from './norby-radio-button.module';

@NgModule({
  declarations: [NorbyRadioButtonGroupComponent],
  imports: [CommonModule, NorbyIconModule, NorbyRadioButtonModule],
  exports: [NorbyRadioButtonGroupComponent]
})
export class NorbyRadioButtonGroupModule {}
