<!-- Show RSVP button if drop is published and there's no user content or rsvp is disabled -->
<div
  *ngIf="drop?.published && !userContent?.rsvpEnabled"
  [logEvent]="userClickedPreEventCta"
  [cubeData]="{ entityType: 'drop', entityId: drop?.contentId }"
>
  <root-button
    type="primary"
    size="large"
    [block]="true"
    [loading]="isLoading"
    [ariaLabel]="preLabel"
    [name]="preLabel"
    [text]="preLabel"
    [disabled]="isDisabled"
    (handleClick)="handleRsvpClick()"
  >
  </root-button>
</div>

<!-- Show confirmed if it's published and we've rsvped -->
<div *ngIf="drop?.published && userContent?.rsvpEnabled">
  <root-button
    type="primary"
    size="large"
    [block]="true"
    [loading]="isLoading"
    aria-label="Menu"
    role="button"
    name="Menu"
    [text]="preConfirmedLabel"
    [rootDropdown]="rsvpMenu"
    position="center"
    ><root-icon name="chevron_down"></root-icon>
  </root-button>
</div>

<!-- Show disabled RSVP button if creating a new signup -->
<div *ngIf="!drop?.published">
  <root-button
    type="primary"
    size="large"
    [block]="true"
    [loading]="isLoading"
    [ariaLabel]="preLabel"
    [name]="preLabel"
    [text]="preLabel"
    [disabled]="true"
  >
  </root-button>
</div>

<!-- REMOVE RSVP MENU -->
<root-dropdown-menu #rsvpMenu>
  <ul rootDropdownMenu>
    <li
      rootDropdownMenuItem
      *ngIf="
        !userContent?.receiveNotifications?.sms && drop?.hasPhoneRequirement
      "
      (click)="handleEnableSMS()"
    >
      <root-icon name="message_circle"></root-icon>
      Turn on texts
    </li>

    <li
      rootDropdownMenuItem
      *ngIf="userContent?.receiveNotifications?.sms"
      (click)="handleDisableSMS()"
    >
      <root-icon name="x_circle"></root-icon>
      Turn off texts
    </li>

    <li
      rootDropdownMenuItem
      *ngIf="
        !userContent?.receiveNotifications?.email && drop?.hasEmailRequirement
      "
      (click)="handleEnableEmail()"
    >
      <root-icon name="mail"></root-icon>
      Turn on emails
    </li>

    <li
      rootDropdownMenuItem
      *ngIf="userContent?.receiveNotifications?.email"
      (click)="handleDisableEmail()"
    >
      <root-icon name="x_circle"></root-icon>
      Turn off emails
    </li>

    <li
      rootDropdownMenuItemDivider
      *ngIf="
        (drop?.hasEmailRequirement || drop?.hasPhoneRequirement) &&
        !drop?.isPaid
      "
    ></li>

    <li *ngIf="!drop?.isPaid" rootDropdownMenuItem (click)="handleRemoveRSVP()">
      <root-icon name="trash"></root-icon>
      Remove signup
    </li>
  </ul>
</root-dropdown-menu>
