<div live-themed [theme]="block?.theme">
  <div
    *ngIf="textBlock?.body"
    class="text-color ql-readonly-body"
    [innerHTML]="textBlock.body"
  ></div>
  <lib-link-card-view
    *ngIf="linkBlock && !showAsAbstract"
    [link]="linkBlock.content"
    [blockTitle]="linkBlock.title"
    (cardClick)="handleClick($event)"
  >
  </lib-link-card-view>
  <lib-abstract-link-view *ngIf="linkBlock && showAsAbstract">
  </lib-abstract-link-view>
  <lib-event-drop-card
    *ngIf="eventDropBlock && !showAsAbstract"
    [content]="eventDropBlock.content"
    [overrideTitle]="eventDropBlock.title"
    [overrideSignupType]="eventDropBlock.signupType"
    (cardClick)="handleClick($event)"
  >
  </lib-event-drop-card>
  <lib-abstract-signup-view *ngIf="signupBlock && showAsAbstract">
  </lib-abstract-signup-view>
  <lib-abstract-event-view *ngIf="eventBlock && showAsAbstract">
  </lib-abstract-event-view>
  <lib-profile-view
    *ngIf="profileBlock"
    [block]="profileBlock"
    [accountInfo]="accountInfo"
  ></lib-profile-view>
  <lib-social-icons-view
    *ngIf="socialIconsBlock"
    [socialLinks]="socialIconsBlock.settingsV2"
  >
  </lib-social-icons-view>
  <lib-image-block-view
    *ngIf="imageBlock"
    [imageBlock]="imageBlock"
  ></lib-image-block-view>
  <lib-spacer-view *ngIf="spacerBlock" [block]="spacerBlock"></lib-spacer-view>
  <lib-upcoming-events
    *ngIf="upcomingEventsBlock"
    [block]="upcomingEventsBlock"
    (cardClick)="handleClick($event)"
  >
  </lib-upcoming-events>
  <lib-youtube-embed-view
    *ngIf="youtubeBlock"
    [block]="youtubeBlock"
    [pageId]="pageId"
  >
  </lib-youtube-embed-view>
  <lib-vimeo-embed-view
    *ngIf="vimeoBlock"
    [embedCode]="vimeoBlock.embedCode"
  ></lib-vimeo-embed-view>
  <lib-spotify-embed-view
    *ngIf="spotifyBlock"
    [block]="spotifyBlock"
    [pageId]="pageId"
  ></lib-spotify-embed-view>
  <lib-calendly-embed-view
    *ngIf="calendlyBlock"
    [embedCode]="calendlyBlock.embedCode"
  ></lib-calendly-embed-view>

  <lib-embed-view *ngIf="embedBlock" [embedCode]="embedBlock.embedCode">
  </lib-embed-view>
  <lib-newsletter-signup-view
    *ngIf="newsletterSignupBlock && !showAsAbstract"
    [block]="newsletterSignupBlock"
  >
  </lib-newsletter-signup-view>
  <lib-header-view
    *ngIf="headerBlock"
    [block]="headerBlock"
    [dangleAvatar]="showAsAbstract"
  >
  </lib-header-view>
</div>
