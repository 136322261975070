import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { SingleSend, ISlug, MmsAttachment } from 'models';

@Component({
  selector: 'norby-send-detail-view',
  templateUrl: './send-detail-view.component.html',
  styleUrls: ['./send-detail-view.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendDetailViewComponent {
  private _cachedSendHash: number;

  @Output() menuStateChanged = new EventEmitter<void>();

  @Input() send: SingleSend;
  @Input() slug: ISlug;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngDoCheck() {
    if (this.send && this.send.hash !== this._cachedSendHash) {
      this._cachedSendHash = this.send.hash;
      this._cdr.markForCheck();
    }
  }

  handleMenuStateChanged() {
    this.menuStateChanged.next();
  }

  get isEmail(): boolean {
    return this.send?.deliveryType === 'email';
  }

  get isSms(): boolean {
    return this.send?.deliveryType === 'sms';
  }

  get fromName(): string {
    return this.send?.senderName || this.slug?.accountInfo?.title || 'No name';
  }

  get replyTo(): string {
    return this.send?.replyTo || this.slug?.mailing?.replyTo || 'No reply to';
  }

  attachmentTrackBy(idx: number, item: MmsAttachment) {
    return item?.source;
  }

  trackBy(idx: number, item) {
    return item.hash;
  }
}
