import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyCheckboxComponent } from '../components';
import { CheckboxModule } from './checkbox.module';

@NgModule({
  declarations: [NorbyCheckboxComponent],
  imports: [CommonModule, CheckboxModule],
  exports: [NorbyCheckboxComponent]
})
export class NorbyCheckboxModule {}
