<div
  class="bg-white rounded shadow p-4 flex flex-col gap-4 transition ease-in-out w-78"
>
  <form [formGroup]="formGroup">
    <div class="flex flex-row gap-4 w-full">
      <img [src]="imageCopy.url" class="object-cover w-24 h-24 rounded" />
      <norby-text-area
        *ngIf="!isAltTextDisabled"
        class="mt-4 w-50"
        label="Alt text"
        placeholder="Add alt text"
        formControlName="altText"
        [isDisabled]="isDisabled"
      ></norby-text-area>
    </div>

    <div class="w-full flex items-center justify-between">
      <div
        class="flex flex-row items-center cursor-pointer"
        nz-popconfirm
        nzPopconfirmTitle="Are you sure?"
        nzOkText="Delete"
        (nzOnConfirm)="handleDeleteButtonClick()"
      >
        <norby-icon name="trash"></norby-icon>
        <label class="cursor-pointer">Delete</label>
      </div>
      <div class="flex flex-row gap-2 items-center">
        <norby-button
          buttonType="secondary"
          text="Cancel"
          (click)="handleCancelButtonClick()"
        ></norby-button>
        <norby-button
          text="Save"
          (click)="handleSaveButtonClick()"
          [isDisabled]="isDisabled"
        ></norby-button>
      </div>
    </div>
  </form>
</div>
