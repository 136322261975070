import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

// 3rd party
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

// Libs
import { ContentSignup, ContentSignupMetadata } from 'models';
import { BaseComponent } from 'uikit';
import { SummaryCardViewItem } from '../../../../views';

@Component({
  selector: 'lib-signup-summary-step',
  templateUrl: './signup-summary-step.component.html',
  styleUrls: ['./signup-summary-step.component.less']
})
export class SignupSummaryStepComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() signup: ContentSignup;
  @Input() signupMetadata: ContentSignupMetadata;
  @Input() isSignupValid: boolean;

  @Output() onUpdatedSignup: EventEmitter<ContentSignup> =
    new EventEmitter<ContentSignup>();
  @Output() onGoToStep: EventEmitter<number> = new EventEmitter<number>();

  signupName: string;
  subtitle: string;
  capacity: string;
  registrationDeadline: string;
  requiredInformation: string;
  customFormFields: string;

  detailsItems: SummaryCardViewItem[] = [];
  registrationItems: SummaryCardViewItem[] = [];

  constructor() {
    super();
  }

  ngOnChanges(): void {
    this._loadValues();
    this._loadItems();
  }

  private _loadValues() {
    this.signupName = this.signup?.title || 'Not set';
    this.subtitle = this.signup?.subtitle || 'Not set';
    this.capacity =
      this.signupMetadata?.rsvpRestrictions?.limit?.toString() || 'No limit';
    this.registrationDeadline = this.signup?.registrationCloseDate
      ? dayjs(this.signup.registrationCloseDate).format('MMMM D, YYYY h:mm A')
      : 'Not set';
    this.customFormFields = this.signup?.prompts?.length
      ? this.signup.prompts.map((p) => p.prompt).join(', ')
      : 'None';

    let infos: string[] = [];
    if (this.signup?.userInfoRequirements?.displayName?.required) {
      infos.push('Name');
    }
    if (this.signup?.privateUserInfoRequirements?.email?.required) {
      infos.push('Email');
    }
    if (this.signup?.privateUserInfoRequirements?.phoneNumber?.required) {
      infos.push('Phone');
    }
    this.requiredInformation = infos.join(', ') || 'None';
  }

  private _loadItems() {
    this.detailsItems = [
      {
        title: 'Title',
        value: this.signupName
      },
      {
        title: 'Subtitle',
        value: this.subtitle
      }
    ];

    this.registrationItems = [
      {
        title: 'Required information',
        value: this.requiredInformation
      },
      {
        title: 'Custom fields',
        value: this.customFormFields
      },
      {
        title: 'Capacity',
        value: this.capacity
      },
      {
        title: 'Registration deadline',
        value: this.registrationDeadline
      }
    ];
  }
}
