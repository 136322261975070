import { RolesEnum } from './general';

export type UserSlugNotificationType =
  | 'eventPublished'
  // | 'eventCreate'
  // | 'eventUpdate'
  | 'eventDelete'
  | 'eventSingleSendScheduled'
  | 'eventSingleSendSent'
  | 'dropPublished'
  // | 'dropCreate'
  // | 'dropUpdate'
  | 'dropDelete'
  | 'dropSingleSendScheduled'
  | 'dropSingleSendSent'
  | 'linkPublished'
  // | 'linkCreate'
  // | 'linkUpdate'
  | 'linkDelete'
  | 'dropTicketPurchase'
  | 'eventTicketPurchase'
  | 'dropRsvp'
  | 'eventRsvp'
  | 'eventNotificationToxic'
  | 'dropToxic'
  | 'eventToxic'
  | 'linkToxic'
  | 'emailQuotaExceeded'
  | 'smsQuotaExceeded'
  | 'singleSendToxic'
  | 'dropSingleSendToxic'
  | 'refundRequested'
  | 'refundFailed'
  | 'dropNotificationToxic'
  | 'newsletterPublished'
  //| 'newsletterCreate'
  //| 'newsletterUpdate'
  | 'newsletterDelete'
  | 'newsletterSingleSendScheduled'
  | 'newsletterSingleSendSent'
  | 'newsletterTicketPurchase'
  | 'newsletterNotificationToxic'
  | 'newsletterRsvp'
  | 'newsletterToxic'
  | 'newsletterSingleSendToxic'
  | 'contactMerger';

export type UserSlugNotificationTypeToMinimumRole = {
  [key in UserSlugNotificationType]: RolesEnum;
};

export const UserSlugNotificationToMinumumRole: UserSlugNotificationTypeToMinimumRole =
  {
    eventSingleSendScheduled: 'editor',
    eventSingleSendSent: 'editor',
    eventDelete: 'contributor',
    eventPublished: 'contributor',
    eventRsvp: 'editor',
    eventToxic: 'editor',
    eventNotificationToxic: 'editor',
    eventTicketPurchase: 'editor',
    dropSingleSendScheduled: 'editor',
    dropSingleSendSent: 'editor',
    dropPublished: 'contributor',
    dropDelete: 'contributor',
    dropRsvp: 'contributor',
    dropTicketPurchase: 'editor',
    dropNotificationToxic: 'editor',
    dropToxic: 'editor',
    dropSingleSendToxic: 'editor',
    singleSendToxic: 'editor',
    linkPublished: 'contributor',
    linkDelete: 'contributor',
    linkToxic: 'contributor',
    emailQuotaExceeded: 'administrator',
    smsQuotaExceeded: 'administrator',
    newsletterPublished: 'contributor',
    newsletterDelete: 'contributor',
    newsletterNotificationToxic: 'editor',
    newsletterRsvp: 'contributor',
    newsletterSingleSendScheduled: 'editor',
    newsletterSingleSendSent: 'editor',
    newsletterSingleSendToxic: 'editor',
    newsletterTicketPurchase: 'editor',
    newsletterToxic: 'editor',
    refundFailed: 'editor',
    refundRequested: 'editor',
    contactMerger: 'administrator'
  };

export const UserSlugNotificationTypes: UserSlugNotificationType[] = [
  'eventPublished',
  // 'eventCreate',
  // 'eventUpdate',
  'eventDelete',
  'eventSingleSendScheduled',
  'eventSingleSendSent',
  'dropSingleSendScheduled',
  'dropSingleSendSent',
  'dropPublished',
  // 'dropCreate',
  // 'dropUpdate',
  'dropDelete',
  'linkPublished',
  // 'linkCreate',
  // 'linkUpdate',
  'linkDelete',
  'linkToxic',
  'dropTicketPurchase',
  'eventTicketPurchase',
  'dropRsvp',
  'eventRsvp',
  'eventNotificationToxic',
  'eventToxic',
  'dropToxic',
  'emailQuotaExceeded',
  'smsQuotaExceeded',
  'singleSendToxic',
  'dropSingleSendToxic',
  'refundRequested',
  'refundFailed',
  'dropNotificationToxic',
  'newsletterPublished',
  // 'newsletterCreate',
  // 'newsletterUpdate',
  'newsletterDelete',
  'newsletterSingleSendScheduled',
  'newsletterSingleSendSent',
  'newsletterTicketPurchase',
  'newsletterNotificationToxic',
  'newsletterRsvp',
  'newsletterToxic',
  'newsletterSingleSendToxic',
  'contactMerger'
];

export type UserSlugNotificationMedium = 'inApp' | 'email'; // 'sms' |
export const UserSlugNotificationMediums: UserSlugNotificationMedium[] = [
  'inApp',
  'email'
  // 'sms'
];
export class IUserSlugNotification {
  id!: string;
  userId!: string;
  slug!: string;
  role!: RolesEnum;
  enabled!: boolean;
  notificationType!: UserSlugNotificationType;
  notificationMedium!: UserSlugNotificationMedium;
  modifiedAt!: Date;
}

export class IToggleUserSlugNotificationAction {
  notificationType!: UserSlugNotificationType;
  notificationMedium!: UserSlugNotificationMedium;
  enabled!: boolean;
}

export class IToggleUserSlugNotificationsRequest {
  slug!: string;
  actions!: IToggleUserSlugNotificationAction[];
}
