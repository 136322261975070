import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyCopyBlockComponent } from '../components/atoms/norby-copy-block/norby-copy-block.component';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';

@NgModule({
  declarations: [NorbyCopyBlockComponent],
  imports: [CommonModule, SafeHtmlPipeModule],
  exports: [NorbyCopyBlockComponent]
})
export class NorbyCopyBlockModule {}
