import { Directive, OnDestroy, OnInit, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BaseComponent } from './base-component';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class FixedHeightComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  protected _document = inject(DOCUMENT);

  ngOnInit(): void {
    this.setDocumentScrollable(false);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.setDocumentScrollable(true);
  }

  setDocumentScrollable(scrollable = true) {
    const overflow = scrollable ? 'visible' : 'hidden';
    const overscroll = scrollable ? 'auto' : 'none';
    this._document.body.style.overflow = overflow;
    this._document.body.style.overscrollBehavior = overscroll;
    this._document.documentElement.style.overflow = overflow;
    this._document.documentElement.style.overscrollBehavior = overscroll;
  }
}
