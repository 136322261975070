import { isBrowser } from '../util';

const rnds8Pool = new Uint8Array(256); // # of random values to pre-allocate
let poolPtr = rnds8Pool.length;

export function rng() {
  if (poolPtr > rnds8Pool.length - 16) {
    if (isBrowser) {
      window.crypto.getRandomValues(rnds8Pool);
    } else {
      const crypto = require('crypto');
      crypto.randomFillSync(rnds8Pool);
    }
    poolPtr = 0;
  }
  return rnds8Pool.slice(poolPtr, (poolPtr += 16));
}
