import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[rootInputGroup]'
})
export class InputGroupDirective {
  @HostBinding('class')
  elementClass = 'table w-full user-theme-input-group';
  constructor() {}
}
