<div
  class="flex user-theme-checkbox-wrapper"
  [ngClass]="{
    'items-center': size === 'default',
    'items-start': size === 'small'
  }"
>
  <span
    class="relative cursor-pointer outline-none mr-2"
    [ngClass]="{
      'user-theme-checkbox-checked': checked,
      'cursor-not-allowed': disabled && !radio
    }"
  >
    <input
      #inputElement
      type="checkbox"
      class="absolute inset-0 w-full h-full cursor-pointer opacity-0 user-theme-checkbox-input"
      [attr.autofocus]="autoFocus ? 'autofocus' : null"
      [attr.id]="id"
      [checked]="checked"
      [ngModel]="checked"
      [disabled]="disabled"
      (ngModelChange)="innerCheckedChange($event)"
    />
    <span
      [ngClass]="radio ? 'user-theme-radio-inner' : 'user-theme-checkbox-inner'"
    ></span>
  </span>
  <span
    class="text-sm"
    [ngClass]="{
      'text-xs': size === 'small'
    }"
    ><ng-content></ng-content
  ></span>
</div>
