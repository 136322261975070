<div #slickList tabindex="-1">
  <!-- Render carousel items. -->
  <div #slickTrack>
    <ng-content></ng-content>
  </div>
</div>
<!-- Render dots. -->
<ul
  *ngIf="dots"
  [ngClass]="{
    'top-3 bottom-auto': dotPosition === 'top',
    'bottom-3': dotPosition === 'bottom',
    'slick-dots-left': dotPosition === 'left',
    'slick-dots-right': dotPosition === 'right',
    'carousel-vertical': vertical
  }"
  class="slick-dots"
>
  <li
    *ngFor="let content of carouselContents; let i = index"
    [class.slick-active]="i === activeIndex"
    (click)="handleLiClick(i)"
  >
    <ng-template
      [ngTemplateOutlet]="dotRender || renderDotTemplate"
      [ngTemplateOutletContext]="{ $implicit: i }"
    ></ng-template>
  </li>
</ul>

<ng-template #renderDotTemplate let-index>
  <button class="bg-white pointer-events-auto block w-full cursor-pointer outline-none">{{ index + 1 }}</button>
</ng-template>
