// Browse pages are not currently paginated but are
// limited to this number of events per query
export const PAGE_SIZE = 10;

// Time interval in minutes available as valid event times
export const EVENT_TIME_INTERVAL_MINUTES = 5;

// Search debounce time in ms for rate limiting
export const SEARCH_DEBOUNCE_TIME = 400;

// Debounce time in ms for slugmetadata observable event emission
export const FIRESTORE_DEBOUNCE_TIME = 50;

// Allowed roles in user management
export const ROLE_OPTIONS = ['administrator', 'editor', 'contributor'];
