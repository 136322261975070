import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyFlyoutOptionsGroupComponent } from '../components/molecules/norby-flyout-options-group/norby-flyout-options-group.component';
import { NorbyFlyoutOptionModule } from './norby-flyout-option.module';

@NgModule({
  declarations: [NorbyFlyoutOptionsGroupComponent],
  imports: [CommonModule, NorbyFlyoutOptionModule],
  exports: [NorbyFlyoutOptionsGroupComponent]
})
export class NorbyFlyoutOptionsGroupModule {}
