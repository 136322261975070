import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import { AuthService } from '../../../services';
import { DeliveryType } from 'models';

@Component({
  selector: 'lib-content-registration-email-confirmation-code',
  templateUrl: './content-registration-email-confirmation-code.component.html',
  styleUrls: ['./content-registration-email-confirmation-code.component.less']
})
export class ContentRegistrationEmailConfirmationCodeComponent
  implements OnInit
{
  @Input() email: string;
  @Input() isOnFinalScreen: boolean;
  @Input() emailVerificationCodeMedium: DeliveryType | 'none';

  @Output() handleNext = new EventEmitter<void>();
  @Output() handleSetError = new EventEmitter<any>();

  isLoading: boolean;
  formGroup: UntypedFormGroup;

  constructor(
    private _auth: AuthService,
    private _formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      verificationCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ]
    });
  }

  async submitEmailCode() {
    if (!this.formGroup.valid || !this.email) return;
    const verificationCode = `${this.formGroup.value.verificationCode}`;
    this.isLoading = true;

    try {
      await this._auth.completeEmailLogin({
        email: this.email,
        verificationCode
      });
      this.isLoading = false;
      this.handleNext.emit();
    } catch (e) {
      this.isLoading = false;
      this.handleSetError.emit(e);
    }
  }
}
