<div class="rsvp-container card-background-color card-text-color">
  <root-result
    class="confirmed-result"
    [iconTpl]="iconTpl"
    [titleTpl]="titleTpl"
    [subtitleTpl]="messageTpl"
  >
    <ng-template #iconTpl>
      <root-icon size="large" [name]="icon"></root-icon>
    </ng-template>
    <ng-template #titleTpl>
      <h2 class="center card-text-color">{{ title }}</h2>
    </ng-template>
    <ng-template #messageTpl>
      <h3 class="center card-text-color">{{ message }}</h3>
    </ng-template>
    <div result-extra *ngIf="content?.urls?.donateRaw">
      <a
        [logEvent]="support"
        [cubeData]="{
          entityType: content?.contentType,
          entityId: content?.contentId
        }"
        [href]="content?.urls?.donateRaw"
        target="_blank"
        rel="noreferrer"
      >
        <root-button
          type="secondary"
          [block]="true"
          class="donate"
          name="donate"
          size="large"
        >
          <root-icon name="heart"></root-icon> Donate
        </root-button>
      </a>
    </div>
  </root-result>
  <div class="confirmed-row">
    <div class="save" *ngIf="showCalendarOptions">
      <h3 class="card-text-color">Add to calendar</h3>
      <div
        [logEvent]="saveToGcal"
        [cubeData]="{ entityType: 'event', entityId: content?.contentId }"
      >
        <root-button
          type="secondary"
          size="large"
          class="secondary"
          name="google"
          [block]="true"
          text="Google Calendar"
          (handleClick)="setGCal()"
        >
          <root-icon name="google"></root-icon>
        </root-button>
      </div>

      <div
        [logEvent]="saveToIcal"
        [cubeData]="{ entityType: 'event', entityId: content?.contentId }"
      >
        <root-button
          type="secondary"
          size="large"
          class="secondary"
          name="apple"
          [block]="true"
          text="Apple Calendar"
          (handleClick)="setICal()"
        >
          <root-icon name="apple"></root-icon>
        </root-button>
      </div>

      <div
        [logEvent]="saveToIcal"
        [cubeData]="{ entityType: 'event', entityId: content?.contentId }"
      >
        <root-button
          type="secondary"
          size="large"
          class="secondary"
          name="outlook"
          [block]="true"
          text="Outlook"
          (handleClick)="setOutlook()"
        >
          <root-icon name="calendar"></root-icon>
        </root-button>
      </div>
    </div>
    <div class="share" *ngIf="showShare">
      <h3 class="card-text-color">Share the link</h3>
      <div class="copy-container">
        <div rootInputGroup>
          <norby-input
            type="text"
            [isReadOnly]="true"
            [value]="
              userContent?.shortLinks?.referralPageShortLink ||
              content.urls?.pageShortLink
            "
            [isJoinedRight]="true"
          >
            <root-icon name="link"></root-icon>
          </norby-input>
          <div
            rootInputGroupSuffix
            *ngIf="!canUseSystemShare"
            [logEvent]="share"
            [cubeData]="{ entityType: 'event', entityId: content?.contentId }"
            [cdkCopyToClipboard]="
              userContent?.shortLinks?.referralPageShortLink ||
              content.urls?.pageShortLink
            "
          >
            <root-button
              type="primary"
              [adjacentInput]="true"
              size="large"
              [loading]="isLoadingUserContent"
              (handleClick)="handleCopy()"
              name="copy"
              text="Copy"
              [disabled]="isLoadingUserContent"
            >
            </root-button>
          </div>

          <div
            rootInputGroupSuffix
            *ngIf="canUseSystemShare"
            [logEvent]="share"
            [cubeData]="{ entityType: 'event', entityId: content?.contentId }"
          >
            <root-button
              type="primary"
              [adjacentInput]="true"
              name="share"
              size="large"
              (handleClick)="doShare()"
              text="Share..."
            ></root-button>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-again" *ngIf="showSubmitAnother">
      <root-button
        type="secondary"
        size="large"
        class="secondary"
        name="submitAnother"
        [block]="true"
        text="Submit another response"
        (handleClick)="handleSubmitAnother()"
      >
      </root-button>
    </div>
  </div>
</div>
