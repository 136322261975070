import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IImage } from 'models';

@Component({
  selector: 'norby-image-edit',
  templateUrl: './norby-image-edit.component.html',
  styleUrls: ['./norby-image-edit.component.less']
})
export class NorbyImageEditComponent implements OnInit {
  @Input() image: IImage;
  @Input() isDisabled: boolean = false;
  @Input() isAltTextDisabled: boolean = false;

  @Output() imageChanged: EventEmitter<IImage> = new EventEmitter<IImage>();
  @Output() deleteImage: EventEmitter<IImage> = new EventEmitter<IImage>();

  showDialog: boolean = false;
  imageCopy: IImage;
  formGroup: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    this.imageCopy = { ...this.image };
  }

  handleImageChange(image: IImage): void {
    this.imageChanged.emit(image);
  }

  handleImageDelete(image: IImage): void {
    this.deleteImage.emit(image);
  }
}
