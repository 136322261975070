import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

import { AnimationCurves, AnimationDuration } from './const';

const ANIMATION_TRANSITION_IN = `${AnimationDuration.BASE} ${AnimationCurves.EASE_OUT_QUINT}`;
const ANIMATION_TRANSITION_OUT = `${AnimationDuration.BASE} ${AnimationCurves.EASE_IN_QUINT}`;

export const slideMotion: AnimationTriggerMetadata = trigger('slideMotion', [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'scaleY(0.8)',
      transformOrigin: '0% 0%'
    })
  ),
  state(
    'enter',
    style({
      opacity: 1,
      transform: 'scaleY(1)',
      transformOrigin: '0% 0%'
    })
  ),
  transition('void => *', [animate(ANIMATION_TRANSITION_IN)]),
  transition('* => void', [animate(ANIMATION_TRANSITION_OUT)])
]);

export const slideAlertMotion: AnimationTriggerMetadata = trigger(
  'slideAlertMotion',
  [
    transition(':leave', [
      style({ opacity: 1, transform: 'scaleY(1)', transformOrigin: '0% 0%' }),
      animate(
        `${AnimationDuration.SLOW} ${AnimationCurves.EASE_IN_OUT_CIRC}`,
        style({
          opacity: 0,
          transform: 'scaleY(0)',
          transformOrigin: '0% 0%'
        })
      )
    ])
  ]
);

export const zoomBigMotion: AnimationTriggerMetadata = trigger(
  'zoomBigMotion',
  [
    transition('void => active', [
      style({ opacity: 0, transform: 'scale(0.8)' }),
      animate(
        `${AnimationDuration.BASE} ${AnimationCurves.EASE_OUT_CIRC}`,
        style({
          opacity: 1,
          transform: 'scale(1)'
        })
      )
    ]),
    transition('active => void', [
      style({ opacity: 1, transform: 'scale(1)' }),
      animate(
        `${AnimationDuration.BASE} ${AnimationCurves.EASE_IN_OUT_CIRC}`,
        style({
          opacity: 0,
          transform: 'scale(0.8)'
        })
      )
    ])
  ]
);
