export const rootTiktok = {
  name: 'tiktok',
  data: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" fill="currentColor" viewBox="0 0 24 24" version="1.1">
  <g id="surface1">
  <path style=" stroke:none;fill-rule:nonzero;fill-opacity:1;" d="M 12.53125 0.0195312 C 13.839844 0 15.140625 0.0117188 16.441406 0 C 16.519531 1.53125 17.070312 3.089844 18.191406 4.171875 C 19.308594 5.28125 20.890625 5.789062 22.429688 5.960938 L 22.429688 9.988281 C 20.988281 9.941406 19.539062 9.640625 18.230469 9.019531 C 17.660156 8.761719 17.128906 8.429688 16.609375 8.089844 C 16.601562 11.011719 16.621094 13.929688 16.589844 16.839844 C 16.511719 18.238281 16.050781 19.628906 15.238281 20.78125 C 13.929688 22.699219 11.660156 23.949219 9.328125 23.988281 C 7.898438 24.070312 6.46875 23.679688 5.25 22.960938 C 3.230469 21.769531 1.808594 19.589844 1.601562 17.25 C 1.578125 16.75 1.570312 16.25 1.589844 15.761719 C 1.769531 13.859375 2.710938 12.039062 4.171875 10.800781 C 5.828125 9.359375 8.148438 8.671875 10.320312 9.078125 C 10.339844 10.558594 10.28125 12.039062 10.28125 13.519531 C 9.289062 13.199219 8.128906 13.289062 7.261719 13.890625 C 6.628906 14.300781 6.148438 14.929688 5.898438 15.640625 C 5.691406 16.148438 5.75 16.710938 5.761719 17.25 C 6 18.890625 7.578125 20.269531 9.261719 20.121094 C 10.378906 20.109375 11.449219 19.460938 12.03125 18.511719 C 12.21875 18.179688 12.429688 17.839844 12.441406 17.449219 C 12.539062 15.660156 12.5 13.878906 12.511719 12.089844 C 12.519531 8.058594 12.5 4.039062 12.53125 0.0195312 Z M 12.53125 0.0195312 "/>
  </g>
  </svg>
  
`
};
