import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Libs
import { LinkCardViewComponent } from '../views/link-card-view';
import { AvatarModule } from './avatar.module';
import { CardModule } from './card.module';
import { CarouselModule } from './carousel.module';
import { SoftImageViewModule } from './soft-image-view.module';

@NgModule({
  declarations: [LinkCardViewComponent],
  imports: [
    CommonModule,
    CarouselModule,
    SoftImageViewModule,
    CardModule,
    AvatarModule
  ],
  exports: [LinkCardViewComponent]
})
export class LinkCardViewModule {}
