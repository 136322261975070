import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpotifyEmbedViewComponent } from '../views/spotify-embed-view';

@NgModule({
  declarations: [SpotifyEmbedViewComponent],
  imports: [CommonModule],
  exports: [SpotifyEmbedViewComponent]
})
export class SpotifyEmbedViewModule {}
