<button
  type="button"
  *ngIf="buttonType === 'default'"
  (click)="handleButtonClick($event)"
  class="flex"
  [disabled]="isDisabled"
  [ngClass]="{
    'text-neutral-600 cursor-not-allowed': isDisabled || isLoading,
    'text-neutral-900': !isDisabled && !isLoading
  }"
>
  <norby-icon *ngIf="!isLoading" [name]="iconName"></norby-icon>
  <div
    *ngIf="isLoading"
    class="animate-spin flex justify-center items-center max-h-4"
  >
    <norby-icon name="loader"></norby-icon>
  </div>
</button>

<button
  type="button"
  *ngIf="buttonType === 'bordered'"
  (click)="handleButtonClick($event)"
  [disabled]="isDisabled"
  class="ant-btn-animation flex items-center justify-center rounded-sm p-1.5 border"
  [ngClass]="{
    'border-neutral-900 bg-white text-neutral-900 hover:bg-neutral-700 hover:border-neutral-700 hover:text-white':
      !isDisabled && !isLoading,
    'border-neutral-200 bg-neutral-200 text-neutral-600 cursor-not-allowed':
      isDisabled || isLoading,
  }"
>
  <norby-icon *ngIf="!isLoading" [name]="iconName"></norby-icon>
  <div
    *ngIf="isLoading"
    class="animate-spin flex justify-center items-center max-h-4"
  >
    <norby-icon name="loader"></norby-icon>
  </div>
</button>
