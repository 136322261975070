<div class="flex justify-start">
  <div class="mb-3 w-full">
    <norby-formcontrol-label
      [label]="label"
      [infoTooltip]="infoTooltip"
      [isRequired]="isRequired"
    ></norby-formcontrol-label>
    <div class="relative">
      <norby-input-base
        type="text"
        (click)="!isDisabled && toggleDatePicker()"
        [value]="val"
        (keydown.escape)="hideDatePicker()"
        [isDisabled]="isDisabled"
        [isJoinedRight]="isJoinedRight"
        [isJoinedLeft]="isJoinedLeft"
        [isRequired]="isRequired"
        [placeholder]="placeholder"
        readonly
        [customFilter]="!isDisabled ? datePickerPanel : null"
        [closeOnClick]="true"
        [keepOpen]="showDatePicker"
      ></norby-input-base>

      <div
        class="calendar-icon absolute right-0 px-3 py-2 pointer-events-none"
        (click)="!isDisabled && toggleDatePicker()"
      >
        <svg
          class="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </div>
    </div>
    <norby-helpertext
      [errorText]="errorText"
      [helperText]="helperText"
    ></norby-helpertext>
  </div>
</div>

<root-dropdown-menu #datePickerPanel>
  <div
    class="bg-white rounded-sm shadow p-4 top-0 left-0 z-10"
    style="width: 17rem"
  >
    <div class="flex justify-between items-center mb-2">
      <div>
        <span class="text-lg font-bold text-gray-800">{{
          MONTH_NAMES[month]
        }}</span>
        <span
          class="ml-1 text-lg text-gray-600 font-normal"
          (click)="toggleYearSelection()"
          >{{ selectedYear }}</span
        >
      </div>
      <div>
        <button
          type="button"
          class="focus:outline-none focus:shadow-outline transition ease-in-out durationeutral-100 inline-flex cursor-pointer hover:bg-gray-100 p-1 rounded-full"
          (click)="handleMonthOrYearDecreaseClick()"
        >
          <svg
            class="h-6 w-6 text-gray-400 inline-flex"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          type="button"
          class="focus:outline-none focus:shadow-outline transition ease-in-out durationeutral-100 inline-flex cursor-pointer hover:bg-gray-100 p-1 rounded-full"
          (click)="handleMonthOrYearIncrease()"
        >
          <svg
            class="h-6 w-6 text-gray-400 inline-flex"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="flex flex-wrap mb-3 -mx-1" *ngIf="!yearSelection">
      <ng-container *ngFor="let day of WEEKDAYS">
        <div style="width: 14.26%" class="px-0.5">
          <div class="text-gray-800 font-medium text-center text-xs">
            {{ day }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="flex flex-wrap -mx-1">
      <ng-container *ngIf="!yearSelection && blankdays && noOfDays">
        <ng-container *ngFor="let blankday of blankdays">
          <div
            style="width: 14.28%"
            class="text-center border p-1 border-transparent text-sm"
          ></div>
        </ng-container>
        <ng-container *ngFor="let day of noOfDays">
          <div style="width: 14.28%" class="px-1 mb-1">
            <div
              (click)="handleDaySelectionClick(day)"
              class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out durationeutral-100"
              [ngClass]="getDayClasses(day)"
            >
              {{ day }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="yearSelection">
        <ng-container *ngFor="let year of years">
          <div style="width: 20%" class="px-1 mb-1">
            <div
              (click)="handleYearSelectionClick(year)"
              class="cursor-pointer text-center text-xs rounded-sm leading-loose transition ease-in-out durationeutral-100 px-1"
              [ngClass]="getYearClasses(year)"
            >
              {{ year }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</root-dropdown-menu>
