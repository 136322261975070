import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import dayjs from 'dayjs';
import { MONTH_NAMES, MONTH_SHORT_NAMES, WEEKDAYS } from '../../../constants';

@Component({
  selector: 'norby-date-input',
  templateUrl: './norby-date-input.component.html',
  styleUrls: ['./norby-date-input.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyDateInputComponent),
      multi: true
    }
  ]
})
export class NorbyDateInputComponent implements ControlValueAccessor, OnInit {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() dateFormat?: string = 'MMM D YYYY';
  @Input() helperText?: string;
  @Input() errorText?: string;
  @Input() infoTooltip?: string;
  @Input() value?: string;
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() isJoinedRight: boolean = false;
  @Input() isJoinedLeft: boolean = false;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  readonly MONTH_NAMES = MONTH_NAMES;
  readonly MONTH_SHORT_NAMES = MONTH_SHORT_NAMES;
  readonly WEEKDAYS = WEEKDAYS;

  years: string[] = [];
  val: string;
  showDatePicker: boolean = false;
  month: number;
  year: number;
  noOfDays: number[] = [];
  blankdays: number[] = [];
  yearSelection: boolean = false;
  selectedYear: number;

  constructor() {}

  ngOnInit(): void {
    this.val = this.value;
    this.hideDatePicker();
  }

  writeValue(value: string) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  private _initialize() {
    const selectedDate = this.val
      ? dayjs(this.val, this.dateFormat).toDate()
      : new Date();
    this.month = selectedDate.getMonth();
    this.year = selectedDate.getFullYear();
    this.selectedYear = this.year;
    this.val = this._formatDateForDisplay(selectedDate);
    this._fillYearsArray(this.selectedYear - 17, this.selectedYear + 17);
    this._onChanged(this.val);
    this._markAsTouched();
  }

  private _formatDateForDisplay(date) {
    return dayjs(date).format(this.dateFormat);
  }

  private _isSelectedDate(day: number) {
    const d = new Date(this.selectedYear, this.month, day);
    return this.val === this._formatDateForDisplay(d);
  }

  private _isToday(day: number) {
    const today = new Date();
    const d = new Date(this.selectedYear, this.month, day);
    return today.toDateString() === d.toDateString();
  }

  handleDaySelectionClick(day: number) {
    const selectedDate = new Date(this.selectedYear, this.month, day);
    this.val = this._formatDateForDisplay(selectedDate);
    this._onChanged(this.val);
    this._markAsTouched();
    this.hideDatePicker();
  }

  getDayClasses(day: number) {
    let classes = '';

    classes = this._isToday(day) ? 'bg-lilac-base' : classes;
    classes =
      !this._isToday(day) && this._isSelectedDate(day) == false
        ? 'text-neutral-900 hover:bg-neutral-300'
        : classes;
    classes = this._isSelectedDate(day)
      ? 'bg-neutral-900 text-white hover:bg-opacity-75'
      : classes;

    return classes;
  }

  private _fillDays() {
    const daysInMonth = new Date(
      this.selectedYear,
      this.month + 1,
      0
    ).getDate();
    const dayOfWeek = new Date(this.selectedYear, this.month).getDay();

    this.blankdays = [];
    for (var i = 1; i <= dayOfWeek; i++) {
      this.blankdays.push(i);
    }

    this.noOfDays = [];
    for (var i = 1; i <= daysInMonth; i++) {
      this.noOfDays.push(i);
    }
  }

  handleMonthOrYearDecreaseClick() {
    if (this.yearSelection) {
      let minYear: number = +this.years[0];
      this._fillYearsArray(minYear - this.years.length, minYear - 1);
    } else {
      if (this.month == 0) {
        this.selectedYear--;
        let minYear: number = +this.years[0];
        if (this.selectedYear < minYear) {
          this._fillYearsArray(minYear - this.years.length, minYear - 1);
        }
        this.month = 12;
      }
      this.month--;
      this._fillDays();
    }
  }

  handleMonthOrYearIncrease() {
    if (this.yearSelection) {
      const maxYear: number = +this.years[this.years.length - 1];
      this._fillYearsArray(maxYear + 1, maxYear + this.years.length);
    } else {
      if (this.month == 11) {
        this.month = 0;
        this.selectedYear++;
        const maxYear: number = +this.years[this.years.length - 1];
        if (this.selectedYear > maxYear) {
          this._fillYearsArray(maxYear + 1, maxYear + this.years.length);
        }
      } else {
        this.month++;
      }
      this._fillDays();
    }
  }

  toggleDatePicker() {
    this.showDatePicker = !this.showDatePicker;
    if (!this.selectedYear) {
      this._initialize();
      this._fillDays();
    }
  }

  toggleYearSelection() {
    this.yearSelection = !this.yearSelection;
  }

  handleYearSelectionClick(year: number) {
    this.selectedYear = year;
    this.yearSelection = false;
    this._fillDays();
  }

  private _fillYearsArray(start: number, end: number) {
    this.years = [];
    for (let i = start; i <= end; i++) {
      this.years.push(i.toString());
    }
  }

  getYearClasses(year: number) {
    let classes = '';
    classes = year == this.year ? 'bg-lilac-base' : classes;

    classes =
      year != this.year && this.selectedYear != year
        ? 'text-neutral-900 hover:bg-neutral-300'
        : classes;

    classes =
      this.selectedYear == year
        ? 'bg-neutral-900 text-white hover:bg-opacity-75'
        : classes;

    return classes;
  }

  hideDatePicker() {
    this.showDatePicker = false;
  }
}
