<ul
  class="flex flex-wrap bg-sand-200 rounded-sm border border-neutral-300"
  [ngClass]="{ 'mx-0': isHeaderFullWidth, 'mx-4': !isHeaderFullWidth }"
  role="tablist"
>
  <li
    *ngFor="let tab of tabs"
    (click)="handleTabClick(tab)"
    class="py-1.5 px-4 m-1 flex flex-1 justify-center border items-center cursor-pointer"
    [ngClass]="{
      'bg-white rounded-sm text-neutral-800 font-medium border-neutral-300':
        tab.active,
      'text-neutral-600 hover:text-neutral-700 border-transparent': !tab.active
    }"
  >
    {{ tab.title }}
  </li>
</ul>
<ng-content></ng-content>
