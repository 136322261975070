import { Segment } from 'models';

export const compareSegments = (
  ...[segmentOne, segmentTwo]: Segment[]
): boolean => {
  if (!segmentOne && !segmentTwo) {
    return true;
  } else if (!segmentOne || !segmentTwo) {
    return false;
  }

  segmentOne =
    segmentOne instanceof Segment ? segmentOne : Segment.fromObject(segmentOne);
  segmentTwo =
    segmentTwo instanceof Segment ? segmentTwo : Segment.fromObject(segmentTwo);

  return (segmentOne as Segment).hash === (segmentTwo as Segment).hash;
};
