<ng-template>
  <div
    @slideMotion
    class="bg-white rounded-md shadow-ant-default w-full overflow-hidden user-theme-dropdown"
    (mouseenter)="handleDropdownStateChanged($event)"
    (mouseleave)="handleDropdownStateChanged($event)"
    (click)="handleDropdownStateChanged($event)"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
