import { QueryList } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { CarouselContentDirective } from '../../../directives/carousel-content.directive';
import { CarouselBaseStrategy } from './base-strategy';

export class CarouselOpacityStrategy extends CarouselBaseStrategy {
  override withCarouselContents(
    contents: QueryList<CarouselContentDirective> | null
  ): void {
    super.withCarouselContents(contents);

    if (this.contents) {
      this.slickTrackEl.style.width = `${this.length * this.unitWidth}px`;

      this.contents.forEach((content: CarouselContentDirective, i: number) => {
        this.renderer.setStyle(
          content.el,
          'opacity',
          this.carouselComponent!.activeIndex === i ? '1' : '0'
        );
        this.renderer.setStyle(content.el, 'position', 'relative');
        this.renderer.setStyle(content.el, 'width', `${this.unitWidth}px`);
        this.renderer.setStyle(content.el, 'left', `${-this.unitWidth * i}px`);
      });
    }
  }

  switch(_f: number, _t: number): Observable<void> {
    const { to: t } = this.getFromToInBoundary(_f, _t);
    const complete$ = new Subject<void>();

    this.contents?.forEach((content: CarouselContentDirective, i: number) => {
      this.renderer.setStyle(content.el, 'opacity', t === i ? '1' : '0');
    });

    setTimeout(() => {
      complete$.next();
      complete$.complete();
    }, this.carouselComponent!.transitionSpeed);

    return complete$;
  }

  override dispose(): void {
    this.contents?.forEach((content: CarouselContentDirective) => {
      this.renderer.setStyle(content.el, 'transition', null);
      this.renderer.setStyle(content.el, 'opacity', null);
      this.renderer.setStyle(content.el, 'width', null);
      this.renderer.setStyle(content.el, 'left', null);
    });

    super.dispose();
  }
}
