import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionGroup } from 'models';

@Component({
  selector: 'norby-flyout-menu',
  templateUrl: './norby-flyout-menu.component.html',
  styleUrls: ['./norby-flyout-menu.component.less']
})
export class NorbyFlyoutMenuComponent {
  @Input() options: OptionGroup[];
  @Output() onOptionClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleOptionClicked(option: string) {
    this.onOptionClicked.emit(option);
  }
}
