<div class="p-3">
  <div class="mb-2 text-xs">
    <span> Token </span>
  </div>
  <form [formGroup]="formGroup">
    <div class="flex">
      <button
        type="button"
        class="ant-btn-animation flex items-center justify-between text-base rounded-sm mb-3 h-12 px-3 pb-4 pt-3.5 border border-neutral-300 hover:border-neutral-500 flex-1 bg-white leading-5 whitespace-nowrap w-80"
        [ngClass]="
          variableDisplayText ? 'text-neutral-900' : 'text-neutral-600'
        "
        [customFilter]="variableOptionsTpl"
        [closeOnClick]="true"
      >
        <span> {{ variableDisplayText ?? 'Select token' }} </span
        ><norby-icon name="chevron_down"></norby-icon>
      </button>
    </div>

    <div *ngIf="isUserVariable">
      <div class="mb-2 text-xs">
        <span> Default value </span>
      </div>
      <div rootFormItem>
        <norby-input-base
          class="prompt-input"
          formControlName="defaultValue"
          placeholder="Friend"
        ></norby-input-base>
      </div>
    </div>

    <div *ngIf="isContentVariable && isSendField">
      <div class="mb-2 flex flex-col text-xs">
        <span> Search </span>
      </div>
      <root-spin [spinning]="isLoadingContent">
        <div rootFormItem [class.hidden]="!!selectedContent">
          <norby-input-base
            class="prompt-input"
            formControlName="contentVariableSearchInput"
            placeholder="Event or signup name..."
            helperText="The content this variable references"
            [searchResults]="contentSearchResultsTpl"
            [results]="
              contentVariableOptions?.length > 0
                ? contentVariableOptions
                : contentVariableDefaultOptions
            "
          ></norby-input-base>
        </div>
        <div
          *ngIf="selectedContent"
          class="flex px-3 h-12 mb-4 justify-between items-center border border-neutral-300 rounded-sm"
        >
          <div class="truncate font-bold">
            {{ selectedContent.title }}
          </div>
          <norby-button
            text="Change"
            buttonType="minimal"
            (buttonClicked)="handleChangeContent()"
          ></norby-button>
        </div>
      </root-spin>
    </div>
  </form>

  <norby-button
    class="flex add-variable-button"
    [text]="editingVariable?.type ? 'Update variable' : 'Insert variable'"
    [isDisabled]="
      !formGroup.valid || (isContentVariable && isSendField && !selectedContent)
    "
    (buttonClicked)="handleAddVariable()"
  ></norby-button>
</div>
<root-dropdown-menu #variableOptionsTpl>
  <div class="p-3 min-w-56">
    <div class="mb-2">User variables</div>
    <div class="px-2 ml-3 border-l">
      <div
        (click)="handleSelectVariable(sendUserVariableType.USER_FIRST_NAME)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.USER_FIRST_NAME }}
      </div>
      <div
        (click)="handleSelectVariable(sendUserVariableType.USER_LAST_NAME)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.USER_LAST_NAME }}
      </div>
      <div
        (click)="handleSelectVariable(sendUserVariableType.USER_FULL_NAME)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.USER_FULL_NAME }}
      </div>
    </div>

    <div class="my-2">Content variables</div>
    <div class="px-2 ml-3 border-l">
      <div
        (click)="handleSelectVariable(sendContentVariableType.CONTENT_LOCATION)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.CONTENT_LOCATION }}
      </div>
      <div
        (click)="handleSelectVariable(sendContentVariableType.CONTENT_TITLE)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.CONTENT_TITLE }}
      </div>
      <div
        (click)="handleSelectVariable(sendContentVariableType.CONTENT_URL)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.CONTENT_URL }}
      </div>
      <div
        (click)="
          handleSelectVariable(sendContentVariableType.TOTAL_TICKETS_PURCHASED)
        "
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.TOTAL_TICKETS_PURCHASED }}
      </div>
      <div
        (click)="handleSelectVariable(sendContentVariableType.CONTENT_PAGE_URL)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.CONTENT_PAGE_URL }}
      </div>
      <div
        (click)="
          handleSelectVariable(sendContentVariableType.USER_REFERRAL_URL)
        "
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.USER_REFERRAL_URL }}
      </div>
      <div
        (click)="handleSelectVariable(sendContentVariableType.CONTENT_SUBTITLE)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.CONTENT_SUBTITLE }}
      </div>
      <div
        (click)="
          handleSelectVariable(sendContentVariableType.CONTENT_START_DATE)
        "
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.CONTENT_START_DATE }}
      </div>
      <div
        (click)="handleSelectVariable(sendContentVariableType.CONTENT_END_DATE)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.CONTENT_END_DATE }}
      </div>
      <div
        *ngIf="!showHtmlVariables"
        (click)="
          handleSelectVariable(sendContentVariableType.APPLE_CALENDAR_URL)
        "
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.APPLE_CALENDAR_URL }}
      </div>
      <div
        *ngIf="showHtmlVariables"
        (click)="handleSelectVariable(sendContentVariableType.APPLE_CALENDAR)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.APPLE_CALENDAR }}
      </div>
      <div
        *ngIf="!showHtmlVariables"
        (click)="
          handleSelectVariable(sendContentVariableType.GOOGLE_CALENDAR_URL)
        "
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.GOOGLE_CALENDAR_URL }}
      </div>
      <div
        *ngIf="showHtmlVariables"
        (click)="handleSelectVariable(sendContentVariableType.GOOGLE_CALENDAR)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.GOOGLE_CALENDAR }}
      </div>
      <div
        *ngIf="!showHtmlVariables"
        (click)="
          handleSelectVariable(sendContentVariableType.OUTLOOK_CALENDAR_URL)
        "
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.OUTLOOK_CALENDAR_URL }}
      </div>
      <div
        *ngIf="showHtmlVariables"
        (click)="handleSelectVariable(sendContentVariableType.OUTLOOK_CALENDAR)"
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.OUTLOOK_CALENDAR }}
      </div>
      <div
        (click)="
          handleSelectVariable(sendContentVariableType.USER_PROMPT_RESPONSES)
        "
        class="cursor-pointer"
      >
        {{ variableDisplayTextType.USER_PROMPT_RESPONSES }}
      </div>
    </div>
  </div>
</root-dropdown-menu>

<root-dropdown-menu #contentSearchResultsTpl>
  <root-spin [spinning]="isSearching">
    <div *ngIf="contentVariableOptions?.length > 0">
      <div
        class="py-2 px-3"
        *ngFor="let option of contentVariableOptions; let i = index"
      >
        <div
          class="flex items-center py-1 cursor-pointer"
          (click)="handleSelectContent(option.content)"
        >
          <norby-icon
            class="flex-initial"
            [name]="getIcon(option?.content.contentType)"
          ></norby-icon>
          <div
            class="flex-auto"
            [innerHtml]="option.highlightedTitle || option.content.title"
          ></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        contentVariableDefaultOptions?.length > 0 &&
        shouldDisplayDefaultOptions &&
        contentVariableOptions?.length === 0
      "
    >
      <div
        class="py-2 px-3"
        *ngFor="let option of contentVariableDefaultOptions; let i = index"
      >
        <div
          class="flex items-center py-1 cursor-pointer"
          (click)="handleSelectContent(option.content)"
        >
          <norby-icon
            class="flex-initial"
            [name]="getIcon(option?.content.contentType)"
          ></norby-icon>
          <div
            class="flex-auto"
            [innerHtml]="option.highlightedTitle || option.content.title"
          ></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        contentVariableOptions?.length === 0 && !shouldDisplayDefaultOptions
      "
    >
      <div class="py-2 px-3">No results...</div>
    </div>
  </root-spin>
</root-dropdown-menu>
