<root-card
  [cardCover]="
    images?.length > 1
      ? carouselCoverTemplate
      : block?.imageUrl
      ? coverTemplate
      : null
  "
  class="card card-background-color"
>
  <h3 class="title card-text-color" *ngIf="!subscribed && block?.title">
    {{ block.title }}
  </h3>
  <p class="subtitle card-text-color" *ngIf="!subscribed && block?.subtitle">
    {{ block.subtitle }}
  </p>
  <h3 class="title card-text-color" *ngIf="subscribed">
    {{ block?.postSubmitTitle || defaultPostSubmitTitle }}
  </h3>
  <p
    class="subtitle card-text-color"
    *ngIf="subscribed && block?.postSubmitMessage"
  >
    {{ block.postSubmitMessage }}
  </p>
  <form *ngIf="!subscribed" [formGroup]="formGroup" (ngSubmit)="submit()">
    <div rootInputGroup>
      <norby-input
        type="email"
        formControlName="email"
        placeholder="your@email.com"
        [isJoinedRight]="true"
      ></norby-input>

      <root-button
        rootInputGroupSuffix
        type="primary"
        [adjacentInput]="true"
        [name]="block?.buttonLabel || 'Subscribe'"
        size="large"
        [loading]="isLoading"
        [disabled]="!formGroup.valid || isLoading"
        text="{{ block?.buttonLabel || 'Subscribe' }}"
      >
      </root-button>
    </div>
  </form>
</root-card>

<ng-template #coverTemplate>
  <lib-soft-image-view
    [imageUrl]="block?.imageUrl"
    [image]="block?.image"
    [imgixImage]="block?.responsiveImage"
    [alt]="block?.title"
  ></lib-soft-image-view>
</ng-template>

<ng-template #carouselCoverTemplate>
  <root-carousel [autoPlay]="true" [autoPlaySpeed]="6000">
    <div carousel-content *ngFor="let image of images; index as i">
      <lib-soft-image-view
        [alt]="block?.title"
        [imageUrl]="image?.url"
        [image]="image"
        [imgixImage]="responsiveImages[i]"
      >
      </lib-soft-image-view>
    </div>
  </root-carousel>
</ng-template>
