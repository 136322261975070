import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';

import {
  ButtonModule,
  IconModule,
  SpinModule,
  FormModule,
  NorbyInputModule,
  TooltipModule,
  CheckboxModule,
  NorbySelectModule,
  NorbyDateInputModule
} from 'uikit';
import { LoginComponent } from './login.component';
import { PaymentFormModule } from '../../modules';
import {
  ContentRegistrationPhoneNumberComponent,
  ContentRegistrationPhoneNumberConfirmationCodeComponent,
  ContentRegistrationEmailComponent,
  ContentRegistrationEmailConfirmationCodeComponent,
  ContentRegistrationProfileComponent,
  ContentRegistrationPromptsComponent,
  ContentRegistrationPurchaseComponent,
  ContentRegistrationTicketsComponent
} from '../../components';

@NgModule({
  declarations: [
    LoginComponent,
    ContentRegistrationPhoneNumberComponent,
    ContentRegistrationPhoneNumberConfirmationCodeComponent,
    ContentRegistrationEmailComponent,
    ContentRegistrationEmailConfirmationCodeComponent,
    ContentRegistrationProfileComponent,
    ContentRegistrationPromptsComponent,
    ContentRegistrationPurchaseComponent,
    ContentRegistrationTicketsComponent
  ],
  imports: [
    CommonModule,
    ClipboardModule,
    ReactiveFormsModule,

    PaymentFormModule,
    ButtonModule,
    IconModule,
    SpinModule,
    FormModule,
    NorbyInputModule,
    TooltipModule,
    CheckboxModule,
    NorbySelectModule,
    NorbyDateInputModule
  ],
  exports: [LoginComponent]
})
export class LoginModule {}
