import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractLinkViewComponent } from '../views/abstract-link-view';

@NgModule({
  declarations: [AbstractLinkViewComponent],
  imports: [CommonModule],
  exports: [AbstractLinkViewComponent]
})
export class AbstractLinkViewModule {}
