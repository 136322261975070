import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[rootList]'
})
export class ListDirective {
  @HostBinding('class')
  elementClass = 'block divide-y divide-border-color-split';
  constructor() {}
}
