<div class="flex flex-col items-start">
  <label class="mb-4 font-bold">{{ title }}</label>
  <div class="flex flex-row flex-wrap gap-4">
    <norby-flyout-option
      *ngFor="let item of items"
      [iconName]="item.icon"
      [label]="item.label"
      (onClick)="handleOptionClicked(item.value)"
    ></norby-flyout-option>
  </div>
</div>
