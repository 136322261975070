export const USER_ANIMALS = [
  'Giraffe',
  'Hippo',
  'Tiger',
  'Zebra',
  'Antelope',
  'Robot',
  'Lion',
  'Turkey',
  'Squirrel',
  'Panda',
  'Raccoon',
  'Wolf',
  'Owl',
  'Turtle',
  'Alligator',
  'Rabbit',
  'Dinosaur',
  'Leopard',
  'Falcon',
  'Shark',
  'Whale',
  'Koala',
  'Walrus',
  'Camel',
  'Bear',
  'Coyote',
  'Chimpanzee',
  'Walrus',
  'Elephant',
  'Dolphin',
  'Kangaroo',
  'Otter',
  'Badger',
  'Frog'
];
