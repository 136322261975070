import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IThemeCustomizerServiceInterface } from './theme-customizer.service.interface';
import {
  DEFAULT_THEME_CUSTOMIZER_STATUS,
  ThemeCustomizerStatus
} from './types';
import { IFont } from 'models';

@Injectable({
  providedIn: 'root'
})
export class ThemeCustomizerService
  implements IThemeCustomizerServiceInterface
{
  private _count = 0;
  private _status$ = new BehaviorSubject<ThemeCustomizerStatus>(
    DEFAULT_THEME_CUSTOMIZER_STATUS
  );
  status$ = this._status$.asObservable();

  constructor() {}

  async importCustomFont(): Promise<IFont> {
    return null;
  }

  async addCardStyle(): Promise<string> {
    return `Card ${++this._count}`;
  }
}
