import { IButtonStyle, ICardStyle } from '../ISlug';
import {
  ButtonSingleSendBlock,
  DividerSingleSendBlock,
  EventSingleSendBlock,
  FooterSingleSendBlock,
  HeaderSingleSendBlock,
  ImageSingleSendBlock,
  SingleSendBlock,
  SingleSendBlockTypeEnum,
  SpacerSingleSendBlock,
  TextSingleSendBlock,
  YoutubeSingleSendBlock,
  QuoteSingleSendBlock,
  SignupSingleSendBlock,
  NlqPromptSingleSendBlock,
  NlqSummarySingleSendBlock
} from './ISingleSend';

export function isEmailHeaderBlock(
  block: SingleSendBlock
): block is HeaderSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.HEADER;
}

export function isEmailTextBlock(
  block: SingleSendBlock
): block is TextSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.TEXT;
}

export function isEmailImageBlock(
  block: SingleSendBlock
): block is ImageSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.IMAGE;
}

export function isEmailSpacerBlock(
  block: SingleSendBlock
): block is SpacerSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.SPACER;
}

export function isEmailFooterBlock(
  block: SingleSendBlock
): block is FooterSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.FOOTER;
}

export function isEmailDividerBlock(
  block: SingleSendBlock
): block is DividerSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.DIVIDER;
}

export function isEmailButtonBlock(
  block: SingleSendBlock
): block is ButtonSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.BUTTON;
}

export function isEmailYoutubeBlock(
  block: SingleSendBlock
): block is YoutubeSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.YOUTUBE;
}

export function isEmailEventBlock(
  block: SingleSendBlock
): block is EventSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.EVENT;
}

export function isEmailQuoteBlock(
  block: SingleSendBlock
): block is QuoteSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.QUOTE;
}

export function isEmailSignupBlock(
  block: SingleSendBlock
): block is SignupSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.SIGNUP;
}

export function isEmailUpcomingEventsBlock(
  block: SingleSendBlock
): block is SignupSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.UPCOMING_EVENTS;
}

export function isNlqPromptBlock(
  block: SingleSendBlock
): block is NlqPromptSingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.NLQ_PROMPT;
}

export function isNlqSummaryBlock(
  block: SingleSendBlock
): block is NlqSummarySingleSendBlock {
  return block?.blockType === SingleSendBlockTypeEnum.NLQ_SUMMARY;
}

export function populateButtonOrCardTheme(value: ICardStyle | IButtonStyle) {
  return {
    ...(value.borderRadius !== null &&
      value.borderRadius > -1 && {
        borderRadius: Number(value.borderRadius)
      }),
    ...(value.borderWidth !== null &&
      value.borderWidth > -1 && {
        borderWidth: Number(value.borderWidth)
      }),
    ...(value.dropShadow && {
      dropShadow: value.dropShadow
    }),
    ...(value.backgroundColor && {
      backgroundColor: value.backgroundColor
    }),
    ...(value.textColor && {
      textColor: value.textColor
    }),
    ...(value.borderColor && {
      borderColor: value.borderColor
    })
  };
}
