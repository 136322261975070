import {
  Component,
  OnInit,
  Input,
  Inject,
  EventEmitter,
  Output,
  HostListener
} from '@angular/core';

import { IUserContent, ContentRegisterable, CUBE_EVENTS, EMBEDS } from 'models';
import { IconService, MessageService, MockShareService } from '../../services';
import {
  rootShare,
  rootLoader,
  rootLink,
  rootCode,
  rootChevronRight
} from '../../../icons';
import { MessageType, THEME_CLASS } from '../../constants';

/*
  Creates a share button for a given event or drop
*/

@Component({
  selector: 'lib-event-drop-share-button-view',
  templateUrl: './event-drop-share-button-view.component.html',
  styleUrls: ['./event-drop-share-button-view.component.less']
})
export class EventDropShareButtonViewComponent implements OnInit {
  @Output() menuStateChanged = new EventEmitter<boolean>();
  @Input() content: ContentRegisterable;
  @Input() userContent: IUserContent;
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Input() showText = false;
  @Input() showIcon = true;
  @Input() size: 'default' | 'large' | 'small' = 'default';
  @Input() type: 'primary' | 'dashed' | 'link' | 'text' = 'text';

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    event.preventDefault();
  }

  // Whether the current device supports system share menu
  canUseSystemShare = false;

  // Analytics events
  userTappedShare = CUBE_EVENTS.userTappedShare;

  // Event embed constants
  eventType = EMBEDS.event.type;
  eventCardVariant = EMBEDS.event.variants.card;
  eventMainVariant = EMBEDS.event.variants.main;
  eventShareVariant = EMBEDS.event.variants.share;

  // Drop embed constants
  dropType = EMBEDS.drop.type;
  dropCardVariant = EMBEDS.drop.variants.card;
  dropMainVariant = EMBEDS.drop.variants.main;
  dropShareVariant = EMBEDS.drop.variants.share;
  dropScrollVariant = EMBEDS.drop.variants.scroll;

  constructor(
    private _share: MockShareService,
    private _message: MessageService,
    private _iconService: IconService,
    @Inject(THEME_CLASS) private _wrapperClass
  ) {
    this._iconService.registerIcons([
      rootShare,
      rootLoader,
      rootLink,
      rootCode,
      rootChevronRight
    ]);
  }

  ngOnInit(): void {
    this.canUseSystemShare = this._share.canShare;
  }

  doShare() {
    this._share.openContentShareSheet(this.content, this.userContent);
  }

  handleCopy() {
    this._message.show(
      { text: 'Copied', type: MessageType.SUCCESS },
      this._wrapperClass
    );
  }

  handleVisibleChanged(event) {
    this.menuStateChanged.emit(event);
  }

  embedStringForType(variant: string) {
    return this._share.embedStringForType(variant, this.content);
  }
}
