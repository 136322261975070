import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, Inject } from '@angular/core';

import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  Observable
} from 'rxjs';
import { IDeviceService, ROOT_HOST_TOKEN } from 'models';

// Browser only

@Injectable({
  providedIn: 'root'
})
export class DeviceService implements IDeviceService {
  private _resize$: Observable<Event>;
  private _scroll$: Observable<Event>;
  private _isMobile$: Observable<boolean>;
  private _currentSlug: string;
  private _currentSlug$ = new BehaviorSubject<string>(this.currentSlug);

  slugInfo$ = this._currentSlug$.asObservable().pipe(
    map((slug) => ({
      rootUrl: `${slug}.${this._host}`,
      referUrl: `nor.by/❤/${slug}`,
      slug
    }))
  );

  currentSlug$ = this._currentSlug$.asObservable().pipe(
    filter((slug) => !!slug),
    distinctUntilChanged()
  );

  constructor(
    @Inject(ROOT_HOST_TOKEN) protected _host: string,
    protected _observer: BreakpointObserver
  ) {
    const invalidHostname =
      !this.hostname ||
      this.hostname === 'localhost' ||
      this.hostname.startsWith('192');
    this._currentSlug = invalidHostname
      ? 'teamnorbyexp'
      : this._clean(this.hostname);
    this._currentSlug$.next(this._currentSlug);
  }

  get resize$() {
    if (!this._resize$) {
      this._resize$ = fromEvent(window, 'resize');
    }

    return this._resize$;
  }

  get scroll$() {
    if (!this._scroll$) {
      this._scroll$ = fromEvent(window, 'scroll');
    }

    return this._scroll$;
  }

  get isMobile$() {
    if (!this._isMobile$) {
      this._isMobile$ = this._observer
        .observe('(max-width: 576px)')
        .pipe(map((result) => !!result?.matches));
    }

    return this._isMobile$;
  }

  forceSlug(slug: string) {
    this._currentSlug = slug;
    this._currentSlug$.next(slug);
  }

  private _clean(input: string) {
    const stripWww =
      input.startsWith('www.') && input.length > 3 ? input.substring(4) : input;
    return stripWww.split('.')[0];
  }

  get timezone(): string {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {
      return 'UTC';
    }
  }

  get currentSlug(): string {
    return this._currentSlug;
  }

  get history(): History {
    return window.history;
  }

  get hostname(): string {
    return window.location.hostname;
  }

  get currentHost(): string {
    if (!this.hostname || this.hostname === 'localhost') {
      return 'localhost';
    }

    return this._host;
  }

  get currentSiteRootURL(): string {
    if (!this.hostname || this.hostname === 'localhost') {
      return 'localhost';
    }

    return `${this.currentSlug}.${this._host}`;
  }
}
