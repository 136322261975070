<div class="flex justify-start ">
  <div class="mb-3" [class.flex-1]="block">
    <label
      *ngIf="label"
      class="form-label inline-block mb-1 text-sm text-neutral-800"
      >{{ label }}</label
    >
    <div class="flex justify-center rounded-sm radio-button-group gap-2" role="group">
      <ng-content></ng-content>
    </div>
  </div>
</div>
