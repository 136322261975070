import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

// Libs
import { ConversationMessage } from 'models';

type MenuItem = {
  label: string;
  icon: string;
  handler: () => void;
};

@Component({
  selector: 'app-conversation-message-actions-view',
  templateUrl: './conversation-message-actions-view.component.html',
  styleUrls: ['./conversation-message-actions-view.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ConversationMessageActionsView {
  @Input() message: ConversationMessage;
  @Input() useDropdown = true;

  @Output() onRateMessageClick = new EventEmitter<void>();
  @Output() onExplainAnswerClick = new EventEmitter<void>();
  @Output() onShareClick = new EventEmitter<void>();
  @Output() menuStateChanged = new EventEmitter<boolean>();

  get timestampRelative(): string {
    const date = dayjs(this.message.createdAt);
    return date.fromNow();
  }

  get timestampAbsolute(): string {
    const date = dayjs(this.message.createdAt);
    return date.format('lll');
  }

  readonly MENU_ITEMS: MenuItem[] = [
    // {
    //   label: 'Explain this answer',
    //   icon: 'feather/help-circle',
    //   handler: () => this.onExplainAnswerClick.emit()
    // },
    {
      label: 'Give feedback',
      icon: 'feather/smile',
      handler: () => this.onRateMessageClick.emit()
    },
    {
      label: 'Share',
      icon: 'feather/share',
      handler: () => this.onShareClick.emit()
    }
  ];

  handleVisibleChanged(event) {
    this.menuStateChanged.emit(event);
  }
}
