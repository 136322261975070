<label
  *ngIf="label"
  class="form-label pb-2 flex flex-row items-center text-neutral-800 text-xs card-text-color"
>
  {{ label }}
  <span class="text-xs ml-2 text-neutral-600" *ngIf="isRequired">Required</span>
  <div class="text-mustard">
    <norby-icon
      *ngIf="infoTooltip"
      name="help-circle"
      [rootTooltip]="infoTooltip"
    ></norby-icon>
  </div>
</label>
