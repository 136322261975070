import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'norby-checkbox',
  templateUrl: './norby-checkbox.component.html',
  styleUrls: ['./norby-checkbox.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyCheckboxComponent),
      multi: true
    }
  ]
})
export class NorbyCheckboxComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() isDisabled?: boolean;
  @Input() framed?: boolean = false;

  val: boolean = true;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {}

  writeValue(value: boolean) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  toggleChecked() {
    if (!this.isDisabled) {
      this.val = !this.val;
      this._onChanged(this.val);
      this._markAsTouched();
    }
  }
}
