import { Component, Input } from '@angular/core';

@Component({
  selector: 'root-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.less']
})
export class AvatarComponent {
  @Input() shape: 'square' | 'circle' = 'square';
  @Input() imageUrl?: string;
  @Input() imageAlt?: string;
}
