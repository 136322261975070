<div
  *ngIf="!imageUrl"
  class="avatar-icon flex items-center justify-center text-white bg-skeleton-row w-8 h-8 rounded-full"
>
  <div class="pl-px scale-125">
    <ng-content></ng-content>
  </div>
</div>

<div
  *ngIf="!!imageUrl"
  class="w-12 h-12 overflow-hidden avatar-image"
  [ngClass]="shape === 'square' ? 'rounded' : 'rounded-full'"
>
  <img class="w-full h-full object-cover" [src]="imageUrl" [alt]="imageAlt" />
</div>
