import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class NorbyOverlayContainer extends OverlayContainer {
  constructor(
    @Inject(DOCUMENT) document,
    @Inject(PLATFORM_ID) protected _platform
  ) {
    super(document, _platform);
  }

  protected _createContainer(): void {
    const isBrowser = typeof window !== 'undefined';
    const containerClass = 'cdk-overlay-container';

    if (isBrowser) {
      const oppositePlatformContainers = this._document?.querySelectorAll(
        `.${containerClass}[platform="server"], ` +
          `.${containerClass}[platform="test"]`
      );

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < oppositePlatformContainers.length; i++) {
        oppositePlatformContainers[i].parentNode?.removeChild(
          oppositePlatformContainers[i]
        );
      }
    }

    const container = this._document?.createElement('div');
    container.classList.add(containerClass, 'norby');
    this._document?.body.appendChild(container);
    this._containerElement = container;
  }
}
