import { Injectable } from '@angular/core';
import { IAnalyticsServiceInterface } from 'models';

@Injectable({
  providedIn: 'root'
})
export class MockAnalyticsService implements IAnalyticsServiceInterface {
  track(
    eventName: string,
    payload?: any,
    options?: any,
    callback?: (...params: any[]) => any
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  identify(
    userId: string,
    traits?: any,
    options?: any,
    callback?: (...params: any[]) => any
  ): void {
    throw new Error('Method not implemented.');
  }
}
