import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractSignupViewComponent } from '../views/abstract-signup-view';

@NgModule({
  declarations: [AbstractSignupViewComponent],
  imports: [CommonModule],
  exports: [AbstractSignupViewComponent]
})
export class AbstractSignupViewModule {}
