import { Transform } from 'class-transformer';

import { transformTimestampToDate } from '../tools/timestamp';
import { PageInfo } from './general';
import { DowngradeReasonType } from './downgrade';

export enum AddOn {
  PHONE_NUMBER = 'phoneNumber',
  TEAM_MEMBER = 'teamMember'
}

export type AddOnType = `${AddOn}`;

export enum ProjectTiers {
  FREE = 'free',
  STARTER = 'starter',
  GROWTH = 'basic',
  PRO = 'unlimited',
  ENTERPRISE = 'enterprise'
}

export type ProjectTiersType = `${ProjectTiers}`;

export enum ProjectPeriods {
  MONTHLY = 'monthly',
  ANNUAL = 'annual'
}

export type ProjectPeriod = `${ProjectPeriods}`;

export interface IAttachAddon {
  addonType: AddOnType;
}

export interface IDetachAddon {
  addonType: AddOnType;
}

export interface ISetBillingAddress {
  countryCode?: string;
  city?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  state?: string;
  firstName?: string;
  lastName?: string;
}

export interface IAttachCardPaymentMethod {
  cvc?: string;
  expMonth?: number;
  expYear?: number;
  number?: number;
  city?: string;
  countryCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  state?: string;
  defaultPaymentMethod?: boolean;
  promoCode?: string;
}

export interface IDetachCardPaymentMethod {
  paymentMethodId: string;
}

export interface ISubscriptionPlan {
  type?: ProjectTiersType;
  title?: string;
  summary?: string;
  features?: string[];
  monthlyPrice?: number;
  yearlyPrice?: number;
  promo?: string;
}

export interface SubscriptionChangeReason {
  reason: DowngradeReasonType;
  otherReason?: string;
}

export class ChangeSubscriptionPlanDto {
  changeReason?: SubscriptionChangeReason;
  retainAddons?: AddOnType[];
  targetProjectTier!: ProjectTiersType;
  targetProjectPeriod!: ProjectPeriods;
}

export interface IEstimationResult {
  amountDue: number;
  total: number;
  discountTotal: number;
  dueDate: Date | null;
  discounts: IEstimationDiscount[];
}

export class IEstimationDiscount {
  type!: EstimationDiscountItemType;
  label!: string;
  amount!: number;
}

export enum EstimationDiscountItemEnum {
  REFERRAL = 'referral',
  PROMOTIONAL = 'promotional'
}

export type EstimationDiscountItemType = `${EstimationDiscountItemEnum}`;

export class ISubscriptionSchedule {
  amountDue: number;
  projectPeriod: ProjectPeriods;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  scheduleAt?: Date;
  projectTier: ProjectTiers;
}

export class IAddOnResultNode {
  id: string;
  slug: string;
  type: AddOnType;
  attached_at: string;
  attached_at_cursor: string;
  remove_at: string;
  remove_at_cursor: string;
  created_at: string;
  created_at_cursor: string;
}

export interface IAddOnsResultEdge {
  cursor: string;
  offset: number;
  node: IAddOnResultNode;
}

export class IAddOnsResult {
  pageInfo!: PageInfo;
  edges?: IAddOnsResultEdge[];
}

export interface CardValidation {
  type: CardBrandEnum;
  patterns: number[];
  mask: any;
  format: RegExp;
  length: number[];
  cvvLength: number[];
  luhn: boolean;
}

export enum CardBrandEnum {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMERICANEXPRESS = 'AMERICANEXPRESS',
  DISCOVER = 'DISCOVER',
  DINERSCLUB = 'DINERSCLUB',
  JCB = 'JCB',
  MAESTRO = 'MAESTRO',
  UNIONPAY = 'UNIONPAY',
  DANKORT = 'DANKORT',
  FORBRUGSFORENINGEN = 'FORBRUGSFORENINGEN'
}

export interface CardValidation {
  type: CardBrandEnum;
  patterns: number[];
  mask: any;
  format: RegExp;
  length: number[];
  cvvLength: number[];
  luhn: boolean;
}

export interface IUsage {
  label: string;
  qty: number;
}

export interface IUsageSummary {
  sms?: IUsage;
  inboundSms?: IUsage;
  outboundSms?: IUsage;
  mms?: IUsage;
  internationalSms?: IUsage;
}

export type Funnel =
  | 'a'
  | 'c'
  | 'b'
  | 'p'
  | 'i'
  | 'r'
  | 'w'
  | 'cc'
  | 'ao'
  | 's'
  | 'ps'
  | 'cc';

export type FunnelPromo = {
  funnel: Funnel;
  code: string;
  caption: string;
  title: string;
  value: string;
};

export type FunnelMap = {
  [key in Funnel]: string;
};

export type FunnelPromoMap = {
  [key in Funnel]: FunnelPromo;
};
