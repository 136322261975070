import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyIconButtonComponent } from '../components/molecules/norby-icon-button/norby-icon-button.component';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyIconButtonComponent],
  imports: [CommonModule, NorbyIconModule],
  exports: [NorbyIconButtonComponent]
})
export class NorbyIconButtonModule {}
