<div class="w-full" [ngClass]="{ 'mb-3': label }">
  <norby-formcontrol-label
    [label]="label"
    [infoTooltip]="infoTooltip"
    [isRequired]="isRequired"
  ></norby-formcontrol-label>

  <div
    class="flex w-full text-base bg-clip-padding transition ease-in-out m-0 leading-5"
    [ngClass]="{
      'border norby-input bg-white border-neutral-300 user-theme-input':
        !isNestedButton,
      'hover:border-neutral-500': !isNestedButton && !isDisabled,
      'rounded-l-none rounded-r-sm user-theme-right-input': !!isJoinedLeft,
      'rounded-r-none rounded-l-sm user-theme-left-input': !!isJoinedRight,
      'rounded-sm': !isJoinedLeft && !isJoinedRight,
      'h-12 px-3 pb-4 pt-3.5': size === 'medium',
      'h-9 px-2 pb-3 pt-3': size === 'small',
      'bg-disabled-input-background': isDisabled,
      'border-red-ui': touched && hasErrors
    }"
  >
    <div class="flex flex-1 items-center relative">
      <ng-content></ng-content>

      <input
        #input
        class="w-full text-base transition ease-in-out outline-none bg-inherit text-neutral-800 user-theme-input-text"
        [ngClass]="{
          'pr-7': (touched && hasErrors) || iconName || isLoading
        }"
        [type]="type"
        [value]="isTextHidden ? '' : val ?? value ?? ''"
        [disabled]="isDisabled"
        [required]="isRequired"
        [readonly]="isReadOnly"
        (input)="handleInput($event.target)"
        [attr.maxlength]="maxLength"
        [placeholder]="placeholder ? placeholder : ''"
        (blur)="handleOnBlur($event)"
        (focus)="handleOnFocus()"
        (keydown.enter)="handleOnEnter($event)"
      />
      <div class="absolute ml-2 top-1/2 transform -translate-y-1/2 right-0">
        <norby-icon
          *ngIf="hasErrors || isLoading || iconName"
          [name]="isLoading ? 'loader' : iconName || 'alert-octagon'"
          [ngClass]="{
            'animate-spin': isLoading,
            'text-red-base': hasErrors,
            'text-neutral-500': iconName && !hasErrors
          }"
          [rootTooltip]="errorText"
        ></norby-icon>
      </div>
    </div>
  </div>

  <div *ngIf="!isNestedButton && !hasErrors" class="flex justify-between">
    <norby-helpertext [helperText]="helperText"></norby-helpertext>
    <span *ngIf="!!maxLength" class="text-xs text-neutral-700"
      >{{ (val && val.length) || (value && value.length) || 0 }}/{{
        maxLength
      }}</span
    >
  </div>
</div>
