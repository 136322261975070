import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventMainButtonViewComponent } from '../views/event-main-button-view';
import { ButtonModule } from './button.module';
import { DropdownModule } from './dropdown.module';
import { IconModule } from './icon.module';
import { LogEventDirectiveModule } from './log-event-directive.module';

@NgModule({
  declarations: [EventMainButtonViewComponent],
  imports: [
    CommonModule,
    ButtonModule,
    IconModule,
    DropdownModule,
    LogEventDirectiveModule
  ],
  exports: [EventMainButtonViewComponent]
})
export class EventMainButtonViewModule {}
