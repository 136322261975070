import {
  ContentPageBlock,
  HeaderPageBlock,
  ImagePageBlock,
  InstagramPageBlock,
  EmbedPageBlock,
  NewsletterSignupPageBlock,
  PageBlock,
  ProfilePageBlock,
  RichTextPageBlock,
  SocialIconsPageBlock,
  SpacerPageBlock,
  TwitterPageBlock,
  UpcomingEventsPageBlock,
  VimeoPageBlock,
  YoutubePageBlock,
  SpotifyPageBlock,
  CalendlyPageBlock
} from './blocks';

export function isContentBlock(x: PageBlock): x is ContentPageBlock {
  return x.blockType === 'content';
}

export function isLinkBlock(x: PageBlock): x is ContentPageBlock {
  return isContentBlock(x) && x.content?.contentType === 'link';
}

export function isEventBlock(x: PageBlock): x is ContentPageBlock {
  return isContentBlock(x) && x.content?.contentType === 'event';
}

export function isSignupBlock(x: PageBlock): x is ContentPageBlock {
  return isContentBlock(x) && x.content?.contentType === 'drop';
}

export function isNewsletterSignupBlock(
  x: PageBlock
): x is NewsletterSignupPageBlock {
  return x.blockType === 'newsletterSignup';
}

export function isUpcomingEventsBlock(
  x: PageBlock
): x is UpcomingEventsPageBlock {
  return x.blockType === 'upcomingEvents';
}

export function isProfileBlock(x: PageBlock): x is ProfilePageBlock {
  return x.blockType === 'profile';
}

export function isHeaderBlock(x: PageBlock): x is HeaderPageBlock {
  return x.blockType === 'header';
}

export function isYoutubeBlock(x: PageBlock): x is YoutubePageBlock {
  return x.blockType === 'youtube';
}

export function isSpotifyBlock(x: PageBlock): x is SpotifyPageBlock {
  return x.blockType === 'spotify';
}

export function isCalendlyBlock(x: PageBlock): x is CalendlyPageBlock {
  return x.blockType === 'calendly';
}

export function isVimeoBlock(x: PageBlock): x is VimeoPageBlock {
  return x.blockType === 'vimeo';
}

export function isTwitterBlock(x: PageBlock): x is TwitterPageBlock {
  return x.blockType === 'twitter';
}

export function isInstagramBlock(x: PageBlock): x is InstagramPageBlock {
  return x.blockType === 'instagram';
}

export function isEmbedBlock(x: PageBlock): x is EmbedPageBlock {
  return x.blockType === 'embed';
}

export function isTextBlock(x: PageBlock): x is RichTextPageBlock {
  return x.blockType === 'text';
}

export function isImageBlock(x: PageBlock): x is ImagePageBlock {
  return x.blockType === 'image';
}

export function isSpacerBlock(x: PageBlock): x is SpacerPageBlock {
  return x.blockType === 'spacer';
}

export function isSocialIconsBlock(x: PageBlock): x is SocialIconsPageBlock {
  return x.blockType === 'socialIcons';
}
