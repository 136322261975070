import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SocialIconsViewComponent } from '../views/social-icons-view';
import { ButtonModule } from './button.module';
import { IconModule } from './icon.module';
import { TooltipModule } from './tooltip.module';

@NgModule({
  declarations: [SocialIconsViewComponent],
  imports: [CommonModule, ButtonModule, TooltipModule, IconModule],
  exports: [SocialIconsViewComponent]
})
export class SocialIconsViewModule {}
