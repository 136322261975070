import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlockOption } from 'models';

@Component({
  selector: 'norby-flyout-options-group',
  templateUrl: './norby-flyout-options-group.component.html',
  styleUrls: ['./norby-flyout-options-group.component.less']
})
export class NorbyFlyoutOptionsGroupComponent {
  @Input() items: BlockOption[];
  @Input() title: string;
  @Output() onOptionClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  handleOptionClicked(option: string) {
    this.onOptionClicked.emit(option);
  }
}
