import {
  Directive,
  ViewChild,
  Component,
  inject,
  ViewContainerRef,
  ComponentRef
} from '@angular/core';

// 3rd party
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha-2';
import { firstValueFrom, Subject } from 'rxjs';

// Lib
import { BaseComponent } from './base-component';

@Directive()
export abstract class CaptchaComponent extends BaseComponent {
  private _viewContainer = inject(ViewContainerRef);
  componentRef: ComponentRef<_CaptchaComponent>;

  ngOnInit() {
    this._initializeCaptcha();
  }

  private _initializeCaptcha() {
    this.componentRef =
      this._viewContainer.createComponent<_CaptchaComponent>(_CaptchaComponent);
  }

  executeCaptcha(): Promise<string> {
    this.componentRef.instance.captchaRef.execute();
    const res = firstValueFrom(this.componentRef.instance.resolved$);
    this._initializeCaptcha();
    return res;
  }
}

@Component({
  standalone: true,
  imports: [RecaptchaModule],
  template: `
    <re-captcha
      size="invisible"
      #captchaRef
      (resolved)="handleResolved($event)"
    >
    </re-captcha>
  `
})
class _CaptchaComponent {
  @ViewChild('captchaRef', { static: true }) captchaRef: RecaptchaComponent;

  private _resolved$ = new Subject<string>();
  resolved$ = this._resolved$.asObservable();

  handleResolved(event) {
    this._resolved$.next(event);
  }
}
