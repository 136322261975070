import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { buttonClicked } from '../../../animations';

@Component({
  selector: 'norby-switch-button',
  templateUrl: './norby-switch-button.component.html',
  styleUrls: ['./norby-switch-button.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbySwitchButtonComponent),
      multi: true
    }
  ],
  animations: [buttonClicked]
})
export class NorbySwitchButtonComponent
  implements ControlValueAccessor, OnInit
{
  @Input() value?: boolean;
  @Input() label?: string;
  @Input() isDisabled?: boolean = false;
  @Input() rightLabel?: string;
  @Input() helperText?: string = '';
  @Input() infoTooltip?: string;

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  animationState: boolean = false;
  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  val = false;

  constructor() {}

  ngOnInit(): void {
    this.val = this.value;
  }

  writeValue(value: boolean) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  handleValueChange(value: boolean) {
    if (value !== this.val) {
      this.animationState = !this.animationState;
      this.val = value;
      this.valueChange.emit(value);
      this._onChanged(value);
      this._markAsTouched();
    }
  }
}
