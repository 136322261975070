import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

export declare type safeAny = any;

@Directive({
  selector: '[stringTemplateOutlet]',
  exportAs: 'stringTemplateOutlet'
})
export class StringTemplateOutletDirective<_T = unknown> implements OnChanges {
  private _embeddedViewRef: EmbeddedViewRef<safeAny> | null = null;
  private _context = new TemplateOutletContext();
  @Input() stringTemplateOutletContext: safeAny | null = null;
  @Input() stringTemplateOutlet: safeAny | TemplateRef<safeAny> = null;

  constructor(
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<safeAny>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { stringTemplateOutletContext, stringTemplateOutlet } = changes;
    const shouldRecreateView = (): boolean => {
      let shouldOutletRecreate = false;
      if (stringTemplateOutlet) {
        if (stringTemplateOutlet.firstChange) {
          shouldOutletRecreate = true;
        } else {
          const isPreviousOutletTemplate =
            stringTemplateOutlet.previousValue instanceof TemplateRef;
          const isCurrentOutletTemplate =
            stringTemplateOutlet.currentValue instanceof TemplateRef;
          shouldOutletRecreate =
            isPreviousOutletTemplate || isCurrentOutletTemplate;
        }
      }
      const hasContextShapeChanged = (ctxChange: SimpleChange): boolean => {
        const prevCtxKeys = Object.keys(ctxChange.previousValue || {});
        const currCtxKeys = Object.keys(ctxChange.currentValue || {});
        if (prevCtxKeys.length === currCtxKeys.length) {
          for (const propName of currCtxKeys) {
            if (prevCtxKeys.indexOf(propName) === -1) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      };
      const shouldContextRecreate =
        stringTemplateOutletContext &&
        hasContextShapeChanged(stringTemplateOutletContext);
      return shouldContextRecreate || shouldOutletRecreate;
    };

    if (stringTemplateOutlet) {
      this._context.$implicit = stringTemplateOutlet.currentValue;
    }

    const recreateView = shouldRecreateView();
    if (recreateView) {
      /** recreate view when context shape or outlet change **/
      this._recreateView();
    } else {
      /** update context **/
      this._updateContext();
    }
  }

  private _recreateView(): void {
    this._viewContainer.clear();
    const isTemplateRef = this.stringTemplateOutlet instanceof TemplateRef;
    const templateRef = (
      isTemplateRef ? this.stringTemplateOutlet : this._templateRef
    ) as safeAny;
    this._embeddedViewRef = this._viewContainer.createEmbeddedView(
      templateRef,
      isTemplateRef ? this.stringTemplateOutletContext : this._context
    );
  }

  private _updateContext(): void {
    const isTemplateRef = this.stringTemplateOutlet instanceof TemplateRef;
    const newCtx = isTemplateRef
      ? this.stringTemplateOutletContext
      : this._context;
    const oldCtx = this._embeddedViewRef!.context as safeAny;
    if (newCtx) {
      for (const propName of Object.keys(newCtx)) {
        oldCtx[propName] = newCtx[propName];
      }
    }
  }
}

export class TemplateOutletContext {
  public $implicit: safeAny;
}
