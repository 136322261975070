export const PRIVACY_URL = 'https://www.withnorby.com/legal/privacy';
export const TERMS_URL = 'https://www.withnorby.com/legal/terms';
export const TERMS_SERVICE_URL =
  'https://www.withnorby.com/legal/terms-of-service';
export const FAQ_URL = 'https://help.withnorby.com/';
export const REFERRAL_URL = 'https://help.withnorby.com/norby-referral-program';
export const HOME_URL = 'https://www.withnorby.com';
export const CHAT_URL = 'https://www.withnorby.com/chat';
export const BUILT_WITH_NORBY_URL =
  'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/builtwithnorbyhorizontal.png?alt=media&token=bdc87543-1750-4bdb-bbb9-be8649e09295';
