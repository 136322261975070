<div
  class="inline-block py-2.5 px-4 bg-white rounded shadow-ant-default user-theme-snackbar"
  [@slideAnimation]="{
    value: animationState,
    params: { slideIn: animation.slideIn, slideOut: animation.slideOut }
  }"
  (@slideAnimation.done)="onSlideFinished($event)"
>
  <div class="flex items-center">
    <div *ngIf="iconType === 'success'" class="text-green-ui">
      <root-icon name="check_circle"></root-icon>
    </div>
    <div *ngIf="iconType === 'error'" class="text-red-base">
      <root-icon name="x_circle"></root-icon>
    </div>
    <div>{{ displayData.text }}</div>
  </div>
</div>
