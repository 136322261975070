<div class="mb-6 text-center">
  <ng-container *ngIf="iconTpl">
    <ng-container *stringTemplateOutlet="iconTpl"> </ng-container>
  </ng-container>
  <ng-container>
    <ng-content *ngIf="!iconTpl" select="[result-icon]"></ng-content>
  </ng-container>
</div>
<ng-container *ngIf="titleTpl">
  <div *stringTemplateOutlet="titleTpl">
    {{ titleTpl }}
  </div>
</ng-container>
<ng-content *ngIf="!titleTpl" select="[result-title]"></ng-content>
<ng-container *ngIf="subtitleTpl">
  <div *stringTemplateOutlet="subtitleTpl">
    {{ subtitleTpl }}
  </div>
</ng-container>
<ng-content *ngIf="!subtitleTpl" select="[result-subtitle]"></ng-content>
<ng-content select="[result-content]"></ng-content>
<div *ngIf="extraTpl">
  <ng-container *stringTemplateOutlet="extraTpl">
    {{ extraTpl }}
  </ng-container>
</div>
<ng-content *ngIf="!extraTpl" select="[result-extra]"></ng-content>
