import { ContentSignupMetadata } from '../types';

export const compareSignupsMetadata = (
  ...[signupOneMetadata, signupTwoMetadata]: ContentSignupMetadata[]
): boolean => {
  if (!signupOneMetadata && !signupTwoMetadata) {
    return true;
  } else if (!signupOneMetadata || !signupTwoMetadata) {
    return false;
  }

  signupOneMetadata =
    signupOneMetadata instanceof ContentSignupMetadata
      ? signupOneMetadata
      : ContentSignupMetadata.fromObject(signupOneMetadata);

  signupTwoMetadata =
    signupTwoMetadata instanceof ContentSignupMetadata
      ? signupTwoMetadata
      : ContentSignupMetadata.fromObject(signupTwoMetadata);

  return (
    (signupOneMetadata as ContentSignupMetadata).fullHash ===
    (signupTwoMetadata as ContentSignupMetadata).fullHash
  );
};
