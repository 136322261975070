import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ButtonModule,
  SpinModule,
  FormModule,
  NorbyInputModule,
  NorbySelectModule
} from 'uikit';
import { PaymentFormComponent } from '../components/payment-form';

@NgModule({
  declarations: [PaymentFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NorbyInputModule,
    NorbySelectModule,
    FormModule,
    SpinModule,
    ButtonModule
  ],
  exports: [PaymentFormComponent]
})
export class PaymentFormModule {}
