import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyHeaderStyleComponent } from '../components/molecules/norby-header-style';
import { NorbyImageModule } from './norby-image.module';

@NgModule({
  declarations: [NorbyHeaderStyleComponent],
  imports: [CommonModule, NorbyImageModule],
  exports: [NorbyHeaderStyleComponent]
})
export class NorbyHeaderStyleModule {}
