import {
  ValidationErrors,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import {
  ALPHA_SENDER_REGEX,
  VALID_COUNTRY_CODES,
  ALPHANUMERIC
} from '../constants';
import { UrlValidator } from './url.validator';

export const isPhoneNumberValid = (
  phoneNumber: string,
  countryCode: string
): ValidationErrors | null => {
  const adjustedNumber = phoneNumber.replace(/[^0-9]+/g, '');
  const regex = VALID_COUNTRY_CODES[countryCode]?.regex;
  return !!adjustedNumber?.match(regex) ? null : { invalidNumber: true };
};

export const isAlphaSenderValid = (
  alphaSender: string
): ValidationErrors | null => {
  if (!alphaSender) return null;
  return !!alphaSender?.match(ALPHA_SENDER_REGEX)
    ? null
    : { invalidAlphaSender: true };
};

// form validator to make sure empty string or string of spaces/tabs was not entered
export function noWhitespaceValidator(control: UntypedFormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

// form validator to make sure string is a valid slug
// - Does not begin with a number
// - Does not contain spacial characters except _ and -
export function isValidSlugValidator(control: UntypedFormControl) {
  const re = /^[a-z0-9]([a-z0-9-]*[a-z0-9])*$/;
  const isValid = re.test(control.value?.trim());
  return isValid ? null : { invalidSlug: true };
}

export function isRequiredValidator(predicate: () => boolean) {
  return (formControl: UntypedFormControl) => {
    return !formControl.parent || !predicate()
      ? null
      : Validators.required(formControl);
  };
}

export function isUrlValidator(predicate: () => boolean) {
  return (formControl: UntypedFormControl) => {
    return !formControl.parent || !predicate()
      ? null
      : UrlValidator(formControl);
  };
}

export const isPaddingValid = (
  control1: { name: string; minValue: number },
  control2: { name: string; minValue: number }
): ValidationErrors | null => {
  return (formControl: UntypedFormControl) => {
    const firstControl = formControl.parent?.controls[control1.name];
    const secondControl = formControl.parent?.controls[control2.name];
    return !(
      firstControl?.value < control1.minValue &&
      secondControl?.value < control2.minValue
    )
      ? null
      : { invalidPadding: true };
  };
};

export function isAlphanumericValidator(control: UntypedFormControl) {
  const validEntry = ALPHANUMERIC.test(control?.value);
  return validEntry ? null : { invalidText: true };
}
