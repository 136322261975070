import dayjs from 'dayjs';
import { EVENT_TIME_INTERVAL_MINUTES } from 'models';

export type TimeSet = {
  defaultTime?: string;
  validTimes?: string[];
};

export const generateTimes = (): TimeSet => {
  const now = dayjs();
  const startingDate = now.date();
  const validTimes = [];

  let defaultTime;
  let time = now.startOf('day');

  while (time.date() === startingDate) {
    const nextTime = time.format('h:mm a');
    if (defaultTime == null && time.isAfter(now)) defaultTime = nextTime;
    validTimes.push(nextTime);
    time = time.add(EVENT_TIME_INTERVAL_MINUTES, 'minutes');
  }

  defaultTime = defaultTime ?? validTimes[validTimes.length - 1];
  return { defaultTime, validTimes };
};
