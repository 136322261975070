import { Injectable } from '@angular/core';

// 3rd party
import { defaultIfEmpty, filter, map, switchMap } from 'rxjs/operators';
import { Observable, combineLatest, from } from 'rxjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

// Lib
import { DeviceService } from '../device';
import { ApiService } from '../api';
import { PaymentIntentEstimateType, PaymentIntentType } from './types';
import { ErrorService } from 'uikit';
import {
  Content,
  ENDPOINTS,
  ApiSurfaces,
  ContentEvent,
  IContentService,
  isEmailEventBlock,
  isEmailSignupBlock,
  SingleSend,
  IContentPublicListingResults,
  IQueryResult,
  EventsFilterArgs,
  IContentPrivateListingResults,
  PaginatedQuerySummary,
  objectIdFromDate,
  ConversationMessage,
  IConversationMessage
} from 'models';
import {
  ObservableDataService,
  RealtimeSocketEventHandler
} from '../observable-data';
import {
  CONTENT_PUBLIC_UPDATED,
  RealtimeServerSocketMessage,
  CONTENT_PUBLIC_DELETED,
  CONTENT_PUBLIC_CREATED_IN_SLUG,
  CONTENT_PUBLIC_UPDATED_IN_SLUG,
  CONTENT_PUBLIC_DELETED_IN_SLUG
} from '../socket';
import { eventSortFn } from './util';

/*
  Content retrieval
*/

@Injectable({
  providedIn: 'root'
})
export class ContentService implements IContentService {
  constructor(
    protected _error: ErrorService,
    protected _api: ApiService,
    protected _device: DeviceService,
    protected _ods: ObservableDataService
  ) {}

  getContent$<T extends Content>(contentId: string): Observable<T> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: CONTENT_PUBLIC_UPDATED,
        payload: {
          resourceId: contentId
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          Content.fromObject(event?.data)
      },
      {
        event: CONTENT_PUBLIC_DELETED,
        payload: {
          resourceId: contentId
        },
        transformer: (event: RealtimeServerSocketMessage) => null
      }
    ];

    return this._ods
      .document$<T>({
        handlers,
        lookup: this.getContent,
        args: contentId,
        isPublic: true
      })
      .pipe(
        map((summary) => summary?.data),
        filter((content, index) => !!(content || index))
      );
  }

  getContent = async <T extends Content>(id: string): Promise<T> => {
    if (!id?.length) {
      return null as T;
    }

    try {
      const ret = await this._api.get<T>(
        ApiSurfaces.END_USER,
        `v2/content/${id}`
      );
      return Content.fromObject(ret) as T;
    } catch (e) {
      this._error?.displayError?.(e);
      throw e;
    }
  };

  getEvents = async (
    args: EventsFilterArgs
  ): Promise<IContentPublicListingResults | IContentPrivateListingResults> => {
    // Default to showing all future events including today's
    if (args.after === undefined) {
      args.after = objectIdFromDate(dayjs().startOf('day').toDate());
    }

    if (args.orderBy === undefined) {
      args.orderBy = 'startDate';
    }

    try {
      const ret = await this._api.get<IContentPublicListingResults>(
        ApiSurfaces.END_USER,
        'v2/event',
        args
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getEventsForCurrentSlug$(
    args: EventsFilterArgs
  ): Observable<PaginatedQuerySummary<ContentEvent>> {
    // Function used to fetch events
    const lookup = this.getEvents;

    // Function used to transform lookup results and
    // incorporate them into the stream
    const transformer = (res: IQueryResult, currentValue: ContentEvent[]) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const events =
        res?.edges?.map((edge) => ContentEvent.fromObject(edge?.node)) ?? [];

      return {
        items: [...(currentValue ?? []), ...events],
        cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
      };
    };

    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: CONTENT_PUBLIC_CREATED_IN_SLUG,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          message: RealtimeServerSocketMessage,
          currentValue: ContentEvent[]
        ) => {
          if (message?.data?.contentType !== 'event' || !currentValue) {
            return currentValue;
          }

          const event = ContentEvent.fromObject(message.data);
          const cursorKey =
            args.orderBy === 'modifiedAt'
              ? 'modifiedAtCursor'
              : 'startDateCursor';
          const isEventPublishStatusCorrect = !!event.published;
          const isEventStartAfterCorrect =
            !args.after ||
            (args.sort === 'desc'
              ? event[cursorKey] < args.after
              : event[cursorKey] > args.after);

          if (!isEventPublishStatusCorrect || !isEventStartAfterCorrect) {
            return currentValue;
          }

          currentValue.push(event);
          currentValue.sort(eventSortFn(args));
          return [...currentValue];
        }
      },
      {
        event: CONTENT_PUBLIC_UPDATED_IN_SLUG,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          message: RealtimeServerSocketMessage,
          currentValue: ContentEvent[]
        ) => {
          if (message?.data?.contentType !== 'event' || !currentValue) {
            return currentValue;
          }

          const updatedEvent = ContentEvent.fromObject(message.data);
          const currentIdx = currentValue.findIndex(
            (node) => node?.contentId === updatedEvent?.contentId
          );

          const currentEvent =
            currentIdx > -1 ? currentValue[currentIdx] : null;
          const hasExistingEvent = !!currentEvent;
          const publishedStateChanged =
            currentEvent?.published !== updatedEvent.published;
          const updatedEventMatchesFilters = !!updatedEvent.published;

          // If we have an existing event and the published state did not change
          // just update it in place
          if (
            hasExistingEvent &&
            !publishedStateChanged &&
            updatedEventMatchesFilters
          ) {
            currentValue[currentIdx] = updatedEvent;
          }

          // If we have an existing event and the published state changed
          // and it no longer matches the filter criteria, remove it
          else if (
            hasExistingEvent &&
            publishedStateChanged &&
            !updatedEventMatchesFilters
          ) {
            currentValue.splice(currentIdx, 1);
          }

          // If we don't have an existing event but the updated event
          // does match the filter criteria, add it and sort
          else if (!hasExistingEvent && updatedEventMatchesFilters) {
            currentValue.push(updatedEvent);
            currentValue.sort(eventSortFn(args));
          }

          // If no changes were made, no need to copy currentValue
          else {
            return currentValue;
          }

          return [...currentValue];
        }
      },
      {
        event: CONTENT_PUBLIC_DELETED_IN_SLUG,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          message: RealtimeServerSocketMessage,
          currentValue: ContentEvent[]
        ) => {
          if (message?.data?.contentType !== 'event' || !currentValue) {
            return currentValue;
          }

          const event = ContentEvent.fromObject(message.data);
          const currentIdx = currentValue.findIndex(
            (node) => node?.contentId === event?.contentId
          );

          if (currentIdx > -1) {
            currentValue.splice(currentIdx, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ];

    return this._ods.query$<ContentEvent>({
      args,
      lookup,
      transformer,
      handlers
    });
  }

  async getPaymentIntentSecret(
    eventId: string,
    label: string,
    qty: number,
    promoCode: string
  ): Promise<PaymentIntentType> {
    try {
      const endpoint = `${ENDPOINTS.event}/${eventId}/payment_intent`;
      const body = { applyTax: true, label, qty, promoCode };
      const ret = await this._api.post<PaymentIntentType>(
        ApiSurfaces.END_USER,
        endpoint,
        body
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getPaymentIntentEstimate(
    eventId: string,
    label: string,
    qty: number,
    promoCode: string
  ): Promise<PaymentIntentEstimateType> {
    try {
      const endpoint = `${ENDPOINTS.event}/${eventId}/payment_intent/estimate`;
      const body = { applyTax: true, label, qty, promoCode };
      const ret = await this._api.post<PaymentIntentEstimateType>(
        ApiSurfaces.END_USER,
        endpoint,
        body
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  getConversationMessage = async ({
    id
  }: {
    id: string;
  }): Promise<ConversationMessage> => {
    try {
      const ret = await this._api.get<IConversationMessage>(
        ApiSurfaces.END_USER,
        `ai/message/${id}`
      );
      return ret ? ConversationMessage.fromObject(ret) : null;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getConversationMessage$(id: string): Observable<ConversationMessage> {
    const handlers: RealtimeSocketEventHandler[] = [];

    return this._ods
      .document$<ConversationMessage>({
        handlers,
        lookup: this.getConversationMessage,
        args: { id },
        isPublic: true
      })
      .pipe(
        map((summary) => summary?.data),
        filter((content, index) => !!(content || index))
      );
  }

  getWebHostedSendById = async ({
    id
  }: {
    id: string;
  }): Promise<SingleSend> => {
    try {
      const ret = await this._api.get<SingleSend>(
        ApiSurfaces.END_USER,
        ENDPOINTS.singlesend + `/webhosted/${id}`
      );
      return SingleSend.fromObject(ret);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  addContentToSend = async (send: SingleSend) =>
    send
      ? Promise.all(
          (send?.blocks ?? []).reduce(
            (acc, block) =>
              isEmailEventBlock(block) || isEmailSignupBlock(block)
                ? [...acc, this.getContent(block.contentId)]
                : acc,
            [] as Promise<Content>[]
          )
        ).then((content) => send.addContentArrayToMap(content))
      : null;

  getWebHostedSendById$ = ({ id }: { id: string }) =>
    this.addContentToSend$(from(this.getWebHostedSendById({ id })));

  addContentToSend$ = (send$: Observable<SingleSend>) =>
    send$.pipe(
      switchMap((send) =>
        combineLatest(
          (send?.blocks ?? []).reduce(
            (acc, block) =>
              isEmailEventBlock(block) || isEmailSignupBlock(block)
                ? [...acc, this.getContent$(block.contentId)]
                : acc,
            [] as Observable<Content>[]
          )
        ).pipe(
          defaultIfEmpty([]),
          map((blocks) => send?.addContentArrayToMap(blocks))
        )
      )
    );
}
