import { RolesEnum } from './general';

export type IRoles = {
  [key in RolesEnum]?: boolean;
};

export interface IUserRoles {
  [slug: string]: IRoles;
}

export interface IUserContext {
  admin?: boolean;
  editor?: boolean;
  developer?: boolean;
  roles?: IUserRoles;
}

export interface IUserMetadata {
  userId?: string;
  phoneNumber?: string;
  email?: string;
  emailVerified?: boolean;
  verifiedEmails?: string[];
}

export class IUserPublicMetadata {
  displayName?: string;
  photoURL?: string;
}

export class UserPublicMetadata extends IUserPublicMetadata {
  get firstName() {
    return this.displayName?.split(' ')?.[0];
  }
}
