<form [formGroup]="dtFormGroup">
  <div class="flex flex-1 items-end gap-4">
    <norby-date-input
      [label]="label"
      [infoTooltip]="infoTooltip"
      class="flex-1"
      [dateFormat]="dateFormat"
      formControlName="date"
      placeholder="Choose date"
      [isDisabled]="isDisabled"
    ></norby-date-input>
    <norby-time-picker
      class="flex-1"
      formControlName="time"
      [isDisabled]="isDisabled"
    ></norby-time-picker>
  </div>
  <norby-helpertext
    [errorText]="errorText"
    [helperText]="helperText"
  ></norby-helpertext>
</form>
