<div class="steps-content">
  <form [formGroup]="formGroup" (ngSubmit)="submitTicketSelection()">
    <h2 *ngIf="content?.tickets?.length > 1; rootFormItem">Choose a ticket</h2>
    <h2 *ngIf="content?.tickets?.length == 1" rootFormItem>
      Confirm your purchase
    </h2>

    <!--    <nz-form-item>
      <nz-select
        nzSize="large"
        formControlName="ticket"
        (nzOpenChange)="$event ? null : handlePriceRecalculate.emit()"
      >
        <nz-option
          *ngFor="let ticket of content?.tickets"
          [nzLabel]="
            ticket?.statementDescription + ' - $' + ticket?.price / 100
          "
          [nzValue]="ticket?.label"
        >
        </nz-option>
      </nz-select>
    </nz-form-item>-->

    <div rootFormItem>
      <div rootList>
        <div rootListItem>
          {{ content?.tickets?.[0]?.label ?? 'Ticket' }}
          <norby-select
            *ngIf="content?.tickets?.[0]?.maxQty > 1"
            label="Quantity"
            class="w-14"
            formControlName="qty"
          >
            <option
              *ngFor="let item of maxNumOfTickets; let i = index"
              [value]="i + 1"
            >
              {{ i + 1 }}
            </option>
          </norby-select>
        </div>
        <div rootListItem>
          <span>Subtotal</span>
          {{ subtotal / 100 | currency }}
        </div>

        <div rootListItem>
          <span class="flex items-center"
            >Stripe Processing Fee
            <root-icon
              name="help_circle"
              rootTooltip="Checkout secured with Stripe"
              [themeClasses]="themeClasses"
            ></root-icon>
          </span>
          {{ fees / 100 | currency }}
        </div>
        <div rootListItem *ngIf="addedPromoCode">
          <span>Promo Applied</span>
          {{ addedPromoCode }}
        </div>

        <div rootListItem class="checkout-total">
          <span>Total</span>
          {{ total / 100 | currency }}
        </div>
      </div>
    </div>

    <div rootFormItem>
      <div rootInputGroup>
        <norby-input
          type="text"
          formControlName="promoCode"
          placeholder="Promo code"
          [isJoinedRight]="true"
        ></norby-input>

        <div rootInputGroupSuffix>
          <root-button
            type="primary"
            [adjacentInput]="true"
            size="large"
            [disabled]="!hasEnteredCode"
            [loading]="isLoading"
            text="Apply"
            formSubmit="button"
            (handleClick)="handleApplyPromoCode()"
          >
          </root-button>
        </div>
      </div>
    </div>

    <div rootFormItem>
      <root-button
        type="primary"
        size="large"
        [block]="true"
        [disabled]="!formGroup.valid"
        [loading]="isLoading"
        text="Next"
      >
      </root-button>
    </div>
  </form>
</div>
